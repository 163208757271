import { Icon as Fe, Button as ut, Table as vi, Dialog as Bo, Input as Nt, Divider as yi, Tree as Vn, Loading as Io, ListItem as bi, List as _i, TagInput as Do, Textarea as Vo, Select as an, TreeSelect as An, Radio as Fn, RadioButton as Tn, Checkbox as pt, Switch as On, DatePicker as Pn, MessagePlugin as vn, Upload as wi, FormItem as ja, Option as Ln, RadioGroup as Ao, CheckboxGroup as Fo, InputNumber as To, RangeInput as Ci, TimePicker as Ei, Slider as ki, ColorPickerPanel as Ni, Space as Si, Form as qa, TabPanel as zn, Tabs as Rn, EnhancedTable as Oo, Card as xi, Dropdown as Po, ImageViewer as $i, Transfer as Mi, Steps as Bi, MenuItem as Ii, Submenu as Di, Menu as Vi, HeadMenu as Ai, Link as Fi, Tag as Ti, Popup as Oi, Drawer as Pi, Image as Li } from "tdesign-vue-next";
import { defineComponent as ne, openBlock as m, createBlock as R, normalizeStyle as pe, useSlots as Je, ref as ae, toRefs as Ge, reactive as Re, watch as ie, computed as me, onMounted as De, createElementBlock as U, createElementVNode as V, toDisplayString as se, createCommentVNode as j, renderSlot as te, unref as x, isRef as Hn, createSlots as Te, withCtx as I, createTextVNode as Ce, createVNode as K, renderList as be, pushScopeId as ht, popScopeId as mt, Fragment as ve, resolveDynamicComponent as it, normalizeProps as St, guardReactiveProps as zt, useCssVars as It, normalizeClass as Pe, mergeProps as Dt, shallowRef as _t, onUnmounted as Vt, withAsyncContext as zi, provide as rt, inject as Qe, withDirectives as Xt, vModelText as Wt, resolveComponent as ft, h as Ne, getCurrentScope as Ja, effectScope as Qa, toRef as Le, onBeforeUnmount as Lo, nextTick as je, onScopeDispose as yn, getCurrentInstance as Un, toValue as ke, onBeforeMount as Ri, createPropsRestProxy as el, useAttrs as Hi, customRef as Ui, markRaw as Tt, readonly as Gi } from "vue";
import { MoveIcon as Zi, ChevronRightIcon as Yi, ChevronDownIcon as Ki, manifest as Xi, BrowseIcon as Wi } from "tdesign-icons-vue-next";
const En = ne({
  __name: "MyIcon",
  props: {
    name: {},
    color: {},
    style: {},
    size: {}
  },
  setup(e) {
    return (t, n) => {
      const a = Fe;
      return m(), R(a, {
        name: t.name,
        size: t.size,
        style: pe(t.style),
        color: t.color
      }, null, 8, ["name", "size", "style", "color"]);
    };
  }
}), xe = (e, t) => {
  const n = e.__vccOpts || e;
  for (const [a, o] of t)
    n[a] = o;
  return n;
}, tl = (e) => (ht("data-v-6886ec62"), e = e(), mt(), e), ji = { style: { width: "100%", height: "100%" } }, qi = { style: { width: "100%", height: "100%" } }, Ji = { class: "inline-flex" }, Qi = /* @__PURE__ */ tl(() => /* @__PURE__ */ V("span", { style: { "padding-left": "15px", "font-size": "14px", "font-family": "'微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif", "font-weight": "700", color: "#666666" } }, "是否确定上传数据？", -1)), er = /* @__PURE__ */ tl(() => /* @__PURE__ */ V("p", { style: { "padding-left": "43px", "font-size": "12px", "font-family": "'微软雅黑', sans-serif", "font-weight": "400", color: "#999999" } }, " 此操作将无法撤销，请谨慎操作", -1)), tr = ne({
  __name: "MyTable",
  props: {
    title: {
      //标题
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "left"
    },
    data: {
      //表格数据
      type: Array,
      default: () => []
    },
    columns: {
      //表头数据
      type: Array,
      default: () => []
    },
    rowKey: {
      //行key
      type: String,
      default: "key"
    },
    pagination: {
      //分页  为true时给个默认的样式
      type: [Boolean, Object],
      default: !1
    },
    maxHeight: {
      //表格最大高度，超过滚动
      type: [String, Number],
      default: ""
    },
    height: {
      //表格最大高度，超过滚动
      type: [String, Number],
      default: ""
    },
    tableLayout: {
      //表格布局方式  auto/fixed
      type: String,
      default: "fixed"
    },
    action: {
      //操作
      type: Object,
      default: () => {
      }
    },
    colSlot: {
      type: Array,
      defalt: () => []
    },
    empty: {
      type: String,
      defalt: "暂无数据"
    },
    filterValue: {
      type: [null, Object],
      defalt: null
    },
    loading: {
      type: Boolean,
      default: !1
    },
    fixedRows: {
      type: Array,
      default: () => [0, 0]
      // [M, N]，表示冻结表头 M 行和表尾 N 行。M 和 N 值为 0 时，表示不冻结行
    },
    showBorder: {
      type: Boolean,
      default: !1
      // 显示表格边框
    },
    size: {
      type: String,
      default: "medium"
      //表格尺寸 可选项：small/medium/large
    },
    filterRow: {
      type: [String, Function],
      default: null
    },
    footData: {
      type: Array,
      default: () => []
      //表尾数据  [{cokKey: value,...},...]
    },
    span: {
      type: [Function, void 0],
      default: void 0
      //用于自定义合并单元格 示例：({ row, col, rowIndex, colIndex }) => { rowspan: 2, colspan: 3 }
    },
    spanInFooter: {
      type: [Function, void 0],
      default: void 0
      //用于合并表尾单元格 示例：({ row, col, rowIndex, colIndex }) => { rowspan: 2, colspan: 3 } 
    },
    boxBorder: {
      type: [Boolean, String],
      default: !1
      //包裹表格的div是否显示边框
    },
    expandIconShow: {
      type: [Boolean, String],
      default: !1
      //是否显示展开图标，支持插槽expandIcon
    },
    expandOnRowClick: {
      type: Boolean,
      default: !1
      //是否允许点击行展开
    },
    defaultSelectedRowKeys: {
      // 默认选中的行数
      type: Array,
      default: () => []
    },
    // 增加分页改变侦听属性，在tsx中使用
    onPageChange: {
      type: Function
    }
  },
  emits: ["multiSelect", "currentRow", "rowUpdata", "rowDelete", "onPageChange", "filterChange"],
  setup(e, { emit: t }) {
    const n = e, a = Je(), o = t, l = ae(), i = ae();
    let { defaultSelectedRowKeys: r } = Ge(n);
    const u = Re({
      // mydata: props.data, //表格数据
      currentRow: {},
      //行数据
      selectedRowKeys: n.defaultSelectedRowKeys || [],
      //多选
      action: Object.assign({
        updata: {
          show: !1
        },
        delete: {
          show: !1
        }
      }, n.action),
      //操作
      visible: !1,
      myTableHeight: "100%"
    });
    ie(r, (_, C) => {
      u.selectedRowKeys = _;
    });
    const s = Re({
      theme: "simple",
      current: 1,
      pageSize: 5,
      total: n.data.length,
      showFirstAndLastPageBtn: !0
    }), d = n.pagination === !0 ? s : me(() => n.pagination || void 0);
    n.pagination instanceof Object && Object.keys(n.pagination).forEach((_) => {
      s[_] = n.pagination[_];
    }), n.pagination === !0 ? ie(() => n.data.length, () => {
      s.total = n.data.length;
    }, { immediate: !0 }) : n.pagination instanceof Object && ie(() => n.pagination.total, () => {
      s.total = n.pagination.total;
    }, { immediate: !0 });
    const c = ae(), f = (_) => {
      u.currentRow = _, o("currentRow", _);
    }, h = (_, { selectedRowData: C }) => {
      console.log("value", _), u.selectedRowKeys = _, o(
        "multiSelect",
        n.data.filter((G) => u.selectedRowKeys.includes(G[n.rowKey]))
      );
    }, y = (_) => {
      o("rowUpdata", _);
    }, b = (_) => {
      u.visible = !0, u.currentRow = _;
    }, p = (_) => {
      s.current && (s.current = _.current, s.pageSize = _.pageSize), n.onPageChange && n.onPageChange(_), o("onPageChange", _);
    }, w = (_) => {
      const { e: C } = _;
      u.visible = !1, C.stopPropagation(), o("rowDelete", u.currentRow);
    }, k = () => {
      u.visible = !1;
    }, E = (_) => {
      o("filterChange", _);
    };
    return De(() => {
      u.myTableHeight = `calc(100%${l.value ? ` - ${l.value.clientHeight}px` : ""}${i.value ? ` - ${i.value.clientHeight}px` : ""})`;
    }), (_, C) => {
      const G = ut, B = Fe, $ = vi, M = Bo;
      return m(), U("div", ji, [
        V("div", qi, [
          l.value ? (m(), U("div", {
            key: 0,
            class: "title",
            ref_key: "title",
            ref: l
          }, se(l.value), 513)) : j("", !0),
          _.$slots.topContent ? (m(), U("div", {
            key: 1,
            class: "top-content",
            ref_key: "topContent",
            ref: i
          }, [
            te(_.$slots, "topContent", {}, void 0, !0)
          ], 512)) : j("", !0),
          V("div", {
            class: "my-table",
            style: pe({ border: `${e.boxBorder === !0 && !e.showBorder ? "1px solid #dcdcdc" : e.showBorder}`, height: u.myTableHeight })
          }, [
            e.data ? (m(), R($, {
              key: 0,
              bordered: e.showBorder,
              style: { "font-size": "0.75rem" },
              ref_key: "table",
              ref: c,
              "row-key": e.rowKey,
              data: e.data,
              columns: e.columns,
              align: e.align,
              height: typeof e.height === void 0 || e.height === "" ? `calc(100%${e.pagination ? " - 64px" : ""})` : e.height,
              maxHeight: e.maxHeight,
              pagination: x(d),
              "onUpdate:pagination": C[0] || (C[0] = (A) => Hn(d) ? d.value = A : null),
              onPageChange: p,
              onRowClick: f,
              tableLayout: e.tableLayout,
              empty: n.empty,
              filterValue: e.filterValue,
              "selected-row-keys": u.selectedRowKeys,
              "onUpdate:selectedRowKeys": C[1] || (C[1] = (A) => u.selectedRowKeys = A),
              onSelectChange: h,
              onFilterChange: E,
              loading: e.loading,
              fixedRows: e.fixedRows,
              size: e.size,
              "filter-row": e.filterRow,
              "foot-data": e.footData,
              "rowspan-and-colspan-in-footer": e.spanInFooter,
              rowspanAndColspan: e.span,
              expandIcon: e.expandIconShow,
              expandOnRowClick: e.expandOnRowClick
            }, Te({
              operate: I((A) => [
                V("div", Ji, [
                  u.action.updata.show ? (m(), R(G, {
                    key: 0,
                    size: "small",
                    variant: "text",
                    theme: "primary",
                    onClick: (S) => y(A)
                  }, {
                    default: I(() => [
                      Ce(" 编辑 ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])) : j("", !0),
                  u.action.delete.show ? (m(), R(G, {
                    key: 1,
                    size: "small",
                    variant: "text",
                    theme: "primary",
                    onClick: (S) => b(A)
                  }, {
                    default: I(() => [
                      Ce(" 删除 ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])) : j("", !0),
                  te(_.$slots, "operate", { rowValue: A }, void 0, !0)
                ])
              ]),
              filterIcon: I(() => [
                K(En, { name: "filter" })
              ]),
              _: 2
            }, [
              be(n.colSlot, (A) => ({
                name: A,
                fn: I(({ row: S }) => [
                  te(_.$slots, A, {
                    colRow: S[A],
                    row: S
                  }, void 0, !0)
                ])
              })),
              e.expandIconShow ? {
                name: "expandIcon",
                fn: I(() => [
                  te(_.$slots, "expandIcon", {}, () => [
                    K(B, { name: "caret-right-small" })
                  ], !0)
                ]),
                key: "0"
              } : void 0,
              x(a).expandedRow ? {
                name: "expandedRow",
                fn: I(({ row: A }) => [
                  te(_.$slots, "expandedRow", { row: A }, void 0, !0)
                ]),
                key: "1"
              } : void 0
            ]), 1032, ["bordered", "row-key", "data", "columns", "align", "height", "maxHeight", "pagination", "tableLayout", "empty", "filterValue", "selected-row-keys", "loading", "fixedRows", "size", "filter-row", "foot-data", "rowspan-and-colspan-in-footer", "rowspanAndColspan", "expandIcon", "expandOnRowClick"])) : j("", !0)
          ], 4)
        ]),
        K(M, {
          visible: u.visible,
          "onUpdate:visible": C[2] || (C[2] = (A) => u.visible = A),
          header: "操作确认",
          "on-close": k,
          onConfirm: w
        }, {
          body: I(() => [
            V("div", null, [
              K(B, {
                size: "2em",
                name: "help-circle",
                color: "orange"
              }),
              Qi,
              er
            ])
          ]),
          _: 1
        }, 8, ["visible"])
      ]);
    };
  }
}), nr = /* @__PURE__ */ xe(tr, [["__scopeId", "data-v-6886ec62"]]), or = { style: { width: "100%", height: "100%" } }, ar = {
  key: 0,
  style: { width: "100%", height: "3.75rem", display: "flex", "align-items": "center", "font-weight": "700", padding: "0 1.25rem", "font-size": "0.85rem" }
}, lr = { class: "my-tree-divider" }, ir = { class: "tdesign-tree-base" }, rr = ne({
  __name: "MyTree",
  props: {
    title: {},
    searchShow: { type: Boolean },
    data: {},
    value: {},
    groundFloor: {},
    activable: { type: Boolean, default: !0 },
    expandOnClickNode: { type: Boolean, default: !0 },
    showSlot: { type: Boolean },
    level: {},
    keys: { default: { label: "label", value: "value", children: "children" } },
    expandAll: { type: Boolean, default: !1 },
    actived: {},
    loading: { type: Boolean, default: !1 },
    treeStyle: {},
    filter: {},
    line: { type: Boolean, default: !1 },
    expandMutex: { type: Boolean, default: !1 },
    checkable: { type: Boolean, default: !1 },
    draggable: { type: Boolean, default: !1 },
    prefixIcons: {},
    scroll: {}
  },
  emits: ["treeNode", "treeActive", "initial", "dragStart", "handleDragStart", "dragEnd", "dragOver", "dragLeave", "drop", "activeChanged"],
  setup(e, { emit: t }) {
    const n = e, a = ae(n.actived || []), o = t, l = ae(), i = ae(), r = ae("0px"), u = ae([]), s = Re({
      title: n.title,
      searchShow: n.searchShow,
      filterText: "",
      //搜索时输出的文本
      // 树形数据
      mydata: n.data,
      groundFloor: n.groundFloor || "",
      expandAll: n.expandAll,
      filter: n.filter,
      expanded: []
    }), d = ae();
    ie(() => n.data, () => {
      n.data.length && (s.mydata = n.data, u.value = JSON.parse(JSON.stringify(n.data)));
    }, {
      immediate: !0
    }), ie(() => s.filterText, () => {
      s.filterText ? s.filter || (s.filter = (E) => E.data[n.keys.label].indexOf(s.filterText) >= 0) : (s.mydata = u.value, s.filter = null);
    });
    const c = (E) => {
      s.filter || (s.filter = (_) => _.data[n.keys.label].indexOf(E) >= 0);
    };
    ie(() => n.actived, () => {
      n.actived !== void 0 && (a.value = n.actived);
    }, { immediate: !0 });
    const f = (E) => {
      o("activeChanged", [E.node.value]), o("treeNode", E.node);
    }, h = (E, _) => {
      E[0] === void 0 && (E = a.value), a.value = E, o("treeActive", { value: E, context: _ });
    };
    De(() => {
      var _, C;
      r.value = `${(((_ = l == null ? void 0 : l.value) == null ? void 0 : _.clientHeight) || 0) + (((C = i == null ? void 0 : i.value) == null ? void 0 : C.clientHeight) || 0)}px`;
      const E = ie(() => n.data, () => {
        n.data && n.data.length > 0 && (o("initial", [...n.data]), setTimeout(() => {
          E();
        }));
      }, { deep: !0, immediate: !0 });
    });
    const y = (E) => {
      o("dragStart", E);
    }, b = (E) => {
      o("dragEnd", E);
    }, p = (E) => {
      o("dragOver", E);
    }, w = (E) => {
      o("dragLeave", E);
    }, k = (E) => {
      o("drop", E);
    };
    return (E, _) => {
      const C = Fe, G = ut, B = Nt, $ = yi, M = Vn, A = Io;
      return m(), U("div", or, [
        s.title || s.searchShow ? (m(), U("div", {
          key: 0,
          ref_key: "searchDom",
          ref: l,
          style: { display: "flex", "justify-content": "center", "flex-flow": "column", width: "100%", height: "auto" }
        }, [
          s.title ? (m(), U("div", ar, se(s.title), 1)) : j("", !0),
          s.searchShow ? (m(), R(B, {
            key: 1,
            class: "my-tree-search-input-container",
            placeholder: "",
            modelValue: s.filterText,
            "onUpdate:modelValue": _[1] || (_[1] = (S) => s.filterText = S)
          }, {
            suffix: I(() => [
              K(G, {
                style: { padding: "0 10px" },
                variant: "text",
                onClick: _[0] || (_[0] = (S) => c(s.filterText))
              }, {
                default: I(() => [
                  K(C, { name: "search" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])) : j("", !0)
        ], 512)) : j("", !0),
        V("div", {
          ref_key: "topContent",
          ref: i
        }, [
          te(E.$slots, "topContent", {}, () => [
            V("div", lr, [
              K($, { style: { margin: "0px" } })
            ])
          ], !0)
        ], 512),
        K(A, {
          loading: E.loading,
          style: pe(Object.assign({ height: `calc(100% - ${r.value})`, overflow: "auto", width: "100%" }, E.treeStyle))
        }, {
          default: I(() => [
            V("div", ir, [
              K(M, {
                ref_key: "tree",
                ref: d,
                data: s.mydata,
                activable: E.activable,
                hover: "",
                transition: "",
                actived: a.value,
                "expand-on-click-node": !1,
                onClick: f,
                "expand-level": E.level,
                keys: E.keys,
                expandAll: s.expandAll,
                onActive: h,
                filter: s.filter,
                line: E.line,
                expandMutex: E.expandMutex,
                checkable: E.checkable,
                draggable: E.draggable,
                onDragStart: y,
                onDragEnd: b,
                onDragOver: p,
                onDragLeave: w,
                onDrop: k,
                scroll: E.scroll
              }, Te({
                label: I(({ node: S }) => [
                  te(E.$slots, "label", { node: S }, () => [
                    S.data && S.data.icon !== void 0 ? (m(), U(ve, { key: 0 }, [
                      typeof S.data.icon == "string" ? (m(), R(C, {
                        key: 0,
                        name: S.data.icon,
                        class: "my-tree-prefix-icon"
                      }, null, 8, ["name"])) : (m(), R(it(S.data.icon), {
                        key: 1,
                        class: "my-tree-prefix-icon"
                      }))
                    ], 64)) : Array.isArray(E.prefixIcons) && E.prefixIcons[S.getLevel()] !== void 0 ? (m(), U(ve, { key: 1 }, [
                      typeof E.prefixIcons[S.getLevel()] == "string" ? (m(), R(C, {
                        key: 0,
                        name: E.prefixIcons[S.getLevel()],
                        class: "my-tree-prefix-icon"
                      }, null, 8, ["name"])) : (m(), R(it(E.prefixIcons[S.getLevel()]), {
                        key: 1,
                        class: "my-tree-prefix-icon"
                      }))
                    ], 64)) : j("", !0),
                    V("span", null, se(S.label), 1)
                  ], !0)
                ]),
                _: 2
              }, [
                s.groundFloor ? {
                  name: "icon",
                  fn: I(({ node: S }) => [
                    !S.expanded && S.getParents().length != s.groundFloor ? (m(), R(C, {
                      key: 0,
                      name: "chevron-right"
                    })) : j("", !0),
                    !S.getChildren() && S.expanded && S.getParents().length != s.groundFloor ? (m(), R(C, {
                      key: 1,
                      name: "chevron-right"
                    })) : S.getChildren() && S.expanded ? (m(), R(C, {
                      key: 2,
                      name: "chevron-down"
                    })) : S.getParents().length == s.groundFloor && S.expanded ? (m(), R(C, {
                      key: 3,
                      name: "arrow-right"
                    })) : S.getParents().length == s.groundFloor && !S.expanded ? (m(), R(C, {
                      key: 4,
                      name: "arrow-right"
                    })) : j("", !0)
                  ]),
                  key: "0"
                } : void 0,
                E.showSlot ? {
                  name: "icon",
                  fn: I(({ node: S }) => [
                    te(E.$slots, "icon", { node: S }, void 0, !0)
                  ]),
                  key: "1"
                } : void 0,
                be(E.$slots, (S, Z) => ({
                  name: Z,
                  fn: I((z) => [
                    te(E.$slots, Z, St(zt(z)), void 0, !0)
                  ])
                }))
              ]), 1032, ["data", "activable", "actived", "expand-level", "keys", "expandAll", "filter", "line", "expandMutex", "checkable", "draggable", "scroll"])
            ])
          ]),
          _: 3
        }, 8, ["loading", "style"])
      ]);
    };
  }
}), sr = /* @__PURE__ */ xe(rr, [["__scopeId", "data-v-ba129e2e"]]), ur = ne({
  __name: "MyLoading",
  props: {
    loading: { type: Boolean, default: !0 },
    size: { default: "medium" },
    text: { default: "加载中..." },
    indicator: { type: Boolean, default: !1 }
  },
  setup(e) {
    return (t, n) => {
      const a = Io;
      return m(), R(a, {
        class: "my-loading",
        loading: t.loading,
        text: t.text,
        size: t.size,
        indicator: t.indicator
      }, {
        default: I(() => [
          te(t.$slots, "default", {}, void 0, !0)
        ]),
        _: 3
      }, 8, ["loading", "text", "size", "indicator"]);
    };
  }
}), dr = /* @__PURE__ */ xe(ur, [["__scopeId", "data-v-26584d3c"]]), zo = ne({
  __name: "MyButton",
  props: {
    theme: { default: "primary" },
    variant: { default: "base" },
    loading: { type: Boolean },
    disabled: { type: Boolean, default: !1 },
    size: { default: "medium" },
    shape: { default: "rectangle" }
  },
  setup(e) {
    return Je(), (t, n) => {
      const a = ut;
      return m(), R(a, {
        theme: t.theme,
        variant: t.variant,
        size: t.size,
        shape: t.shape,
        disabled: t.disabled,
        loading: t.loading
      }, {
        suffix: I(() => [
          te(t.$slots, "suffix")
        ]),
        default: I(() => [
          te(t.$slots, "default")
        ]),
        _: 3
      }, 8, ["theme", "variant", "size", "shape", "disabled", "loading"]);
    };
  }
}), cr = ne({
  __name: "MyInput",
  props: {
    value: {},
    placeholder: { default: "请输入" },
    disabled: { type: Boolean, default: !1 },
    type: { default: "text" },
    size: { default: "medium" },
    suffix: {},
    suffixIcon: {},
    clearable: { type: Boolean, default: !1 }
  },
  emits: ["update:value", "change", "blur", "suffixIconClick"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    Je();
    const o = ae(a.value);
    ie(
      () => a.value,
      (u) => {
        o.value = u;
      }
    );
    const l = (u) => {
      n("update:value", u), n("change", { value: u });
    }, i = (u) => {
      n("update:value", u), n("blur", { value: u });
    }, r = () => {
      n("suffixIconClick", { value: o.value });
    };
    return (u, s) => {
      const d = Nt;
      return m(), R(d, {
        modelValue: o.value,
        "onUpdate:modelValue": s[0] || (s[0] = (c) => o.value = c),
        placeholder: u.placeholder,
        disabled: u.disabled,
        type: u.type,
        size: u.size,
        suffix: u.suffix,
        clearable: u.clearable,
        onChange: l,
        onBlur: i,
        class: "my-input-suffix-icon"
      }, {
        suffixIcon: I(() => [
          u.suffixIcon ? (m(), R(zo, {
            key: 0,
            theme: "default",
            variant: "text",
            onClick: r
          }, {
            default: I(() => [
              K(En, { name: u.suffixIcon }, null, 8, ["name"])
            ]),
            _: 1
          })) : te(u.$slots, "suffixIcon", { key: 1 }, void 0, !0)
        ]),
        _: 3
      }, 8, ["modelValue", "placeholder", "disabled", "type", "size", "suffix", "clearable"]);
    };
  }
}), fr = /* @__PURE__ */ xe(cr, [["__scopeId", "data-v-2a8c3d14"]]), pr = {
  key: 1,
  class: "my-list-header-title"
}, hr = { class: "truncate min-w-0 my-list-header-title-text" }, mr = { class: "truncate min-w-0" }, gr = ne({
  __name: "MyList",
  props: {
    loading: { type: Boolean, default: !1 },
    data: {},
    prefixIcon: {},
    activable: { type: Boolean },
    actived: {},
    search: { type: [Boolean, String], default: !1 },
    hover: { type: Boolean, default: !0 },
    title: {}
  },
  emits: ["click", "update:actived", "active", "search"],
  setup(e, { emit: t }) {
    It((s) => ({
      "1316240b": i.innerHeight
    }));
    const n = e, a = t;
    Je();
    const o = ae(n.actived || ""), l = ae(), i = Re({
      innerHeight: "100%"
    }), r = (s) => {
      n.activable && (o.value = s.value, a("update:actived", o.value), a("active", { value: s })), a("click", { value: s });
    }, u = (s, d) => {
      a("search", { value: s.value, triggerType: d });
    };
    return De(() => {
      i.innerHeight = l.value ? `calc(100% - ${l.value.parentNode.clientHeight}px)` : "100%";
    }), (s, d) => {
      const c = bi, f = _i;
      return m(), R(dr, { loading: s.loading }, {
        default: I(() => [
          K(f, { class: "my-list" }, Te({
            default: I(() => [
              s.data && s.data.length > 0 ? (m(!0), U(ve, { key: 0 }, be(s.data, (h, y) => (m(), R(c, {
                key: `scroll${y}`,
                onClick: (b) => r(h),
                class: Pe(`${h.value === o.value ? "t-list-item-actived" : ""}  ${s.hover ? "t-list-item-hover" : ""}`)
              }, Te({
                content: I(() => [
                  s.prefixIcon ? (m(), R(En, {
                    key: 0,
                    class: "my-list-item-prefixIcon",
                    name: s.prefixIcon
                  }, null, 8, ["name"])) : j("", !0),
                  V("div", mr, se(h.name), 1)
                ]),
                _: 2
              }, [
                s.$slots.action ? {
                  name: "action",
                  fn: I(() => [
                    te(s.$slots, "action", { item: h }, void 0, !0)
                  ]),
                  key: "0"
                } : void 0
              ]), 1032, ["onClick", "class"]))), 128)) : (m(), U(ve, { key: 1 }, [
                Ce(" 暂无数据 ")
              ], 64))
            ]),
            _: 2
          }, [
            s.search || s.title || s.$slots.header ? {
              name: "header",
              fn: I(() => [
                V("div", {
                  ref_key: "headerDom",
                  ref: l,
                  class: "w-full"
                }, [
                  te(s.$slots, "header", {}, () => [
                    s.search ? (m(), R(fr, {
                      key: 0,
                      onChange: d[0] || (d[0] = (h) => {
                        u(h, "change");
                      }),
                      onSuffixIconClick: d[1] || (d[1] = (h) => {
                        u(h, "click");
                      }),
                      placeholder: typeof s.search == "string" ? s.search : "请输入",
                      "suffix-icon": "search"
                    }, null, 8, ["placeholder"])) : j("", !0),
                    s.title ? (m(), U("div", pr, [
                      K(En, {
                        class: "shrink-0",
                        size: "large",
                        name: "command"
                      }),
                      V("div", hr, se(s.title), 1)
                    ])) : j("", !0)
                  ], !0)
                ], 512)
              ]),
              key: "0"
            } : void 0
          ]), 1024)
        ]),
        _: 3
      }, 8, ["loading"]);
    };
  }
}), Tg = /* @__PURE__ */ xe(gr, [["__scopeId", "data-v-fe911f0c"]]), Og = ne({
  __name: "MyDialog",
  props: {
    visible: { type: Boolean },
    placement: { default: "center" },
    top: {},
    header: { type: [String, Boolean], default: "" },
    body: {},
    theme: { default: "default" },
    showFooter: { type: Boolean, default: !1 },
    width: { default: "40%" },
    confirmBtn: { default: "确认" },
    cancelBtn: { default: "取消" },
    draggable: { type: Boolean, default: !1 },
    mode: { default: "modal" },
    showOverlay: { type: Boolean, default: !0 },
    attach: { default: "" },
    destroyOnClose: { type: Boolean, default: !1 }
  },
  emits: ["update:visible", "cancel", "confirm", "close"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    let { visible: o, header: l, body: i, confirmBtn: r, cancelBtn: u, width: s, showFooter: d, placement: c, theme: f } = Ge(a);
    const h = () => {
      n("update:visible", !1), n("close");
    }, y = () => {
      n("cancel");
    }, b = () => {
      n("confirm");
    };
    return (p, w) => {
      const k = Bo;
      return m(), U("div", null, [
        K(k, {
          visible: x(o),
          header: x(l),
          body: x(i),
          footer: x(d),
          cancelBtn: x(u),
          confirmBtn: x(r),
          theme: x(f),
          placement: x(c),
          width: x(s),
          onClose: h,
          onCancel: y,
          mode: p.mode,
          draggable: p.draggable,
          showOverlay: p.showOverlay,
          attach: p.attach,
          destroyOnClose: p.destroyOnClose,
          onConfirm: b
        }, Te({
          body: I(() => [
            te(p.$slots, "default")
          ]),
          _: 2
        }, [
          p.$slots.footer ? {
            name: "footer",
            fn: I(() => [
              te(p.$slots, "footer")
            ]),
            key: "0"
          } : void 0
        ]), 1032, ["visible", "header", "body", "footer", "cancelBtn", "confirmBtn", "theme", "placement", "width", "mode", "draggable", "showOverlay", "attach", "destroyOnClose"])
      ]);
    };
  }
}), vr = (e) => (ht("data-v-2d1426e1"), e = e(), mt(), e), yr = { style: { "margin-top": "12px" } }, br = { style: { "align-items": "center", "justify-content": "center", display: "flex" } }, _r = /* @__PURE__ */ vr(() => /* @__PURE__ */ V("span", { style: { "margin-left": "2px" } }, "继续添加", -1)), wr = ne({
  __name: "MyTableForm",
  props: {
    title: {
      //标题
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "center"
    },
    data: {
      //表格数据
      type: Array,
      default: () => []
    },
    columns: {
      //表头数据
      type: Array,
      default: () => []
    },
    rowKey: {
      //行key
      type: String,
      default: "key"
    },
    pagination: {
      //分页  为true时给个默认的样式
      type: [Boolean, Object],
      default: !1
    },
    maxHeight: {
      //表格最大高度，超过滚动
      type: [String, Number],
      default: ""
    },
    height: {
      //表格最大高度，超过滚动
      type: [String, Number],
      default: ""
    },
    tableLayout: {
      //表格布局方式  auto/fixed
      type: String,
      default: "fixed"
    },
    action: {
      //操作
      type: Object,
      default: () => {
      }
    },
    colSlot: {
      type: Array,
      defalt: () => []
    },
    empty: {
      type: String,
      defalt: "暂无数据"
    },
    filterValue: {
      type: [null, Object],
      defalt: null
    },
    loading: {
      type: Boolean,
      default: !1
    },
    fixedRows: {
      type: Array,
      default: () => [0, 0]
      // [M, N]，表示冻结表头 M 行和表尾 N 行。M 和 N 值为 0 时，表示不冻结行
    },
    showBorder: {
      type: Boolean,
      default: !0
      // 显示表格边框
    },
    size: {
      type: String,
      default: "small"
      //表格尺寸 可选项：small/medium/large
    },
    filterRow: {
      type: [String, Function],
      default: null
    },
    footData: {
      type: Array,
      default: () => []
      //表尾数据  [{cokKey: value,...},...]
    },
    span: {
      type: [Function, void 0],
      default: void 0
      //用于自定义合并单元格 示例：({ row, col, rowIndex, colIndex }) => { rowspan: 2, colspan: 3 }
    },
    spanInFooter: {
      type: [Function, void 0],
      default: void 0
      //用于合并表尾单元格 示例：({ row, col, rowIndex, colIndex }) => { rowspan: 2, colspan: 3 } 
    },
    boxBorder: {
      type: [Boolean, String],
      default: !1
      //包裹表格的div是否显示边框
    },
    expandIconShow: {
      type: [Boolean, String],
      default: !1
      //是否显示展开图标，支持插槽expandIcon
    },
    expandOnRowClick: {
      type: Boolean,
      default: !1
      //是否允许点击行展开
    },
    defaultSelectedRowKeys: {
      // 默认选中的行数
      type: Array,
      default: () => []
    },
    // 增加分页改变侦听属性，在tsx中使用
    onPageChange: {
      type: Function
    }
  },
  emits: ["add", "remove", "change"],
  setup(e, { emit: t }) {
    const n = {
      input: Nt,
      tagInput: Do,
      text: Vo,
      select: an,
      treeSelect: An,
      radio: Fn,
      radioBtn: Tn,
      checkbox: pt,
      "checkbox-single": pt,
      number: Number,
      switch: On,
      date: Pn
    }, a = e, o = t, l = me(() => a.columns.map((u) => {
      let s = u;
      return u.colKey != "operate" && (s = {
        ...u,
        edit: {
          // @ts-ignore
          component: typeof u.editComponent == "object" || typeof u.editComponent == "function" ? u.editComponent : n[u.editComponent] || Nt,
          defaultEditable: !0,
          keepEditMode: !0,
          abortEditOnEvent: ["onBlur"],
          validateTrigger: "change",
          onEdited: (d) => {
            o("change", d);
          },
          rules: u.rule
        }
      }), s;
    })), i = (u) => {
      o("remove", u);
    }, r = () => {
      o("add");
    };
    return (u, s) => {
      const d = Fe, c = ut;
      return m(), U("div", null, [
        K(nr, Dt({ class: "mytableform" }, a, { columns: l.value }), Te({
          operate: I((f) => [
            K(c, {
              theme: "default",
              variant: "text",
              onClick: (h) => i(f)
            }, {
              default: I(() => [
                K(d, {
                  size: "16",
                  name: "close-circle"
                })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, [
          be(u.$slots, (f, h) => ({
            name: h,
            fn: I((y) => [
              te(u.$slots, h, St(zt(y)), void 0, !0)
            ])
          }))
        ]), 1040, ["columns"]),
        V("div", yr, [
          te(u.$slots, "footer", {}, () => [
            K(c, {
              theme: "primary",
              variant: "outline",
              size: "small",
              onClick: r
            }, {
              default: I(() => [
                V("div", br, [
                  K(d, { name: "add" }),
                  _r
                ])
              ]),
              _: 1
            })
          ], !0)
        ])
      ]);
    };
  }
}), Cr = /* @__PURE__ */ xe(wr, [["__scopeId", "data-v-2d1426e1"]]), Er = ne({
  __name: "MyUpload",
  props: {
    accept: {},
    autoUpload: { type: Boolean, default: !0 },
    action: { default: "" },
    formatRequest: {},
    formatResponse: {},
    theme: { default: "file" },
    placeholder: {},
    value: {},
    tips: {},
    showUploadProgress: { type: Boolean, default: !0 },
    multiple: { type: Boolean, default: !1 },
    max: { default: 0 },
    beforeUpload: {},
    requestMethod: { type: [Boolean, Function] },
    beforeAllFilesUpload: {},
    maxSize: {},
    minSize: {}
  },
  emits: ["remove", "success", "update:value", "selectChange"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value || []);
    ie(
      () => a.value,
      (c) => {
        o.value = c || [];
      }
    );
    const l = (c) => {
      n("remove", { context: c });
    }, i = (c) => {
      var f;
      if (a.accept) {
        const h = a.accept.split(","), y = (f = c.name) == null ? void 0 : f.replace(/.+\./, ".");
        let b = !1;
        if (h.some((p) => (b = new RegExp(p).test(c.type || "") || y === p, b)), !b)
          return vn.error(`文件格式错误: ${c.name}`), !1;
      }
      if (c.size) {
        if (typeof a.maxSize == "number" && a.maxSize * 1024 * 1024 < c.size)
          return vn.error(`文件大小大于最大允许的上传大小: ${c.name}`), !1;
        if (typeof a.minSize == "number" && a.minSize * 1024 * 1024 > c.size)
          return vn.error(`文件大小小于最小允许的上传大小: ${c.name}`), !1;
      }
      return a.beforeUpload ? a.beforeUpload(c) : !0;
    }, r = (c) => a.beforeAllFilesUpload ? a.beforeAllFilesUpload(c) : !0;
    a.requestMethod && (a.requestMethod === !0 || typeof a.requestMethod == "function" && a.requestMethod);
    const u = (c) => {
      n("success", { context: c }), o.value = c.fileList || [];
    };
    ie(o, () => {
      n("update:value", o.value);
    });
    const s = ae(), d = (c) => {
      n("selectChange", c);
    };
    return (c, f) => {
      const h = wi;
      return m(), R(h, Dt({
        ref_key: "upload",
        ref: s,
        modelValue: o.value,
        "onUpdate:modelValue": f[0] || (f[0] = (y) => o.value = y),
        onRemove: l,
        onSuccess: u
      }, a, {
        onSelectChange: d,
        beforeAllFilesUpload: r,
        beforeUpload: i
      }), Te({ _: 2 }, [
        be(c.$slots, (y, b) => ({
          name: b,
          fn: I((p) => [
            te(c.$slots, b, St(zt(p)))
          ])
        }))
      ]), 1040, ["modelValue"]);
    };
  }
}), kr = ne({
  __name: "MyForm",
  props: {
    data: {},
    content: {},
    labelWidth: { default: 100 },
    showBtn: { type: Boolean, default: !0 },
    confirmBtn: { default: "提交" },
    cancelBtn: { default: "重置" },
    showConfirm: { type: Boolean, default: !0 },
    showCancel: { type: Boolean, default: !0 },
    formDisabled: { type: Boolean, default: !1 },
    colon: { type: Boolean, default: !1 },
    labelAlign: { default: "right" },
    onReset: {},
    onSubmit: {},
    layout: { default: "vertical" }
  },
  emits: ["submit", "reset", "change"],
  setup(e, { emit: t }) {
    const n = e, a = ae();
    let { data: o, content: l, showBtn: i, confirmBtn: r, cancelBtn: u, formDisabled: s } = Ge(n), d = null;
    ie(o, () => {
      d = Object.assign({}, o.value);
    });
    let c = {};
    const f = () => {
      for (const C in l.value) {
        const G = l.value[C];
        c = Object.assign(c, {
          [G.fieldName]: G.rule ? G.rule : [{ required: G.required, message: G.message, type: G.type }, { validator: (B) => G.validator ? G.validator(B, o.value) : { result: !0 } }]
        });
      }
    };
    ie(l, () => {
      f();
    }, { immediate: !0 });
    const h = t, y = (C) => {
      C.validateResult === !0 && h("submit", o);
    }, b = () => {
      h("reset", d);
    };
    let p = null;
    const w = (C) => {
      p || (p = {}, C.columns.map((B) => {
        B.colKey != "serial-number" && B.colKey != "operate" && B.colKey != "row-select" && (p[B.colKey] = "");
      }));
      const G = C.fieldName;
      o.value[G] = o.value[G] || [], o.value[G].push(p);
    }, k = (C, G) => {
      const B = G.fieldName;
      Array.isArray(o.value[B]) && o.value[G.fieldName].splice(C.rowValue.rowIndex, 1);
    }, E = (C, G) => {
      const B = G.fieldName;
      Array.isArray(o.value[B]) && (o.value[B][C.rowIndex] = C.newRowData);
    }, _ = (C, G) => {
      const B = G.fieldName;
      G.action || (C = C.map(($) => {
        var M;
        return {
          name: $.name,
          status: $.status,
          url: $.url ? $.url : G.fileUrl + ((M = $.response) == null ? void 0 : M.data[0].url),
          size: $.size,
          uploadTime: $.uploadTime
        };
      })), o.value[B] = C, h("change", { ...o.value, [B]: C });
    };
    return (C, G) => {
      const B = Nt, $ = ja, M = Do, A = Vo, S = Ln, Z = an, z = An, N = Ao, P = Tn, T = Fn, g = Fo, H = pt, F = To, W = Ci, oe = Pn, re = Ei, ye = On, Q = ki, q = Ni, Y = Si, fe = ut, ce = qa;
      return m(), U("div", null, [
        K(ce, {
          class: "myform",
          ref_key: "form",
          ref: a,
          layout: C.layout,
          data: x(o),
          labelAlign: C.labelAlign,
          rules: x(c),
          onReset: b,
          onSubmit: y,
          resetType: "initial",
          labelWidth: C.labelWidth || "100px",
          disabled: x(s),
          colon: C.colon
        }, {
          default: I(() => [
            (m(!0), U(ve, null, be(x(l), (v, ge) => (m(), U(ve, { key: ge }, [
              v.type == "input" ? (m(), R($, {
                key: 0,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({ width: v.width || "100%" })
                  }, [
                    K(B, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      placeholder: v.placeholder || "请输入内容",
                      disabled: v.disabled
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "password" ? (m(), R($, {
                key: 1,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(B, {
                      type: "password",
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      placeholder: v.placeholder || "请输入",
                      disabled: v.disabled
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "tagInput" ? (m(), R($, {
                key: 2,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(M, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      placeholder: v.placeholder || "请输入内容",
                      disabled: v.disabled
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "text" ? (m(), R($, {
                key: 3,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(A, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      placeholder: v.placeholder || "请输入内容",
                      disabled: v.disabled
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "select" ? (m(), R($, {
                key: 4,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    v.keys ? (m(), R(Z, {
                      key: 0,
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      placeholder: v.placeholder || "请选择",
                      disabled: v.disabled,
                      multiple: v.multiple,
                      clearable: v.clearable,
                      filterable: ""
                    }, {
                      default: I(() => [
                        (m(!0), U(ve, null, be(v.options, (X, we) => {
                          var Se, D;
                          return m(), R(S, {
                            key: we,
                            value: X[(Se = v.keys) == null ? void 0 : Se.value],
                            label: X[(D = v.keys) == null ? void 0 : D.label]
                          }, {
                            default: I(() => {
                              var O;
                              return [
                                Ce(se(X[(O = v.keys) == null ? void 0 : O.label]), 1)
                              ];
                            }),
                            _: 2
                          }, 1032, ["value", "label"]);
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled", "multiple", "clearable"])) : (m(), R(Z, {
                      key: 1,
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      class: "demo-select-base",
                      placeholder: v.placeholder || "请选择",
                      disabled: v.disabled,
                      multiple: v.multiple,
                      clearable: v.clearable
                    }, {
                      default: I(() => [
                        (m(!0), U(ve, null, be(v.options, (X, we) => (m(), R(S, {
                          key: we,
                          value: X.value,
                          label: X.label
                        }, {
                          default: I(() => [
                            Ce(se(X.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value", "label"]))), 128))
                      ]),
                      _: 2
                    }, 1032, ["onUpdate:modelValue", "modelValue", "placeholder", "disabled", "multiple", "clearable"]))
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "treeSelect" ? (m(), R($, {
                key: 5,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(z, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      data: v.options,
                      placeholder: v.placeholder || "请选择",
                      disabled: v.disabled
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "data", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "radio" ? (m(), R($, {
                key: 6,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(N, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      disabled: v.disabled,
                      options: v.options,
                      placeholder: v.placeholder || "请选择"
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "disabled", "options", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "radioBtn" ? (m(), R($, {
                key: 7,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(N, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      disabled: v.disabled,
                      placeholder: v.placeholder || "请选择"
                    }, {
                      default: I(() => [
                        !v.theme || v.theme == "button" ? (m(!0), U(ve, { key: 0 }, be(v.options, (X, we) => (m(), R(P, {
                          value: X.value
                        }, {
                          default: I(() => [
                            Ce(se(X.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))), 256)) : j("", !0),
                        v.theme == "circle" ? (m(!0), U(ve, { key: 1 }, be(v.options, (X, we) => (m(), R(T, {
                          value: X.value
                        }, {
                          default: I(() => [
                            Ce(se(X.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))), 256)) : j("", !0)
                      ]),
                      _: 2
                    }, 1032, ["onUpdate:modelValue", "modelValue", "disabled", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "checkbox" ? (m(), R($, {
                key: 8,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  K(g, {
                    "onUpdate:modelValue": (X) => {
                      x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                    },
                    modelValue: x(o)[v.fieldName],
                    disabled: v.disabled,
                    options: v.options,
                    placeholder: v.placeholder || "请选择"
                  }, null, 8, ["onUpdate:modelValue", "modelValue", "disabled", "options", "placeholder"])
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "checkbox-single" ? (m(), R($, {
                key: 9,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  K(H, {
                    "onUpdate:modelValue": (X) => {
                      x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                    },
                    modelValue: x(o)[v.fieldName],
                    disabled: v.disabled,
                    options: v.options
                  }, {
                    default: I(() => [
                      Ce(se(v.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["onUpdate:modelValue", "modelValue", "disabled", "options"])
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "number" ? (m(), R($, {
                key: 10,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    K(F, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      theme: v.theme || "row",
                      size: "medium",
                      min: v.min,
                      max: v.max,
                      disabled: v.disabled,
                      placeholder: v.placeholder || "请输入数字"
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "theme", "min", "max", "disabled", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "rangeInput" ? (m(), R($, {
                key: 11,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    K(W, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      disabled: v.disabled,
                      placeholder: v.placeholder || "请输入"
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "disabled", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "date" ? (m(), R($, {
                key: 12,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    K(oe, {
                      "onUpdate:modelValue": [(X) => C.$emit("change", { ...x(o), [v.fieldName]: X }), (X) => x(o)[v.fieldName] = X],
                      modelValue: x(o)[v.fieldName],
                      disabled: v.disabled,
                      enableTimePicker: v.enableTimePicker,
                      disableDate: v.disableDate,
                      format: v.format,
                      "allow-input": "",
                      placeholder: v.placeholder || "请选择日期",
                      clearable: ""
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "disabled", "enableTimePicker", "disableDate", "format", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "time" ? (m(), R($, {
                key: 13,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    K(re, {
                      "onUpdate:modelValue": [(X) => C.$emit("change", { ...x(o), [v.fieldName]: X }), (X) => x(o)[v.fieldName] = X],
                      modelValue: x(o)[v.fieldName],
                      disabled: v.disabled,
                      format: v.format,
                      "allow-input": "",
                      placeholder: v.placeholder || "请选择时间",
                      clearable: ""
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "disabled", "format", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "switch" ? (m(), R($, {
                key: 14,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    K(ye, {
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      modelValue: x(o)[v.fieldName],
                      label: v.switchLable
                    }, null, 8, ["onUpdate:modelValue", "modelValue", "label"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "table" ? (m(), R($, {
                key: 15,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: v.width || "100%"
                    })
                  }, [
                    K(Cr, {
                      onAdd: () => w(v),
                      onRemove: (X) => k(X, v),
                      data: x(o)[v.fieldName],
                      columns: v.columns,
                      rowKey: v.rowKey,
                      onChange: (X) => E(X, v)
                    }, null, 8, ["onAdd", "onRemove", "data", "columns", "rowKey", "onChange"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "slider" ? (m(), R($, {
                key: 16,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({ width: v.width || "100%" })
                  }, [
                    K(Q, {
                      modelValue: x(o)[v.fieldName],
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      disabled: v.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "colorPicker" ? (m(), R($, {
                key: 17,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({ width: v.width || "100%" })
                  }, [
                    K(q, {
                      modelValue: x(o)[v.fieldName],
                      "onUpdate:modelValue": (X) => {
                        x(o)[v.fieldName] = X, C.$emit("change", { ...x(o), [v.fieldName]: X });
                      },
                      disabled: v.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "upload" ? (m(), R($, {
                key: 18,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  K(Y, {
                    direction: "vertical",
                    style: pe({ width: v.width || "100%" })
                  }, {
                    default: I(() => [
                      K(Er, {
                        "onUpdate:modelValue": (X) => _(X, v),
                        modelValue: x(o)[v.fieldName],
                        placeholder: v.placeholder || "请上传文件",
                        "show-upload-progress": !0,
                        theme: v.theme,
                        accept: v.accept,
                        "auto-upload": !0,
                        multiple: v.multiple,
                        maxSize: v.maxSize,
                        minSize: v.minSize,
                        action: v.action || "/api/upload",
                        disabled: v.disabled,
                        requestMethod: !0
                      }, null, 8, ["onUpdate:modelValue", "modelValue", "placeholder", "theme", "accept", "multiple", "maxSize", "minSize", "action", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["style"])
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              v.type == "slot" ? (m(), R($, {
                key: 19,
                label: v.name,
                name: v.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe(
                      {
                        width: v.width || "100%"
                      }
                    )
                  }, [
                    te(C.$slots, v.fieldName, {
                      data: x(o),
                      slotData: v
                    }, void 0, !0)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0)
            ], 64))), 128)),
            x(i) && (C.showConfirm || C.showCancel) ? (m(), R($, {
              key: 0,
              "status-icon": !1,
              style: { "padding-top": "8px", margin: "auto" }
            }, {
              default: I(() => [
                C.showConfirm ? (m(), R(fe, {
                  key: 0,
                  theme: "primary",
                  type: "submit",
                  style: { "font-size": "12px", "margin-right": "10px" }
                }, {
                  default: I(() => [
                    Ce(se(x(r)), 1)
                  ]),
                  _: 1
                })) : j("", !0),
                C.showCancel ? (m(), R(fe, {
                  key: 1,
                  variant: "outline",
                  theme: "primary",
                  type: "reset",
                  style: { "font-size": "12px", "margin-right": "10px" }
                }, {
                  default: I(() => [
                    Ce(se(x(u)), 1)
                  ]),
                  _: 1
                })) : j("", !0)
              ]),
              _: 1
            })) : te(C.$slots, "foot", { key: 1 }, void 0, !0)
          ]),
          _: 3
        }, 8, ["layout", "data", "labelAlign", "rules", "labelWidth", "disabled", "colon"])
      ]);
    };
  }
}), Pg = /* @__PURE__ */ xe(kr, [["__scopeId", "data-v-561fbece"]]), Nr = { class: "tdesign-demo-block-column" }, Sr = { style: { display: "flex", "align-items": "center" } }, Lg = ne({
  __name: "MyTab",
  props: {
    panelData: {},
    theme: { default: "normal" },
    value: { default: 0 },
    destroyOnHide: { type: Boolean, default: !0 }
  },
  emits: ["tabsChange"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value);
    let { panelData: l } = Ge(a);
    const i = () => {
      n("tabsChange", o.value);
    };
    return (r, u) => {
      const s = Fe, d = zn, c = Rn;
      return m(), U("div", Nr, [
        K(c, {
          modelValue: o.value,
          "onUpdate:modelValue": u[0] || (u[0] = (f) => o.value = f),
          theme: r.theme,
          onChange: i
        }, {
          default: I(() => [
            (m(!0), U(ve, null, be(x(l), (f, h) => (m(), R(d, {
              key: f.value,
              value: f.value || h,
              destroyOnHide: r.destroyOnHide,
              label: f.label
            }, Te({
              default: I(() => [
                te(r.$slots, f.slot)
              ]),
              _: 2
            }, [
              f.tIcon ? {
                name: "label",
                fn: I(() => [
                  V("div", Sr, [
                    K(s, {
                      name: f.tIcon,
                      class: "tabs-icon-margin",
                      style: { "margin-right": "2px" }
                    }, null, 8, ["name"]),
                    Ce(" " + se(f.label), 1)
                  ])
                ]),
                key: "0"
              } : void 0
            ]), 1032, ["value", "destroyOnHide", "label"]))), 128))
          ]),
          _: 3
        }, 8, ["modelValue", "theme"])
      ]);
    };
  }
}), xr = {
  style: {
    width: "100%",
    height: "100%"
  }
}, $r = /* @__PURE__ */ ne({
  __name: "MyTableTree",
  props: {
    selectable: {
      type: Boolean,
      default: !1
    },
    data: {},
    dragable: {
      type: Boolean,
      default: !1
    },
    columns: {},
    rowKey: {},
    maxHeight: {
      default: "100%"
    },
    tree: {},
    treeExpandAndFoldIcon: {},
    pagination: {},
    tableLayout: {},
    selectedvalue: {},
    expandAll: {
      type: [Boolean, Number],
      default: !0
    },
    loading: {
      type: Boolean,
      default: !1
    },
    sequence: {
      type: Boolean
    },
    filterValue: {},
    size: {
      default: "small"
    },
    scroll: {}
  },
  emits: ["selectChange", "dragSorted"],
  setup(e, {
    emit: t
  }) {
    Je();
    const n = e, a = t, o = (C, {
      type: G
    }) => G === "expand" ? K(Yi, null, null) : K(Ki, null, null), l = ae(), i = me(() => n.data), r = me(() => {
      if (n.pagination)
        return n.pagination == !0 ? {
          theme: "simple",
          defaultCurrent: 1,
          defaultPageSize: 5,
          total: s.mydata.length,
          showFirstAndLastPageBtn: !0
        } : n.pagination;
    }), u = (C) => {
      if (!C.children || C.children.length === 0)
        return 0;
      let G = C.children.length;
      return C.children.map((B) => {
        G += u(B);
      }), G;
    }, s = Re({
      data: i || [],
      rowKey: n.rowKey || "key",
      tree: Object.assign({
        treeNodeColumnIndex: 0,
        indent: 16,
        checkStrictly: !1
      }, n.tree),
      treeExpandAndFoldIcon: n.treeExpandAndFoldIcon || o,
      maxHeight: n.maxHeight || "100%",
      mypagination: r,
      tableLayout: n.tableLayout || "fixed",
      dragSort: "row-handler"
    }), d = me(() => {
      const C = n.columns || [];
      return n.selectable === !0 && C.findIndex((G) => G.colKey === "value") === -1 && C.unshift({
        colKey: "value",
        title: "序号",
        type: "multiple",
        width: 50
      }), n.sequence === !0 && C.findIndex((G) => G.colKey === "serial-number") === -1 && C.unshift({
        colKey: "serial-number",
        title: "序号",
        width: 60
      }), n.dragable === !0 && C.findIndex((G) => G.colKey === "drag") === -1 && C.unshift({
        // 列拖拽排序必要参数
        colKey: "drag",
        title: "排序",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cell: (G) => K("div", {
          style: "color:#AAAAAA"
        }, [K(Zi, null, null)]),
        width: 80
      }), C;
    });
    s.columns = d;
    const c = ae(n.selectedvalue || []);
    let f = ae();
    ie(f, (C, G) => {
      if (G) {
        if (C.row.key == G.row.key && G != null)
          return;
        G.e.path.filter((B) => B.nodeName == "TR")[0].className = "";
      }
    });
    const h = me(() => s.treeExpandAndFoldIcon), y = (C, {
      selectedRowData: G
    }) => {
      c.value = C;
    };
    ie([() => n.data, l], () => {
    }, {
      immediate: !0
    });
    let b = !1;
    const p = (C) => (b = !1, !0), w = (C) => {
      b === !1 && a("dragSorted", C);
    }, k = (C) => {
      b = !0, C.code === 1001 && vn.warning("不同层级的元素，不允许调整顺序");
    }, E = (C) => {
    }, _ = (C) => {
    };
    return (C, G) => (m(), U("div", xr, [s.data ? (m(), R(x(Oo), {
      key: 0,
      ref_key: "table",
      ref: l,
      style: {
        "font-size": "12px"
      },
      "drag-sort": s.dragSort,
      beforeDragSort: p,
      onDragSort: w,
      onAbnormalDragSort: k,
      onDataChange: _,
      onExpandedTreeNodesChange: E,
      "row-key": s.rowKey,
      data: s.data,
      columns: s.columns,
      tableLayout: s.tableLayout,
      "tree-expand-and-fold-icon": h.value,
      "selected-row-keys": c.value,
      maxHeight: s.maxHeight,
      loading: C.loading,
      tree: s.tree,
      onSelectChange: y,
      filterValue: C.filterValue,
      size: C.size,
      scroll: C.scroll
    }, Te({
      loading: I(() => [te(C.$slots, "loading", {}, () => [Ce(" 加载中... ")], !0)]),
      operate: I((B) => [V("div", null, [te(C.$slots, "operate", {
        slotProps: B
      }, void 0, !0)])]),
      _: 2
    }, [be(s.columns, (B, $) => ({
      name: B.colKey,
      fn: I(({
        row: M,
        rowIndex: A,
        col: S,
        colIndex: Z
      }) => {
        var z;
        return [l.value ? te(C.$slots, B.colKey, {
          key: 0,
          childrenCount: u(M),
          node: (z = l.value) == null ? void 0 : z.getData(M[C.rowKey]),
          row: M,
          rowIndex: A,
          col: S,
          colIndex: Z
        }, () => [Ce(se(M[B.colKey]), 1)], !0) : j("", !0)];
      })
    }))]), 1032, ["drag-sort", "row-key", "data", "columns", "tableLayout", "tree-expand-and-fold-icon", "selected-row-keys", "maxHeight", "loading", "tree", "filterValue", "size", "scroll"])) : j("", !0)]));
  }
}), zg = /* @__PURE__ */ xe($r, [["__scopeId", "data-v-72fbbcc4"]]), Rg = ne({
  __name: "MyTreeSelect",
  props: {
    value: {},
    options: {},
    clearable: { type: Boolean, default: !0 },
    multiple: { type: Boolean, default: !1 },
    filterable: { type: Boolean, default: !0 },
    readonly: { type: Boolean, default: !1 },
    placeholder: { default: "请选择" },
    valueType: { default: "value" },
    treeProps: { default: {
      // 默认全部展开
      //@ts-ignore
      expandAll: !0
    } },
    onChange: {},
    loading: { type: Boolean, default: !1 }
  },
  emits: ["update:value", "popupVisibleChange"],
  setup(e, { emit: t }) {
    const n = e, a = t, o = n.multiple === !0 ? [n] : "", l = ae(o);
    ie(() => n.value, () => {
      l.value = n.value;
    }, {
      immediate: !0
    }), ie(l, () => {
      a("update:value", l.value), n.onChange && n.onChange(l.value);
    });
    const i = ae(!1), r = (u, s) => {
      var d;
      (s.trigger || ((d = s.node) == null ? void 0 : d.label) !== "广州市") && (i.value = u);
    };
    return (u, s) => {
      const d = An;
      return m(), R(d, {
        modelValue: l.value,
        "onUpdate:modelValue": s[0] || (s[0] = (c) => l.value = c),
        data: u.options,
        clearable: u.clearable,
        multiple: u.multiple,
        placeholder: u.placeholder,
        "popup-visible": i.value,
        valueType: u.valueType,
        treeProps: u.treeProps,
        filterable: u.filterable,
        onPopupVisibleChange: r,
        loading: u.loading
      }, null, 8, ["modelValue", "data", "clearable", "multiple", "placeholder", "popup-visible", "valueType", "treeProps", "filterable", "loading"]);
    };
  }
}), Mr = { style: { "font-weight": "bold" } }, wt = ne({
  __name: "MyCard",
  props: {
    theme: { default: "normal" },
    headerBordered: { type: Boolean, default: !1 },
    header: {},
    bordered: { type: Boolean, default: !0 },
    showRadius: { type: Boolean, default: !1 },
    loading: {},
    fill: { type: Boolean, default: !0 },
    padding: { default: "20px" },
    hoverShadow: { type: Boolean, default: !1 },
    title: {},
    cover: {},
    subtitle: {}
  },
  setup(e) {
    It((i) => ({
      "46dac13b": l.contentHeight,
      "2d14063a": t.padding
    }));
    const t = e, n = ae(), a = ae(), { loading: o } = Ge(t), l = Re({
      contentHeight: "100%"
    });
    return De(() => {
      l.contentHeight = `calc(100%${n.value ? ` - ${n.value.offsetParent.clientHeight}px` : ""}${a.value ? ` - ${[...a.value.offsetParent.children].find((i) => i.className.includes("t-card__footer")).clientHeight}px + ${t.padding}` : ""})`;
    }), (i, r) => {
      var d, c, f;
      const u = xi, s = Io;
      return m(), R(s, {
        class: Pe(`${i.fill ? "fill" : ""} my-card-loading`),
        loading: ((d = x(o)) == null ? void 0 : d.loading) || !1,
        size: ((c = x(o)) == null ? void 0 : c.size) || "medium",
        text: (f = x(o)) == null ? void 0 : f.text
      }, {
        default: I(() => [
          K(u, {
            hoverShadow: i.hoverShadow,
            cover: i.cover,
            subtitle: i.subtitle,
            class: Pe(`${i.showRadius ? "" : "my-card-border-radius-0"} my-card`),
            theme: i.theme,
            headerBordered: i.headerBordered,
            bordered: i.bordered
          }, Te({
            default: I(() => [
              i.$slots.description ? te(i.$slots, "description", { key: 0 }) : j("", !0)
            ]),
            _: 2
          }, [
            i.$slots.header || i.header ? {
              name: "header",
              fn: I(() => [
                V("div", {
                  ref_key: "headerDom",
                  ref: n,
                  class: "w-fill"
                }, [
                  te(i.$slots, "header", {}, () => [
                    V("div", Mr, se(i.title || i.header), 1)
                  ]),
                  i.$slots.subtitle ? te(i.$slots, "subtitle", { key: 0 }, () => [
                    Ce(se(i.subtitle), 1)
                  ]) : j("", !0),
                  i.$slots.actions ? te(i.$slots, "actions", { key: 1 }) : j("", !0)
                ], 512)
              ]),
              key: "0"
            } : void 0,
            i.$slots.default ? {
              name: "content",
              fn: I(() => [
                te(i.$slots, "default")
              ]),
              key: "1"
            } : void 0,
            i.$slots.footer ? {
              name: "footer",
              fn: I(() => [
                V("div", {
                  ref_key: "footerDom",
                  ref: a,
                  class: "w-fill"
                }, [
                  te(i.$slots, "footer")
                ], 512)
              ]),
              key: "2"
            } : void 0
          ]), 1032, ["hoverShadow", "cover", "subtitle", "class", "theme", "headerBordered", "bordered"])
        ]),
        _: 3
      }, 8, ["class", "loading", "size", "text"]);
    };
  }
}), Br = { key: 0 }, Ir = { style: { cursor: "pointer" } }, Hg = ne({
  __name: "MyStackList",
  props: {
    data: {},
    actions: {},
    schema: { default: {
      //@ts-ignore
      header: {
        fieldName: "header"
      },
      description: {
        fieldName: "description"
      }
    } },
    classNames: {}
  },
  emits: ["clicked"],
  setup(e, { emit: t }) {
    const n = t, a = (o) => {
      n("clicked", o);
    };
    return (o, l) => (m(), U("div", {
      class: Pe(`${o.classNames} space-y-3`)
    }, [
      !o.data || o.data.length === 0 ? (m(), U("div", Br, [
        K(wt, null, {
          default: I(() => [
            Ce("暂无数据")
          ]),
          _: 1
        })
      ])) : (m(!0), U(ve, { key: 1 }, be(o.data, (i) => (m(), U("div", Ir, [
        K(wt, {
          onClick: (r) => a(i),
          header: i[o.schema.header.fieldName],
          "hover-shadow": !0
        }, Te({
          default: I(() => [
            Ce(se(i[o.schema.description.fieldName]) + " ", 1)
          ]),
          _: 2
        }, [
          be(o.$slots, (r, u) => ({
            name: u,
            fn: I((s) => [
              te(o.$slots, u, Dt({ row: i }, s))
            ])
          }))
        ]), 1032, ["onClick", "header"])
      ]))), 256))
    ], 2));
  }
}), Ug = ne({
  __name: "MyIconSelect",
  props: {
    width: { default: "100%" },
    defaultIcons: { default: Xi },
    value: {}
  },
  emits: ["update:value"],
  setup(e, { emit: t }) {
    const n = e, a = t, o = _t(n.defaultIcons), l = ae(n.value);
    return ie(l, () => {
      a("update:value", l.value);
    }), (i, r) => {
      const u = Fe, s = Ln, d = an;
      return m(), R(d, {
        modelValue: l.value,
        "onUpdate:modelValue": r[0] || (r[0] = (c) => l.value = c),
        placeholder: "请选择",
        style: pe({ width: i.width })
      }, {
        valueDisplay: I(() => [
          K(u, {
            name: l.value,
            style: { marginRight: "8px" }
          }, null, 8, ["name"]),
          Ce(se(l.value), 1)
        ]),
        default: I(() => [
          (m(!0), U(ve, null, be(o.value, (c) => (m(), R(s, {
            key: c.stem,
            value: c.stem,
            class: "my-overlay-options"
          }, {
            default: I(() => [
              V("div", null, [
                K(u, {
                  name: c.stem
                }, null, 8, ["name"])
              ])
            ]),
            _: 2
          }, 1032, ["value"]))), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "style"]);
    };
  }
}), Dr = ["src"], Vr = ["title"], Ar = { key: 0 }, Fr = ne({
  __name: "MyCell",
  props: {
    data: {},
    actions: {}
  },
  emits: ["click", "actionClick"],
  setup(e, { emit: t }) {
    const n = t, a = (l) => {
      n("click", { data: l });
    }, o = (l, i, r) => {
      l.stopPropagation(), n("actionClick", { actionItem: i, data: r });
    };
    return (l, i) => {
      const r = Po;
      return m(), U("div", {
        class: "cell cursor-pointer",
        onClick: i[2] || (i[2] = () => {
          a(l.data);
        })
      }, [
        te(l.$slots, "cover", {}, () => [
          V("img", {
            class: "cell-img",
            src: l.data.cover
          }, null, 8, Dr)
        ], !0),
        te(l.$slots, "name", {}, () => [
          V("div", {
            title: l.data.name,
            class: "absolute bottom-0 p-3 w-full bg-opacity-50 bg-gray-700 text-white truncate"
          }, se(l.data.name), 9, Vr)
        ], !0),
        te(l.$slots, "description", {}, () => [
          l.data.description ? (m(), U("div", Ar, se(l.data.description), 1)) : j("", !0)
        ], !0),
        te(l.$slots, "actions", {}, () => {
          var u;
          return [
            (u = l.actions) != null && u.length ? (m(), R(r, {
              key: 0,
              trigger: "click",
              options: l.actions,
              onClick: i[1] || (i[1] = (s, d) => {
                o(d.e, s, l.data);
              })
            }, {
              default: I(() => [
                V("div", {
                  class: "cell-extra-btn",
                  onClick: i[0] || (i[0] = (s) => {
                    s.stopPropagation();
                  })
                }, "···")
              ]),
              _: 1
            }, 8, ["options"])) : j("", !0)
          ];
        }, !0)
      ]);
    };
  }
}), Tr = /* @__PURE__ */ xe(Fr, [["__scopeId", "data-v-733fab96"]]), Or = { class: "my-item-content" }, Pr = ne({
  __name: "MyGridList",
  props: {
    schema: {},
    data: {},
    actions: {},
    gridConfig: {},
    maxCol: { default: 3 },
    cardMinWidth: { default: 400 },
    gap: { default: 20 },
    heightRatio: { default: "50%" },
    theme: { default: "default" }
  },
  emits: ["click", "actionClick"],
  setup(e, { emit: t }) {
    It((f) => ({
      "31b412c1": o.value,
      45797778: f.maxCol,
      fbbe86b0: l.value,
      "32d93d48": f.heightRatio
    }));
    const n = e, a = t, o = me(() => `${n.gap}px`), l = me(() => n.theme === "auto" ? "100%" : "auto"), i = me(() => n.theme === "auto" ? 0 : n.cardMinWidth), r = _t(), u = _t(), s = () => {
      if (r.value && u.value && u.value.length > 0) {
        const f = u.value[n.maxCol];
        f ? f.offsetTop < f.offsetHeight ? r.value.style.gridTemplateColumns = `repeat(${n.maxCol},1fr)` : f.offsetWidth <= i.value && (r.value.style.gridTemplateColumns = `repeat(auto-fit, minmax(${i.value}px, 1fr))`) : r.value.clientWidth >= i.value * u.value.length + n.gap * (u.value.length - 1) ? r.value.style.gridTemplateColumns = `repeat(${n.maxCol}, minmax(${i.value}px, 1fr))` : r.value.style.gridTemplateColumns = `repeat(auto-fit, minmax(${i.value}px, 1fr))`;
      }
    };
    De(() => {
      s(), window.addEventListener("resize", () => {
        s();
      });
    }), ie(() => {
      var f;
      return (f = n.data) == null ? void 0 : f.length;
    }, () => {
      n.data.length && setTimeout(() => {
        s();
      });
    }, { immediate: !0 });
    const d = (f) => {
      console.log("actionClick inner"), a("actionClick", f);
    }, c = (f) => {
      a("click", f);
    };
    return (f, h) => (m(), U("div", {
      class: "my-container",
      ref_key: "containerRef",
      ref: r
    }, [
      (m(!0), U(ve, null, be(f.data, (y, b) => (m(), U("div", {
        key: b,
        class: Pe(`my-item-container ${f.theme === "auto" ? "my-item-container-fixed-height" : "my-item-container-ratio-height"}`),
        ref_for: !0,
        ref_key: "cradsRef",
        ref: u
      }, [
        V("div", Or, [
          K(Tr, {
            data: y,
            actions: f.actions,
            onClick: c,
            onActionClick: d
          }, Te({ _: 2 }, [
            be(f.$slots, (p, w) => ({
              name: w,
              fn: I((k) => [
                te(f.$slots, w, Dt({ item: y }, k), void 0, !0)
              ])
            }))
          ]), 1032, ["data", "actions"])
        ])
      ], 2))), 128))
    ], 512));
  }
}), Gg = /* @__PURE__ */ xe(Pr, [["__scopeId", "data-v-6e0d67e3"]]);
var Lr = typeof window < "u";
const nl = {
  Promise: Lr ? window.Promise : void 0
};
var ol = "4.24", po = "next";
function al(e) {
  if (e.toLowerCase() === po)
    return po;
  var t = e && e.match(/^(\d)\.(\d+)/);
  return t && {
    major: parseInt(t[1], 10),
    minor: parseInt(t[2], 10)
  };
}
function ll(e) {
  return e === void 0 && (e = ol), "https://js.arcgis.com/".concat(e, "/");
}
function zr(e) {
  e === void 0 && (e = ol);
  var t = ll(e), n = al(e);
  if (n !== po && n.major === 3) {
    var a = n.minor <= 10 ? "js/" : "";
    return "".concat(t).concat(a, "esri/css/esri.css");
  } else
    return "".concat(t, "esri/themes/light/main.css");
}
function Rr(e) {
  var t = document.createElement("link");
  return t.rel = "stylesheet", t.href = e, t;
}
function Hr(e, t) {
  if (t) {
    var n = document.querySelector(t);
    n.parentNode.insertBefore(e, n);
  } else
    document.head.appendChild(e);
}
function Ur(e) {
  return document.querySelector('link[href*="'.concat(e, '"]'));
}
function Gr(e) {
  return !e || al(e) ? zr(e) : e;
}
function Zr(e, t) {
  var n = Gr(e), a = Ur(n);
  return a || (a = Rr(n), Hr(a, t)), a;
}
var il = {};
function Yr(e) {
  var t = document.createElement("script");
  return t.type = "text/javascript", t.src = e, t.setAttribute("data-esri-loader", "loading"), t;
}
function ta(e, t, n) {
  var a;
  n && (a = Kr(e, n));
  var o = function() {
    t(e), e.removeEventListener("load", o, !1), a && e.removeEventListener("error", a, !1);
  };
  e.addEventListener("load", o, !1);
}
function Kr(e, t) {
  var n = function(a) {
    t(a.error || new Error("There was an error attempting to load ".concat(e.src))), e.removeEventListener("error", n, !1);
  };
  return e.addEventListener("error", n, !1), n;
}
function Xr(e) {
  e === void 0 && (e = {}), il = e;
}
function rl() {
  return document.querySelector("script[data-esri-loader]");
}
function ho() {
  var e = window.require;
  return e && e.on;
}
function Wr(e) {
  e === void 0 && (e = {});
  var t = {};
  [il, e].forEach(function(o) {
    for (var l in o)
      Object.prototype.hasOwnProperty.call(o, l) && (t[l] = o[l]);
  });
  var n = t.version, a = t.url || ll(n);
  return new nl.Promise(function(o, l) {
    var i = rl();
    if (i) {
      var r = i.getAttribute("src");
      r !== a ? l(new Error("The ArcGIS API for JavaScript is already loaded (".concat(r, ")."))) : ho() ? o(i) : ta(i, o, l);
    } else if (ho())
      l(new Error("The ArcGIS API for JavaScript is already loaded."));
    else {
      var u = t.css;
      if (u) {
        var s = u === !0;
        Zr(s ? n : u, t.insertCssBefore);
      }
      i = Yr(a), ta(i, function() {
        i.setAttribute("data-esri-loader", "loaded"), o(i);
      }, l), document.body.appendChild(i);
    }
  });
}
function na(e) {
  return new nl.Promise(function(t, n) {
    var a = window.require.on("error", n);
    window.require(e, function() {
      for (var o = [], l = 0; l < arguments.length; l++)
        o[l] = arguments[l];
      a.remove(), t(o);
    });
  });
}
function jr(e, t) {
  if (t === void 0 && (t = {}), ho())
    return na(e);
  var n = rl(), a = n && n.getAttribute("src");
  return !t.url && a && (t.url = a), Wr(t).then(function() {
    return na(e);
  });
}
const qr = (e) => (ht("data-v-77c750cc"), e = e(), mt(), e), Jr = /* @__PURE__ */ qr(() => /* @__PURE__ */ V("link", {
  rel: "stylesheet",
  href: "https://js.arcgis.com/4.23/esri/themes/light/main.css"
}, null, -1)), Qr = ne({
  __name: "MyModelViewer",
  props: {
    data: { default: {
      //@ts-ignore
      viewMode: "global",
      viewMap: {
        basemap: "satellite",
        ground: "world-elevation"
      },
      heading: 0
    } }
  },
  emits: ["modelClick"],
  async setup(e, { emit: t }) {
    let n, a;
    const o = e;
    let { apiKey: l, modelUrl: i, modelCenter: r, modelTitle: u, viewMode: s, viewMap: d, heading: c, selected: f } = o.data;
    Xr({
      version: "4.23"
    });
    const h = ae(), y = _t();
    let b = [];
    const p = t, w = _t([]);
    let k, E = _t();
    const _ = (ye, Q) => new Promise(async (q) => {
      await fetch(ye).then((Y) => Y.json()).then((Y) => {
        q(Y.layerType);
      });
    }), C = async () => await Promise.all(i.map((ye, Q) => _(ye))), G = async () => {
      if (B.apiKey = l, !E.value) {
        const Q = new $(d);
        E.value = new M({
          map: Q,
          center: r,
          container: "map",
          camera: {
            tilt: 68.64828713751528,
            heading: c,
            position: [
              0,
              // lon
              0,
              // lat
              1e5
              // elevation in meters
            ]
          },
          enviroment: {
            atmosphereEnabled: !1
          },
          ui: {
            components: []
          },
          viewingMode: s
        });
      }
      await (async () => {
        b = await C();
        const Q = [];
        i.map((q, Y) => {
          b[Y] && (b[Y] == "building" || b[Y] == "Building") && (k = new S({
            url: i[Y],
            title: u[Y],
            visible: !0
          })), b[Y] && b[Y] == "3DObject" && (k = new A({
            url: i[Y],
            title: u[Y],
            visible: !0,
            elevationInfo: {
              mode: "absolute-height",
              offset: 8
            }
          })), E.value.map.layers.add(k), Q.push(k);
        }), w.value = [...w.value, ...Q], y.value = k, console.log("///////////", y.value);
      })(), y.value && E.value && E.value.when(() => {
        console.log("Map is loaded"), new W({
          view: E.value
        }), E.value.ui.add("compass", "top-right"), new z({
          view: E.value
        });
        const Q = new N({
          view: E.value,
          multipleSelectionEnabled: !1,
          listItemCreatedFunction: (Y) => {
            const fe = Y.item;
            fe.layer.type != "web-tile" && (fe.actionsSections = [
              [
                {
                  title: "查看",
                  className: "esri-icon-zoom-out-fixed",
                  id: "full-extent"
                }
              ]
            ]);
          }
        });
        Q.on("trigger-action", (Y) => {
          Y.action.id === "full-extent" && (q.toggle(), E.value.goTo(Y.item.layer.fullExtent, { speedFactor: 2 }));
        }), E.value.ui.remove("attribution");
        const q = new P({
          view: E.value,
          content: Q
          // expanded: true,
        });
        E.value.ui.add(q, "top-right"), new oe({
          view: E.value
        }), E.value.ui.add("zoom", "bottom-right"), k.when(async function() {
          await E.value.goTo(y.value.fullExtent, {
            speedFactor: 2
          });
        });
      }), E.value.on("click", function(Q) {
        p("modelClick", Q);
      });
    };
    ie(() => o.data, async () => {
      console.log(o.data), E.value.map.removeMany(w.value), k = null, w.value = [], await G();
    }, { deep: !0 }), ie(
      w,
      () => {
        E.value && f && f.length > 0 && w.value.forEach((ye) => {
          console.log(2), f && (console.log(3), E.value.whenLayerView(ye).then(function(Q) {
            E.value && E.value.watch("updating", () => {
              y.value && Q.sublayerViews && Q.sublayerViews.forEach(function(q) {
                q.highlight(f);
              });
            });
          }).catch((Q) => {
          }));
        });
      },
      {
        immediate: !0
      }
    ), De(() => {
      h.value && G();
    }), Vt(() => {
      E.value.map.removeMany(w.value), k = null, y.value = null, w.value = [];
    });
    const [
      B,
      $,
      M,
      A,
      S,
      Z,
      z,
      N,
      P,
      T,
      g,
      H,
      F,
      W,
      oe,
      re
    ] = ([n, a] = zi(() => jr([
      "esri/config",
      "esri/Map",
      "esri/views/SceneView",
      "esri/layers/SceneLayer",
      "esri/layers/BuildingSceneLayer",
      "esri/layers/IntegratedMeshLayer",
      "esri/widgets/Home",
      "esri/widgets/LayerList",
      "esri/widgets/Expand",
      "esri/layers/WebTileLayer",
      "esri/layers/MapImageLayer",
      "esri/layers/ElevationLayer",
      "esri/layers/PointCloudLayer",
      "esri/widgets/CoordinateConversion",
      "esri/widgets/DirectLineMeasurement3D",
      "esri/layers/FeatureLayer"
    ])), n = await n, a(), n);
    return (ye, Q) => (m(), U(ve, null, [
      Jr,
      V("div", {
        id: "map",
        ref_key: "mapDiv",
        ref: h
      }, null, 512)
    ], 64));
  }
}), Zg = /* @__PURE__ */ xe(Qr, [["__scopeId", "data-v-77c750cc"]]), es = ne({
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    mapInstance: {
      type: Object
    },
    center: {
      type: Object
      // default: () => {
      //   return { lon: 114.3483089, lat: 30.5520081 }
      // },
    },
    heightOffset: {
      type: Number,
      default: 8
    },
    zoom: {
      type: Number,
      default: 5
    },
    tilt: {
      type: Number,
      default: 0
    },
    heading: {
      type: Number,
      default: 0
    },
    mapStyle: {
      type: String || Array
    },
    widget: {
      type: Array,
      default: {}
    }
  },
  setup(e, { emit: t }) {
    const n = ae(), a = _t(), o = ae();
    return rt("map", a), rt("currentClickPoint", o), De(() => {
      e.width && e.height && (n.value.style.width = `${e.width}px`, n.value.style.height = `${e.height}px`), a.value = new window.BMapGL.Map("map", {
        // enableMapClick: false,
      }), e.center ? a.value.centerAndZoom(
        new window.BMapGL.Point(e.center.lon, e.center.lat),
        e.zoom
      ) : a.value.centerAndZoom("全国", e.zoom), a.value.enableScrollWheelZoom(!0), a.value.setHeading(e.heading), a.value.setTilt(e.tilt), a.value.setDisplayOptions({
        poi: !0,
        //是否显示POI信息
        poiText: !0,
        poiIcon: !1,
        building: !1,
        street: !0
      });
      const l = new window.BMapGL.ScaleControl();
      a.value.addControl(l);
      const i = new window.BMapGL.ZoomControl();
      a.value.addControl(i), typeof e.mapStyle == "string" ? a.value.setMapStyleV2({
        styleId: e.mapStyle
      }) : typeof e.mapStyle == "object" && a.value.setMapStyleV2({
        styleJson: e.mapStyle
      }), e.widget && e.widget.length > 0 && e.widget.forEach((r) => {
        const u = {};
        r.offset && (u.offset = new window.BMapGL.Size(r.offset.x, r.offset.y)), r.anchor && (u.anchor = r.anchor), a.value.addControl(new window.BMapGL[r.name](u));
      }), e.mapInstance && t("update:mapInstance", a.value), a.value.addEventListener("click", (r) => {
        o.value = r.latlng;
      });
    }), Vt(() => {
      a.value.removeEventListener("click"), a.value = null;
    }), {
      mapContainer: n
    };
  }
}), ts = (e) => (ht("data-v-329787e0"), e = e(), mt(), e), ns = {
  ref: "mapContainer",
  style: { width: "100%", height: "100%", position: "relative" }
}, os = /* @__PURE__ */ ts(() => /* @__PURE__ */ V("div", { id: "map" }, null, -1));
function as(e, t, n, a, o, l) {
  return m(), U("div", ns, [
    os,
    te(e.$slots, "default", {}, void 0, !0)
  ], 512);
}
const Yg = /* @__PURE__ */ xe(es, [["render", as], ["__scopeId", "data-v-329787e0"]]), ls = { class: "tdesign-demo-image-viewer__base" }, is = { class: "tdesign-demo-image-viewer__ui-image" }, rs = ["src"], ss = ["onClick"], us = ne({
  __name: "MyImageViewer",
  props: {
    img: {}
  },
  setup(e) {
    return (t, n) => {
      const a = $i;
      return m(), U("div", null, [
        V("div", ls, [
          K(a, {
            images: [t.img]
          }, {
            trigger: I(({ open: o }) => [
              V("div", is, [
                V("img", {
                  alt: "test",
                  src: t.img,
                  class: "tdesign-demo-image-viewer__ui-image--img"
                }, null, 8, rs),
                V("div", {
                  class: "tdesign-demo-image-viewer__ui-image--hover",
                  onClick: o
                }, [
                  V("span", null, [
                    K(x(Wi), { size: "1.4em" }),
                    Ce(" 预览 ")
                  ])
                ], 8, ss)
              ])
            ]),
            _: 1
          }, 8, ["images"])
        ])
      ]);
    };
  }
}), Kg = /* @__PURE__ */ xe(us, [["__scopeId", "data-v-250ce09e"]]), ds = ne({
  emits: ["clicked", "marked", "infoWindoClose"],
  props: {
    marker: {
      type: Object
    },
    icon: {
      type: Object
    },
    canMark: {
      type: Boolean,
      default: !0
    },
    setCenter: {
      type: Boolean,
      default: !0
    }
  },
  setup(e, { emit: t }) {
    const n = ae(null), a = Qe("map"), { marker: o } = Ge(e), l = ae({}), i = () => {
      if (o.value && o.value.point && o.value.point.lon && o.value.point.lat) {
        n.value && a.value.removeOverlay(n.value);
        const r = new window.BMapGL.Point(
          o.value.point.lon,
          o.value.point.lat
        );
        o.value.style && (l.value = o.value.style), e.icon && (l.value.icon = new window.BMapGL.Icon(e.icon.url, new window.BMapGL.Size(e.icon.width || 25, e.icon.width || 25))), n.value = new window.BMapGL.Marker(
          r,
          l.value
        ), a.value.addOverlay(n.value), n.value && n.value.addEventListener("click", function() {
          t("clicked", { markerPoint: n.value });
        }), e.setCenter && a.value.setCenter(r);
      }
    };
    ie(o, () => {
      i();
    }), De(() => {
      a.value && i();
    }), ie(a, () => {
      a.value && (i(), e.canMark && a.value.addEventListener("click", function(r) {
        n.value && a.value.removeOverlay(n.value), n.value = new window.BMapGL.Marker(
          new window.BMapGL.Point(r.latlng.lng, r.latlng.lat)
        ), a.value.addOverlay(n.value), t("marked", { point: r.latlng });
      }));
    }), Vt(() => {
      var r;
      a.value && (a.value.removeEventListener("click"), (r = n.value) == null || r.removeEventListener("click"), a.value.removeOverlay(n.value));
    });
  }
});
function cs(e, t, n, a, o, l) {
  return m(), U("div");
}
const Xg = /* @__PURE__ */ xe(ds, [["render", cs]]), Wg = ne({
  __name: "MyPassWordForm",
  props: {
    data: {},
    content: {},
    labelWidth: { default: 100 },
    showBtn: { type: Boolean, default: !0 },
    confirmBtn: { default: "提交" },
    cancelBtn: { default: "取消" },
    showConfirm: { type: Boolean, default: !0 },
    showCancel: { type: Boolean, default: !0 },
    formDisabled: { type: Boolean, default: !1 },
    colon: { type: Boolean, default: !1 },
    labelAlign: { default: "left" },
    rules: { default: Object }
  },
  emits: ["submit", "reset"],
  setup(e, { emit: t }) {
    const n = e, a = ae();
    let { data: o, content: l, showBtn: i, confirmBtn: r, cancelBtn: u, formDisabled: s } = Ge(n), d = null;
    ie(o, () => {
      d = Object.assign({}, o.value);
    });
    const c = t, f = (y) => {
      y.validateResult === !0 && c("submit", o);
    }, h = () => {
      c("reset", d);
    };
    return (y, b) => {
      const p = Nt, w = ja, k = Do, E = Vo, _ = Ln, C = an, G = An, B = Ao, $ = Tn, M = Fn, A = Fo, S = pt, Z = To, z = Pn, N = On, P = ut, T = qa;
      return m(), U("div", null, [
        K(T, {
          ref_key: "form",
          ref: a,
          data: x(o),
          labelAlign: y.labelAlign,
          rules: y.rules,
          onReset: h,
          onSubmit: f,
          resetType: "initial",
          labelWidth: y.labelWidth || "100px",
          disabled: x(s),
          colon: y.colon
        }, {
          default: I(() => [
            (m(!0), U(ve, null, be(x(l), (g, H) => (m(), U(ve, { key: H }, [
              g.type == "input" ? (m(), R(w, {
                key: 0,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(p, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      placeholder: g.placeholder || "请输入内容",
                      disabled: g.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "password" ? (m(), R(w, {
                key: 1,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(p, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      type: "password",
                      placeholder: g.placeholder || "请输入内容",
                      disabled: g.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "tagInput" ? (m(), R(w, {
                key: 2,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(k, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      placeholder: g.placeholder || "请输入内容",
                      disabled: g.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "text" ? (m(), R(w, {
                key: 3,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(E, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      placeholder: g.placeholder || "请输入内容",
                      disabled: g.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "select" ? (m(), R(w, {
                key: 4,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    g.keys ? (m(), R(C, {
                      key: 0,
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      class: "demo-select-base",
                      placeholder: g.placeholder || "请选择",
                      disabled: g.disabled,
                      multiple: g.multiple
                    }, {
                      default: I(() => [
                        (m(!0), U(ve, null, be(g.options, (F, W) => {
                          var oe, re;
                          return m(), R(_, {
                            key: W,
                            value: F[(oe = g.keys) == null ? void 0 : oe.value],
                            label: F[(re = g.keys) == null ? void 0 : re.label]
                          }, {
                            default: I(() => {
                              var ye;
                              return [
                                Ce(se(F[(ye = g.keys) == null ? void 0 : ye.label]), 1)
                              ];
                            }),
                            _: 2
                          }, 1032, ["value", "label"]);
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "multiple"])) : (m(), R(C, {
                      key: 1,
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      class: "demo-select-base",
                      placeholder: g.placeholder || "请选择",
                      disabled: g.disabled,
                      multiple: g.multiple
                    }, {
                      default: I(() => [
                        (m(!0), U(ve, null, be(g.options, (F, W) => (m(), R(_, {
                          key: W,
                          value: F.value,
                          label: F.label
                        }, {
                          default: I(() => [
                            Ce(se(F.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value", "label"]))), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "multiple"]))
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "treeSelect" ? (m(), R(w, {
                key: 5,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(G, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      data: g.options,
                      placeholder: g.placeholder || "请选择",
                      disabled: g.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "data", "placeholder", "disabled"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "radio" ? (m(), R(w, {
                key: 6,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(B, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      disabled: g.disabled,
                      options: g.options,
                      placeholder: g.placeholder || "请选择"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "options", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "radioBtn" ? (m(), R(w, {
                key: 7,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(B, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      disabled: g.disabled,
                      placeholder: g.placeholder || "请选择"
                    }, {
                      default: I(() => [
                        !g.theme || g.theme == "button" ? (m(!0), U(ve, { key: 0 }, be(g.options, (F, W) => (m(), R($, {
                          value: F.value
                        }, {
                          default: I(() => [
                            Ce(se(F.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))), 256)) : j("", !0),
                        g.theme == "circle" ? (m(!0), U(ve, { key: 1 }, be(g.options, (F, W) => (m(), R(M, {
                          value: F.value
                        }, {
                          default: I(() => [
                            Ce(se(F.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))), 256)) : j("", !0)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "checkbox" ? (m(), R(w, {
                key: 8,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  K(A, {
                    modelValue: x(o)[g.fieldName],
                    "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                    disabled: g.disabled,
                    options: g.options,
                    placeholder: g.placeholder || "请选择"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "options", "placeholder"])
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "checkbox-single" ? (m(), R(w, {
                key: 9,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  K(S, {
                    modelValue: x(o)[g.fieldName],
                    "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                    disabled: g.disabled,
                    options: g.options
                  }, {
                    default: I(() => [
                      Ce(se(g.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "options"])
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "number" ? (m(), R(w, {
                key: 10,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(Z, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      theme: "row",
                      size: "medium",
                      min: 0,
                      disabled: g.disabled,
                      placeholder: g.placeholder || "请输入数字"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "date" ? (m(), R(w, {
                key: 11,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(z, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      disabled: g.disabled,
                      enableTimePicker: g.enableTimePicker,
                      disableDate: g.disableDate,
                      format: g.format,
                      "allow-input": "",
                      placeholder: g.placeholder || "请选择时间",
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "enableTimePicker", "disableDate", "format", "placeholder"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "switch" ? (m(), R(w, {
                key: 12,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    K(N, {
                      modelValue: x(o)[g.fieldName],
                      "onUpdate:modelValue": (F) => x(o)[g.fieldName] = F,
                      label: g.switchLable
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0),
              g.type == "slot" ? (m(), R(w, {
                key: 13,
                label: g.name,
                name: g.fieldName
              }, {
                default: I(() => [
                  V("div", {
                    style: pe({
                      width: g.width || "100%"
                    })
                  }, [
                    te(y.$slots, g.fieldName, { slotData: g })
                  ], 4)
                ]),
                _: 2
              }, 1032, ["label", "name"])) : j("", !0)
            ], 64))), 128)),
            x(i) && (y.showConfirm || y.showCancel) ? (m(), R(w, {
              key: 0,
              "status-icon": !1,
              style: { "padding-top": "8px", margin: "auto" }
            }, {
              default: I(() => [
                y.showCancel ? (m(), R(P, {
                  key: 0,
                  theme: "default",
                  variant: "base",
                  type: "reset",
                  style: { "margin-right": "10px" }
                }, {
                  default: I(() => [
                    Ce(se(x(u)), 1)
                  ]),
                  _: 1
                })) : j("", !0),
                y.showConfirm ? (m(), R(P, {
                  key: 1,
                  theme: "primary",
                  type: "submit",
                  style: { "margin-right": "10px" }
                }, {
                  default: I(() => [
                    Ce(se(x(r)), 1)
                  ]),
                  _: 1
                })) : j("", !0)
              ]),
              _: 1
            })) : j("", !0)
          ]),
          _: 3
        }, 8, ["data", "labelAlign", "rules", "labelWidth", "disabled", "colon"])
      ]);
    };
  }
}), jg = ne({
  __name: "geojsonLayer",
  props: {
    layerName: {},
    data: {},
    options: {}
  },
  emits: ["clicked"],
  setup(e, { emit: t }) {
    const n = e, a = t, o = ae(null), l = Qe("map"), { layerName: i, options: r, data: u } = Ge(n), s = () => {
      if (i && u) {
        o.value && l.value.removeGeoJSONLayer(o.value);
        let d = { dataSource: u.value, reference: "BD09LL" };
        n.options && (d = Object.assign(d, r == null ? void 0 : r.value)), o.value = new window.BMapGL.GeoJSONLayer(n.layerName, d), l.value.addGeoJSONLayer(o.value), o.value && o.value.addEventListener("click", (c) => {
          a("clicked", c);
        });
      }
    };
    return De(() => {
      l.value && s();
    }), ie(l, () => {
      l.value && s();
    }), Vt(() => {
      l.value && (l.value.removeEventListener("click"), o.value.removeEventListener("click"), l.value.removeOverlay(o.value));
    }), (d, c) => (m(), U("div"));
  }
}), fs = ne({
  __name: "MyTransfer",
  props: {
    data: { default: [] },
    keys: { default: { label: "label", value: "value" } },
    value: { default: [] },
    treeConfig: { type: Boolean, default: !1 },
    pagination: { default: void 0 },
    searchable: { type: Boolean, default: !1 }
  },
  emits: ["change", "update:value"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = Re({
      value: a.value || [],
      checkedValue: []
    }), l = (...u) => {
    }, i = ({ checked: u, sourceChecked: s, targetChecked: d, type: c }) => {
      o.checkedValue = u;
    }, r = (u, s) => {
      o.value = u, n("change", { newTargetValue: u, ctx: s }), n("update:value", u);
    };
    return (u, s) => {
      const d = Vn, c = Mi;
      return m(), R(c, {
        modelValue: o.value,
        data: a.data,
        keys: a.keys,
        class: "tdesign-transfer-custom",
        onChange: r,
        onCheckedChange: i,
        theme: "primary",
        search: u.searchable,
        "checked-value": o.checkedValue,
        pagination: u.pagination,
        "on-page-change": l
      }, Te({
        title: I((f) => [
          V("div", null, se(f.type === "target" ? "目标" : "来源"), 1)
        ]),
        default: I(() => [
          te(u.$slots, "transferItem", {}, void 0, !0)
        ]),
        _: 2
      }, [
        a.treeConfig ? {
          name: "tree",
          fn: I((f) => [
            K(d, Dt({ style: { height: "calc(100% - 16px)" } }, f, {
              checkable: "",
              hover: "",
              "expand-all": "",
              transition: "",
              scroll: { bufferSize: 20, type: "virtual" }
            }), null, 16)
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["modelValue", "data", "keys", "search", "checked-value", "pagination"]);
    };
  }
}), qg = /* @__PURE__ */ xe(fs, [["__scopeId", "data-v-021c1854"]]);
var sl;
sl = { __e: function(e, t, n, a) {
  for (var o, l, i; t = t.__; )
    if ((o = t.__c) && !o.__)
      try {
        if ((l = o.constructor) && l.getDerivedStateFromError != null && (o.setState(l.getDerivedStateFromError(e)), i = o.__d), o.componentDidCatch != null && (o.componentDidCatch(e, a || {}), i = o.__d), i)
          return o.__E = o;
      } catch (r) {
        e = r;
      }
  throw e;
} }, typeof Promise == "function" && Promise.prototype.then.bind(Promise.resolve());
const Jg = ne({
  __name: "SingleCustomOverlay",
  props: {
    createDOM_fn: {},
    point: {},
    options: {},
    setCenter: { type: Boolean },
    canCustomOverlay: { type: Boolean, default: !0 }
  },
  emits: ["clicked", "marked"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(null), l = Qe("map"), { point: i } = Ge(a), r = {
      MinZoom: 21,
      MaxZoom: 21
    }, u = () => {
      if (a.createDOM_fn && i.value && i.value.lon && i.value.lat && l.value) {
        o.value && l.value.removeOverlay(o.value);
        const s = new window.BMapGL.Point(
          i.value.lon,
          i.value.lat
        );
        o.value = new window.BMapGL.CustomOverlay(
          a.createDOM_fn,
          {
            point: s,
            ...r,
            ...sl
          }
        ), l.value.addOverlay(o.value), o.value && o.value.addEventListener("click", function() {
          n("clicked", { CustomOverlay: i.value });
        }), a.setCenter && l.value.setCenter(i.value);
      }
    };
    return ie(() => a.createDOM_fn, () => {
      u();
    }, { immediate: !0, deep: !0 }), De(() => {
      l.value && u();
    }), ie(l, () => {
      l.value && (u(), a.canCustomOverlay && l.value.addEventListener("click", function(s) {
        o.value && l.value.removeOverlay(o.value), o.value = new window.BMapGL.Marker(
          new window.BMapGL.Point(s.latlng.lng, s.latlng.lat)
        ), l.value.addOverlay(o.value), n("marked", { point: s.latlng });
      }));
    }), Vt(() => {
      var s;
      l.value && (l.value.removeEventListener("click"), (s = o.value) == null || s.removeEventListener("click"), l.value.removeOverlay(o.value));
    }), (s, d) => (m(), U("div"));
  }
}), ps = ne({
  __name: "MyModal",
  props: {
    show: { type: Boolean, default: !1 },
    placement: { default: "center" },
    top: {},
    header: { type: [String, Boolean], default: "" },
    body: {},
    theme: { default: "default" },
    showFooter: { type: Boolean, default: !1 },
    width: { default: "40%" },
    confirmBtn: { default: "确认" },
    cancelBtn: { default: "取消" },
    draggable: { type: Boolean, default: !1 },
    mode: { default: "modal" },
    showOverlay: { type: Boolean, default: !0 },
    attach: { default: "" },
    destroyOnClose: { type: Boolean, default: !1 }
  },
  emits: ["update:visible", "cancel", "confirm", "close"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    let { show: o } = Ge(a);
    const l = ae(!1), i = () => {
      n("update:visible", !1), n("close");
    }, r = () => {
      n("update:visible", !1), n("cancel");
    }, u = () => {
      n("update:visible", !1), n("confirm");
    };
    return ie(o, (s, d) => {
      l.value = s;
    }), (s, d) => {
      const c = Bo;
      return m(), U("div", null, [
        K(c, {
          visible: l.value,
          "onUpdate:visible": d[0] || (d[0] = (f) => l.value = f),
          header: s.header,
          body: s.body,
          footer: s.showFooter,
          cancelBtn: s.cancelBtn,
          confirmBtn: s.confirmBtn,
          theme: s.theme,
          placement: s.placement,
          width: s.width,
          onClose: i,
          onCancel: r,
          mode: s.mode,
          draggable: s.draggable,
          showOverlay: s.showOverlay,
          attach: s.attach,
          destroyOnClose: s.destroyOnClose,
          onConfirm: u
        }, {
          body: I(() => [
            te(s.$slots, "default", {}, void 0, !0)
          ]),
          _: 3
        }, 8, ["visible", "header", "body", "footer", "cancelBtn", "confirmBtn", "theme", "placement", "width", "mode", "draggable", "showOverlay", "attach", "destroyOnClose"])
      ]);
    };
  }
}), Qg = /* @__PURE__ */ xe(ps, [["__scopeId", "data-v-b0781ed4"]]);
/*!
  * vue-router v4.4.0
  * (c) 2024 Eduardo San Martin Morote
  * @license MIT
  */
var oa;
(function(e) {
  e.pop = "pop", e.push = "push";
})(oa || (oa = {}));
var aa;
(function(e) {
  e.back = "back", e.forward = "forward", e.unknown = "";
})(aa || (aa = {}));
Symbol(process.env.NODE_ENV !== "production" ? "navigation failure" : "");
var la;
(function(e) {
  e[e.aborted = 4] = "aborted", e[e.cancelled = 8] = "cancelled", e[e.duplicated = 16] = "duplicated";
})(la || (la = {}));
Symbol(process.env.NODE_ENV !== "production" ? "router view location matched" : "");
Symbol(process.env.NODE_ENV !== "production" ? "router view depth" : "");
const hs = Symbol(process.env.NODE_ENV !== "production" ? "router" : "");
Symbol(process.env.NODE_ENV !== "production" ? "route location" : "");
Symbol(process.env.NODE_ENV !== "production" ? "router view location" : "");
function ms() {
  return Qe(hs);
}
const gs = (e) => (ht("data-v-5a4f1d7e"), e = e(), mt(), e), vs = {
  class: /* @__PURE__ */ Pe(["flex flex-col items-start font-bold pl-5 bread-crumb "])
}, ys = /* @__PURE__ */ gs(() => /* @__PURE__ */ V("div", { class: "bread-crumb-divider" }, null, -1)), bs = { class: "font-semibold inline-flex items-center text-base bread-crumb-header" }, _s = {
  key: 0,
  class: "bread-crumb-tabs"
}, ws = ne({
  __name: "MyBreadCrumb",
  props: {
    title: {},
    tabs: {},
    defaultTabValue: {},
    canBack: { type: Boolean },
    backLocation: {},
    preGoBack: { type: Function }
  },
  emits: ["tabsChange"],
  setup(e, { emit: t }) {
    var u;
    const n = e, a = t, o = ms(), l = Re({
      // 显示列表页
      pageTitle: n.title,
      // 当前点击的tab
      currentTab: ((u = n.tabs) == null ? void 0 : u.find((s) => s.value === n.defaultTabValue)) || (n.tabs ? n.tabs[0] : {
        label: "",
        // 当前值
        value: "",
        // tab的对应route
        href: ""
      }),
      // 整体可变的高度
      height: n.tabs ? "h-20" : "h-9"
    });
    ie(() => n.defaultTabValue, () => {
      var d;
      const s = (d = n.tabs) == null ? void 0 : d.find((c) => c.value === n.defaultTabValue);
      s && (l.currentTab = s);
    });
    const i = (s) => {
      l.pageTitle = s;
    }, r = (s) => {
      if (n.tabs) {
        const d = n.tabs.find((c) => c.value === s);
        d && (l.currentTab = d, o && l.currentTab.href && o.push(l.currentTab.href), a("tabsChange", d));
      }
    };
    return rt("setPageTitle", i), (s, d) => {
      const c = Fe, f = zn, h = Rn;
      return m(), U(ve, null, [
        V("div", vs, [
          ys,
          V("div", bs, [
            s.canBack ? (m(), R(c, {
              key: 0,
              onClick: d[0] || (d[0] = () => {
                s.preGoBack && s.preGoBack(), l.pageTitle = s.title, s.backLocation ? s.$router.push(s.backLocation) : s.$router.go(-1);
              }),
              style: { color: "var(--td-brand-color)" },
              class: "mr-2 cursor-pointer",
              size: "large",
              name: "arrow-left"
            })) : j("", !0),
            V("span", null, se(l.pageTitle), 1)
          ]),
          s.tabs ? (m(), U("div", _s, [
            K(h, {
              value: l.currentTab.value,
              "default-value": s.defaultTabValue,
              onChange: r
            }, {
              default: I(() => [
                (m(!0), U(ve, null, be(s.tabs, (y) => (m(), R(f, {
                  value: y.value,
                  label: y.label
                }, null, 8, ["value", "label"]))), 256))
              ]),
              _: 1
            }, 8, ["value", "default-value"])
          ])) : j("", !0)
        ]),
        te(s.$slots, "default", {}, void 0, !0)
      ], 64);
    };
  }
}), Cs = /* @__PURE__ */ xe(ws, [["__scopeId", "data-v-5a4f1d7e"]]), ev = /* @__PURE__ */ ne({
  __name: "MySteps",
  props: {
    defaultCurrent: {},
    separator: {
      default: "line"
    },
    theme: {
      default: "default"
    },
    layout: {
      default: "horizontal"
    },
    options: {},
    sequence: {
      default: "positive"
    },
    onChange: {},
    value: {}
  },
  setup(e) {
    const t = ae(n.value), n = e;
    return (a, o) => {
      const l = Bi;
      return m(), R(l, {
        options: a.options,
        modelValue: t.value,
        "onUpdate:modelValue": o[0] || (o[0] = (i) => t.value = i),
        separator: a.separator,
        theme: a.theme,
        sequence: a.sequence,
        layout: a.layout,
        "default-current": a.defaultCurrent,
        readonly: ""
      }, {
        default: I(() => [te(a.$slots, "default")]),
        _: 3
      }, 8, ["options", "modelValue", "separator", "theme", "sequence", "layout", "default-current"]);
    };
  }
}), Es = { class: "my-layout" }, ks = { class: "my-layout-content-left" }, Ns = { class: "my-layout-content-right" }, Ss = {
  key: 0,
  class: "my-layout-content-right-header"
}, xs = { key: 0 }, $s = { key: 1 }, Ms = {
  key: 0,
  class: "my-layout-content-right-header"
}, Bs = { key: 0 }, Is = { key: 1 }, tv = ne({
  __name: "MyLayout",
  props: {
    theme: { default: "default" },
    crumb: {},
    showCrumb: { type: Boolean, default: !0 },
    card: {},
    showHeader: { type: Boolean, default: !0 },
    tree: {}
  },
  setup(e) {
    const t = e, { card: n, crumb: a, tree: o } = Ge(t), l = ae(), i = ae();
    return De(() => {
      l.value && i.value && (i.value.style.height = `calc(100% - ${l.value.clientHeight}px)`);
    }), (r, u) => {
      const s = Fe, d = ut, c = Nt;
      return m(), U("div", Es, [
        r.showHeader || r.$slots.header ? (m(), U("div", {
          key: 0,
          class: "my-layout-header",
          ref_key: "headerDom",
          ref: l
        }, [
          te(r.$slots, "header", {}, () => {
            var f, h, y, b;
            return [
              K(Cs, {
                title: ((f = x(a)) == null ? void 0 : f.title) || "",
                tabs: (h = x(a)) == null ? void 0 : h.tabs,
                defaultTabValue: (y = x(a)) == null ? void 0 : y.defaultTabValue,
                "can-back": (b = x(a)) == null ? void 0 : b.canBack
              }, null, 8, ["title", "tabs", "defaultTabValue", "can-back"])
            ];
          })
        ], 512)) : j("", !0),
        V("div", {
          class: Pe(`my-layout-content my-layout-theme-${r.theme}`),
          ref_key: "contentDom",
          ref: i
        }, [
          r.theme === "horizontal" ? (m(), U(ve, { key: 0 }, [
            V("div", ks, [
              te(r.$slots, "content-left", {}, () => [
                K(wt, null, {
                  default: I(() => [
                    x(o) ? (m(), R(sr, {
                      key: 0,
                      class: "my-layout-tree",
                      showSlot: !0,
                      searchShow: "",
                      data: x(o).data,
                      expandAll: "",
                      activable: ""
                    }, {
                      icon: I(({ node: f }) => [
                        !f.expanded && f.getChildren() ? (m(), R(s, {
                          key: 0,
                          name: "chevron-right"
                        })) : f.getChildren() && f.expanded ? (m(), R(s, {
                          key: 1,
                          name: "chevron-down"
                        })) : j("", !0)
                      ]),
                      _: 1
                    }, 8, ["data"])) : j("", !0)
                  ]),
                  _: 1
                })
              ])
            ]),
            V("div", Ns, [
              te(r.$slots, "content", {}, () => {
                var f, h, y;
                return [
                  K(wt, {
                    header: ((f = x(n)) == null ? void 0 : f.header) || "",
                    loading: (h = x(n)) == null ? void 0 : h.loading
                  }, Te({ _: 2 }, [
                    r.$slots.cardHeader || (y = x(n)) != null && y.headerConfig ? {
                      name: "header",
                      fn: I(() => [
                        te(r.$slots, "cardHeader", {}, () => {
                          var b;
                          return [
                            (b = x(n)) != null && b.headerConfig ? (m(), U("div", Ss, [
                              x(n).headerConfig.left ? (m(), U("div", xs, [
                                K(d, {
                                  onClick: x(n).headerConfig.left[0].click
                                }, {
                                  default: I(() => [
                                    Ce(se(x(n).headerConfig.left[0].name), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ])) : j("", !0),
                              x(n).headerConfig.right ? (m(), U("div", $s, [
                                K(c, {
                                  modelValue: x(n).headerConfig.right[0].value,
                                  "onUpdate:modelValue": u[0] || (u[0] = (p) => x(n).headerConfig.right[0].value = p),
                                  class: "my-layout-content-search-input-container",
                                  placeholder: x(n).headerConfig.right[0].placeholder,
                                  onChange: x(n).headerConfig.right[0].change
                                }, {
                                  suffix: I(() => [
                                    K(d, {
                                      style: { padding: "0 10px" },
                                      variant: "text",
                                      onClick: x(n).headerConfig.right[0].click
                                    }, {
                                      default: I(() => [
                                        K(s, { name: "search" })
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "placeholder", "onChange"])
                              ])) : j("", !0)
                            ])) : j("", !0)
                          ];
                        })
                      ]),
                      key: "0"
                    } : void 0,
                    r.$slots.cardContent ? {
                      name: "default",
                      fn: I(() => [
                        te(r.$slots, "cardContent")
                      ]),
                      key: "1"
                    } : void 0
                  ]), 1032, ["header", "loading"])
                ];
              })
            ])
          ], 64)) : te(r.$slots, "content", { key: 1 }, () => {
            var f, h, y;
            return [
              K(wt, {
                header: ((f = x(n)) == null ? void 0 : f.header) || "",
                loading: (h = x(n)) == null ? void 0 : h.loading
              }, Te({ _: 2 }, [
                r.$slots.cardHeader || (y = x(n)) != null && y.headerConfig ? {
                  name: "header",
                  fn: I(() => [
                    te(r.$slots, "cardHeader", {}, () => {
                      var b;
                      return [
                        (b = x(n)) != null && b.headerConfig ? (m(), U("div", Ms, [
                          x(n).headerConfig.left ? (m(), U("div", Bs, [
                            K(d, {
                              onClick: x(n).headerConfig.left[0].click
                            }, {
                              default: I(() => [
                                Ce(se(x(n).headerConfig.left[0].name), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])) : j("", !0),
                          x(n).headerConfig.right ? (m(), U("div", Is, [
                            K(c, {
                              modelValue: x(n).headerConfig.right[0].value,
                              "onUpdate:modelValue": u[1] || (u[1] = (p) => x(n).headerConfig.right[0].value = p),
                              class: "my-layout-content-search-input-container",
                              placeholder: x(n).headerConfig.right[0].placeholder,
                              onChange: x(n).headerConfig.right[0].change
                            }, {
                              suffix: I(() => [
                                K(d, {
                                  style: { padding: "0 10px" },
                                  variant: "text",
                                  onClick: x(n).headerConfig.right[0].click
                                }, {
                                  default: I(() => [
                                    K(s, { name: "search" })
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "placeholder", "onChange"])
                          ])) : j("", !0)
                        ])) : j("", !0)
                      ];
                    })
                  ]),
                  key: "0"
                } : void 0,
                r.$slots.cardContent ? {
                  name: "default",
                  fn: I(() => [
                    te(r.$slots, "cardContent")
                  ]),
                  key: "1"
                } : void 0
              ]), 1032, ["header", "loading"])
            ];
          })
        ], 2)
      ]);
    };
  }
}), Ds = { class: "my-legend" }, Vs = {
  key: 0,
  class: "my-legend-header"
}, As = { class: "my-legend-body" }, Fs = {
  key: 0,
  class: "my-legend-items my-legend-item-checkboxs"
}, Ts = { class: "my-legend-item" }, Os = ["onChange", "checked"], Ps = { class: "my-legend-items my-legend-item-labels" }, Ls = { class: "my-legend-item" }, zs = { class: "my-legend-item-label" }, Rs = { class: "my-legend-items my-legend-item-colorboxs" }, Hs = { class: "my-legend-item" }, Us = ne({
  __name: "MyLegend",
  props: {
    title: {},
    list: {},
    check: { type: Boolean },
    checkedAll: { type: Boolean }
  },
  emits: ["onChange"],
  setup(e, { emit: t }) {
    const n = e, a = Re({
      list: {
        label: [],
        checked: [],
        colors: []
      }
    }), o = t;
    ie(() => (n.list, n.checkedAll), () => {
      n.list && n.list.length > 0 && (a.list = {
        labels: [],
        checkeds: [],
        colors: []
      }, n.list.forEach((i, r) => {
        a.list.labels[r] = i.label, a.list.colors[r] = i.color, n.checkedAll ? a.list.checkeds[r] = !0 : a.list.checkeds[r] = i.checked !== void 0 ? i.checked : !1;
      }));
    }, { immediate: !0 });
    const l = (i, r) => {
      o("onChange", { value: i.target.checked, item: n.list[r] });
    };
    return (i, r) => (m(), U("div", Ds, [
      i.title ? (m(), U("div", Vs, se(i.title), 1)) : j("", !0),
      V("div", As, [
        i.check ? (m(), U("div", Fs, [
          (m(!0), U(ve, null, be(a.list.checkeds, (u, s) => (m(), U("div", Ts, [
            V("input", {
              onChange: (d) => {
                l(d, s);
              },
              class: "my-legend-item-checkbox",
              type: "checkbox",
              checked: u
            }, null, 40, Os)
          ]))), 256))
        ])) : j("", !0),
        V("div", Ps, [
          (m(!0), U(ve, null, be(a.list.labels, (u) => (m(), U("div", Ls, [
            V("span", zs, se(u), 1)
          ]))), 256))
        ]),
        V("div", Rs, [
          (m(!0), U(ve, null, be(a.list.colors, (u) => (m(), U("div", Hs, [
            V("div", {
              class: "my-legend-item-colorbox",
              style: pe({ backgroundColor: u })
            }, null, 4)
          ]))), 256))
        ])
      ])
    ]));
  }
}), nv = /* @__PURE__ */ xe(Us, [["__scopeId", "data-v-5bb46f7b"]]), Gs = { class: "search" }, Zs = { class: "tree-label" }, Ys = { class: "tree-label-content" }, Ks = {
  key: 0,
  class: "tree-label-icon"
}, Xs = { class: "tree-label-text" }, Ws = {
  key: 0,
  class: "node-children-length"
}, js = ne({
  __name: "MyModelDiffViewer",
  props: {
    tabsData: {},
    tabsValue: {},
    treeData: {},
    treeKeys: {},
    treeScroll: {}
  },
  emits: ["update:tabsValue", "tabsChange", "treeNodeClick", "treeNodeExpand"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    let o = { label: "label", value: "value", children: "children" };
    a.treeKeys && (o = Object.assign(o, a.treeKeys));
    const l = ae(), i = Re({
      // 搜索框的值
      search: "",
      // tree筛选的方法
      treeFilter: null,
      // tree 虚拟滚动配置
      treeScroll: Object.assign({ bufferSize: 10, threshold: 100, type: "lazy" }, a.treeScroll),
      // tree 展开的节点
      treeExpanded: [],
      // tree 高亮的值
      treeActived: []
    }), r = (f) => {
      n("update:tabsValue", f), n("tabsChange", f), u();
    }, u = () => {
      i.search !== "" ? i.treeFilter = (f) => {
        var h;
        return ((h = f.label) == null ? void 0 : h.includes(i.search)) || !1;
      } : i.treeFilter = null;
    }, s = (f) => {
      const h = f.e.target, y = f.node.value;
      !c(h) && y !== void 0 && (i.treeActived = [y], n("treeNodeClick", f));
    }, d = (f, h) => {
      const y = h.e.target;
      c(y) && (i.treeExpanded = f, n("treeNodeExpand", { node: h.node, e: h.e, value: f, expanded: !h.node.expanded }));
    }, c = (f) => {
      var y, b;
      let h = !1;
      return f && ([...f.classList].includes("node") || ((b = (y = f.attributes) == null ? void 0 : y.trigger) == null ? void 0 : b.nodeValue) === "expand" || [...f.parentNode.classList].includes("node")) && (h = !0), h;
    };
    return (f, h) => {
      const y = zn, b = Rn, p = Fe, w = Vn;
      return m(), R(wt, { padding: "0px" }, {
        default: I(() => [
          K(b, {
            value: f.tabsValue,
            onChange: r
          }, {
            default: I(() => [
              (m(!0), U(ve, null, be(f.tabsData, (k) => (m(), R(y, {
                key: k.value,
                value: k.value,
                label: k.label
              }, null, 8, ["value", "label"]))), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          V("div", Gs, [
            K(p, {
              class: "search-icon",
              name: "search"
            }),
            Xt(V("input", {
              type: "text",
              class: "search-input",
              "onUpdate:modelValue": h[0] || (h[0] = (k) => i.search = k),
              onInput: u
            }, null, 544), [
              [Wt, i.search]
            ])
          ]),
          K(w, {
            ref_key: "treeDom",
            ref: l,
            hover: "",
            data: f.treeData,
            keys: x(o),
            activable: "",
            expandOnClickNode: "",
            filter: i.treeFilter,
            scroll: i.treeScroll,
            onClick: s,
            onExpand: d,
            expanded: i.treeExpanded,
            actived: i.treeActived
          }, {
            label: I(({ node: k }) => {
              var E;
              return [
                V("div", Zs, [
                  V("div", Ys, [
                    k.data.icon ? (m(), U("div", Ks, [
                      typeof k.data.icon == "string" ? (m(), R(p, {
                        key: 0,
                        style: { color: "#999999" },
                        size: "14px",
                        name: k.data.icon
                      }, null, 8, ["name"])) : j("", !0),
                      typeof k.data.icon != "string" ? (m(), R(it(k.data.icon), { key: 1 })) : j("", !0)
                    ])) : j("", !0),
                    V("span", Xs, se(k.label), 1)
                  ]),
                  k.getChildren() ? (m(), U("div", Ws, "(" + se((E = k.getChildren()) == null ? void 0 : E.length) + ")", 1)) : j("", !0)
                ])
              ];
            }),
            icon: I(({ node: k }) => [
              !k.expanded && k.getChildren() ? (m(), R(p, {
                key: 0,
                class: "node",
                size: "14px",
                name: "chevron-right"
              })) : k.getChildren() && k.expanded ? (m(), R(p, {
                key: 1,
                class: "node",
                size: "14px",
                name: "chevron-down"
              })) : j("", !0)
            ]),
            _: 1
          }, 8, ["data", "keys", "filter", "scroll", "expanded", "actived"])
        ]),
        _: 1
      });
    };
  }
}), ov = /* @__PURE__ */ xe(js, [["__scopeId", "data-v-2983463e"]]), qs = (e) => (ht("data-v-11ee658d"), e = e(), mt(), e), Js = { class: "object-diff-viewer" }, Qs = { class: "object-diff-viewer-table-empty" }, eu = /* @__PURE__ */ qs(() => /* @__PURE__ */ V("svg", {
  width: "106px",
  height: "90px",
  viewBox: "0 0 122 104",
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}, [
  /* @__PURE__ */ V("g", {
    id: "Page-1",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, [
    /* @__PURE__ */ V("g", {
      id: "空白页",
      transform: "translate(-810.000000, -352.000000)"
    }, [
      /* @__PURE__ */ V("g", {
        id: "暂无数据",
        transform: "translate(810.000000, 352.000000)"
      }, [
        /* @__PURE__ */ V("g", { id: "分组-6" }, [
          /* @__PURE__ */ V("g", {
            id: "背景/线",
            fill: "#C3CBD6"
          }, [
            /* @__PURE__ */ V("g", { id: "-" }, [
              /* @__PURE__ */ V("g", {
                id: "编组",
                transform: "translate(14.200000, 9.200000)",
                "fill-rule": "nonzero"
              }, [
                /* @__PURE__ */ V("path", {
                  d: "M0.8,73.1 C0.3581722,73.1 -5.68434189e-14,72.7418278 -5.68434189e-14,72.3 C-5.68434189e-14,71.8581722 0.3581722,71.5 0.8,71.5 L70.8,71.5 C71.2418278,71.5 71.6,71.8581722 71.6,72.3 C71.6,72.7418278 71.2418278,73.1 70.8,73.1 L0.8,73.1 Z M74.8,73.1 C74.3581722,73.1 74,72.7418278 74,72.3 C74,71.8581722 74.3581722,71.5 74.8,71.5 L77.3,71.5 C77.7418278,71.5 78.1,71.8581722 78.1,72.3 C78.1,72.7418278 77.7418278,73.1 77.3,73.1 L74.8,73.1 Z M83.8,73.1 C83.3581722,73.1 83,72.7418278 83,72.3 C83,71.8581722 83.3581722,71.5 83.8,71.5 L92.8,71.5 C93.2418278,71.5 93.6,71.8581722 93.6,72.3 C93.6,72.7418278 93.2418278,73.1 92.8,73.1 L83.8,73.1 Z M23.8,80.6 C23.3581722,80.6 23,80.2418278 23,79.8 C23,79.3581722 23.3581722,79 23.8,79 L30.8,79 C31.2418278,79 31.6,79.3581722 31.6,79.8 C31.6,80.2418278 31.2418278,80.6 30.8,80.6 L23.8,80.6 Z M35.3,80.6 C34.8581722,80.6 34.5,80.2418278 34.5,79.8 C34.5,79.3581722 34.8581722,79 35.3,79 L65.8,79 C66.2418278,79 66.6,79.3581722 66.6,79.8 C66.6,80.2418278 66.2418278,80.6 65.8,80.6 L35.3,80.6 Z M80,52.8 C80,51.7333333 81.6,51.7333333 81.6,52.8 L81.6,55.8 C81.6,56.2418278 81.2418278,56.6 80.8,56.6 L77.8,56.6 C76.7333333,56.6 76.7333333,55 77.8,55 L80,55 L80,52.8 Z M81.6,58.8 C81.6,59.8666667 80,59.8666667 80,58.8 L80,55.8 C80,55.3581722 80.3581722,55 80.8,55 L83.8,55 C84.8666667,55 84.8666667,56.6 83.8,56.6 L81.6,56.6 L81.6,58.8 Z M4,28.8 C4,27.7333333 5.6,27.7333333 5.6,28.8 L5.6,31.8 C5.6,32.2418278 5.2418278,32.6 4.8,32.6 L1.8,32.6 C0.733333333,32.6 0.733333333,31 1.8,31 L4,31 L4,28.8 Z M78.1,3.5 L80.8,3.5 C81.8666667,3.5 81.8666667,5.1 80.8,5.1 L78.1,5.1 L78.1,7.8 C78.1,8.86666667 76.5,8.86666667 76.5,7.8 L76.5,5.1 L73.8,5.1 C72.7333333,5.1 72.7333333,3.5 73.8,3.5 L76.5,3.5 L76.5,0.8 C76.5,-0.266666667 78.1,-0.266666667 78.1,0.8 L78.1,3.5 Z M5.6,34.8 C5.6,35.8666667 4,35.8666667 4,34.8 L4,31.8 C4,31.3581722 4.3581722,31 4.8,31 L7.8,31 C8.86666667,31 8.86666667,32.6 7.8,32.6 L5.6,32.6 L5.6,34.8 Z",
                  id: "Path-2"
                }),
                /* @__PURE__ */ V("path", {
                  d: "M14.0928932,61.1431458 C14.5642977,60.6717412 15.2714045,61.378848 14.8,61.8502525 L13.7393398,62.9109127 C13.5440777,63.1061748 13.2274952,63.1061748 13.032233,62.9109127 L11.9715729,61.8502525 C11.5001684,61.378848 12.2072751,60.6717412 12.6786797,61.1431458 L13.3857864,61.8502525 L14.0928932,61.1431458 Z M12.6786797,63.9715729 C12.2072751,64.4429774 11.5001684,63.7358706 11.9715729,63.2644661 L13.032233,62.2038059 C13.2274952,62.0085438 13.5440777,62.0085438 13.7393398,62.2038059 L14.8,63.2644661 C15.2714045,63.7358706 14.5642977,64.4429774 14.0928932,63.9715729 L13.3857864,63.2644661 L12.6786797,63.9715729 Z M22.9213203,8.8 C23.3927249,8.32859548 24.0998316,9.03570226 23.6284271,9.50710678 L22.567767,10.567767 C22.3725048,10.7630291 22.0559223,10.7630291 21.8606602,10.567767 L20.8,9.50710678 C20.3285955,9.03570226 21.0357023,8.32859548 21.5071068,8.8 L22.2142136,9.50710678 L22.9213203,8.8 Z M21.5071068,11.6284271 C21.0357023,12.0998316 20.3285955,11.3927249 20.8,10.9213203 L21.8606602,9.86066017 C22.0559223,9.66539803 22.3725048,9.66539803 22.567767,9.86066017 L23.6284271,10.9213203 C24.0998316,11.3927249 23.3927249,12.0998316 22.9213203,11.6284271 L22.2142136,10.9213203 L21.5071068,11.6284271 Z",
                  id: "Path复制"
                })
              ])
            ])
          ]),
          /* @__PURE__ */ V("g", {
            id: "Group-3",
            transform: "translate(44.000000, 33.000000)"
          }, [
            /* @__PURE__ */ V("path", {
              d: "M34,13 C34,14 34,36.3333333 34,38 C34,39.1045695 33.1045695,40 32,40 L2,40 C0.8954305,40 0,39.1045695 0,38 L0,2 C0,0.8954305 0.8954305,0 2,0 L22,0",
              id: "Rectangle-4",
              fill: "#FFFFFF"
            }),
            /* @__PURE__ */ V("path", {
              d: "M32.4,13.6255741 L21.29947,1.6 L2,1.6 C1.7790861,1.6 1.6,1.7790861 1.6,2 L1.6,38 C1.6,38.2209139 1.7790861,38.4 2,38.4 L32,38.4 C32.2209139,38.4 32.4,38.2209139 32.4,38 C32.4,37.536087 32.4,37.536087 32.4,36.5248672 C32.4,35.2790874 32.4,35.2790874 32.4,33.6423742 C32.4,29.8998278 32.4,29.8998278 32.4,25.531788 C32.4,21.0228437 32.4,21.0228437 32.4,17.201898 C32.4,15.5494247 32.4,15.5494247 32.4,14.3269505 C32.4,13.9990726 32.4,13.7826584 32.4,13.6255741 Z",
              id: "Rectangle-4-Copy-2",
              stroke: "#C3CBD6",
              "stroke-width": "3.2"
            }),
            /* @__PURE__ */ V("path", {
              d: "M22.8,2.0461204 L22.8,11 C22.8,11.6627417 23.3372583,12.2 24,12.2 L32.1728119,12.2 L22.8,2.0461204 Z",
              id: "Rectangle-4-Copy",
              stroke: "#C3CBD6",
              "stroke-width": "1.6"
            }),
            /* @__PURE__ */ V("path", {
              d: "M11.4542125,11.4239785 L11.4542125,16.3586731 L11.4542125,11.4239785 Z M20.5805173,16.7015396 L16.650796,19.8070435 L20.5805173,16.7015396 Z M23.7751015,26.6475524 L18.8480956,25.5374876 L23.7751015,26.6475524 Z",
              id: "路径-7",
              stroke: "#C3CAD7",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              transform: "translate(17.000000, 20.000000) rotate(-52.000000) translate(-17.000000, -20.000000) "
            })
          ])
        ])
      ])
    ])
  ])
], -1)), tu = { class: "object-diff-viewer-table-text" }, nu = {
  key: 0,
  class: "object-diff-viewer-table-footer"
}, ou = ne({
  __name: "MyObjDiffViewer",
  props: {
    data: {},
    columns: {},
    rowKey: { default: "key" },
    empty: { default: "请选择图元" },
    tableFooter: { type: [Boolean, String], default: !0 }
  },
  emits: ["tableFooterChange"],
  setup(e, { emit: t }) {
    It((s) => ({
      58479786: u.value
    }));
    const n = e, a = t, o = ({ row: s }) => {
      if (s.children)
        return {
          colspan: n.columns.length
        };
    }, l = (s, { type: d, row: c }) => d === "expand" ? s(Fe, { name: "chevron-right", size: "12px" }) : s(Fe, { name: "chevron-down", size: "12px" }), i = {
      /** 树形结构子节点字段 */
      childrenKey: "children",
      /** 树结点在第几列渲染 */
      treeNodeColumnIndex: 0,
      /** 树结点缩进距离 */
      indent: 10,
      /** 是否默认展开全部 */
      defaultExpandAll: !0
    }, r = (s) => {
      a("tableFooterChange", s);
    }, u = me(() => n.tableFooter ? "calc(100% - 30px)" : "100%");
    return (s, d) => {
      var f;
      const c = pt;
      return m(), U("div", Js, [
        K(x(Oo), {
          class: "object-diff-viewer-table",
          rowKey: s.rowKey,
          rowspanAndColspan: o,
          columns: (f = s.columns) == null ? void 0 : f.map((h) => Object.assign({ ellipsis: !0 }, h)),
          data: s.data,
          "tree-expand-and-fold-icon": l,
          tree: i,
          empty: s.empty,
          height: "100%",
          footerAffixedBottom: ""
        }, {
          empty: I(() => [
            te(s.$slots, "emptyStyle", {}, () => [
              V("div", Qs, [
                eu,
                V("p", tu, se(s.empty), 1)
              ])
            ], !0)
          ]),
          _: 3
        }, 8, ["rowKey", "columns", "data", "empty"]),
        s.tableFooter ? (m(), U("div", nu, [
          te(s.$slots, "tableFooterStyle", {}, () => [
            K(c, { onChange: r }, {
              default: I(() => [
                Ce(se(typeof s.tableFooter == "string" ? s.tableFooter : "筛选"), 1)
              ]),
              _: 1
            })
          ], !0)
        ])) : j("", !0)
      ]);
    };
  }
}), av = /* @__PURE__ */ xe(ou, [["__scopeId", "data-v-11ee658d"]]), au = (e) => (ht("data-v-f56e3120"), e = e(), mt(), e), lu = { class: "content" }, iu = { class: "model-ouct-property-view-empty" }, ru = {
  style: { margin: "0 auto" },
  width: "106px",
  height: "90px",
  viewBox: "0 0 122 104",
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}, su = /* @__PURE__ */ au(() => /* @__PURE__ */ V("g", {
  id: "Page-1",
  stroke: "none",
  "stroke-width": "1",
  fill: "none",
  "fill-rule": "evenodd"
}, [
  /* @__PURE__ */ V("g", {
    id: "空白页",
    transform: "translate(-810.000000, -352.000000)"
  }, [
    /* @__PURE__ */ V("g", {
      id: "暂无数据",
      transform: "translate(810.000000, 352.000000)"
    }, [
      /* @__PURE__ */ V("g", { id: "分组-6" }, [
        /* @__PURE__ */ V("g", {
          id: "背景/线",
          fill: "#C3CBD6"
        }, [
          /* @__PURE__ */ V("g", { id: "-" }, [
            /* @__PURE__ */ V("g", {
              id: "编组",
              transform: "translate(14.200000, 9.200000)",
              "fill-rule": "nonzero"
            }, [
              /* @__PURE__ */ V("path", {
                d: "M0.8,73.1 C0.3581722,73.1 -5.68434189e-14,72.7418278 -5.68434189e-14,72.3 C-5.68434189e-14,71.8581722 0.3581722,71.5 0.8,71.5 L70.8,71.5 C71.2418278,71.5 71.6,71.8581722 71.6,72.3 C71.6,72.7418278 71.2418278,73.1 70.8,73.1 L0.8,73.1 Z M74.8,73.1 C74.3581722,73.1 74,72.7418278 74,72.3 C74,71.8581722 74.3581722,71.5 74.8,71.5 L77.3,71.5 C77.7418278,71.5 78.1,71.8581722 78.1,72.3 C78.1,72.7418278 77.7418278,73.1 77.3,73.1 L74.8,73.1 Z M83.8,73.1 C83.3581722,73.1 83,72.7418278 83,72.3 C83,71.8581722 83.3581722,71.5 83.8,71.5 L92.8,71.5 C93.2418278,71.5 93.6,71.8581722 93.6,72.3 C93.6,72.7418278 93.2418278,73.1 92.8,73.1 L83.8,73.1 Z M23.8,80.6 C23.3581722,80.6 23,80.2418278 23,79.8 C23,79.3581722 23.3581722,79 23.8,79 L30.8,79 C31.2418278,79 31.6,79.3581722 31.6,79.8 C31.6,80.2418278 31.2418278,80.6 30.8,80.6 L23.8,80.6 Z M35.3,80.6 C34.8581722,80.6 34.5,80.2418278 34.5,79.8 C34.5,79.3581722 34.8581722,79 35.3,79 L65.8,79 C66.2418278,79 66.6,79.3581722 66.6,79.8 C66.6,80.2418278 66.2418278,80.6 65.8,80.6 L35.3,80.6 Z M80,52.8 C80,51.7333333 81.6,51.7333333 81.6,52.8 L81.6,55.8 C81.6,56.2418278 81.2418278,56.6 80.8,56.6 L77.8,56.6 C76.7333333,56.6 76.7333333,55 77.8,55 L80,55 L80,52.8 Z M81.6,58.8 C81.6,59.8666667 80,59.8666667 80,58.8 L80,55.8 C80,55.3581722 80.3581722,55 80.8,55 L83.8,55 C84.8666667,55 84.8666667,56.6 83.8,56.6 L81.6,56.6 L81.6,58.8 Z M4,28.8 C4,27.7333333 5.6,27.7333333 5.6,28.8 L5.6,31.8 C5.6,32.2418278 5.2418278,32.6 4.8,32.6 L1.8,32.6 C0.733333333,32.6 0.733333333,31 1.8,31 L4,31 L4,28.8 Z M78.1,3.5 L80.8,3.5 C81.8666667,3.5 81.8666667,5.1 80.8,5.1 L78.1,5.1 L78.1,7.8 C78.1,8.86666667 76.5,8.86666667 76.5,7.8 L76.5,5.1 L73.8,5.1 C72.7333333,5.1 72.7333333,3.5 73.8,3.5 L76.5,3.5 L76.5,0.8 C76.5,-0.266666667 78.1,-0.266666667 78.1,0.8 L78.1,3.5 Z M5.6,34.8 C5.6,35.8666667 4,35.8666667 4,34.8 L4,31.8 C4,31.3581722 4.3581722,31 4.8,31 L7.8,31 C8.86666667,31 8.86666667,32.6 7.8,32.6 L5.6,32.6 L5.6,34.8 Z",
                id: "Path-2"
              }),
              /* @__PURE__ */ V("path", {
                d: "M14.0928932,61.1431458 C14.5642977,60.6717412 15.2714045,61.378848 14.8,61.8502525 L13.7393398,62.9109127 C13.5440777,63.1061748 13.2274952,63.1061748 13.032233,62.9109127 L11.9715729,61.8502525 C11.5001684,61.378848 12.2072751,60.6717412 12.6786797,61.1431458 L13.3857864,61.8502525 L14.0928932,61.1431458 Z M12.6786797,63.9715729 C12.2072751,64.4429774 11.5001684,63.7358706 11.9715729,63.2644661 L13.032233,62.2038059 C13.2274952,62.0085438 13.5440777,62.0085438 13.7393398,62.2038059 L14.8,63.2644661 C15.2714045,63.7358706 14.5642977,64.4429774 14.0928932,63.9715729 L13.3857864,63.2644661 L12.6786797,63.9715729 Z M22.9213203,8.8 C23.3927249,8.32859548 24.0998316,9.03570226 23.6284271,9.50710678 L22.567767,10.567767 C22.3725048,10.7630291 22.0559223,10.7630291 21.8606602,10.567767 L20.8,9.50710678 C20.3285955,9.03570226 21.0357023,8.32859548 21.5071068,8.8 L22.2142136,9.50710678 L22.9213203,8.8 Z M21.5071068,11.6284271 C21.0357023,12.0998316 20.3285955,11.3927249 20.8,10.9213203 L21.8606602,9.86066017 C22.0559223,9.66539803 22.3725048,9.66539803 22.567767,9.86066017 L23.6284271,10.9213203 C24.0998316,11.3927249 23.3927249,12.0998316 22.9213203,11.6284271 L22.2142136,10.9213203 L21.5071068,11.6284271 Z",
                id: "Path复制"
              })
            ])
          ])
        ]),
        /* @__PURE__ */ V("g", {
          id: "Group-3",
          transform: "translate(44.000000, 33.000000)"
        }, [
          /* @__PURE__ */ V("path", {
            d: "M34,13 C34,14 34,36.3333333 34,38 C34,39.1045695 33.1045695,40 32,40 L2,40 C0.8954305,40 0,39.1045695 0,38 L0,2 C0,0.8954305 0.8954305,0 2,0 L22,0",
            id: "Rectangle-4",
            fill: "#FFFFFF"
          }),
          /* @__PURE__ */ V("path", {
            d: "M32.4,13.6255741 L21.29947,1.6 L2,1.6 C1.7790861,1.6 1.6,1.7790861 1.6,2 L1.6,38 C1.6,38.2209139 1.7790861,38.4 2,38.4 L32,38.4 C32.2209139,38.4 32.4,38.2209139 32.4,38 C32.4,37.536087 32.4,37.536087 32.4,36.5248672 C32.4,35.2790874 32.4,35.2790874 32.4,33.6423742 C32.4,29.8998278 32.4,29.8998278 32.4,25.531788 C32.4,21.0228437 32.4,21.0228437 32.4,17.201898 C32.4,15.5494247 32.4,15.5494247 32.4,14.3269505 C32.4,13.9990726 32.4,13.7826584 32.4,13.6255741 Z",
            id: "Rectangle-4-Copy-2",
            stroke: "#C3CBD6",
            "stroke-width": "3.2"
          }),
          /* @__PURE__ */ V("path", {
            d: "M22.8,2.0461204 L22.8,11 C22.8,11.6627417 23.3372583,12.2 24,12.2 L32.1728119,12.2 L22.8,2.0461204 Z",
            id: "Rectangle-4-Copy",
            stroke: "#C3CBD6",
            "stroke-width": "1.6"
          }),
          /* @__PURE__ */ V("path", {
            d: "M11.4542125,11.4239785 L11.4542125,16.3586731 L11.4542125,11.4239785 Z M20.5805173,16.7015396 L16.650796,19.8070435 L20.5805173,16.7015396 Z M23.7751015,26.6475524 L18.8480956,25.5374876 L23.7751015,26.6475524 Z",
            id: "路径-7",
            stroke: "#C3CAD7",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            transform: "translate(17.000000, 20.000000) rotate(-52.000000) translate(-17.000000, -20.000000) "
          })
        ])
      ])
    ])
  ])
], -1)), uu = [
  su
], du = { class: "model-ouct-property-view-text" }, cu = {
  key: 0,
  class: "model-ouct-property-view-footer"
}, fu = ne({
  __name: "MyModelOuctPropertyView",
  props: {
    data: {},
    keyNameAlias: {},
    onlyShowKeyName: { type: Boolean },
    empty: { default: "请选择" },
    tableFooter: { type: [Boolean, String], default: !1 },
    abnormalEnable: { type: Boolean, default: !1 },
    abnormalColor: { default: "#F56C6C" },
    abnormalFn: {},
    abnormalColourObj: { default: "all" },
    defaultExpandAll: { type: Boolean, default: !0 }
  },
  emits: ["tableFooterChange"],
  setup(e, { emit: t }) {
    It((h) => ({
      "5d819993": d.value,
      "0c5648ae": c.value
    }));
    const n = e, a = t, o = ({ row: h }) => {
      if (h.children)
        return {
          colspan: i.columns.length
        };
    }, l = ae(), i = Re({
      columns: [],
      data: []
    }), r = (h, { type: y, row: b }) => y === "expand" ? h(Fe, { name: "chevron-right", size: "12px" }) : h(Fe, { name: "chevron-down", size: "12px" }), u = {
      /** 树形结构子节点字段 */
      childrenKey: "children",
      /** 树结点在第几列渲染 */
      treeNodeColumnIndex: 0,
      /** 树结点缩进距离 */
      indent: 10,
      /** 是否默认展开全部 */
      defaultExpandAll: n.defaultExpandAll
    }, s = (h) => {
      a("tableFooterChange", h);
    }, d = me(
      () => n.tableFooter ? "calc(100% - 30px)" : "100%"
    ), c = me(
      () => n.onlyShowKeyName ? "30px" : "10px"
    );
    ie(
      () => n.data,
      () => {
        if (i.columns = [], i.data = [], n.data) {
          const h = [], y = [];
          h.push({
            colKey: "key",
            title: n.keyNameAlias || "属性键",
            ellipsis: !0
          });
          const b = {}, p = {};
          Object.keys(n.data).forEach((w, k) => {
            h.push({
              colKey: `d${k}`,
              title: n.onlyShowKeyName ? "" : w,
              ellipsis: !0
            });
            const E = n.data[w];
            Object.keys(E).forEach((_) => {
              !b[_] && (b[_] = /* @__PURE__ */ new Set());
              const C = E[_];
              Object.keys(C).forEach((G) => {
                b[_].add(G), p[`${_}.${G}`] || (p[`${_}.${G}`] = []), p[`${_}.${G}`][k] = C[G];
              });
            });
          }), Object.keys(b).forEach((w) => {
            const k = { key: w, children: [] };
            b[w].forEach((E) => {
              const _ = { key: E, parent: w };
              for (let C = 0; C < h.length - 1; C++)
                _[`d${C}`] = p[`${w}.${E}`][C] === void 0 ? null : p[`${w}.${E}`][C];
              k.children.push(_);
            }), y.push(k);
          }), i.columns = h, i.data = y, l.value && n.defaultExpandAll && setTimeout(() => {
            l.value && l.value.expandAll();
          });
        }
      },
      { deep: !0, immediate: !0 }
    );
    const f = (h, y) => {
      let b = "var(--td-gray-color-9)";
      if (n.abnormalColourObj === "key" && y !== "key" || n.abnormalColourObj === "value" && y === "key")
        return b;
      if (n.abnormalFn)
        n.abnormalFn(h, y) && (b = n.abnormalColor);
      else {
        if (h.children)
          return b;
        let p = /* @__PURE__ */ new Set();
        Object.keys(h).forEach((w, k) => {
          k > 0 && (p.add(h[w]), p.size > 1 && (b = n.abnormalColor));
        });
      }
      return b;
    };
    return (h, y) => {
      const b = pt;
      return m(), U("div", lu, [
        K(x(Oo), {
          class: "model-ouct-property-view",
          rowKey: "key",
          rowspanAndColspan: o,
          columns: i.columns,
          data: i.data,
          ref_key: "tableTreeDom",
          ref: l,
          "tree-expand-and-fold-icon": r,
          tree: u,
          empty: h.empty,
          height: "100%",
          footerAffixedBottom: ""
        }, Te({
          empty: I(() => [
            te(h.$slots, "emptyStyle", {}, () => [
              V("div", iu, [
                (m(), U("svg", ru, uu)),
                V("p", du, se(h.empty), 1)
              ])
            ], !0)
          ]),
          key: I(({ row: p }) => [
            V("span", {
              class: Pe(`${p.children ? "level-1-text" : "level-2-text"}`),
              style: pe({
                color: h.abnormalEnable ? f(p, "key") : "#444444"
              })
            }, se(p.key), 7)
          ]),
          _: 2
        }, [
          be(i.columns.slice(1, i.columns.length).map((p) => p.colKey), (p) => ({
            name: p,
            fn: I(({ row: w }) => [
              V("span", {
                style: pe({
                  color: h.abnormalEnable ? f(w, p) : "#444444"
                })
              }, se(w[p]), 5)
            ])
          }))
        ]), 1032, ["columns", "data", "empty"]),
        h.tableFooter ? (m(), U("div", cu, [
          te(h.$slots, "tableFooterStyle", {}, () => [
            K(b, { onChange: s }, {
              default: I(() => [
                Ce(se(typeof h.tableFooter == "string" ? h.tableFooter : "筛选"), 1)
              ]),
              _: 1
            })
          ], !0)
        ])) : j("", !0)
      ]);
    };
  }
}), lv = /* @__PURE__ */ xe(fu, [["__scopeId", "data-v-f56e3120"]]), ln = (e) => (ht("data-v-c8c00544"), e = e(), mt(), e), pu = { class: "search" }, hu = { class: "tree-label" }, mu = { class: "tree-label-content" }, gu = { class: "tree-label-icon" }, vu = {
  key: 0,
  width: "12",
  height: "12",
  viewBox: "0 0 1024 1024",
  fill: "#999999",
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.1"
}, yu = /* @__PURE__ */ ln(() => /* @__PURE__ */ V("path", {
  d: "M192 96H832C885.019 96 928 138.981 928 192V832C928 885.019 885.019 928 832 928H192C138.981 928 96 885.019 96 832V192C96 138.981 138.981 96 192 96ZM832 160H192C174.327 160 160 174.327 160 192V352H864V192C864 174.327 849.673 160 832 160ZM160 832C160 849.673 174.327 864 192 864H352V416H160V832ZM416 864H832C849.673 864 864 849.673 864 832V416H416V864Z",
  fill: "#999999"
}, null, -1)), bu = [
  yu
], _u = ["fill"], wu = /* @__PURE__ */ ln(() => /* @__PURE__ */ V("path", { d: "M608 383.982H256V319.982H608V383.982Z" }, null, -1)), Cu = /* @__PURE__ */ ln(() => /* @__PURE__ */ V("path", { d: "M256 703.998H512V639.998H256V703.998Z" }, null, -1)), Eu = /* @__PURE__ */ ln(() => /* @__PURE__ */ V("path", { d: "M608 544H256V480H608V544Z" }, null, -1)), ku = /* @__PURE__ */ ln(() => /* @__PURE__ */ V("path", { d: "M768 864C768 899.346 739.346 928 704 928H160C124.654 928 96 899.346 96 864V160C96 124.654 124.654 96 160 96H704C739.346 96 768 124.654 768 160H864C899.346 160 928 188.654 928 224V800C928 835.346 899.346 864 864 864H768ZM704 160H160L160 864H704V160ZM768 224V800H864V224H768Z" }, null, -1)), Nu = [
  wu,
  Cu,
  Eu,
  ku
], Su = { class: "tree-label-text" }, xu = {
  key: 0,
  class: "node-children-length"
}, $u = ne({
  __name: "MyRuleCheckResultExplorer",
  props: {
    tabsData: {},
    tabsValue: {},
    treeData: {},
    treeKeys: {},
    treeScroll: {},
    sonNodeState: {}
  },
  emits: ["update:tabsValue", "tabsChange", "treeNodeClick", "treeNodeExpand"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    let o = { label: "label", value: "value", children: "children" };
    a.treeKeys && (o = Object.assign(o, a.treeKeys));
    let l = { key: "state", passValue: !0, nopassValue: !1, nopassColor: "#F56C6C", passColor: "#19BE6B" };
    a.sonNodeState && (l = Object.assign(l, a.sonNodeState));
    const i = ae(), r = Re({
      // 搜索框的值
      search: "",
      // tree筛选的方法
      treeFilter: null,
      // tree 虚拟滚动配置
      treeScroll: Object.assign({ bufferSize: 10, threshold: 100, type: "lazy" }, a.treeScroll),
      // tree 展开的节点
      treeExpanded: [],
      // tree 高亮的值
      treeActived: []
    }), u = (y) => {
      n("update:tabsValue", y), n("tabsChange", y), s();
    }, s = () => {
      r.search !== "" ? r.treeFilter = (y) => {
        var b;
        return ((b = y.label) == null ? void 0 : b.includes(r.search)) || !1;
      } : r.treeFilter = null;
    }, d = (y) => {
      const b = y.e.target, p = y.node.value;
      !f(b) && p !== void 0 && (r.treeActived = [p], n("treeNodeClick", y));
    }, c = (y, b) => {
      const p = b.e.target;
      f(p) && (r.treeExpanded = y, n("treeNodeExpand", { node: b.node, e: b.e, value: y, expanded: !b.node.expanded }));
    }, f = (y) => {
      var p, w;
      let b = !1;
      return y && ([...y.classList].includes("node") || ((w = (p = y.attributes) == null ? void 0 : p.trigger) == null ? void 0 : w.nodeValue) === "expand" || [...y.parentNode.classList].includes("node")) && (b = !0), b;
    }, h = (y) => y[l.key] === l.passValue ? l.passColor : l.nopassColor;
    return (y, b) => {
      const p = zn, w = Rn, k = Fe, E = Vn;
      return m(), R(wt, { padding: "0px" }, {
        default: I(() => [
          K(w, {
            value: y.tabsValue,
            onChange: u
          }, {
            default: I(() => [
              (m(!0), U(ve, null, be(y.tabsData, (_) => (m(), R(p, {
                key: _.value,
                value: _.value,
                label: _.label
              }, null, 8, ["value", "label"]))), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          V("div", pu, [
            K(k, {
              class: "search-icon",
              name: "search"
            }),
            Xt(V("input", {
              type: "text",
              class: "search-input",
              "onUpdate:modelValue": b[0] || (b[0] = (_) => r.search = _),
              onInput: s
            }, null, 544), [
              [Wt, r.search]
            ])
          ]),
          K(E, {
            ref_key: "treeDom",
            ref: i,
            hover: "",
            data: y.treeData,
            keys: x(o),
            activable: "",
            expandOnClickNode: "",
            filter: r.treeFilter,
            scroll: r.treeScroll,
            onClick: d,
            onExpand: c,
            expanded: r.treeExpanded,
            actived: r.treeActived
          }, {
            label: I(({ node: _ }) => {
              var C;
              return [
                V("div", hu, [
                  V("div", mu, [
                    V("div", gu, [
                      _.data[x(o).children] ? (m(), U("svg", vu, bu)) : (m(), U("svg", {
                        key: 1,
                        width: "12",
                        height: "12",
                        viewBox: "0 0 1024 1024",
                        fill: h(_.data),
                        xmlns: "http://www.w3.org/2000/svg",
                        version: "1.1"
                      }, Nu, 8, _u))
                    ]),
                    V("span", Su, se(_.label), 1)
                  ]),
                  _.getChildren() ? (m(), U("div", xu, "(" + se((C = _.getChildren()) == null ? void 0 : C.length) + ")", 1)) : j("", !0)
                ])
              ];
            }),
            icon: I(({ node: _ }) => [
              !_.expanded && _.getChildren() ? (m(), R(k, {
                key: 0,
                class: "node",
                size: "14px",
                name: "chevron-right"
              })) : _.getChildren() && _.expanded ? (m(), R(k, {
                key: 1,
                class: "node",
                size: "14px",
                name: "chevron-down"
              })) : j("", !0)
            ]),
            _: 1
          }, 8, ["data", "keys", "filter", "scroll", "expanded", "actived"])
        ]),
        _: 1
      });
    };
  }
}), iv = /* @__PURE__ */ xe($u, [["__scopeId", "data-v-c8c00544"]]), Mu = /* @__PURE__ */ ne({
  __name: "MyMenuContent",
  props: {
    navData: {
      type: Array,
      default: () => []
    },
    keys: {
      type: Object,
      default: {}
    },
    collapsed: {
      type: Boolean,
      dafult: !1
    }
  },
  emits: ["clicked"],
  setup(e, {
    emit: t
  }) {
    It((b) => ({
      "088efcbd": y.value,
      45978594: h.value
    }));
    const n = e, a = t, o = Object.assign({
      value: "path",
      name: "name",
      children: "children",
      title: "title"
    }, n.keys || {});
    me(() => "/");
    const l = me(() => {
      const {
        navData: b
      } = n;
      return s(b);
    }), i = (b) => {
      if (b.icon) {
        if (typeof b.icon == "string")
          return K(Fe, {
            name: b.icon
          }, null);
      } else
        return K("div", {
          class: "default-icon-container"
        }, [K("div", {
          class: "default-icon"
        }, null)]);
      return b.icon;
    }, r = (b) => typeof b == "string" ? b : "", u = (b) => o.value === "name" || o.value === "path" ? b[o.value] : b == null ? void 0 : b.meta[o.value], s = (b, p) => !b || b.length === 0 ? [] : (b.sort((w, k) => {
      var E, _;
      return (((E = w.meta) == null ? void 0 : E.orderNo) || 0) - (((_ = k.meta) == null ? void 0 : _.orderNo) || 0);
    }), b.map((w) => {
      var k, E;
      return {
        path: w.path,
        title: (k = w.meta) == null ? void 0 : k.title,
        name: w.name,
        icon: (E = w.meta) == null ? void 0 : E.icon,
        children: s(w.children, w.path),
        meta: w.meta || {},
        redirect: w.redirect
      };
    }).filter((w) => w.meta && w.meta.hidden !== !0)), d = (b) => {
      const {
        frameSrc: p,
        frameBlank: w
      } = b.meta;
      return p && w ? p.match(/(http|https):\/\/([\w.]+\/?)\S*/) : null;
    }, c = (b) => {
      window.open(b);
    }, f = (b) => {
      b.e.stopPropagation(), a("clicked", b);
    }, h = me(() => n.collapsed ? "" : "0px 10px 0px 40px"), y = me(() => n.collapsed ? "0px 10px 0px 24px" : "0px 10px 0px 30px");
    return (b, p) => {
      const w = Ii, k = ft("MyMenuContent", !0), E = Di;
      return m(!0), U(ve, null, be(l.value, (_) => {
        var C;
        return m(), U(ve, {
          key: _.path
        }, [!_.children || !_.children.length || (C = _.meta) != null && C.single ? (m(), U(ve, {
          key: 0
        }, [d(_) ? (m(), R(w, {
          key: 0,
          class: "t-menu-item",
          collapsed: "false",
          name: _.name,
          value: u(_),
          onClick: (G) => c(d(_)[0])
        }, {
          icon: I(() => [(m(), R(it(i(_)), {
            class: "t-icon"
          }))]),
          default: I(() => [Ce(" " + se(r(_.title)), 1)]),
          _: 2
        }, 1032, ["name", "value", "onClick"])) : (m(), R(w, {
          key: 1,
          class: "t-menu-item",
          name: _.name,
          value: u(_),
          onClick: f
        }, {
          icon: I(() => [(m(), R(it(i(_)), {
            class: "t-icon"
          }))]),
          default: I(() => [Ce(" " + se(r(_.title)), 1)]),
          _: 2
        }, 1032, ["name", "value"]))], 64)) : (m(), R(E, {
          key: 1,
          class: "t-submenu",
          name: u(_),
          value: u(_),
          title: _.title
        }, {
          icon: I(() => [(m(), R(it(i(_)), {
            class: "t-icon"
          }))]),
          default: I(() => [V("div", null, [_.children ? (m(), R(k, {
            key: 0,
            collapsed: e.collapsed,
            keys: x(o),
            "nav-data": _.children,
            onClicked: f
          }, null, 8, ["collapsed", "keys", "nav-data"])) : j("", !0)])]),
          _: 2
        }, 1032, ["name", "value", "title"]))], 64);
      }), 128);
    };
  }
}), ul = /* @__PURE__ */ xe(Mu, [["__scopeId", "data-v-b067bb8e"]]), Bu = /* @__PURE__ */ ne({
  __name: "MyMenu",
  props: {
    title: {},
    menu: {
      default: []
    },
    theme: {
      default: "light"
    },
    value: {},
    style: {},
    collapsed: {
      type: Boolean,
      default: !1
    },
    expanded: {
      default: []
    },
    keys: {},
    collapsedIcon: {}
  },
  emits: ["clicked", "update:value", "update:expanded"],
  setup(e, {
    emit: t
  }) {
    It((d) => ({
      db1e5dd6: r.value,
      "5376eb4c": u.value
    }));
    const n = t, a = e, o = (d) => {
      console.log(d.value), n("update:value", d.value), n("clicked", d);
    }, l = (d) => {
      n("update:expanded", d.value);
    }, i = ae(a.expanded);
    ie(() => a.expanded, () => {
      i.value = a.expanded;
    });
    const r = me(() => a.collapsed ? "0px 14px 0px 14px" : "0px 10px 0px 30px"), u = me(() => a.collapsed ? "0px" : "6px"), s = () => typeof a.collapsedIcon == "string" ? K(Fe, {
      name: a.collapsedIcon
    }, null) : a.collapsedIcon;
    return (d, c) => {
      const f = Vi;
      return m(), U("div", {
        class: "my-t-menu",
        style: pe(d.style || {})
      }, [K(f, {
        class: "my-t-menu",
        theme: d.theme,
        value: d.value,
        expanded: i.value,
        "onUpdate:expanded": c[0] || (c[0] = (h) => i.value = h),
        collapsed: d.collapsed,
        onExpand: l
      }, Te({
        logo: I(() => [V("div", {
          class: Pe(`${d.title ? "my-t-menu-title" : "my-t-menu-title-no"}`)
        }, [d.collapsed ? (m(), R(it(s()), {
          key: 1,
          class: "t-icon"
        })) : (m(), U(ve, {
          key: 0
        }, [Ce(se(d.title), 1)], 64))], 2)]),
        default: I(() => [K(ul, {
          "nav-data": d.menu,
          onClicked: o,
          keys: d.keys,
          collapsed: d.collapsed
        }, null, 8, ["nav-data", "keys", "collapsed"])]),
        _: 2
      }, [be(d.$slots, (h, y) => ({
        name: y,
        fn: I((b) => [te(d.$slots, y, St(zt(b)), void 0, !0)])
      }))]), 1032, ["theme", "value", "expanded", "collapsed"])], 4);
    };
  }
}), rv = /* @__PURE__ */ xe(Bu, [["__scopeId", "data-v-4f17c387"]]), Iu = { style: { display: "flex", "justify-content": "center", "align-items": "center" } }, Du = ["src"], Vu = ne({
  __name: "MyHeadMenu",
  props: {
    logo: {},
    value: {},
    menu: { default: [] },
    theme: { default: "light" },
    active: {},
    operations: {},
    style: {},
    keys: {},
    defaultValue: {}
  },
  emits: ["clicked", "operationClick", "logoClicked", "update:value"],
  setup(e, { emit: t }) {
    const n = t, a = (o) => {
      typeof o == "string" ? (n("clicked", { value: o }), n("update:value", o)) : (n("clicked", o), n("update:value", o.value));
    };
    return (o, l) => {
      const i = ut, r = Ai;
      return m(), U("div", {
        style: pe(o.style || {})
      }, [
        K(r, {
          class: "my-t-head-menu",
          theme: o.theme,
          value: o.value,
          "expand-type": "popup",
          defaultValue: o.defaultValue || ""
        }, {
          logo: I(() => [
            te(o.$slots, "logo", {}, () => {
              var u, s, d, c, f;
              return [
                V("div", Iu, [
                  (u = o.logo) != null && u.image ? (m(), U("img", {
                    key: 0,
                    src: o.logo.image,
                    width: "136"
                  }, null, 8, Du)) : (s = o.logo) != null && s.icon ? (m(), R(x(Fe), {
                    key: 1,
                    name: (d = o.logo) == null ? void 0 : d.icon,
                    size: ((c = o.logo) == null ? void 0 : c.size) || 48
                  }, null, 8, ["name", "size"])) : j("", !0),
                  (f = o.logo) != null && f.title ? (m(), U("span", {
                    key: 2,
                    class: Pe("my-side-nav-logo-wrapper"),
                    onClick: l[0] || (l[0] = (h) => o.$emit("logoClicked)"))
                  }, se(o.logo.title), 1)) : j("", !0)
                ])
              ];
            }, !0)
          ]),
          operations: I(() => [
            o.operations || o.$slots.operations ? te(o.$slots, "operations", { key: 0 }, () => [
              (m(!0), U(ve, null, be(o.operations, (u) => (m(), R(i, {
                onClick: (s) => o.$emit("operationClick", u.value),
                variant: "text",
                shape: "square"
              }, {
                icon: I(() => [
                  K(x(Fe), {
                    name: u.icon
                  }, null, 8, ["name"])
                ]),
                _: 2
              }, 1032, ["onClick"]))), 256))
            ], !0) : j("", !0)
          ]),
          default: I(() => [
            K(ul, {
              keys: o.keys,
              "nav-data": o.menu,
              onClicked: l[1] || (l[1] = (u) => a(u))
            }, null, 8, ["keys", "nav-data"])
          ]),
          _: 3
        }, 8, ["theme", "value", "defaultValue"])
      ], 4);
    };
  }
}), sv = /* @__PURE__ */ xe(Vu, [["__scopeId", "data-v-474184bc"]]), Au = ne({
  __name: "MyFullScreen",
  props: {
    bgImage: {}
  },
  setup(e) {
    return (t, n) => (m(), U("div", {
      class: "bg-cover bg-center w-full h-screen",
      style: pe({ backgroundImage: `url(${t.bgImage})` })
    }, [
      te(t.$slots, "default")
    ], 4));
  }
}), Fu = { class: "w-full mx-auto px-4 sm:px-6 lg:px-8 h-full min-h-full py-8 flex justify-between items-center" }, Tu = ne({
  __name: "MyBox",
  props: {
    title: {},
    borderImage: { default: "/border.svg" },
    titleSize: { default: "text-xs 2k:text-sm" }
  },
  setup(e) {
    return (t, n) => (m(), U("div", {
      class: "w-full h-full bg-cover bg-center bg-no-repeat relative",
      style: pe({ backgroundImage: `url(${t.borderImage})`, backgroundSize: "100% 100%" })
    }, [
      V("div", {
        class: Pe([[t.titleSize == "" ? "text-xs 2k:text-sm" : t.titleSize], "absolute font-bold mt-1"])
      }, "　" + se(t.title || ""), 3),
      V("div", Fu, [
        te(t.$slots, "default")
      ])
    ], 4));
  }
}), Ou = ne({
  components: {
    MyFullScreen: Au,
    MyBox: Tu
    // ShieldCheckIcon
  },
  props: {
    logo: {
      type: String
    },
    // 登录背景图片
    loginBg: {
      type: String
    },
    name: {
      type: String
    },
    enName: {
      type: String
    },
    loginHeader: {
      type: String
    },
    // 是否需要验证验证码
    needVerifyCode: {
      type: Boolean,
      default: !1
    },
    // 异步返回验证码的函数
    verifyCode: {
      type: Function,
      default: () => {
      }
    }
  },
  setup(e, t) {
    De(() => {
      n.value && n.value.addEventListener("keydown", (r) => {
        var u = r || window.event, s = u.keyCode || u.which || u.charCode;
        s == "13" && l();
      });
    });
    const n = ae(), a = Re({
      errors: [],
      form: {
        username: "",
        password: "",
        code: ""
      },
      // 验证码
      verifyCode: void 0
    }), o = me(() => a.errors.length > 0), l = () => {
      if (a.errors = [], a.form.username.trim() === "") {
        a.errors = ["请输入用户名"];
        return;
      }
      if (a.form.password.trim() === "") {
        a.errors = ["请输入密码"];
        return;
      }
      t.emit("login", a.form);
    };
    return {
      form: n,
      loginHandler: l,
      data: a,
      hasError: o,
      getVerifyCode: async () => {
        a.verifyCode = await e.verifyCode();
      }
    };
  }
}), Pu = {
  class: "flex items-center justify-center relative",
  style: { height: "calc(100% - 40px)" }
}, Lu = { class: "w-xl lg:w-3/4 relative" }, zu = { class: "fixed top-20 left-36 3k:left-60 text-white flex items-center space-x-4" }, Ru = ["src"], Hu = { class: "" }, Uu = { class: "text-2xl xl:text-4xl" }, Gu = { class: "text-sm xl:text-lg" }, Zu = { class: "mx-4 max-w-md" }, Yu = {
  class: "w-full h-full",
  ref: "form"
}, Ku = { class: "relative flex w-full item-center text-center" }, Xu = ["src"], Wu = /* @__PURE__ */ V("h2", { class: "absolute inset-x-0 text-white text-xl font-semibold" }, " 登录 ", -1), ju = { class: "mt-8 w-full h-full" }, qu = { class: "w-full mt-8 space-y-8 flex flex-col items-center justify-center" }, Ju = { class: "w-2/3" }, Qu = { class: "mt-2 relative flex items-center" }, ed = /* @__PURE__ */ V("div", { class: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" }, [
  /* @__PURE__ */ V("svg", {
    class: "h-5 w-5 text-white",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    "stroke-width": "1.5",
    stroke: "currentColor"
  }, [
    /* @__PURE__ */ V("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
    })
  ])
], -1), td = { class: "w-2/3 mt-2 relative flex items-center" }, nd = /* @__PURE__ */ V("div", { class: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" }, [
  /* @__PURE__ */ V("svg", {
    class: "h-5 w-5 text-white",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    "stroke-width": "1.5",
    stroke: "currentColor"
  }, [
    /* @__PURE__ */ V("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
    })
  ])
], -1), od = {
  key: 0,
  class: "w-2/3 mt-2 relative flex items-center"
}, ad = /* @__PURE__ */ V("div", { class: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" }, [
  /* @__PURE__ */ V("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    "stroke-width": "1.5",
    stroke: "currentColor",
    class: "h-5 w-5 text-white"
  }, [
    /* @__PURE__ */ V("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
    })
  ])
], -1), ld = ["src"], id = /* @__PURE__ */ V("span", { class: "px-4 text-white" }, "登录", -1);
function rd(e, t, n, a, o, l) {
  const i = ft("Button"), r = ft("MyBox"), u = ft("MyFullScreen");
  return m(), R(u, { "bg-image": e.loginBg }, {
    default: I(() => [
      V("div", Pu, [
        V("div", Lu, [
          V("div", zu, [
            V("div", null, [
              V("img", {
                src: e.logo,
                class: "w-14 h-14 xl:w-20 xl:h-20"
              }, null, 8, Ru)
            ]),
            V("div", Hu, [
              V("p", Uu, [
                V("span", null, se(e.name), 1)
              ]),
              V("p", Gu, [
                V("span", null, se(e.enName), 1)
              ])
            ])
          ])
        ]),
        V("div", Zu, [
          K(r, { class: "w-full h-full min-h-full" }, {
            default: I(() => [
              V("div", Yu, [
                V("div", Ku, [
                  V("img", { src: e.loginHeader }, null, 8, Xu),
                  Wu
                ]),
                V("div", ju, [
                  V("div", null, [
                    V("div", qu, [
                      V("div", Ju, [
                        V("div", Qu, [
                          ed,
                          Xt(V("input", {
                            "onUpdate:modelValue": t[0] || (t[0] = (s) => e.data.form.username = s),
                            name: "username",
                            type: "text",
                            placeholder: "请输入用户名称",
                            required: "",
                            style: { border: "1px #13dae3 solid" },
                            class: "text-white pl-10 appearance-none block w-full px-4 py-3 border rounded-md shadow-sm placeholder-myblue-800 focus:outline-none focus:ring-myblue focus:border-myblue sm:text-sm bg-transparent"
                          }, null, 512), [
                            [Wt, e.data.form.username]
                          ])
                        ])
                      ]),
                      V("div", td, [
                        nd,
                        Xt(V("input", {
                          "onUpdate:modelValue": t[1] || (t[1] = (s) => e.data.form.password = s),
                          name: "password",
                          type: "password",
                          autocomplete: "current-password",
                          placeholder: "请输入登录密码",
                          required: "",
                          style: { border: "1px #13dae3 solid" },
                          class: "text-white pl-10 appearance-none block w-full px-4 py-3 border rounded-md shadow-sm focus:outline-none focus:ring-myblue focus:border-myblue sm:text-sm bg-transparent"
                        }, null, 512), [
                          [Wt, e.data.form.password]
                        ])
                      ]),
                      e.needVerifyCode ? (m(), U("div", od, [
                        ad,
                        Xt(V("input", {
                          "onUpdate:modelValue": t[2] || (t[2] = (s) => e.data.form.code = s),
                          name: "code",
                          type: "text",
                          required: "",
                          placeholder: "请输入右侧校验码",
                          style: { border: "1px #13dae3 solid" },
                          class: "text-white pl-10 appearance-none block w-full px-3 py-3 border rounded-md shadow-sm focus:outline-none focus:ring-myblue focus:border-myblue sm:text-sm bg-transparent"
                        }, null, 512), [
                          [Wt, e.data.form.code]
                        ]),
                        e.data.verifyCode ? (m(), U("div", {
                          key: 0,
                          class: "relative flex items-center",
                          onClick: t[3] || (t[3] = (...s) => e.getVerifyCode && e.getVerifyCode(...s))
                        }, [
                          V("img", {
                            class: "h-12 w-auto",
                            src: e.data.verifyCode
                          }, null, 8, ld)
                        ])) : j("", !0)
                      ])) : j("", !0),
                      V("div", {
                        class: "text-yellow-400 text-left",
                        style: pe({ display: e.hasError ? "" : "none" })
                      }, [
                        V("span", null, se(e.data.errors[0] || " "), 1)
                      ], 4),
                      K(i, {
                        style: { "background-color": "rgba(3, 180, 245, 1)" },
                        class: "text-center w-2/3 text-white py-3",
                        onClick: e.loginHandler
                      }, {
                        default: I(() => [
                          id
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ], 512)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["bg-image"]);
}
const uv = /* @__PURE__ */ xe(Ou, [["render", rd]]), dv = ne({
  __name: "MyIfcDataTransform",
  props: {
    treeData: {},
    modelUID: {},
    elementGuids: {},
    elementNameGuids: {},
    properties: {}
  },
  emits: ["treeTransform", "getGuids", "getNames", "getProperties"],
  setup(e, { emit: t }) {
    const n = e, a = t;
    let o = {}, l = [], i = "";
    const r = (d) => {
      let c = "";
      return d.inBuildingStorey !== "None" ? c = d.inBuildingStorey : d.inBuilding !== "None" ? c = d.inBuilding : d.inSite !== "None" ? c = d.inSite : d.inProject !== "None" && (c = d.inProject), c;
    }, u = (d, c) => {
      var f;
      d.children ? (f = d.children) == null || f.forEach((h) => {
        u(h, c);
      }) : c.push(d.id);
    };
    ie(() => n.properties, () => {
      const d = [[
        {
          name: "element_info",
          desp: "实体信息",
          properties: []
        }
      ]], c = {};
      n.properties, n.properties && Object.keys(n.properties).forEach((f) => {
        const h = f.split(".");
        if (h.length === 1)
          d[0][0].properties.push(
            {
              name: f,
              desp: f
            }
          );
        else {
          const y = h[0], b = h[1], p = {
            name: f,
            desp: b
          };
          c[y] ? c[y].properties.push(p) : (c[y] = {
            name: f,
            desp: y,
            properties: [p]
          }, d[0].push(c[y]));
        }
      }), a("getProperties", { metaData: d, properties: n.properties });
    }, { deep: !0, immediate: !0 }), ie(() => n.elementNameGuids, () => {
      const d = [];
      n.elementNameGuids && n.elementNameGuids.forEach((c) => {
        var h;
        const f = (h = o[c]) == null ? void 0 : h.name;
        d.push(f !== void 0 ? f : "");
      }), a("getNames", d);
    }), ie(() => n.elementGuids, () => {
      const d = [];
      n.elementGuids && n.elementGuids.forEach((c) => {
        o[c] && u(o[c], d);
      }), a("getGuids", d);
    }, { deep: !0 });
    const s = ["BuiltElement", "DistributionElement", "FurnishingElement", "FeatureElement", "GeotechnicalElement", "TransportationDevice", "BUILTELEMENT", "DISTRIBUTIONELEMENT", "FURNISHINGELEMENT", "FEATUREELEMENT", "GEOTECHNICALELEMENT", "TRANSPORTATIONDEVICE"];
    return De(() => {
      ie(() => n.treeData, () => {
        o = {}, n.treeData && n.modelUID && (n.treeData.forEach((d) => {
          var c, f, h;
          if (d.ifcClass === "IfcProject" || d.ifcClass === "项目" || d.ifcClass === "IFCPROJECT" || ["IfcSite", "IFCSITE", "IfcBuilding", "IFCBUILDING", "IfcBuildingStorey", "IFCBUILDINGSTOREY", "场地", "建筑", "楼层"].includes(d.ifcClass) && (d.elementType === "SpatialElement" || d.elementType === "SPTIALELMENT") || s.includes(d.elementType)) {
            const y = {
              id: d.guid,
              name: d.longName,
              kind: "C",
              parent: r(d),
              modelUID: n.modelUID,
              value: `${n.modelUID}.${d.guid}`,
              attribute: Object.assign(d, {
                Category: d.ifcClass,
                GUID: d.guid,
                名称: d.longName
              }),
              isParent: !0,
              expand: !["IfcBuildingStorey", "楼层", "IFCBUILDINGSTOREY"].includes(d.ifcClass)
            };
            if (o[d.guid] = y, !s.includes(d.elementType))
              y.children = [], o[y.parent] ? (c = o[y.parent].children) == null || c.push(y) : l.push(y.id);
            else {
              const b = `${d.ifcClass}_${y.parent}`;
              if (!o[b]) {
                const p = {
                  id: b,
                  name: d.ifcClass,
                  kind: "C",
                  parent: y.parent,
                  value: `${n.modelUID}.${b}`,
                  modelUID: n.modelUID,
                  attribute: {
                    Category: d.ifcClass,
                    GUID: b,
                    inProject: d.inProject || "None",
                    inSite: d.inSite || "None",
                    inBuilding: d.inBuilding || "None",
                    inBuildingStorey: d.inBuildingStorey || "None",
                    longName: d.ifcClass,
                    名称: d.ifcClass
                  },
                  children: [],
                  expand: !1,
                  isParent: !0
                };
                o[b] = p, o[y.parent] ? (f = o[y.parent].children) == null || f.push(o[b]) : l.push(b);
              }
              y.isParent = !1, (h = o[b].children) == null || h.push(y), y.parent = b;
            }
            (d.ifcClass === "Project" || d.ifcClass === "项目" || d.ifcClass === "IfcProject" || d.ifcClass === "IFCPROJECT") && (i = d.guid);
          }
        }), l.forEach((d) => {
          var f, h;
          const c = o[d];
          (h = (f = o[c.parent]) == null ? void 0 : f.children) == null || h.push(c);
        }), a("treeTransform", [o[i]]));
      }, { deep: !0, immediate: !0 });
    }), (d, c) => (m(), U("div"));
  }
}), sd = ["align"], ud = {
  style: {
    display: "flex",
    padding: "0.5rem 1rem"
  }
}, dd = {
  key: 0
}, cd = /* @__PURE__ */ ne({
  __name: "MyContent",
  props: {
    columns: {},
    data: {},
    separator: {
      default: () => K("span", null, null)
    },
    detailSlots: {},
    align: {
      default: "top"
    },
    justify: {
      default: "start"
    },
    rowAlign: {
      default: "top"
    }
  },
  setup(e) {
    const t = e, n = me(() => {
      let a = [], o = 0, l = 0, i;
      return t.columns.map((r, u) => {
        let s = 0;
        r.map((d) => {
          d.width && (s = d.width > s ? d.width : s), d.showSeprator === !1 && (a[u] = a[u] || {}, a[u].showSeprator = !1);
        }), a[u] = a[u] || {}, a[u].width = s, s > 0 && (o += s, l += 0);
      }), i = `calc((100% - ${o}px) / ${t.columns.length - l})`, a = a.map((r) => r.width === 0 ? {
        width: i,
        showSeprator: r.showSeprator
      } : {
        width: `${r.width}px`,
        showSeprator: r.showSeprator
      }), a;
    });
    return (a, o) => (m(), U("div", {
      align: a.align,
      style: {
        width: "100%",
        "font-size": "12px",
        display: "flex"
      }
    }, [(m(!0), U(ve, null, be(a.columns, (l, i) => (m(), U("div", {
      class: "",
      style: pe({
        width: `${n.value[i].width}`,
        borderRight: n.value[i].showSeprator != !1 && i < l.length && a.columns.length > 1 ? "1.5px solid rgba(0, 0, 0, 0.1)" : "none"
      })
    }, [(m(!0), U(ve, null, be(l, (r) => (m(), U("div", ud, [(r == null ? void 0 : r.showTitle) !== !1 ? (m(), U("div", {
      key: 0,
      style: pe({
        fontSize: "12px",
        height: r == null ? void 0 : r.height,
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        width: "5rem",
        color: "rgb(136, 136, 136)"
      })
    }, se(r == null ? void 0 : r.title), 5)) : j("", !0), V("div", {
      class: "truncate",
      style: pe({
        fontSize: "12px",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        width: "calc(100 % - 80px)"
      })
    }, [(m(!0), U(ve, null, be(t.detailSlots, (u) => (m(), U(ve, null, [r != null && r.render && (r == null ? void 0 : r.colKey) === u ? te(a.$slots, u, {
      key: 0,
      colRow: r,
      row: a.data
    }, () => [(m(), R(it(() => r == null ? void 0 : r.render({
      colRow: r,
      row: a.data
    }))))], !0) : j("", !0)], 64))), 256)), r != null && r.render ? j("", !0) : (m(), U("div", dd, se(a.data[r == null ? void 0 : r.colKey] || ""), 1))], 4)]))), 256))], 4))), 256))], 8, sd));
  }
}), cv = /* @__PURE__ */ xe(cd, [["__scopeId", "data-v-7f0943da"]]);
function Gn(e) {
  return Ja() ? (yn(e), !0) : !1;
}
function at(e) {
  return typeof e == "function" ? e() : x(e);
}
const fd = typeof window < "u" && typeof document < "u", pd = (e) => typeof e < "u", hd = Object.prototype.toString, md = (e) => hd.call(e) === "[object Object]", gd = () => {
};
function vd(e, t) {
  function n(...a) {
    return new Promise((o, l) => {
      Promise.resolve(e(() => t.apply(this, a), { fn: t, thisArg: this, args: a })).then(o).catch(l);
    });
  }
  return n;
}
const dl = (e) => e();
function yd(e = dl) {
  const t = ae(!0);
  function n() {
    t.value = !1;
  }
  function a() {
    t.value = !0;
  }
  const o = (...l) => {
    t.value && e(...l);
  };
  return { isActive: Gi(t), pause: n, resume: a, eventFilter: o };
}
function ia(e, t = !1, n = "Timeout") {
  return new Promise((a, o) => {
    setTimeout(t ? () => o(n) : a, e);
  });
}
function bd(e, t, n = {}) {
  const {
    eventFilter: a = dl,
    ...o
  } = n;
  return ie(
    e,
    vd(
      a,
      t
    ),
    o
  );
}
function At(e, t, n = {}) {
  const {
    eventFilter: a,
    ...o
  } = n, { eventFilter: l, pause: i, resume: r, isActive: u } = yd(a);
  return { stop: bd(
    e,
    t,
    {
      ...o,
      eventFilter: l
    }
  ), pause: i, resume: r, isActive: u };
}
function _d(e, t = {}) {
  if (!Hn(e))
    return Ge(e);
  const n = Array.isArray(e.value) ? Array.from({ length: e.value.length }) : {};
  for (const a in e.value)
    n[a] = Ui(() => ({
      get() {
        return e.value[a];
      },
      set(o) {
        var l;
        if ((l = at(t.replaceRef)) != null ? l : !0)
          if (Array.isArray(e.value)) {
            const r = [...e.value];
            r[a] = o, e.value = r;
          } else {
            const r = { ...e.value, [a]: o };
            Object.setPrototypeOf(r, Object.getPrototypeOf(e.value)), e.value = r;
          }
        else
          e.value[a] = o;
      }
    }));
  return n;
}
function mo(e, t = !1) {
  function n(c, { flush: f = "sync", deep: h = !1, timeout: y, throwOnTimeout: b } = {}) {
    let p = null;
    const k = [new Promise((E) => {
      p = ie(
        e,
        (_) => {
          c(_) !== t && (p == null || p(), E(_));
        },
        {
          flush: f,
          deep: h,
          immediate: !0
        }
      );
    })];
    return y != null && k.push(
      ia(y, b).then(() => at(e)).finally(() => p == null ? void 0 : p())
    ), Promise.race(k);
  }
  function a(c, f) {
    if (!Hn(c))
      return n((_) => _ === c, f);
    const { flush: h = "sync", deep: y = !1, timeout: b, throwOnTimeout: p } = f ?? {};
    let w = null;
    const E = [new Promise((_) => {
      w = ie(
        [e, c],
        ([C, G]) => {
          t !== (C === G) && (w == null || w(), _(C));
        },
        {
          flush: h,
          deep: y,
          immediate: !0
        }
      );
    })];
    return b != null && E.push(
      ia(b, p).then(() => at(e)).finally(() => (w == null || w(), at(e)))
    ), Promise.race(E);
  }
  function o(c) {
    return n((f) => !!f, c);
  }
  function l(c) {
    return a(null, c);
  }
  function i(c) {
    return a(void 0, c);
  }
  function r(c) {
    return n(Number.isNaN, c);
  }
  function u(c, f) {
    return n((h) => {
      const y = Array.from(h);
      return y.includes(c) || y.includes(at(c));
    }, f);
  }
  function s(c) {
    return d(1, c);
  }
  function d(c = 1, f) {
    let h = -1;
    return n(() => (h += 1, h >= c), f);
  }
  return Array.isArray(at(e)) ? {
    toMatch: n,
    toContains: u,
    changed: s,
    changedTimes: d,
    get not() {
      return mo(e, !t);
    }
  } : {
    toMatch: n,
    toBe: a,
    toBeTruthy: o,
    toBeNull: l,
    toBeNaN: r,
    toBeUndefined: i,
    changed: s,
    changedTimes: d,
    get not() {
      return mo(e, !t);
    }
  };
}
function kn(e) {
  return mo(e);
}
function wd(e) {
  var t;
  const n = at(e);
  return (t = n == null ? void 0 : n.$el) != null ? t : n;
}
const cl = fd ? window : void 0;
function fl(...e) {
  let t, n, a, o;
  if (typeof e[0] == "string" || Array.isArray(e[0]) ? ([n, a, o] = e, t = cl) : [t, n, a, o] = e, !t)
    return gd;
  Array.isArray(n) || (n = [n]), Array.isArray(a) || (a = [a]);
  const l = [], i = () => {
    l.forEach((d) => d()), l.length = 0;
  }, r = (d, c, f, h) => (d.addEventListener(c, f, h), () => d.removeEventListener(c, f, h)), u = ie(
    () => [wd(t), at(o)],
    ([d, c]) => {
      if (i(), !d)
        return;
      const f = md(c) ? { ...c } : c;
      l.push(
        ...n.flatMap((h) => a.map((y) => r(d, h, y, f)))
      );
    },
    { immediate: !0, flush: "post" }
  ), s = () => {
    u(), i();
  };
  return Gn(s), s;
}
function Cd(e) {
  return typeof e == "function" ? e : typeof e == "string" ? (t) => t.key === e : Array.isArray(e) ? (t) => e.includes(t.key) : () => !0;
}
function ra(...e) {
  let t, n, a = {};
  e.length === 3 ? (t = e[0], n = e[1], a = e[2]) : e.length === 2 ? typeof e[1] == "object" ? (t = !0, n = e[0], a = e[1]) : (t = e[0], n = e[1]) : (t = !0, n = e[0]);
  const {
    target: o = cl,
    eventName: l = "keydown",
    passive: i = !1,
    dedupe: r = !1
  } = a, u = Cd(t);
  return fl(o, l, (d) => {
    d.repeat && at(r) || u(d) && n(d);
  }, i);
}
function Ed(e) {
  return JSON.parse(JSON.stringify(e));
}
function jt(e, t, n, a = {}) {
  var o, l, i;
  const {
    clone: r = !1,
    passive: u = !1,
    eventName: s,
    deep: d = !1,
    defaultValue: c,
    shouldEmit: f
  } = a, h = Un(), y = n || (h == null ? void 0 : h.emit) || ((o = h == null ? void 0 : h.$emit) == null ? void 0 : o.bind(h)) || ((i = (l = h == null ? void 0 : h.proxy) == null ? void 0 : l.$emit) == null ? void 0 : i.bind(h == null ? void 0 : h.proxy));
  let b = s;
  t || (t = "modelValue"), b = b || `update:${t.toString()}`;
  const p = (E) => r ? typeof r == "function" ? r(E) : Ed(E) : E, w = () => pd(e[t]) ? p(e[t]) : c, k = (E) => {
    f ? f(E) && y(b, E) : y(b, E);
  };
  if (u) {
    const E = w(), _ = ae(E);
    let C = !1;
    return ie(
      () => e[t],
      (G) => {
        C || (C = !0, _.value = p(G), je(() => C = !1));
      }
    ), ie(
      _,
      (G) => {
        !C && (G !== e[t] || d) && k(G);
      },
      { deep: d }
    ), _;
  } else
    return me({
      get() {
        return w();
      },
      set(E) {
        k(E);
      }
    });
}
var kd = { value: () => {
} };
function Zn() {
  for (var e = 0, t = arguments.length, n = {}, a; e < t; ++e) {
    if (!(a = arguments[e] + "") || a in n || /[\s.]/.test(a))
      throw new Error("illegal type: " + a);
    n[a] = [];
  }
  return new bn(n);
}
function bn(e) {
  this._ = e;
}
function Nd(e, t) {
  return e.trim().split(/^|\s+/).map(function(n) {
    var a = "", o = n.indexOf(".");
    if (o >= 0 && (a = n.slice(o + 1), n = n.slice(0, o)), n && !t.hasOwnProperty(n))
      throw new Error("unknown type: " + n);
    return { type: n, name: a };
  });
}
bn.prototype = Zn.prototype = {
  constructor: bn,
  on: function(e, t) {
    var n = this._, a = Nd(e + "", n), o, l = -1, i = a.length;
    if (arguments.length < 2) {
      for (; ++l < i; )
        if ((o = (e = a[l]).type) && (o = Sd(n[o], e.name)))
          return o;
      return;
    }
    if (t != null && typeof t != "function")
      throw new Error("invalid callback: " + t);
    for (; ++l < i; )
      if (o = (e = a[l]).type)
        n[o] = sa(n[o], e.name, t);
      else if (t == null)
        for (o in n)
          n[o] = sa(n[o], e.name, null);
    return this;
  },
  copy: function() {
    var e = {}, t = this._;
    for (var n in t)
      e[n] = t[n].slice();
    return new bn(e);
  },
  call: function(e, t) {
    if ((o = arguments.length - 2) > 0)
      for (var n = new Array(o), a = 0, o, l; a < o; ++a)
        n[a] = arguments[a + 2];
    if (!this._.hasOwnProperty(e))
      throw new Error("unknown type: " + e);
    for (l = this._[e], a = 0, o = l.length; a < o; ++a)
      l[a].value.apply(t, n);
  },
  apply: function(e, t, n) {
    if (!this._.hasOwnProperty(e))
      throw new Error("unknown type: " + e);
    for (var a = this._[e], o = 0, l = a.length; o < l; ++o)
      a[o].value.apply(t, n);
  }
};
function Sd(e, t) {
  for (var n = 0, a = e.length, o; n < a; ++n)
    if ((o = e[n]).name === t)
      return o.value;
}
function sa(e, t, n) {
  for (var a = 0, o = e.length; a < o; ++a)
    if (e[a].name === t) {
      e[a] = kd, e = e.slice(0, a).concat(e.slice(a + 1));
      break;
    }
  return n != null && e.push({ name: t, value: n }), e;
}
var go = "http://www.w3.org/1999/xhtml";
const ua = {
  svg: "http://www.w3.org/2000/svg",
  xhtml: go,
  xlink: "http://www.w3.org/1999/xlink",
  xml: "http://www.w3.org/XML/1998/namespace",
  xmlns: "http://www.w3.org/2000/xmlns/"
};
function Yn(e) {
  var t = e += "", n = t.indexOf(":");
  return n >= 0 && (t = e.slice(0, n)) !== "xmlns" && (e = e.slice(n + 1)), ua.hasOwnProperty(t) ? { space: ua[t], local: e } : e;
}
function xd(e) {
  return function() {
    var t = this.ownerDocument, n = this.namespaceURI;
    return n === go && t.documentElement.namespaceURI === go ? t.createElement(e) : t.createElementNS(n, e);
  };
}
function $d(e) {
  return function() {
    return this.ownerDocument.createElementNS(e.space, e.local);
  };
}
function pl(e) {
  var t = Yn(e);
  return (t.local ? $d : xd)(t);
}
function Md() {
}
function Ro(e) {
  return e == null ? Md : function() {
    return this.querySelector(e);
  };
}
function Bd(e) {
  typeof e != "function" && (e = Ro(e));
  for (var t = this._groups, n = t.length, a = new Array(n), o = 0; o < n; ++o)
    for (var l = t[o], i = l.length, r = a[o] = new Array(i), u, s, d = 0; d < i; ++d)
      (u = l[d]) && (s = e.call(u, u.__data__, d, l)) && ("__data__" in u && (s.__data__ = u.__data__), r[d] = s);
  return new Ue(a, this._parents);
}
function Id(e) {
  return e == null ? [] : Array.isArray(e) ? e : Array.from(e);
}
function Dd() {
  return [];
}
function hl(e) {
  return e == null ? Dd : function() {
    return this.querySelectorAll(e);
  };
}
function Vd(e) {
  return function() {
    return Id(e.apply(this, arguments));
  };
}
function Ad(e) {
  typeof e == "function" ? e = Vd(e) : e = hl(e);
  for (var t = this._groups, n = t.length, a = [], o = [], l = 0; l < n; ++l)
    for (var i = t[l], r = i.length, u, s = 0; s < r; ++s)
      (u = i[s]) && (a.push(e.call(u, u.__data__, s, i)), o.push(u));
  return new Ue(a, o);
}
function ml(e) {
  return function() {
    return this.matches(e);
  };
}
function gl(e) {
  return function(t) {
    return t.matches(e);
  };
}
var Fd = Array.prototype.find;
function Td(e) {
  return function() {
    return Fd.call(this.children, e);
  };
}
function Od() {
  return this.firstElementChild;
}
function Pd(e) {
  return this.select(e == null ? Od : Td(typeof e == "function" ? e : gl(e)));
}
var Ld = Array.prototype.filter;
function zd() {
  return Array.from(this.children);
}
function Rd(e) {
  return function() {
    return Ld.call(this.children, e);
  };
}
function Hd(e) {
  return this.selectAll(e == null ? zd : Rd(typeof e == "function" ? e : gl(e)));
}
function Ud(e) {
  typeof e != "function" && (e = ml(e));
  for (var t = this._groups, n = t.length, a = new Array(n), o = 0; o < n; ++o)
    for (var l = t[o], i = l.length, r = a[o] = [], u, s = 0; s < i; ++s)
      (u = l[s]) && e.call(u, u.__data__, s, l) && r.push(u);
  return new Ue(a, this._parents);
}
function vl(e) {
  return new Array(e.length);
}
function Gd() {
  return new Ue(this._enter || this._groups.map(vl), this._parents);
}
function Nn(e, t) {
  this.ownerDocument = e.ownerDocument, this.namespaceURI = e.namespaceURI, this._next = null, this._parent = e, this.__data__ = t;
}
Nn.prototype = {
  constructor: Nn,
  appendChild: function(e) {
    return this._parent.insertBefore(e, this._next);
  },
  insertBefore: function(e, t) {
    return this._parent.insertBefore(e, t);
  },
  querySelector: function(e) {
    return this._parent.querySelector(e);
  },
  querySelectorAll: function(e) {
    return this._parent.querySelectorAll(e);
  }
};
function Zd(e) {
  return function() {
    return e;
  };
}
function Yd(e, t, n, a, o, l) {
  for (var i = 0, r, u = t.length, s = l.length; i < s; ++i)
    (r = t[i]) ? (r.__data__ = l[i], a[i] = r) : n[i] = new Nn(e, l[i]);
  for (; i < u; ++i)
    (r = t[i]) && (o[i] = r);
}
function Kd(e, t, n, a, o, l, i) {
  var r, u, s = /* @__PURE__ */ new Map(), d = t.length, c = l.length, f = new Array(d), h;
  for (r = 0; r < d; ++r)
    (u = t[r]) && (f[r] = h = i.call(u, u.__data__, r, t) + "", s.has(h) ? o[r] = u : s.set(h, u));
  for (r = 0; r < c; ++r)
    h = i.call(e, l[r], r, l) + "", (u = s.get(h)) ? (a[r] = u, u.__data__ = l[r], s.delete(h)) : n[r] = new Nn(e, l[r]);
  for (r = 0; r < d; ++r)
    (u = t[r]) && s.get(f[r]) === u && (o[r] = u);
}
function Xd(e) {
  return e.__data__;
}
function Wd(e, t) {
  if (!arguments.length)
    return Array.from(this, Xd);
  var n = t ? Kd : Yd, a = this._parents, o = this._groups;
  typeof e != "function" && (e = Zd(e));
  for (var l = o.length, i = new Array(l), r = new Array(l), u = new Array(l), s = 0; s < l; ++s) {
    var d = a[s], c = o[s], f = c.length, h = jd(e.call(d, d && d.__data__, s, a)), y = h.length, b = r[s] = new Array(y), p = i[s] = new Array(y), w = u[s] = new Array(f);
    n(d, c, b, p, w, h, t);
    for (var k = 0, E = 0, _, C; k < y; ++k)
      if (_ = b[k]) {
        for (k >= E && (E = k + 1); !(C = p[E]) && ++E < y; )
          ;
        _._next = C || null;
      }
  }
  return i = new Ue(i, a), i._enter = r, i._exit = u, i;
}
function jd(e) {
  return typeof e == "object" && "length" in e ? e : Array.from(e);
}
function qd() {
  return new Ue(this._exit || this._groups.map(vl), this._parents);
}
function Jd(e, t, n) {
  var a = this.enter(), o = this, l = this.exit();
  return typeof e == "function" ? (a = e(a), a && (a = a.selection())) : a = a.append(e + ""), t != null && (o = t(o), o && (o = o.selection())), n == null ? l.remove() : n(l), a && o ? a.merge(o).order() : o;
}
function Qd(e) {
  for (var t = e.selection ? e.selection() : e, n = this._groups, a = t._groups, o = n.length, l = a.length, i = Math.min(o, l), r = new Array(o), u = 0; u < i; ++u)
    for (var s = n[u], d = a[u], c = s.length, f = r[u] = new Array(c), h, y = 0; y < c; ++y)
      (h = s[y] || d[y]) && (f[y] = h);
  for (; u < o; ++u)
    r[u] = n[u];
  return new Ue(r, this._parents);
}
function ec() {
  for (var e = this._groups, t = -1, n = e.length; ++t < n; )
    for (var a = e[t], o = a.length - 1, l = a[o], i; --o >= 0; )
      (i = a[o]) && (l && i.compareDocumentPosition(l) ^ 4 && l.parentNode.insertBefore(i, l), l = i);
  return this;
}
function tc(e) {
  e || (e = nc);
  function t(c, f) {
    return c && f ? e(c.__data__, f.__data__) : !c - !f;
  }
  for (var n = this._groups, a = n.length, o = new Array(a), l = 0; l < a; ++l) {
    for (var i = n[l], r = i.length, u = o[l] = new Array(r), s, d = 0; d < r; ++d)
      (s = i[d]) && (u[d] = s);
    u.sort(t);
  }
  return new Ue(o, this._parents).order();
}
function nc(e, t) {
  return e < t ? -1 : e > t ? 1 : e >= t ? 0 : NaN;
}
function oc() {
  var e = arguments[0];
  return arguments[0] = this, e.apply(null, arguments), this;
}
function ac() {
  return Array.from(this);
}
function lc() {
  for (var e = this._groups, t = 0, n = e.length; t < n; ++t)
    for (var a = e[t], o = 0, l = a.length; o < l; ++o) {
      var i = a[o];
      if (i)
        return i;
    }
  return null;
}
function ic() {
  let e = 0;
  for (const t of this)
    ++e;
  return e;
}
function rc() {
  return !this.node();
}
function sc(e) {
  for (var t = this._groups, n = 0, a = t.length; n < a; ++n)
    for (var o = t[n], l = 0, i = o.length, r; l < i; ++l)
      (r = o[l]) && e.call(r, r.__data__, l, o);
  return this;
}
function uc(e) {
  return function() {
    this.removeAttribute(e);
  };
}
function dc(e) {
  return function() {
    this.removeAttributeNS(e.space, e.local);
  };
}
function cc(e, t) {
  return function() {
    this.setAttribute(e, t);
  };
}
function fc(e, t) {
  return function() {
    this.setAttributeNS(e.space, e.local, t);
  };
}
function pc(e, t) {
  return function() {
    var n = t.apply(this, arguments);
    n == null ? this.removeAttribute(e) : this.setAttribute(e, n);
  };
}
function hc(e, t) {
  return function() {
    var n = t.apply(this, arguments);
    n == null ? this.removeAttributeNS(e.space, e.local) : this.setAttributeNS(e.space, e.local, n);
  };
}
function mc(e, t) {
  var n = Yn(e);
  if (arguments.length < 2) {
    var a = this.node();
    return n.local ? a.getAttributeNS(n.space, n.local) : a.getAttribute(n);
  }
  return this.each((t == null ? n.local ? dc : uc : typeof t == "function" ? n.local ? hc : pc : n.local ? fc : cc)(n, t));
}
function yl(e) {
  return e.ownerDocument && e.ownerDocument.defaultView || e.document && e || e.defaultView;
}
function gc(e) {
  return function() {
    this.style.removeProperty(e);
  };
}
function vc(e, t, n) {
  return function() {
    this.style.setProperty(e, t, n);
  };
}
function yc(e, t, n) {
  return function() {
    var a = t.apply(this, arguments);
    a == null ? this.style.removeProperty(e) : this.style.setProperty(e, a, n);
  };
}
function bc(e, t, n) {
  return arguments.length > 1 ? this.each((t == null ? gc : typeof t == "function" ? yc : vc)(e, t, n ?? "")) : Rt(this.node(), e);
}
function Rt(e, t) {
  return e.style.getPropertyValue(t) || yl(e).getComputedStyle(e, null).getPropertyValue(t);
}
function _c(e) {
  return function() {
    delete this[e];
  };
}
function wc(e, t) {
  return function() {
    this[e] = t;
  };
}
function Cc(e, t) {
  return function() {
    var n = t.apply(this, arguments);
    n == null ? delete this[e] : this[e] = n;
  };
}
function Ec(e, t) {
  return arguments.length > 1 ? this.each((t == null ? _c : typeof t == "function" ? Cc : wc)(e, t)) : this.node()[e];
}
function bl(e) {
  return e.trim().split(/^|\s+/);
}
function Ho(e) {
  return e.classList || new _l(e);
}
function _l(e) {
  this._node = e, this._names = bl(e.getAttribute("class") || "");
}
_l.prototype = {
  add: function(e) {
    var t = this._names.indexOf(e);
    t < 0 && (this._names.push(e), this._node.setAttribute("class", this._names.join(" ")));
  },
  remove: function(e) {
    var t = this._names.indexOf(e);
    t >= 0 && (this._names.splice(t, 1), this._node.setAttribute("class", this._names.join(" ")));
  },
  contains: function(e) {
    return this._names.indexOf(e) >= 0;
  }
};
function wl(e, t) {
  for (var n = Ho(e), a = -1, o = t.length; ++a < o; )
    n.add(t[a]);
}
function Cl(e, t) {
  for (var n = Ho(e), a = -1, o = t.length; ++a < o; )
    n.remove(t[a]);
}
function kc(e) {
  return function() {
    wl(this, e);
  };
}
function Nc(e) {
  return function() {
    Cl(this, e);
  };
}
function Sc(e, t) {
  return function() {
    (t.apply(this, arguments) ? wl : Cl)(this, e);
  };
}
function xc(e, t) {
  var n = bl(e + "");
  if (arguments.length < 2) {
    for (var a = Ho(this.node()), o = -1, l = n.length; ++o < l; )
      if (!a.contains(n[o]))
        return !1;
    return !0;
  }
  return this.each((typeof t == "function" ? Sc : t ? kc : Nc)(n, t));
}
function $c() {
  this.textContent = "";
}
function Mc(e) {
  return function() {
    this.textContent = e;
  };
}
function Bc(e) {
  return function() {
    var t = e.apply(this, arguments);
    this.textContent = t ?? "";
  };
}
function Ic(e) {
  return arguments.length ? this.each(e == null ? $c : (typeof e == "function" ? Bc : Mc)(e)) : this.node().textContent;
}
function Dc() {
  this.innerHTML = "";
}
function Vc(e) {
  return function() {
    this.innerHTML = e;
  };
}
function Ac(e) {
  return function() {
    var t = e.apply(this, arguments);
    this.innerHTML = t ?? "";
  };
}
function Fc(e) {
  return arguments.length ? this.each(e == null ? Dc : (typeof e == "function" ? Ac : Vc)(e)) : this.node().innerHTML;
}
function Tc() {
  this.nextSibling && this.parentNode.appendChild(this);
}
function Oc() {
  return this.each(Tc);
}
function Pc() {
  this.previousSibling && this.parentNode.insertBefore(this, this.parentNode.firstChild);
}
function Lc() {
  return this.each(Pc);
}
function zc(e) {
  var t = typeof e == "function" ? e : pl(e);
  return this.select(function() {
    return this.appendChild(t.apply(this, arguments));
  });
}
function Rc() {
  return null;
}
function Hc(e, t) {
  var n = typeof e == "function" ? e : pl(e), a = t == null ? Rc : typeof t == "function" ? t : Ro(t);
  return this.select(function() {
    return this.insertBefore(n.apply(this, arguments), a.apply(this, arguments) || null);
  });
}
function Uc() {
  var e = this.parentNode;
  e && e.removeChild(this);
}
function Gc() {
  return this.each(Uc);
}
function Zc() {
  var e = this.cloneNode(!1), t = this.parentNode;
  return t ? t.insertBefore(e, this.nextSibling) : e;
}
function Yc() {
  var e = this.cloneNode(!0), t = this.parentNode;
  return t ? t.insertBefore(e, this.nextSibling) : e;
}
function Kc(e) {
  return this.select(e ? Yc : Zc);
}
function Xc(e) {
  return arguments.length ? this.property("__data__", e) : this.node().__data__;
}
function Wc(e) {
  return function(t) {
    e.call(this, t, this.__data__);
  };
}
function jc(e) {
  return e.trim().split(/^|\s+/).map(function(t) {
    var n = "", a = t.indexOf(".");
    return a >= 0 && (n = t.slice(a + 1), t = t.slice(0, a)), { type: t, name: n };
  });
}
function qc(e) {
  return function() {
    var t = this.__on;
    if (t) {
      for (var n = 0, a = -1, o = t.length, l; n < o; ++n)
        l = t[n], (!e.type || l.type === e.type) && l.name === e.name ? this.removeEventListener(l.type, l.listener, l.options) : t[++a] = l;
      ++a ? t.length = a : delete this.__on;
    }
  };
}
function Jc(e, t, n) {
  return function() {
    var a = this.__on, o, l = Wc(t);
    if (a) {
      for (var i = 0, r = a.length; i < r; ++i)
        if ((o = a[i]).type === e.type && o.name === e.name) {
          this.removeEventListener(o.type, o.listener, o.options), this.addEventListener(o.type, o.listener = l, o.options = n), o.value = t;
          return;
        }
    }
    this.addEventListener(e.type, l, n), o = { type: e.type, name: e.name, value: t, listener: l, options: n }, a ? a.push(o) : this.__on = [o];
  };
}
function Qc(e, t, n) {
  var a = jc(e + ""), o, l = a.length, i;
  if (arguments.length < 2) {
    var r = this.node().__on;
    if (r) {
      for (var u = 0, s = r.length, d; u < s; ++u)
        for (o = 0, d = r[u]; o < l; ++o)
          if ((i = a[o]).type === d.type && i.name === d.name)
            return d.value;
    }
    return;
  }
  for (r = t ? Jc : qc, o = 0; o < l; ++o)
    this.each(r(a[o], t, n));
  return this;
}
function El(e, t, n) {
  var a = yl(e), o = a.CustomEvent;
  typeof o == "function" ? o = new o(t, n) : (o = a.document.createEvent("Event"), n ? (o.initEvent(t, n.bubbles, n.cancelable), o.detail = n.detail) : o.initEvent(t, !1, !1)), e.dispatchEvent(o);
}
function ef(e, t) {
  return function() {
    return El(this, e, t);
  };
}
function tf(e, t) {
  return function() {
    return El(this, e, t.apply(this, arguments));
  };
}
function nf(e, t) {
  return this.each((typeof t == "function" ? tf : ef)(e, t));
}
function* of() {
  for (var e = this._groups, t = 0, n = e.length; t < n; ++t)
    for (var a = e[t], o = 0, l = a.length, i; o < l; ++o)
      (i = a[o]) && (yield i);
}
var kl = [null];
function Ue(e, t) {
  this._groups = e, this._parents = t;
}
function rn() {
  return new Ue([[document.documentElement]], kl);
}
function af() {
  return this;
}
Ue.prototype = rn.prototype = {
  constructor: Ue,
  select: Bd,
  selectAll: Ad,
  selectChild: Pd,
  selectChildren: Hd,
  filter: Ud,
  data: Wd,
  enter: Gd,
  exit: qd,
  join: Jd,
  merge: Qd,
  selection: af,
  order: ec,
  sort: tc,
  call: oc,
  nodes: ac,
  node: lc,
  size: ic,
  empty: rc,
  each: sc,
  attr: mc,
  style: bc,
  property: Ec,
  classed: xc,
  text: Ic,
  html: Fc,
  raise: Oc,
  lower: Lc,
  append: zc,
  insert: Hc,
  remove: Gc,
  clone: Kc,
  datum: Xc,
  on: Qc,
  dispatch: nf,
  [Symbol.iterator]: of
};
function Ze(e) {
  return typeof e == "string" ? new Ue([[document.querySelector(e)]], [document.documentElement]) : new Ue([[e]], kl);
}
function lf(e) {
  let t;
  for (; t = e.sourceEvent; )
    e = t;
  return e;
}
function Xe(e, t) {
  if (e = lf(e), t === void 0 && (t = e.currentTarget), t) {
    var n = t.ownerSVGElement || t;
    if (n.createSVGPoint) {
      var a = n.createSVGPoint();
      return a.x = e.clientX, a.y = e.clientY, a = a.matrixTransform(t.getScreenCTM().inverse()), [a.x, a.y];
    }
    if (t.getBoundingClientRect) {
      var o = t.getBoundingClientRect();
      return [e.clientX - o.left - t.clientLeft, e.clientY - o.top - t.clientTop];
    }
  }
  return [e.pageX, e.pageY];
}
const rf = { passive: !1 }, Qt = { capture: !0, passive: !1 };
function no(e) {
  e.stopImmediatePropagation();
}
function Ot(e) {
  e.preventDefault(), e.stopImmediatePropagation();
}
function Nl(e) {
  var t = e.document.documentElement, n = Ze(e).on("dragstart.drag", Ot, Qt);
  "onselectstart" in t ? n.on("selectstart.drag", Ot, Qt) : (t.__noselect = t.style.MozUserSelect, t.style.MozUserSelect = "none");
}
function Sl(e, t) {
  var n = e.document.documentElement, a = Ze(e).on("dragstart.drag", null);
  t && (a.on("click.drag", Ot, Qt), setTimeout(function() {
    a.on("click.drag", null);
  }, 0)), "onselectstart" in n ? a.on("selectstart.drag", null) : (n.style.MozUserSelect = n.__noselect, delete n.__noselect);
}
const un = (e) => () => e;
function vo(e, {
  sourceEvent: t,
  subject: n,
  target: a,
  identifier: o,
  active: l,
  x: i,
  y: r,
  dx: u,
  dy: s,
  dispatch: d
}) {
  Object.defineProperties(this, {
    type: { value: e, enumerable: !0, configurable: !0 },
    sourceEvent: { value: t, enumerable: !0, configurable: !0 },
    subject: { value: n, enumerable: !0, configurable: !0 },
    target: { value: a, enumerable: !0, configurable: !0 },
    identifier: { value: o, enumerable: !0, configurable: !0 },
    active: { value: l, enumerable: !0, configurable: !0 },
    x: { value: i, enumerable: !0, configurable: !0 },
    y: { value: r, enumerable: !0, configurable: !0 },
    dx: { value: u, enumerable: !0, configurable: !0 },
    dy: { value: s, enumerable: !0, configurable: !0 },
    _: { value: d }
  });
}
vo.prototype.on = function() {
  var e = this._.on.apply(this._, arguments);
  return e === this._ ? this : e;
};
function sf(e) {
  return !e.ctrlKey && !e.button;
}
function uf() {
  return this.parentNode;
}
function df(e, t) {
  return t ?? { x: e.x, y: e.y };
}
function cf() {
  return navigator.maxTouchPoints || "ontouchstart" in this;
}
function ff() {
  var e = sf, t = uf, n = df, a = cf, o = {}, l = Zn("start", "drag", "end"), i = 0, r, u, s, d, c = 0;
  function f(_) {
    _.on("mousedown.drag", h).filter(a).on("touchstart.drag", p).on("touchmove.drag", w, rf).on("touchend.drag touchcancel.drag", k).style("touch-action", "none").style("-webkit-tap-highlight-color", "rgba(0,0,0,0)");
  }
  function h(_, C) {
    if (!(d || !e.call(this, _, C))) {
      var G = E(this, t.call(this, _, C), _, C, "mouse");
      G && (Ze(_.view).on("mousemove.drag", y, Qt).on("mouseup.drag", b, Qt), Nl(_.view), no(_), s = !1, r = _.clientX, u = _.clientY, G("start", _));
    }
  }
  function y(_) {
    if (Ot(_), !s) {
      var C = _.clientX - r, G = _.clientY - u;
      s = C * C + G * G > c;
    }
    o.mouse("drag", _);
  }
  function b(_) {
    Ze(_.view).on("mousemove.drag mouseup.drag", null), Sl(_.view, s), Ot(_), o.mouse("end", _);
  }
  function p(_, C) {
    if (e.call(this, _, C)) {
      var G = _.changedTouches, B = t.call(this, _, C), $ = G.length, M, A;
      for (M = 0; M < $; ++M)
        (A = E(this, B, _, C, G[M].identifier, G[M])) && (no(_), A("start", _, G[M]));
    }
  }
  function w(_) {
    var C = _.changedTouches, G = C.length, B, $;
    for (B = 0; B < G; ++B)
      ($ = o[C[B].identifier]) && (Ot(_), $("drag", _, C[B]));
  }
  function k(_) {
    var C = _.changedTouches, G = C.length, B, $;
    for (d && clearTimeout(d), d = setTimeout(function() {
      d = null;
    }, 500), B = 0; B < G; ++B)
      ($ = o[C[B].identifier]) && (no(_), $("end", _, C[B]));
  }
  function E(_, C, G, B, $, M) {
    var A = l.copy(), S = Xe(M || G, C), Z, z, N;
    if ((N = n.call(_, new vo("beforestart", {
      sourceEvent: G,
      target: f,
      identifier: $,
      active: i,
      x: S[0],
      y: S[1],
      dx: 0,
      dy: 0,
      dispatch: A
    }), B)) != null)
      return Z = N.x - S[0] || 0, z = N.y - S[1] || 0, function P(T, g, H) {
        var F = S, W;
        switch (T) {
          case "start":
            o[$] = P, W = i++;
            break;
          case "end":
            delete o[$], --i;
          case "drag":
            S = Xe(H || g, C), W = i;
            break;
        }
        A.call(
          T,
          _,
          new vo(T, {
            sourceEvent: g,
            subject: N,
            target: f,
            identifier: $,
            active: W,
            x: S[0] + Z,
            y: S[1] + z,
            dx: S[0] - F[0],
            dy: S[1] - F[1],
            dispatch: A
          }),
          B
        );
      };
  }
  return f.filter = function(_) {
    return arguments.length ? (e = typeof _ == "function" ? _ : un(!!_), f) : e;
  }, f.container = function(_) {
    return arguments.length ? (t = typeof _ == "function" ? _ : un(_), f) : t;
  }, f.subject = function(_) {
    return arguments.length ? (n = typeof _ == "function" ? _ : un(_), f) : n;
  }, f.touchable = function(_) {
    return arguments.length ? (a = typeof _ == "function" ? _ : un(!!_), f) : a;
  }, f.on = function() {
    var _ = l.on.apply(l, arguments);
    return _ === l ? f : _;
  }, f.clickDistance = function(_) {
    return arguments.length ? (c = (_ = +_) * _, f) : Math.sqrt(c);
  }, f;
}
function Uo(e, t, n) {
  e.prototype = t.prototype = n, n.constructor = e;
}
function xl(e, t) {
  var n = Object.create(e.prototype);
  for (var a in t)
    n[a] = t[a];
  return n;
}
function sn() {
}
var en = 0.7, Sn = 1 / en, Pt = "\\s*([+-]?\\d+)\\s*", tn = "\\s*([+-]?(?:\\d*\\.)?\\d+(?:[eE][+-]?\\d+)?)\\s*", qe = "\\s*([+-]?(?:\\d*\\.)?\\d+(?:[eE][+-]?\\d+)?)%\\s*", pf = /^#([0-9a-f]{3,8})$/, hf = new RegExp(`^rgb\\(${Pt},${Pt},${Pt}\\)$`), mf = new RegExp(`^rgb\\(${qe},${qe},${qe}\\)$`), gf = new RegExp(`^rgba\\(${Pt},${Pt},${Pt},${tn}\\)$`), vf = new RegExp(`^rgba\\(${qe},${qe},${qe},${tn}\\)$`), yf = new RegExp(`^hsl\\(${tn},${qe},${qe}\\)$`), bf = new RegExp(`^hsla\\(${tn},${qe},${qe},${tn}\\)$`), da = {
  aliceblue: 15792383,
  antiquewhite: 16444375,
  aqua: 65535,
  aquamarine: 8388564,
  azure: 15794175,
  beige: 16119260,
  bisque: 16770244,
  black: 0,
  blanchedalmond: 16772045,
  blue: 255,
  blueviolet: 9055202,
  brown: 10824234,
  burlywood: 14596231,
  cadetblue: 6266528,
  chartreuse: 8388352,
  chocolate: 13789470,
  coral: 16744272,
  cornflowerblue: 6591981,
  cornsilk: 16775388,
  crimson: 14423100,
  cyan: 65535,
  darkblue: 139,
  darkcyan: 35723,
  darkgoldenrod: 12092939,
  darkgray: 11119017,
  darkgreen: 25600,
  darkgrey: 11119017,
  darkkhaki: 12433259,
  darkmagenta: 9109643,
  darkolivegreen: 5597999,
  darkorange: 16747520,
  darkorchid: 10040012,
  darkred: 9109504,
  darksalmon: 15308410,
  darkseagreen: 9419919,
  darkslateblue: 4734347,
  darkslategray: 3100495,
  darkslategrey: 3100495,
  darkturquoise: 52945,
  darkviolet: 9699539,
  deeppink: 16716947,
  deepskyblue: 49151,
  dimgray: 6908265,
  dimgrey: 6908265,
  dodgerblue: 2003199,
  firebrick: 11674146,
  floralwhite: 16775920,
  forestgreen: 2263842,
  fuchsia: 16711935,
  gainsboro: 14474460,
  ghostwhite: 16316671,
  gold: 16766720,
  goldenrod: 14329120,
  gray: 8421504,
  green: 32768,
  greenyellow: 11403055,
  grey: 8421504,
  honeydew: 15794160,
  hotpink: 16738740,
  indianred: 13458524,
  indigo: 4915330,
  ivory: 16777200,
  khaki: 15787660,
  lavender: 15132410,
  lavenderblush: 16773365,
  lawngreen: 8190976,
  lemonchiffon: 16775885,
  lightblue: 11393254,
  lightcoral: 15761536,
  lightcyan: 14745599,
  lightgoldenrodyellow: 16448210,
  lightgray: 13882323,
  lightgreen: 9498256,
  lightgrey: 13882323,
  lightpink: 16758465,
  lightsalmon: 16752762,
  lightseagreen: 2142890,
  lightskyblue: 8900346,
  lightslategray: 7833753,
  lightslategrey: 7833753,
  lightsteelblue: 11584734,
  lightyellow: 16777184,
  lime: 65280,
  limegreen: 3329330,
  linen: 16445670,
  magenta: 16711935,
  maroon: 8388608,
  mediumaquamarine: 6737322,
  mediumblue: 205,
  mediumorchid: 12211667,
  mediumpurple: 9662683,
  mediumseagreen: 3978097,
  mediumslateblue: 8087790,
  mediumspringgreen: 64154,
  mediumturquoise: 4772300,
  mediumvioletred: 13047173,
  midnightblue: 1644912,
  mintcream: 16121850,
  mistyrose: 16770273,
  moccasin: 16770229,
  navajowhite: 16768685,
  navy: 128,
  oldlace: 16643558,
  olive: 8421376,
  olivedrab: 7048739,
  orange: 16753920,
  orangered: 16729344,
  orchid: 14315734,
  palegoldenrod: 15657130,
  palegreen: 10025880,
  paleturquoise: 11529966,
  palevioletred: 14381203,
  papayawhip: 16773077,
  peachpuff: 16767673,
  peru: 13468991,
  pink: 16761035,
  plum: 14524637,
  powderblue: 11591910,
  purple: 8388736,
  rebeccapurple: 6697881,
  red: 16711680,
  rosybrown: 12357519,
  royalblue: 4286945,
  saddlebrown: 9127187,
  salmon: 16416882,
  sandybrown: 16032864,
  seagreen: 3050327,
  seashell: 16774638,
  sienna: 10506797,
  silver: 12632256,
  skyblue: 8900331,
  slateblue: 6970061,
  slategray: 7372944,
  slategrey: 7372944,
  snow: 16775930,
  springgreen: 65407,
  steelblue: 4620980,
  tan: 13808780,
  teal: 32896,
  thistle: 14204888,
  tomato: 16737095,
  turquoise: 4251856,
  violet: 15631086,
  wheat: 16113331,
  white: 16777215,
  whitesmoke: 16119285,
  yellow: 16776960,
  yellowgreen: 10145074
};
Uo(sn, nn, {
  copy(e) {
    return Object.assign(new this.constructor(), this, e);
  },
  displayable() {
    return this.rgb().displayable();
  },
  hex: ca,
  // Deprecated! Use color.formatHex.
  formatHex: ca,
  formatHex8: _f,
  formatHsl: wf,
  formatRgb: fa,
  toString: fa
});
function ca() {
  return this.rgb().formatHex();
}
function _f() {
  return this.rgb().formatHex8();
}
function wf() {
  return $l(this).formatHsl();
}
function fa() {
  return this.rgb().formatRgb();
}
function nn(e) {
  var t, n;
  return e = (e + "").trim().toLowerCase(), (t = pf.exec(e)) ? (n = t[1].length, t = parseInt(t[1], 16), n === 6 ? pa(t) : n === 3 ? new ze(t >> 8 & 15 | t >> 4 & 240, t >> 4 & 15 | t & 240, (t & 15) << 4 | t & 15, 1) : n === 8 ? dn(t >> 24 & 255, t >> 16 & 255, t >> 8 & 255, (t & 255) / 255) : n === 4 ? dn(t >> 12 & 15 | t >> 8 & 240, t >> 8 & 15 | t >> 4 & 240, t >> 4 & 15 | t & 240, ((t & 15) << 4 | t & 15) / 255) : null) : (t = hf.exec(e)) ? new ze(t[1], t[2], t[3], 1) : (t = mf.exec(e)) ? new ze(t[1] * 255 / 100, t[2] * 255 / 100, t[3] * 255 / 100, 1) : (t = gf.exec(e)) ? dn(t[1], t[2], t[3], t[4]) : (t = vf.exec(e)) ? dn(t[1] * 255 / 100, t[2] * 255 / 100, t[3] * 255 / 100, t[4]) : (t = yf.exec(e)) ? ga(t[1], t[2] / 100, t[3] / 100, 1) : (t = bf.exec(e)) ? ga(t[1], t[2] / 100, t[3] / 100, t[4]) : da.hasOwnProperty(e) ? pa(da[e]) : e === "transparent" ? new ze(NaN, NaN, NaN, 0) : null;
}
function pa(e) {
  return new ze(e >> 16 & 255, e >> 8 & 255, e & 255, 1);
}
function dn(e, t, n, a) {
  return a <= 0 && (e = t = n = NaN), new ze(e, t, n, a);
}
function Cf(e) {
  return e instanceof sn || (e = nn(e)), e ? (e = e.rgb(), new ze(e.r, e.g, e.b, e.opacity)) : new ze();
}
function yo(e, t, n, a) {
  return arguments.length === 1 ? Cf(e) : new ze(e, t, n, a ?? 1);
}
function ze(e, t, n, a) {
  this.r = +e, this.g = +t, this.b = +n, this.opacity = +a;
}
Uo(ze, yo, xl(sn, {
  brighter(e) {
    return e = e == null ? Sn : Math.pow(Sn, e), new ze(this.r * e, this.g * e, this.b * e, this.opacity);
  },
  darker(e) {
    return e = e == null ? en : Math.pow(en, e), new ze(this.r * e, this.g * e, this.b * e, this.opacity);
  },
  rgb() {
    return this;
  },
  clamp() {
    return new ze(Ct(this.r), Ct(this.g), Ct(this.b), xn(this.opacity));
  },
  displayable() {
    return -0.5 <= this.r && this.r < 255.5 && -0.5 <= this.g && this.g < 255.5 && -0.5 <= this.b && this.b < 255.5 && 0 <= this.opacity && this.opacity <= 1;
  },
  hex: ha,
  // Deprecated! Use color.formatHex.
  formatHex: ha,
  formatHex8: Ef,
  formatRgb: ma,
  toString: ma
}));
function ha() {
  return `#${yt(this.r)}${yt(this.g)}${yt(this.b)}`;
}
function Ef() {
  return `#${yt(this.r)}${yt(this.g)}${yt(this.b)}${yt((isNaN(this.opacity) ? 1 : this.opacity) * 255)}`;
}
function ma() {
  const e = xn(this.opacity);
  return `${e === 1 ? "rgb(" : "rgba("}${Ct(this.r)}, ${Ct(this.g)}, ${Ct(this.b)}${e === 1 ? ")" : `, ${e})`}`;
}
function xn(e) {
  return isNaN(e) ? 1 : Math.max(0, Math.min(1, e));
}
function Ct(e) {
  return Math.max(0, Math.min(255, Math.round(e) || 0));
}
function yt(e) {
  return e = Ct(e), (e < 16 ? "0" : "") + e.toString(16);
}
function ga(e, t, n, a) {
  return a <= 0 ? e = t = n = NaN : n <= 0 || n >= 1 ? e = t = NaN : t <= 0 && (e = NaN), new Ye(e, t, n, a);
}
function $l(e) {
  if (e instanceof Ye)
    return new Ye(e.h, e.s, e.l, e.opacity);
  if (e instanceof sn || (e = nn(e)), !e)
    return new Ye();
  if (e instanceof Ye)
    return e;
  e = e.rgb();
  var t = e.r / 255, n = e.g / 255, a = e.b / 255, o = Math.min(t, n, a), l = Math.max(t, n, a), i = NaN, r = l - o, u = (l + o) / 2;
  return r ? (t === l ? i = (n - a) / r + (n < a) * 6 : n === l ? i = (a - t) / r + 2 : i = (t - n) / r + 4, r /= u < 0.5 ? l + o : 2 - l - o, i *= 60) : r = u > 0 && u < 1 ? 0 : i, new Ye(i, r, u, e.opacity);
}
function kf(e, t, n, a) {
  return arguments.length === 1 ? $l(e) : new Ye(e, t, n, a ?? 1);
}
function Ye(e, t, n, a) {
  this.h = +e, this.s = +t, this.l = +n, this.opacity = +a;
}
Uo(Ye, kf, xl(sn, {
  brighter(e) {
    return e = e == null ? Sn : Math.pow(Sn, e), new Ye(this.h, this.s, this.l * e, this.opacity);
  },
  darker(e) {
    return e = e == null ? en : Math.pow(en, e), new Ye(this.h, this.s, this.l * e, this.opacity);
  },
  rgb() {
    var e = this.h % 360 + (this.h < 0) * 360, t = isNaN(e) || isNaN(this.s) ? 0 : this.s, n = this.l, a = n + (n < 0.5 ? n : 1 - n) * t, o = 2 * n - a;
    return new ze(
      oo(e >= 240 ? e - 240 : e + 120, o, a),
      oo(e, o, a),
      oo(e < 120 ? e + 240 : e - 120, o, a),
      this.opacity
    );
  },
  clamp() {
    return new Ye(va(this.h), cn(this.s), cn(this.l), xn(this.opacity));
  },
  displayable() {
    return (0 <= this.s && this.s <= 1 || isNaN(this.s)) && 0 <= this.l && this.l <= 1 && 0 <= this.opacity && this.opacity <= 1;
  },
  formatHsl() {
    const e = xn(this.opacity);
    return `${e === 1 ? "hsl(" : "hsla("}${va(this.h)}, ${cn(this.s) * 100}%, ${cn(this.l) * 100}%${e === 1 ? ")" : `, ${e})`}`;
  }
}));
function va(e) {
  return e = (e || 0) % 360, e < 0 ? e + 360 : e;
}
function cn(e) {
  return Math.max(0, Math.min(1, e || 0));
}
function oo(e, t, n) {
  return (e < 60 ? t + (n - t) * e / 60 : e < 180 ? n : e < 240 ? t + (n - t) * (240 - e) / 60 : t) * 255;
}
const Ml = (e) => () => e;
function Nf(e, t) {
  return function(n) {
    return e + n * t;
  };
}
function Sf(e, t, n) {
  return e = Math.pow(e, n), t = Math.pow(t, n) - e, n = 1 / n, function(a) {
    return Math.pow(e + a * t, n);
  };
}
function xf(e) {
  return (e = +e) == 1 ? Bl : function(t, n) {
    return n - t ? Sf(t, n, e) : Ml(isNaN(t) ? n : t);
  };
}
function Bl(e, t) {
  var n = t - e;
  return n ? Nf(e, n) : Ml(isNaN(e) ? t : e);
}
const ya = function e(t) {
  var n = xf(t);
  function a(o, l) {
    var i = n((o = yo(o)).r, (l = yo(l)).r), r = n(o.g, l.g), u = n(o.b, l.b), s = Bl(o.opacity, l.opacity);
    return function(d) {
      return o.r = i(d), o.g = r(d), o.b = u(d), o.opacity = s(d), o + "";
    };
  }
  return a.gamma = e, a;
}(1);
function ct(e, t) {
  return e = +e, t = +t, function(n) {
    return e * (1 - n) + t * n;
  };
}
var bo = /[-+]?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]?\d+)?/g, ao = new RegExp(bo.source, "g");
function $f(e) {
  return function() {
    return e;
  };
}
function Mf(e) {
  return function(t) {
    return e(t) + "";
  };
}
function Bf(e, t) {
  var n = bo.lastIndex = ao.lastIndex = 0, a, o, l, i = -1, r = [], u = [];
  for (e = e + "", t = t + ""; (a = bo.exec(e)) && (o = ao.exec(t)); )
    (l = o.index) > n && (l = t.slice(n, l), r[i] ? r[i] += l : r[++i] = l), (a = a[0]) === (o = o[0]) ? r[i] ? r[i] += o : r[++i] = o : (r[++i] = null, u.push({ i, x: ct(a, o) })), n = ao.lastIndex;
  return n < t.length && (l = t.slice(n), r[i] ? r[i] += l : r[++i] = l), r.length < 2 ? u[0] ? Mf(u[0].x) : $f(t) : (t = u.length, function(s) {
    for (var d = 0, c; d < t; ++d)
      r[(c = u[d]).i] = c.x(s);
    return r.join("");
  });
}
var ba = 180 / Math.PI, _o = {
  translateX: 0,
  translateY: 0,
  rotate: 0,
  skewX: 0,
  scaleX: 1,
  scaleY: 1
};
function Il(e, t, n, a, o, l) {
  var i, r, u;
  return (i = Math.sqrt(e * e + t * t)) && (e /= i, t /= i), (u = e * n + t * a) && (n -= e * u, a -= t * u), (r = Math.sqrt(n * n + a * a)) && (n /= r, a /= r, u /= r), e * a < t * n && (e = -e, t = -t, u = -u, i = -i), {
    translateX: o,
    translateY: l,
    rotate: Math.atan2(t, e) * ba,
    skewX: Math.atan(u) * ba,
    scaleX: i,
    scaleY: r
  };
}
var fn;
function If(e) {
  const t = new (typeof DOMMatrix == "function" ? DOMMatrix : WebKitCSSMatrix)(e + "");
  return t.isIdentity ? _o : Il(t.a, t.b, t.c, t.d, t.e, t.f);
}
function Df(e) {
  return e == null || (fn || (fn = document.createElementNS("http://www.w3.org/2000/svg", "g")), fn.setAttribute("transform", e), !(e = fn.transform.baseVal.consolidate())) ? _o : (e = e.matrix, Il(e.a, e.b, e.c, e.d, e.e, e.f));
}
function Dl(e, t, n, a) {
  function o(s) {
    return s.length ? s.pop() + " " : "";
  }
  function l(s, d, c, f, h, y) {
    if (s !== c || d !== f) {
      var b = h.push("translate(", null, t, null, n);
      y.push({ i: b - 4, x: ct(s, c) }, { i: b - 2, x: ct(d, f) });
    } else
      (c || f) && h.push("translate(" + c + t + f + n);
  }
  function i(s, d, c, f) {
    s !== d ? (s - d > 180 ? d += 360 : d - s > 180 && (s += 360), f.push({ i: c.push(o(c) + "rotate(", null, a) - 2, x: ct(s, d) })) : d && c.push(o(c) + "rotate(" + d + a);
  }
  function r(s, d, c, f) {
    s !== d ? f.push({ i: c.push(o(c) + "skewX(", null, a) - 2, x: ct(s, d) }) : d && c.push(o(c) + "skewX(" + d + a);
  }
  function u(s, d, c, f, h, y) {
    if (s !== c || d !== f) {
      var b = h.push(o(h) + "scale(", null, ",", null, ")");
      y.push({ i: b - 4, x: ct(s, c) }, { i: b - 2, x: ct(d, f) });
    } else
      (c !== 1 || f !== 1) && h.push(o(h) + "scale(" + c + "," + f + ")");
  }
  return function(s, d) {
    var c = [], f = [];
    return s = e(s), d = e(d), l(s.translateX, s.translateY, d.translateX, d.translateY, c, f), i(s.rotate, d.rotate, c, f), r(s.skewX, d.skewX, c, f), u(s.scaleX, s.scaleY, d.scaleX, d.scaleY, c, f), s = d = null, function(h) {
      for (var y = -1, b = f.length, p; ++y < b; )
        c[(p = f[y]).i] = p.x(h);
      return c.join("");
    };
  };
}
var Vf = Dl(If, "px, ", "px)", "deg)"), Af = Dl(Df, ", ", ")", ")"), Ff = 1e-12;
function _a(e) {
  return ((e = Math.exp(e)) + 1 / e) / 2;
}
function Tf(e) {
  return ((e = Math.exp(e)) - 1 / e) / 2;
}
function Of(e) {
  return ((e = Math.exp(2 * e)) - 1) / (e + 1);
}
const Pf = function e(t, n, a) {
  function o(l, i) {
    var r = l[0], u = l[1], s = l[2], d = i[0], c = i[1], f = i[2], h = d - r, y = c - u, b = h * h + y * y, p, w;
    if (b < Ff)
      w = Math.log(f / s) / t, p = function(B) {
        return [
          r + B * h,
          u + B * y,
          s * Math.exp(t * B * w)
        ];
      };
    else {
      var k = Math.sqrt(b), E = (f * f - s * s + a * b) / (2 * s * n * k), _ = (f * f - s * s - a * b) / (2 * f * n * k), C = Math.log(Math.sqrt(E * E + 1) - E), G = Math.log(Math.sqrt(_ * _ + 1) - _);
      w = (G - C) / t, p = function(B) {
        var $ = B * w, M = _a(C), A = s / (n * k) * (M * Of(t * $ + C) - Tf(C));
        return [
          r + A * h,
          u + A * y,
          s * M / _a(t * $ + C)
        ];
      };
    }
    return p.duration = w * 1e3 * t / Math.SQRT2, p;
  }
  return o.rho = function(l) {
    var i = Math.max(1e-3, +l), r = i * i, u = r * r;
    return e(i, r, u);
  }, o;
}(Math.SQRT2, 2, 4);
var Ht = 0, Yt = 0, Gt = 0, Vl = 1e3, $n, Kt, Mn = 0, xt = 0, Kn = 0, on = typeof performance == "object" && performance.now ? performance : Date, Al = typeof window == "object" && window.requestAnimationFrame ? window.requestAnimationFrame.bind(window) : function(e) {
  setTimeout(e, 17);
};
function Go() {
  return xt || (Al(Lf), xt = on.now() + Kn);
}
function Lf() {
  xt = 0;
}
function Bn() {
  this._call = this._time = this._next = null;
}
Bn.prototype = Fl.prototype = {
  constructor: Bn,
  restart: function(e, t, n) {
    if (typeof e != "function")
      throw new TypeError("callback is not a function");
    n = (n == null ? Go() : +n) + (t == null ? 0 : +t), !this._next && Kt !== this && (Kt ? Kt._next = this : $n = this, Kt = this), this._call = e, this._time = n, wo();
  },
  stop: function() {
    this._call && (this._call = null, this._time = 1 / 0, wo());
  }
};
function Fl(e, t, n) {
  var a = new Bn();
  return a.restart(e, t, n), a;
}
function zf() {
  Go(), ++Ht;
  for (var e = $n, t; e; )
    (t = xt - e._time) >= 0 && e._call.call(void 0, t), e = e._next;
  --Ht;
}
function wa() {
  xt = (Mn = on.now()) + Kn, Ht = Yt = 0;
  try {
    zf();
  } finally {
    Ht = 0, Hf(), xt = 0;
  }
}
function Rf() {
  var e = on.now(), t = e - Mn;
  t > Vl && (Kn -= t, Mn = e);
}
function Hf() {
  for (var e, t = $n, n, a = 1 / 0; t; )
    t._call ? (a > t._time && (a = t._time), e = t, t = t._next) : (n = t._next, t._next = null, t = e ? e._next = n : $n = n);
  Kt = e, wo(a);
}
function wo(e) {
  if (!Ht) {
    Yt && (Yt = clearTimeout(Yt));
    var t = e - xt;
    t > 24 ? (e < 1 / 0 && (Yt = setTimeout(wa, e - on.now() - Kn)), Gt && (Gt = clearInterval(Gt))) : (Gt || (Mn = on.now(), Gt = setInterval(Rf, Vl)), Ht = 1, Al(wa));
  }
}
function Ca(e, t, n) {
  var a = new Bn();
  return t = t == null ? 0 : +t, a.restart((o) => {
    a.stop(), e(o + t);
  }, t, n), a;
}
var Uf = Zn("start", "end", "cancel", "interrupt"), Gf = [], Tl = 0, Ea = 1, Co = 2, _n = 3, ka = 4, Eo = 5, wn = 6;
function Xn(e, t, n, a, o, l) {
  var i = e.__transition;
  if (!i)
    e.__transition = {};
  else if (n in i)
    return;
  Zf(e, n, {
    name: t,
    index: a,
    // For context during callback.
    group: o,
    // For context during callback.
    on: Uf,
    tween: Gf,
    time: l.time,
    delay: l.delay,
    duration: l.duration,
    ease: l.ease,
    timer: null,
    state: Tl
  });
}
function Zo(e, t) {
  var n = Ke(e, t);
  if (n.state > Tl)
    throw new Error("too late; already scheduled");
  return n;
}
function et(e, t) {
  var n = Ke(e, t);
  if (n.state > _n)
    throw new Error("too late; already running");
  return n;
}
function Ke(e, t) {
  var n = e.__transition;
  if (!n || !(n = n[t]))
    throw new Error("transition not found");
  return n;
}
function Zf(e, t, n) {
  var a = e.__transition, o;
  a[t] = n, n.timer = Fl(l, 0, n.time);
  function l(s) {
    n.state = Ea, n.timer.restart(i, n.delay, n.time), n.delay <= s && i(s - n.delay);
  }
  function i(s) {
    var d, c, f, h;
    if (n.state !== Ea)
      return u();
    for (d in a)
      if (h = a[d], h.name === n.name) {
        if (h.state === _n)
          return Ca(i);
        h.state === ka ? (h.state = wn, h.timer.stop(), h.on.call("interrupt", e, e.__data__, h.index, h.group), delete a[d]) : +d < t && (h.state = wn, h.timer.stop(), h.on.call("cancel", e, e.__data__, h.index, h.group), delete a[d]);
      }
    if (Ca(function() {
      n.state === _n && (n.state = ka, n.timer.restart(r, n.delay, n.time), r(s));
    }), n.state = Co, n.on.call("start", e, e.__data__, n.index, n.group), n.state === Co) {
      for (n.state = _n, o = new Array(f = n.tween.length), d = 0, c = -1; d < f; ++d)
        (h = n.tween[d].value.call(e, e.__data__, n.index, n.group)) && (o[++c] = h);
      o.length = c + 1;
    }
  }
  function r(s) {
    for (var d = s < n.duration ? n.ease.call(null, s / n.duration) : (n.timer.restart(u), n.state = Eo, 1), c = -1, f = o.length; ++c < f; )
      o[c].call(e, d);
    n.state === Eo && (n.on.call("end", e, e.__data__, n.index, n.group), u());
  }
  function u() {
    n.state = wn, n.timer.stop(), delete a[t];
    for (var s in a)
      return;
    delete e.__transition;
  }
}
function Cn(e, t) {
  var n = e.__transition, a, o, l = !0, i;
  if (n) {
    t = t == null ? null : t + "";
    for (i in n) {
      if ((a = n[i]).name !== t) {
        l = !1;
        continue;
      }
      o = a.state > Co && a.state < Eo, a.state = wn, a.timer.stop(), a.on.call(o ? "interrupt" : "cancel", e, e.__data__, a.index, a.group), delete n[i];
    }
    l && delete e.__transition;
  }
}
function Yf(e) {
  return this.each(function() {
    Cn(this, e);
  });
}
function Kf(e, t) {
  var n, a;
  return function() {
    var o = et(this, e), l = o.tween;
    if (l !== n) {
      a = n = l;
      for (var i = 0, r = a.length; i < r; ++i)
        if (a[i].name === t) {
          a = a.slice(), a.splice(i, 1);
          break;
        }
    }
    o.tween = a;
  };
}
function Xf(e, t, n) {
  var a, o;
  if (typeof n != "function")
    throw new Error();
  return function() {
    var l = et(this, e), i = l.tween;
    if (i !== a) {
      o = (a = i).slice();
      for (var r = { name: t, value: n }, u = 0, s = o.length; u < s; ++u)
        if (o[u].name === t) {
          o[u] = r;
          break;
        }
      u === s && o.push(r);
    }
    l.tween = o;
  };
}
function Wf(e, t) {
  var n = this._id;
  if (e += "", arguments.length < 2) {
    for (var a = Ke(this.node(), n).tween, o = 0, l = a.length, i; o < l; ++o)
      if ((i = a[o]).name === e)
        return i.value;
    return null;
  }
  return this.each((t == null ? Kf : Xf)(n, e, t));
}
function Yo(e, t, n) {
  var a = e._id;
  return e.each(function() {
    var o = et(this, a);
    (o.value || (o.value = {}))[t] = n.apply(this, arguments);
  }), function(o) {
    return Ke(o, a).value[t];
  };
}
function Ol(e, t) {
  var n;
  return (typeof t == "number" ? ct : t instanceof nn ? ya : (n = nn(t)) ? (t = n, ya) : Bf)(e, t);
}
function jf(e) {
  return function() {
    this.removeAttribute(e);
  };
}
function qf(e) {
  return function() {
    this.removeAttributeNS(e.space, e.local);
  };
}
function Jf(e, t, n) {
  var a, o = n + "", l;
  return function() {
    var i = this.getAttribute(e);
    return i === o ? null : i === a ? l : l = t(a = i, n);
  };
}
function Qf(e, t, n) {
  var a, o = n + "", l;
  return function() {
    var i = this.getAttributeNS(e.space, e.local);
    return i === o ? null : i === a ? l : l = t(a = i, n);
  };
}
function ep(e, t, n) {
  var a, o, l;
  return function() {
    var i, r = n(this), u;
    return r == null ? void this.removeAttribute(e) : (i = this.getAttribute(e), u = r + "", i === u ? null : i === a && u === o ? l : (o = u, l = t(a = i, r)));
  };
}
function tp(e, t, n) {
  var a, o, l;
  return function() {
    var i, r = n(this), u;
    return r == null ? void this.removeAttributeNS(e.space, e.local) : (i = this.getAttributeNS(e.space, e.local), u = r + "", i === u ? null : i === a && u === o ? l : (o = u, l = t(a = i, r)));
  };
}
function np(e, t) {
  var n = Yn(e), a = n === "transform" ? Af : Ol;
  return this.attrTween(e, typeof t == "function" ? (n.local ? tp : ep)(n, a, Yo(this, "attr." + e, t)) : t == null ? (n.local ? qf : jf)(n) : (n.local ? Qf : Jf)(n, a, t));
}
function op(e, t) {
  return function(n) {
    this.setAttribute(e, t.call(this, n));
  };
}
function ap(e, t) {
  return function(n) {
    this.setAttributeNS(e.space, e.local, t.call(this, n));
  };
}
function lp(e, t) {
  var n, a;
  function o() {
    var l = t.apply(this, arguments);
    return l !== a && (n = (a = l) && ap(e, l)), n;
  }
  return o._value = t, o;
}
function ip(e, t) {
  var n, a;
  function o() {
    var l = t.apply(this, arguments);
    return l !== a && (n = (a = l) && op(e, l)), n;
  }
  return o._value = t, o;
}
function rp(e, t) {
  var n = "attr." + e;
  if (arguments.length < 2)
    return (n = this.tween(n)) && n._value;
  if (t == null)
    return this.tween(n, null);
  if (typeof t != "function")
    throw new Error();
  var a = Yn(e);
  return this.tween(n, (a.local ? lp : ip)(a, t));
}
function sp(e, t) {
  return function() {
    Zo(this, e).delay = +t.apply(this, arguments);
  };
}
function up(e, t) {
  return t = +t, function() {
    Zo(this, e).delay = t;
  };
}
function dp(e) {
  var t = this._id;
  return arguments.length ? this.each((typeof e == "function" ? sp : up)(t, e)) : Ke(this.node(), t).delay;
}
function cp(e, t) {
  return function() {
    et(this, e).duration = +t.apply(this, arguments);
  };
}
function fp(e, t) {
  return t = +t, function() {
    et(this, e).duration = t;
  };
}
function pp(e) {
  var t = this._id;
  return arguments.length ? this.each((typeof e == "function" ? cp : fp)(t, e)) : Ke(this.node(), t).duration;
}
function hp(e, t) {
  if (typeof t != "function")
    throw new Error();
  return function() {
    et(this, e).ease = t;
  };
}
function mp(e) {
  var t = this._id;
  return arguments.length ? this.each(hp(t, e)) : Ke(this.node(), t).ease;
}
function gp(e, t) {
  return function() {
    var n = t.apply(this, arguments);
    if (typeof n != "function")
      throw new Error();
    et(this, e).ease = n;
  };
}
function vp(e) {
  if (typeof e != "function")
    throw new Error();
  return this.each(gp(this._id, e));
}
function yp(e) {
  typeof e != "function" && (e = ml(e));
  for (var t = this._groups, n = t.length, a = new Array(n), o = 0; o < n; ++o)
    for (var l = t[o], i = l.length, r = a[o] = [], u, s = 0; s < i; ++s)
      (u = l[s]) && e.call(u, u.__data__, s, l) && r.push(u);
  return new st(a, this._parents, this._name, this._id);
}
function bp(e) {
  if (e._id !== this._id)
    throw new Error();
  for (var t = this._groups, n = e._groups, a = t.length, o = n.length, l = Math.min(a, o), i = new Array(a), r = 0; r < l; ++r)
    for (var u = t[r], s = n[r], d = u.length, c = i[r] = new Array(d), f, h = 0; h < d; ++h)
      (f = u[h] || s[h]) && (c[h] = f);
  for (; r < a; ++r)
    i[r] = t[r];
  return new st(i, this._parents, this._name, this._id);
}
function _p(e) {
  return (e + "").trim().split(/^|\s+/).every(function(t) {
    var n = t.indexOf(".");
    return n >= 0 && (t = t.slice(0, n)), !t || t === "start";
  });
}
function wp(e, t, n) {
  var a, o, l = _p(t) ? Zo : et;
  return function() {
    var i = l(this, e), r = i.on;
    r !== a && (o = (a = r).copy()).on(t, n), i.on = o;
  };
}
function Cp(e, t) {
  var n = this._id;
  return arguments.length < 2 ? Ke(this.node(), n).on.on(e) : this.each(wp(n, e, t));
}
function Ep(e) {
  return function() {
    var t = this.parentNode;
    for (var n in this.__transition)
      if (+n !== e)
        return;
    t && t.removeChild(this);
  };
}
function kp() {
  return this.on("end.remove", Ep(this._id));
}
function Np(e) {
  var t = this._name, n = this._id;
  typeof e != "function" && (e = Ro(e));
  for (var a = this._groups, o = a.length, l = new Array(o), i = 0; i < o; ++i)
    for (var r = a[i], u = r.length, s = l[i] = new Array(u), d, c, f = 0; f < u; ++f)
      (d = r[f]) && (c = e.call(d, d.__data__, f, r)) && ("__data__" in d && (c.__data__ = d.__data__), s[f] = c, Xn(s[f], t, n, f, s, Ke(d, n)));
  return new st(l, this._parents, t, n);
}
function Sp(e) {
  var t = this._name, n = this._id;
  typeof e != "function" && (e = hl(e));
  for (var a = this._groups, o = a.length, l = [], i = [], r = 0; r < o; ++r)
    for (var u = a[r], s = u.length, d, c = 0; c < s; ++c)
      if (d = u[c]) {
        for (var f = e.call(d, d.__data__, c, u), h, y = Ke(d, n), b = 0, p = f.length; b < p; ++b)
          (h = f[b]) && Xn(h, t, n, b, f, y);
        l.push(f), i.push(d);
      }
  return new st(l, i, t, n);
}
var xp = rn.prototype.constructor;
function $p() {
  return new xp(this._groups, this._parents);
}
function Mp(e, t) {
  var n, a, o;
  return function() {
    var l = Rt(this, e), i = (this.style.removeProperty(e), Rt(this, e));
    return l === i ? null : l === n && i === a ? o : o = t(n = l, a = i);
  };
}
function Pl(e) {
  return function() {
    this.style.removeProperty(e);
  };
}
function Bp(e, t, n) {
  var a, o = n + "", l;
  return function() {
    var i = Rt(this, e);
    return i === o ? null : i === a ? l : l = t(a = i, n);
  };
}
function Ip(e, t, n) {
  var a, o, l;
  return function() {
    var i = Rt(this, e), r = n(this), u = r + "";
    return r == null && (u = r = (this.style.removeProperty(e), Rt(this, e))), i === u ? null : i === a && u === o ? l : (o = u, l = t(a = i, r));
  };
}
function Dp(e, t) {
  var n, a, o, l = "style." + t, i = "end." + l, r;
  return function() {
    var u = et(this, e), s = u.on, d = u.value[l] == null ? r || (r = Pl(t)) : void 0;
    (s !== n || o !== d) && (a = (n = s).copy()).on(i, o = d), u.on = a;
  };
}
function Vp(e, t, n) {
  var a = (e += "") == "transform" ? Vf : Ol;
  return t == null ? this.styleTween(e, Mp(e, a)).on("end.style." + e, Pl(e)) : typeof t == "function" ? this.styleTween(e, Ip(e, a, Yo(this, "style." + e, t))).each(Dp(this._id, e)) : this.styleTween(e, Bp(e, a, t), n).on("end.style." + e, null);
}
function Ap(e, t, n) {
  return function(a) {
    this.style.setProperty(e, t.call(this, a), n);
  };
}
function Fp(e, t, n) {
  var a, o;
  function l() {
    var i = t.apply(this, arguments);
    return i !== o && (a = (o = i) && Ap(e, i, n)), a;
  }
  return l._value = t, l;
}
function Tp(e, t, n) {
  var a = "style." + (e += "");
  if (arguments.length < 2)
    return (a = this.tween(a)) && a._value;
  if (t == null)
    return this.tween(a, null);
  if (typeof t != "function")
    throw new Error();
  return this.tween(a, Fp(e, t, n ?? ""));
}
function Op(e) {
  return function() {
    this.textContent = e;
  };
}
function Pp(e) {
  return function() {
    var t = e(this);
    this.textContent = t ?? "";
  };
}
function Lp(e) {
  return this.tween("text", typeof e == "function" ? Pp(Yo(this, "text", e)) : Op(e == null ? "" : e + ""));
}
function zp(e) {
  return function(t) {
    this.textContent = e.call(this, t);
  };
}
function Rp(e) {
  var t, n;
  function a() {
    var o = e.apply(this, arguments);
    return o !== n && (t = (n = o) && zp(o)), t;
  }
  return a._value = e, a;
}
function Hp(e) {
  var t = "text";
  if (arguments.length < 1)
    return (t = this.tween(t)) && t._value;
  if (e == null)
    return this.tween(t, null);
  if (typeof e != "function")
    throw new Error();
  return this.tween(t, Rp(e));
}
function Up() {
  for (var e = this._name, t = this._id, n = Ll(), a = this._groups, o = a.length, l = 0; l < o; ++l)
    for (var i = a[l], r = i.length, u, s = 0; s < r; ++s)
      if (u = i[s]) {
        var d = Ke(u, t);
        Xn(u, e, n, s, i, {
          time: d.time + d.delay + d.duration,
          delay: 0,
          duration: d.duration,
          ease: d.ease
        });
      }
  return new st(a, this._parents, e, n);
}
function Gp() {
  var e, t, n = this, a = n._id, o = n.size();
  return new Promise(function(l, i) {
    var r = { value: i }, u = { value: function() {
      --o === 0 && l();
    } };
    n.each(function() {
      var s = et(this, a), d = s.on;
      d !== e && (t = (e = d).copy(), t._.cancel.push(r), t._.interrupt.push(r), t._.end.push(u)), s.on = t;
    }), o === 0 && l();
  });
}
var Zp = 0;
function st(e, t, n, a) {
  this._groups = e, this._parents = t, this._name = n, this._id = a;
}
function Ll() {
  return ++Zp;
}
var nt = rn.prototype;
st.prototype = {
  constructor: st,
  select: Np,
  selectAll: Sp,
  selectChild: nt.selectChild,
  selectChildren: nt.selectChildren,
  filter: yp,
  merge: bp,
  selection: $p,
  transition: Up,
  call: nt.call,
  nodes: nt.nodes,
  node: nt.node,
  size: nt.size,
  empty: nt.empty,
  each: nt.each,
  on: Cp,
  attr: np,
  attrTween: rp,
  style: Vp,
  styleTween: Tp,
  text: Lp,
  textTween: Hp,
  remove: kp,
  tween: Wf,
  delay: dp,
  duration: pp,
  ease: mp,
  easeVarying: vp,
  end: Gp,
  [Symbol.iterator]: nt[Symbol.iterator]
};
function Yp(e) {
  return ((e *= 2) <= 1 ? e * e * e : (e -= 2) * e * e + 2) / 2;
}
var Kp = {
  time: null,
  // Set on use.
  delay: 0,
  duration: 250,
  ease: Yp
};
function Xp(e, t) {
  for (var n; !(n = e.__transition) || !(n = n[t]); )
    if (!(e = e.parentNode))
      throw new Error(`transition ${t} not found`);
  return n;
}
function Wp(e) {
  var t, n;
  e instanceof st ? (t = e._id, e = e._name) : (t = Ll(), (n = Kp).time = Go(), e = e == null ? null : e + "");
  for (var a = this._groups, o = a.length, l = 0; l < o; ++l)
    for (var i = a[l], r = i.length, u, s = 0; s < r; ++s)
      (u = i[s]) && Xn(u, e, t, s, i, n || Xp(u, t));
  return new st(a, this._parents, e, t);
}
rn.prototype.interrupt = Yf;
rn.prototype.transition = Wp;
const pn = (e) => () => e;
function jp(e, {
  sourceEvent: t,
  target: n,
  transform: a,
  dispatch: o
}) {
  Object.defineProperties(this, {
    type: { value: e, enumerable: !0, configurable: !0 },
    sourceEvent: { value: t, enumerable: !0, configurable: !0 },
    target: { value: n, enumerable: !0, configurable: !0 },
    transform: { value: a, enumerable: !0, configurable: !0 },
    _: { value: o }
  });
}
function lt(e, t, n) {
  this.k = e, this.x = t, this.y = n;
}
lt.prototype = {
  constructor: lt,
  scale: function(e) {
    return e === 1 ? this : new lt(this.k * e, this.x, this.y);
  },
  translate: function(e, t) {
    return e === 0 & t === 0 ? this : new lt(this.k, this.x + this.k * e, this.y + this.k * t);
  },
  apply: function(e) {
    return [e[0] * this.k + this.x, e[1] * this.k + this.y];
  },
  applyX: function(e) {
    return e * this.k + this.x;
  },
  applyY: function(e) {
    return e * this.k + this.y;
  },
  invert: function(e) {
    return [(e[0] - this.x) / this.k, (e[1] - this.y) / this.k];
  },
  invertX: function(e) {
    return (e - this.x) / this.k;
  },
  invertY: function(e) {
    return (e - this.y) / this.k;
  },
  rescaleX: function(e) {
    return e.copy().domain(e.range().map(this.invertX, this).map(e.invert, e));
  },
  rescaleY: function(e) {
    return e.copy().domain(e.range().map(this.invertY, this).map(e.invert, e));
  },
  toString: function() {
    return "translate(" + this.x + "," + this.y + ") scale(" + this.k + ")";
  }
};
var Ut = new lt(1, 0, 0);
lt.prototype;
function lo(e) {
  e.stopImmediatePropagation();
}
function Zt(e) {
  e.preventDefault(), e.stopImmediatePropagation();
}
function qp(e) {
  return (!e.ctrlKey || e.type === "wheel") && !e.button;
}
function Jp() {
  var e = this;
  return e instanceof SVGElement ? (e = e.ownerSVGElement || e, e.hasAttribute("viewBox") ? (e = e.viewBox.baseVal, [[e.x, e.y], [e.x + e.width, e.y + e.height]]) : [[0, 0], [e.width.baseVal.value, e.height.baseVal.value]]) : [[0, 0], [e.clientWidth, e.clientHeight]];
}
function Na() {
  return this.__zoom || Ut;
}
function Qp(e) {
  return -e.deltaY * (e.deltaMode === 1 ? 0.05 : e.deltaMode ? 1 : 2e-3) * (e.ctrlKey ? 10 : 1);
}
function eh() {
  return navigator.maxTouchPoints || "ontouchstart" in this;
}
function th(e, t, n) {
  var a = e.invertX(t[0][0]) - n[0][0], o = e.invertX(t[1][0]) - n[1][0], l = e.invertY(t[0][1]) - n[0][1], i = e.invertY(t[1][1]) - n[1][1];
  return e.translate(
    o > a ? (a + o) / 2 : Math.min(0, a) || Math.max(0, o),
    i > l ? (l + i) / 2 : Math.min(0, l) || Math.max(0, i)
  );
}
function nh() {
  var e = qp, t = Jp, n = th, a = Qp, o = eh, l = [0, 1 / 0], i = [[-1 / 0, -1 / 0], [1 / 0, 1 / 0]], r = 250, u = Pf, s = Zn("start", "zoom", "end"), d, c, f, h = 500, y = 150, b = 0, p = 10;
  function w(N) {
    N.property("__zoom", Na).on("wheel.zoom", $, { passive: !1 }).on("mousedown.zoom", M).on("dblclick.zoom", A).filter(o).on("touchstart.zoom", S).on("touchmove.zoom", Z).on("touchend.zoom touchcancel.zoom", z).style("-webkit-tap-highlight-color", "rgba(0,0,0,0)");
  }
  w.transform = function(N, P, T, g) {
    var H = N.selection ? N.selection() : N;
    H.property("__zoom", Na), N !== H ? C(N, P, T, g) : H.interrupt().each(function() {
      G(this, arguments).event(g).start().zoom(null, typeof P == "function" ? P.apply(this, arguments) : P).end();
    });
  }, w.scaleBy = function(N, P, T, g) {
    w.scaleTo(N, function() {
      var H = this.__zoom.k, F = typeof P == "function" ? P.apply(this, arguments) : P;
      return H * F;
    }, T, g);
  }, w.scaleTo = function(N, P, T, g) {
    w.transform(N, function() {
      var H = t.apply(this, arguments), F = this.__zoom, W = T == null ? _(H) : typeof T == "function" ? T.apply(this, arguments) : T, oe = F.invert(W), re = typeof P == "function" ? P.apply(this, arguments) : P;
      return n(E(k(F, re), W, oe), H, i);
    }, T, g);
  }, w.translateBy = function(N, P, T, g) {
    w.transform(N, function() {
      return n(this.__zoom.translate(
        typeof P == "function" ? P.apply(this, arguments) : P,
        typeof T == "function" ? T.apply(this, arguments) : T
      ), t.apply(this, arguments), i);
    }, null, g);
  }, w.translateTo = function(N, P, T, g, H) {
    w.transform(N, function() {
      var F = t.apply(this, arguments), W = this.__zoom, oe = g == null ? _(F) : typeof g == "function" ? g.apply(this, arguments) : g;
      return n(Ut.translate(oe[0], oe[1]).scale(W.k).translate(
        typeof P == "function" ? -P.apply(this, arguments) : -P,
        typeof T == "function" ? -T.apply(this, arguments) : -T
      ), F, i);
    }, g, H);
  };
  function k(N, P) {
    return P = Math.max(l[0], Math.min(l[1], P)), P === N.k ? N : new lt(P, N.x, N.y);
  }
  function E(N, P, T) {
    var g = P[0] - T[0] * N.k, H = P[1] - T[1] * N.k;
    return g === N.x && H === N.y ? N : new lt(N.k, g, H);
  }
  function _(N) {
    return [(+N[0][0] + +N[1][0]) / 2, (+N[0][1] + +N[1][1]) / 2];
  }
  function C(N, P, T, g) {
    N.on("start.zoom", function() {
      G(this, arguments).event(g).start();
    }).on("interrupt.zoom end.zoom", function() {
      G(this, arguments).event(g).end();
    }).tween("zoom", function() {
      var H = this, F = arguments, W = G(H, F).event(g), oe = t.apply(H, F), re = T == null ? _(oe) : typeof T == "function" ? T.apply(H, F) : T, ye = Math.max(oe[1][0] - oe[0][0], oe[1][1] - oe[0][1]), Q = H.__zoom, q = typeof P == "function" ? P.apply(H, F) : P, Y = u(Q.invert(re).concat(ye / Q.k), q.invert(re).concat(ye / q.k));
      return function(fe) {
        if (fe === 1)
          fe = q;
        else {
          var ce = Y(fe), v = ye / ce[2];
          fe = new lt(v, re[0] - ce[0] * v, re[1] - ce[1] * v);
        }
        W.zoom(null, fe);
      };
    });
  }
  function G(N, P, T) {
    return !T && N.__zooming || new B(N, P);
  }
  function B(N, P) {
    this.that = N, this.args = P, this.active = 0, this.sourceEvent = null, this.extent = t.apply(N, P), this.taps = 0;
  }
  B.prototype = {
    event: function(N) {
      return N && (this.sourceEvent = N), this;
    },
    start: function() {
      return ++this.active === 1 && (this.that.__zooming = this, this.emit("start")), this;
    },
    zoom: function(N, P) {
      return this.mouse && N !== "mouse" && (this.mouse[1] = P.invert(this.mouse[0])), this.touch0 && N !== "touch" && (this.touch0[1] = P.invert(this.touch0[0])), this.touch1 && N !== "touch" && (this.touch1[1] = P.invert(this.touch1[0])), this.that.__zoom = P, this.emit("zoom"), this;
    },
    end: function() {
      return --this.active === 0 && (delete this.that.__zooming, this.emit("end")), this;
    },
    emit: function(N) {
      var P = Ze(this.that).datum();
      s.call(
        N,
        this.that,
        new jp(N, {
          sourceEvent: this.sourceEvent,
          target: w,
          type: N,
          transform: this.that.__zoom,
          dispatch: s
        }),
        P
      );
    }
  };
  function $(N, ...P) {
    if (!e.apply(this, arguments))
      return;
    var T = G(this, P).event(N), g = this.__zoom, H = Math.max(l[0], Math.min(l[1], g.k * Math.pow(2, a.apply(this, arguments)))), F = Xe(N);
    if (T.wheel)
      (T.mouse[0][0] !== F[0] || T.mouse[0][1] !== F[1]) && (T.mouse[1] = g.invert(T.mouse[0] = F)), clearTimeout(T.wheel);
    else {
      if (g.k === H)
        return;
      T.mouse = [F, g.invert(F)], Cn(this), T.start();
    }
    Zt(N), T.wheel = setTimeout(W, y), T.zoom("mouse", n(E(k(g, H), T.mouse[0], T.mouse[1]), T.extent, i));
    function W() {
      T.wheel = null, T.end();
    }
  }
  function M(N, ...P) {
    if (f || !e.apply(this, arguments))
      return;
    var T = N.currentTarget, g = G(this, P, !0).event(N), H = Ze(N.view).on("mousemove.zoom", re, !0).on("mouseup.zoom", ye, !0), F = Xe(N, T), W = N.clientX, oe = N.clientY;
    Nl(N.view), lo(N), g.mouse = [F, this.__zoom.invert(F)], Cn(this), g.start();
    function re(Q) {
      if (Zt(Q), !g.moved) {
        var q = Q.clientX - W, Y = Q.clientY - oe;
        g.moved = q * q + Y * Y > b;
      }
      g.event(Q).zoom("mouse", n(E(g.that.__zoom, g.mouse[0] = Xe(Q, T), g.mouse[1]), g.extent, i));
    }
    function ye(Q) {
      H.on("mousemove.zoom mouseup.zoom", null), Sl(Q.view, g.moved), Zt(Q), g.event(Q).end();
    }
  }
  function A(N, ...P) {
    if (e.apply(this, arguments)) {
      var T = this.__zoom, g = Xe(N.changedTouches ? N.changedTouches[0] : N, this), H = T.invert(g), F = T.k * (N.shiftKey ? 0.5 : 2), W = n(E(k(T, F), g, H), t.apply(this, P), i);
      Zt(N), r > 0 ? Ze(this).transition().duration(r).call(C, W, g, N) : Ze(this).call(w.transform, W, g, N);
    }
  }
  function S(N, ...P) {
    if (e.apply(this, arguments)) {
      var T = N.touches, g = T.length, H = G(this, P, N.changedTouches.length === g).event(N), F, W, oe, re;
      for (lo(N), W = 0; W < g; ++W)
        oe = T[W], re = Xe(oe, this), re = [re, this.__zoom.invert(re), oe.identifier], H.touch0 ? !H.touch1 && H.touch0[2] !== re[2] && (H.touch1 = re, H.taps = 0) : (H.touch0 = re, F = !0, H.taps = 1 + !!d);
      d && (d = clearTimeout(d)), F && (H.taps < 2 && (c = re[0], d = setTimeout(function() {
        d = null;
      }, h)), Cn(this), H.start());
    }
  }
  function Z(N, ...P) {
    if (this.__zooming) {
      var T = G(this, P).event(N), g = N.changedTouches, H = g.length, F, W, oe, re;
      for (Zt(N), F = 0; F < H; ++F)
        W = g[F], oe = Xe(W, this), T.touch0 && T.touch0[2] === W.identifier ? T.touch0[0] = oe : T.touch1 && T.touch1[2] === W.identifier && (T.touch1[0] = oe);
      if (W = T.that.__zoom, T.touch1) {
        var ye = T.touch0[0], Q = T.touch0[1], q = T.touch1[0], Y = T.touch1[1], fe = (fe = q[0] - ye[0]) * fe + (fe = q[1] - ye[1]) * fe, ce = (ce = Y[0] - Q[0]) * ce + (ce = Y[1] - Q[1]) * ce;
        W = k(W, Math.sqrt(fe / ce)), oe = [(ye[0] + q[0]) / 2, (ye[1] + q[1]) / 2], re = [(Q[0] + Y[0]) / 2, (Q[1] + Y[1]) / 2];
      } else if (T.touch0)
        oe = T.touch0[0], re = T.touch0[1];
      else
        return;
      T.zoom("touch", n(E(W, oe, re), T.extent, i));
    }
  }
  function z(N, ...P) {
    if (this.__zooming) {
      var T = G(this, P).event(N), g = N.changedTouches, H = g.length, F, W;
      for (lo(N), f && clearTimeout(f), f = setTimeout(function() {
        f = null;
      }, h), F = 0; F < H; ++F)
        W = g[F], T.touch0 && T.touch0[2] === W.identifier ? delete T.touch0 : T.touch1 && T.touch1[2] === W.identifier && delete T.touch1;
      if (T.touch1 && !T.touch0 && (T.touch0 = T.touch1, delete T.touch1), T.touch0)
        T.touch0[1] = this.__zoom.invert(T.touch0[0]);
      else if (T.end(), T.taps === 2 && (W = Xe(W, this), Math.hypot(c[0] - W[0], c[1] - W[1]) < p)) {
        var oe = Ze(this).on("dblclick.zoom");
        oe && oe.apply(this, arguments);
      }
    }
  }
  return w.wheelDelta = function(N) {
    return arguments.length ? (a = typeof N == "function" ? N : pn(+N), w) : a;
  }, w.filter = function(N) {
    return arguments.length ? (e = typeof N == "function" ? N : pn(!!N), w) : e;
  }, w.touchable = function(N) {
    return arguments.length ? (o = typeof N == "function" ? N : pn(!!N), w) : o;
  }, w.extent = function(N) {
    return arguments.length ? (t = typeof N == "function" ? N : pn([[+N[0][0], +N[0][1]], [+N[1][0], +N[1][1]]]), w) : t;
  }, w.scaleExtent = function(N) {
    return arguments.length ? (l[0] = +N[0], l[1] = +N[1], w) : [l[0], l[1]];
  }, w.translateExtent = function(N) {
    return arguments.length ? (i[0][0] = +N[0][0], i[1][0] = +N[1][0], i[0][1] = +N[0][1], i[1][1] = +N[1][1], w) : [[i[0][0], i[0][1]], [i[1][0], i[1][1]]];
  }, w.constrain = function(N) {
    return arguments.length ? (n = N, w) : n;
  }, w.duration = function(N) {
    return arguments.length ? (r = +N, w) : r;
  }, w.interpolate = function(N) {
    return arguments.length ? (u = N, w) : u;
  }, w.on = function() {
    var N = s.on.apply(s, arguments);
    return N === s ? w : N;
  }, w.clickDistance = function(N) {
    return arguments.length ? (b = (N = +N) * N, w) : Math.sqrt(b);
  }, w.tapDistance = function(N) {
    return arguments.length ? (p = +N, w) : p;
  }, w;
}
var he = /* @__PURE__ */ ((e) => (e.Left = "left", e.Top = "top", e.Right = "right", e.Bottom = "bottom", e))(he || {}), Ko = /* @__PURE__ */ ((e) => (e.Partial = "partial", e.Full = "full", e))(Ko || {}), gt = /* @__PURE__ */ ((e) => (e.Bezier = "default", e.SimpleBezier = "simple-bezier", e.Straight = "straight", e.Step = "step", e.SmoothStep = "smoothstep", e))(gt || {}), $t = /* @__PURE__ */ ((e) => (e.Strict = "strict", e.Loose = "loose", e))($t || {}), He = /* @__PURE__ */ ((e) => (e.Arrow = "arrow", e.ArrowClosed = "arrowclosed", e))(He || {}), qt = /* @__PURE__ */ ((e) => (e.Free = "free", e.Vertical = "vertical", e.Horizontal = "horizontal", e))(qt || {});
function Xo() {
  return typeof window < "u" ? window : {
    chrome: !1,
    addEventListener(...e) {
    }
  };
}
function ko(e) {
  var t, n;
  const a = ((n = (t = e.composedPath) == null ? void 0 : t.call(e)) == null ? void 0 : n[0]) || e.target, o = typeof (a == null ? void 0 : a.hasAttribute) == "function" ? a.hasAttribute("contenteditable") : !1, l = typeof (a == null ? void 0 : a.closest) == "function" ? a.closest(".nokey") : null;
  return ["INPUT", "SELECT", "TEXTAREA"].includes(a == null ? void 0 : a.nodeName) || o || !!l;
}
function oh(e) {
  return e.ctrlKey || e.metaKey || e.shiftKey;
}
function Sa(e, t, n, a) {
  const o = t.split("+").map((l) => l.trim().toLowerCase());
  return o.length === 1 ? e.toLowerCase() === t.toLowerCase() : (a ? n.delete(e.toLowerCase()) : n.add(e.toLowerCase()), o.every(
    (l, i) => n.has(l) && Array.from(n.values())[i] === o[i]
  ));
}
function ah(e, t) {
  return (n) => {
    if (!n.code && !n.key)
      return !1;
    const a = lh(n.code, e);
    return Array.isArray(e) ? e.some((o) => Sa(n[a], o, t, n.type === "keyup")) : Sa(n[a], e, t, n.type === "keyup");
  };
}
function lh(e, t) {
  return typeof t == "string" ? e === t ? "code" : "key" : t.includes(e) ? "code" : "key";
}
function Jt(e, t, n = { actInsideInputWithModifier: !0 }) {
  const a = Xo(), o = ae(ke(e) === !0);
  let l = !1;
  const i = /* @__PURE__ */ new Set();
  let r = s(ke(e));
  return ie(o, (d, c) => {
    d !== c && (t == null || t(d));
  }), ie(
    () => ke(e),
    (d, c) => {
      typeof c == "boolean" && typeof d != "boolean" && u(), r = s(d);
    },
    {
      immediate: !0
    }
  ), fl(a, "blur", () => {
    ke(e) !== !0 && (o.value = !1);
  }), ra(
    (...d) => r(...d),
    (d) => {
      l = oh(d), !((!l || l && !n.actInsideInputWithModifier) && ko(d)) && (d.preventDefault(), o.value = !0);
    },
    { eventName: "keydown" }
  ), ra(
    (...d) => r(...d),
    (d) => {
      if (o.value) {
        if ((!l || l && !n.actInsideInputWithModifier) && ko(d))
          return;
        u();
      }
    },
    { eventName: "keyup" }
  ), o;
  function u() {
    l = !1, i.clear(), o.value = !1;
  }
  function s(d) {
    return d === null ? (u(), () => !1) : typeof d == "boolean" ? (u(), o.value = d, () => !1) : Array.isArray(d) || typeof d == "string" ? ah(d, i) : d;
  }
}
const zl = "vue-flow__node-desc", Rl = "vue-flow__edge-desc", ih = "vue-flow__aria-live", Hl = ["Enter", " ", "Escape"], Lt = {
  ArrowUp: { x: 0, y: -1 },
  ArrowDown: { x: 0, y: 1 },
  ArrowLeft: { x: -1, y: 0 },
  ArrowRight: { x: 1, y: 0 }
};
function Ul(e) {
  const {
    vueFlowRef: t,
    snapToGrid: n,
    snapGrid: a,
    noDragClassName: o,
    nodes: l,
    nodeExtent: i,
    nodeDragThreshold: r,
    viewport: u,
    autoPanOnNodeDrag: s,
    nodesDraggable: d,
    panBy: c,
    findNode: f,
    multiSelectionActive: h,
    nodesSelectionActive: y,
    selectNodesOnDrag: b,
    removeSelectedElements: p,
    addSelectedNodes: w,
    updateNodePositions: k,
    emits: E
  } = $e(), { onStart: _, onDrag: C, onStop: G, el: B, disabled: $, id: M, selectable: A, dragHandle: S } = e, Z = ae(!1);
  let z = [], N, P = null, T = { x: void 0, y: void 0 }, g = { x: 0, y: 0 }, H = null, F = !1, W = 0, oe = !1;
  const re = ch(), ye = ({ x: v, y: ge }) => {
    T = { x: v, y: ge };
    let X = !1;
    if (z = z.map((we) => {
      const Se = { x: v - we.distance.x, y: ge - we.distance.y };
      n.value && (Se.x = a.value[0] * Math.round(Se.x / a.value[0]), Se.y = a.value[1] * Math.round(Se.y / a.value[1]));
      const { computedPosition: D } = jo(
        we,
        Se,
        E.error,
        i.value,
        we.parentNode ? f(we.parentNode) : void 0
      );
      return X = X || we.position.x !== D.x || we.position.y !== D.y, we.position = D, we;
    }), !!X && (k(z, !0, !0), Z.value = !0, H)) {
      const [we, Se] = co({
        id: M,
        dragItems: z,
        findNode: f
      });
      C({ event: H, node: we, nodes: Se });
    }
  }, Q = () => {
    if (!P)
      return;
    const [v, ge] = oi(g, P);
    if (v !== 0 || ge !== 0) {
      const X = {
        x: (T.x ?? 0) - v / u.value.zoom,
        y: (T.y ?? 0) - ge / u.value.zoom
      };
      c({ x: v, y: ge }) && ye(X);
    }
    W = requestAnimationFrame(Q);
  }, q = (v, ge) => {
    F = !0;
    const X = f(M);
    !b.value && !h.value && X && (X.selected || p()), X && ke(A) && b.value && $o(
      X,
      h.value,
      w,
      p,
      y,
      !1,
      ge
    );
    const we = re(v);
    if (T = we, z = xh(l.value, d.value, we, f, M), z.length) {
      const [Se, D] = co({
        id: M,
        dragItems: z,
        findNode: f
      });
      _({ event: v.sourceEvent, node: Se, nodes: D });
    }
  }, Y = (v, ge) => {
    var X;
    r.value === 0 && q(v, ge), T = re(v), P = ((X = t.value) == null ? void 0 : X.getBoundingClientRect()) || null, g = kt(v.sourceEvent, P);
  }, fe = (v, ge) => {
    const X = re(v);
    if (!oe && F && s.value && (oe = !0, Q()), !F) {
      const we = X.xSnapped - (T.x ?? 0), Se = X.ySnapped - (T.y ?? 0);
      Math.sqrt(we * we + Se * Se) > r.value && q(v, ge);
    }
    (T.x !== X.xSnapped || T.y !== X.ySnapped) && z.length && F && (H = v.sourceEvent, g = kt(v.sourceEvent, P), ye(X));
  }, ce = (v) => {
    if (F && (Z.value = !1, oe = !1, F = !1, cancelAnimationFrame(W), z.length)) {
      k(z, !1, !1);
      const [ge, X] = co({
        id: M,
        dragItems: z,
        findNode: f
      });
      G({ event: v.sourceEvent, node: ge, nodes: X });
    }
  };
  return ie([() => ke($), B], ([v, ge], X, we) => {
    if (ge) {
      const Se = Ze(ge);
      v || (N = ff().on("start", (D) => Y(D, ge)).on("drag", (D) => fe(D, ge)).on("end", (D) => ce(D)).filter((D) => {
        const O = D.target, L = ke(S);
        return !D.button && (!o.value || !Ta(O, `.${o.value}`, ge) && (!L || Ta(O, L, ge)));
      }), Se.call(N)), we(() => {
        Se.on(".drag", null), N && (N.on("start", null), N.on("drag", null), N.on("end", null));
      });
    }
  }), Z;
}
const xa = Symbol("vueFlow"), Gl = Symbol("nodeId"), Zl = Symbol("nodeRef"), rh = Symbol("edgeId"), sh = Symbol("edgeRef"), Wn = Symbol("slots");
function uh() {
  return {
    doubleClick: le(),
    click: le(),
    mouseEnter: le(),
    mouseMove: le(),
    mouseLeave: le(),
    contextMenu: le(),
    updateStart: le(),
    update: le(),
    updateEnd: le()
  };
}
function dh(e, t) {
  const n = uh();
  return n.doubleClick.on((a) => {
    var o, l;
    t.edgeDoubleClick(a), (l = (o = e.events) == null ? void 0 : o.doubleClick) == null || l.call(o, a);
  }), n.click.on((a) => {
    var o, l;
    t.edgeClick(a), (l = (o = e.events) == null ? void 0 : o.click) == null || l.call(o, a);
  }), n.mouseEnter.on((a) => {
    var o, l;
    t.edgeMouseEnter(a), (l = (o = e.events) == null ? void 0 : o.mouseEnter) == null || l.call(o, a);
  }), n.mouseMove.on((a) => {
    var o, l;
    t.edgeMouseMove(a), (l = (o = e.events) == null ? void 0 : o.mouseMove) == null || l.call(o, a);
  }), n.mouseLeave.on((a) => {
    var o, l;
    t.edgeMouseLeave(a), (l = (o = e.events) == null ? void 0 : o.mouseLeave) == null || l.call(o, a);
  }), n.contextMenu.on((a) => {
    var o, l;
    t.edgeContextMenu(a), (l = (o = e.events) == null ? void 0 : o.contextMenu) == null || l.call(o, a);
  }), n.updateStart.on((a) => {
    var o, l;
    t.edgeUpdateStart(a), (l = (o = e.events) == null ? void 0 : o.updateStart) == null || l.call(o, a);
  }), n.update.on((a) => {
    var o, l;
    t.edgeUpdate(a), (l = (o = e.events) == null ? void 0 : o.update) == null || l.call(o, a);
  }), n.updateEnd.on((a) => {
    var o, l;
    t.edgeUpdateEnd(a), (l = (o = e.events) == null ? void 0 : o.updateEnd) == null || l.call(o, a);
  }), Object.entries(n).reduce(
    (a, [o, l]) => (a.emit[o] = l.trigger, a.on[o] = l.on, a),
    { emit: {}, on: {} }
  );
}
function ch() {
  const { viewport: e, snapGrid: t, snapToGrid: n } = $e();
  return ({ sourceEvent: a }) => {
    const o = a.touches ? a.touches[0].clientX : a.clientX, l = a.touches ? a.touches[0].clientY : a.clientY, i = {
      x: (o - e.value.x) / e.value.zoom,
      y: (l - e.value.y) / e.value.zoom
    };
    return {
      xSnapped: n.value ? t.value[0] * Math.round(i.x / t.value[0]) : i.x,
      ySnapped: n.value ? t.value[1] * Math.round(i.y / t.value[1]) : i.y,
      ...i
    };
  };
}
function hn() {
  return !0;
}
function Yl({
  handleId: e,
  nodeId: t,
  type: n,
  isValidConnection: a,
  edgeUpdaterType: o,
  onEdgeUpdate: l,
  onEdgeUpdateEnd: i
}) {
  const {
    vueFlowRef: r,
    connectionMode: u,
    connectionRadius: s,
    connectOnClick: d,
    connectionClickStartHandle: c,
    nodesConnectable: f,
    autoPanOnConnect: h,
    findNode: y,
    panBy: b,
    startConnection: p,
    updateConnection: w,
    endConnection: k,
    emits: E,
    viewport: _,
    edges: C,
    nodes: G,
    isValidConnection: B
  } = $e();
  let $ = null, M = !1, A = null;
  function S(z) {
    var N;
    const P = ke(n) === "target", T = qo(z), g = Ma(z.target);
    if (T && z.button === 0 || !T) {
      let H = function(D) {
        ge = kt(D, ce);
        const { handle: O, validHandleResult: L } = Ah(
          D,
          g,
          So(ge, _.value, !1, [1, 1]),
          s.value,
          we,
          (J) => za(
            D,
            J,
            u.value,
            ke(t),
            ke(e),
            P ? "target" : "source",
            oe,
            g,
            C.value,
            G.value,
            y
          )
        );
        if (re = O, X || (Se(), X = !0), $ = L.connection, M = L.isValid, A = L.handleDomNode, w(
          re && M ? Jl(
            {
              x: re.x,
              y: re.y
            },
            _.value
          ) : ge,
          L.endHandle,
          Th(!!re, M)
        ), !re && !M && !A)
          return fo(v);
        $ && $.source !== $.target && A && (fo(v), v = A, A.classList.add("connecting", "vue-flow__handle-connecting"), A.classList.toggle("valid", M), A.classList.toggle("vue-flow__handle-valid", M));
      }, F = function(D) {
        (re || A) && $ && M && (l ? l(D, $) : E.connect($)), E.connectEnd(D), o && (i == null || i(D)), fo(v), cancelAnimationFrame(ye), k(D), X = !1, M = !1, $ = null, A = null, g.removeEventListener("mousemove", H), g.removeEventListener("mouseup", F), g.removeEventListener("touchmove", H), g.removeEventListener("touchend", F);
      };
      const W = y(ke(t));
      let oe = ke(a) || B.value || hn;
      !oe && W && (oe = (P ? W.isValidSourcePos : W.isValidTargetPos) || hn);
      let re, ye = 0;
      const { x: Q, y: q } = kt(z), Y = g == null ? void 0 : g.elementFromPoint(Q, q), fe = Jo(ke(o), Y), ce = (N = r.value) == null ? void 0 : N.getBoundingClientRect();
      if (!ce || !fe)
        return;
      let v, ge = kt(z, ce), X = !1;
      const we = Fh({
        nodes: G.value,
        nodeId: ke(t),
        handleId: ke(e),
        handleType: fe
      }), Se = () => {
        if (!h.value)
          return;
        const [D, O] = oi(ge, ce);
        b({ x: D, y: O }), ye = requestAnimationFrame(Se);
      };
      p(
        {
          nodeId: ke(t),
          handleId: ke(e),
          type: fe
        },
        {
          x: Q - ce.left,
          y: q - ce.top
        },
        z
      ), E.connectStart({ event: z, nodeId: ke(t), handleId: ke(e), handleType: fe }), g.addEventListener("mousemove", H), g.addEventListener("mouseup", F), g.addEventListener("touchmove", H), g.addEventListener("touchend", F);
    }
  }
  function Z(z) {
    if (!d.value)
      return;
    const N = ke(n) === "target";
    if (!c.value)
      E.clickConnectStart({ event: z, nodeId: ke(t), handleId: ke(e) }), p({ nodeId: ke(t), type: ke(n), handleId: ke(e) }, void 0, z, !0);
    else {
      let P = ke(a) || B.value || hn;
      const T = y(ke(t));
      if (!P && T && (P = (N ? T.isValidSourcePos : T.isValidTargetPos) || hn), T && (typeof T.connectable > "u" ? f.value : T.connectable) === !1)
        return;
      const g = Ma(z.target), { connection: H, isValid: F } = za(
        z,
        {
          nodeId: ke(t),
          id: ke(e),
          type: ke(n)
        },
        u.value,
        c.value.nodeId,
        c.value.handleId || null,
        c.value.type,
        P,
        g,
        C.value,
        G.value,
        y
      ), W = H.source === H.target;
      F && !W && E.connect(H), E.clickConnectEnd(z), k(z, !0);
    }
  }
  return {
    handlePointerDown: S,
    handleClick: Z
  };
}
function fh() {
  return Qe(Gl, "");
}
function ph(e) {
  const t = fh() ?? "", n = Qe(Zl, ae(null)), { findNode: a, edges: o, emits: l } = $e(), i = a(t);
  return i || l.error(new Oe(Ae.NODE_NOT_FOUND, t)), {
    id: t,
    nodeEl: n,
    node: i,
    parentNode: me(() => a(i.parentNode)),
    connectedEdges: me(() => Wo([i], o.value))
  };
}
function hh() {
  return {
    doubleClick: le(),
    click: le(),
    mouseEnter: le(),
    mouseMove: le(),
    mouseLeave: le(),
    contextMenu: le(),
    dragStart: le(),
    drag: le(),
    dragStop: le()
  };
}
function mh(e, t) {
  const n = hh();
  return n.doubleClick.on((a) => {
    var o, l;
    t.nodeDoubleClick(a), (l = (o = e.events) == null ? void 0 : o.doubleClick) == null || l.call(o, a);
  }), n.click.on((a) => {
    var o, l;
    t.nodeClick(a), (l = (o = e.events) == null ? void 0 : o.click) == null || l.call(o, a);
  }), n.mouseEnter.on((a) => {
    var o, l;
    t.nodeMouseEnter(a), (l = (o = e.events) == null ? void 0 : o.mouseEnter) == null || l.call(o, a);
  }), n.mouseMove.on((a) => {
    var o, l;
    t.nodeMouseMove(a), (l = (o = e.events) == null ? void 0 : o.mouseMove) == null || l.call(o, a);
  }), n.mouseLeave.on((a) => {
    var o, l;
    t.nodeMouseLeave(a), (l = (o = e.events) == null ? void 0 : o.mouseLeave) == null || l.call(o, a);
  }), n.contextMenu.on((a) => {
    var o, l;
    t.nodeContextMenu(a), (l = (o = e.events) == null ? void 0 : o.contextMenu) == null || l.call(o, a);
  }), n.dragStart.on((a) => {
    var o, l;
    t.nodeDragStart(a), (l = (o = e.events) == null ? void 0 : o.dragStart) == null || l.call(o, a);
  }), n.drag.on((a) => {
    var o, l;
    t.nodeDrag(a), (l = (o = e.events) == null ? void 0 : o.drag) == null || l.call(o, a);
  }), n.dragStop.on((a) => {
    var o, l;
    t.nodeDragStop(a), (l = (o = e.events) == null ? void 0 : o.dragStop) == null || l.call(o, a);
  }), Object.entries(n).reduce(
    (a, [o, l]) => (a.emit[o] = l.trigger, a.on[o] = l.on, a),
    { emit: {}, on: {} }
  );
}
function Kl() {
  const { getSelectedNodes: e, nodeExtent: t, updateNodePositions: n, findNode: a, snapGrid: o, snapToGrid: l, nodesDraggable: i, emits: r } = $e();
  return (u, s = !1) => {
    const d = l.value ? o.value[0] : 5, c = l.value ? o.value[1] : 5, f = s ? 4 : 1, h = u.x * d * f, y = u.y * c * f, b = e.value.filter((p) => p.draggable || i && typeof p.draggable > "u").map((p) => {
      const w = { x: p.computedPosition.x + h, y: p.computedPosition.y + y }, { computedPosition: k } = jo(
        p,
        w,
        r.error,
        t.value,
        p.parentNode ? a(p.parentNode) : void 0
      );
      return {
        id: p.id,
        position: k,
        from: p.position,
        distance: { x: u.x, y: u.y },
        dimensions: p.dimensions
      };
    });
    n(b, !0, !1);
  };
}
const io = 0.1;
function dt() {
  return qn("Viewport not initialized yet."), Promise.resolve(!1);
}
const gh = {
  zoomIn: dt,
  zoomOut: dt,
  zoomTo: dt,
  fitView: dt,
  setCenter: dt,
  fitBounds: dt,
  project: (e) => e,
  screenToFlowCoordinate: (e) => e,
  flowToScreenCoordinate: (e) => e,
  setViewport: dt,
  setTransform: dt,
  getViewport: () => ({ x: 0, y: 0, zoom: 1 }),
  getTransform: () => ({ x: 0, y: 0, zoom: 1 }),
  viewportInitialized: !1
};
function vh(e) {
  function t(a, o) {
    return new Promise((l) => {
      e.d3Selection && e.d3Zoom ? e.d3Zoom.scaleBy(
        ro(e.d3Selection, o, () => {
          l(!0);
        }),
        a
      ) : l(!1);
    });
  }
  function n(a, o, l, i) {
    return new Promise((r) => {
      const { x: u, y: s } = Xl({ x: -a, y: -o }, e.translateExtent), d = Ut.translate(-u, -s).scale(l);
      e.d3Selection && e.d3Zoom ? e.d3Zoom.transform(
        ro(e.d3Selection, i, () => {
          r(!0);
        }),
        d
      ) : r(!1);
    });
  }
  return me(() => e.d3Zoom && e.d3Selection && e.dimensions.width && e.dimensions.height ? {
    viewportInitialized: !0,
    // todo: allow passing scale as option
    zoomIn: (o) => t(1.2, o == null ? void 0 : o.duration),
    zoomOut: (o) => t(1 / 1.2, o == null ? void 0 : o.duration),
    zoomTo: (o, l) => new Promise((i) => {
      e.d3Selection && e.d3Zoom ? e.d3Zoom.scaleTo(
        ro(e.d3Selection, l == null ? void 0 : l.duration, () => {
          i(!0);
        }),
        o
      ) : i(!1);
    }),
    setViewport: (o, l) => n(o.x, o.y, o.zoom, l == null ? void 0 : l.duration),
    setTransform: (o, l) => n(o.x, o.y, o.zoom, l == null ? void 0 : l.duration),
    getViewport: () => ({
      x: e.viewport.x,
      y: e.viewport.y,
      zoom: e.viewport.zoom
    }),
    getTransform: () => ({
      x: e.viewport.x,
      y: e.viewport.y,
      zoom: e.viewport.zoom
    }),
    fitView: (o = {
      padding: io,
      includeHiddenNodes: !1,
      duration: 0
    }) => {
      const l = e.nodes.filter((d) => {
        var c;
        const f = d.dimensions.width && d.dimensions.height && ((o == null ? void 0 : o.includeHiddenNodes) || !d.hidden);
        return (c = o.nodes) != null && c.length ? f && o.nodes.includes(d.id) : f;
      });
      if (!l.length)
        return Promise.resolve(!1);
      const i = ei(l), { x: r, y: u, zoom: s } = Ba(
        i,
        e.dimensions.width,
        e.dimensions.height,
        o.minZoom ?? e.minZoom,
        o.maxZoom ?? e.maxZoom,
        o.padding ?? io,
        o.offset
      );
      return n(r, u, s, o == null ? void 0 : o.duration);
    },
    setCenter: (o, l, i) => {
      const r = typeof (i == null ? void 0 : i.zoom) < "u" ? i.zoom : e.maxZoom, u = e.dimensions.width / 2 - o * r, s = e.dimensions.height / 2 - l * r;
      return n(u, s, r, i == null ? void 0 : i.duration);
    },
    fitBounds: (o, l = { padding: io }) => {
      const { x: i, y: r, zoom: u } = Ba(
        o,
        e.dimensions.width,
        e.dimensions.height,
        e.minZoom,
        e.maxZoom,
        l.padding
      );
      return n(i, r, u, l == null ? void 0 : l.duration);
    },
    project: (o) => So(o, e.viewport, e.snapToGrid, e.snapGrid),
    screenToFlowCoordinate: (o) => {
      if (e.vueFlowRef) {
        const { x: l, y: i } = e.vueFlowRef.getBoundingClientRect(), r = {
          x: o.x - l,
          y: o.y - i
        };
        return So(r, e.viewport, e.snapToGrid, e.snapGrid);
      }
      return { x: 0, y: 0 };
    },
    flowToScreenCoordinate: (o) => {
      if (e.vueFlowRef) {
        const { x: l, y: i } = e.vueFlowRef.getBoundingClientRect(), r = {
          x: o.x + l,
          y: o.y + i
        };
        return Jl(r, e.viewport);
      }
      return { x: 0, y: 0 };
    }
  } : gh);
}
function ro(e, t = 0, n) {
  return e.transition().duration(t).on("end", n);
}
function yh(e, t, n) {
  const a = Qa(!0);
  return a.run(() => {
    const o = () => {
      a.run(() => {
        let b, p, w = !!(n.nodes.value.length || n.edges.value.length);
        b = At([e.modelValue, () => {
          var k, E;
          return (E = (k = e.modelValue) == null ? void 0 : k.value) == null ? void 0 : E.length;
        }], ([k]) => {
          k && Array.isArray(k) && (p == null || p.pause(), n.setElements(k), !p && !w && k.length ? w = !0 : p == null || p.resume());
        }), p = At(
          [n.nodes, n.edges, () => n.edges.value.length, () => n.nodes.value.length],
          ([k, E]) => {
            var _;
            (_ = e.modelValue) != null && _.value && Array.isArray(e.modelValue.value) && (b == null || b.pause(), e.modelValue.value = [...k, ...E], je(() => {
              b == null || b.resume();
            }));
          },
          { immediate: w }
        ), yn(() => {
          b == null || b.stop(), p == null || p.stop();
        });
      });
    }, l = () => {
      a.run(() => {
        let b, p, w = !!n.nodes.value.length;
        b = At([e.nodes, () => {
          var k, E;
          return (E = (k = e.nodes) == null ? void 0 : k.value) == null ? void 0 : E.length;
        }], ([k]) => {
          k && Array.isArray(k) && (p == null || p.pause(), n.setNodes(k), !p && !w && k.length ? w = !0 : p == null || p.resume());
        }), p = At(
          [n.nodes, () => n.nodes.value.length],
          ([k]) => {
            var E;
            (E = e.nodes) != null && E.value && Array.isArray(e.nodes.value) && (b == null || b.pause(), e.nodes.value = [...k], je(() => {
              b == null || b.resume();
            }));
          },
          { immediate: w }
        ), yn(() => {
          b == null || b.stop(), p == null || p.stop();
        });
      });
    }, i = () => {
      a.run(() => {
        let b, p, w = !!n.edges.value.length;
        b = At([e.edges, () => {
          var k, E;
          return (E = (k = e.edges) == null ? void 0 : k.value) == null ? void 0 : E.length;
        }], ([k]) => {
          k && Array.isArray(k) && (p == null || p.pause(), n.setEdges(k), !p && !w && k.length ? w = !0 : p == null || p.resume());
        }), p = At(
          [n.edges, () => n.edges.value.length],
          ([k]) => {
            var E;
            (E = e.edges) != null && E.value && Array.isArray(e.edges.value) && (b == null || b.pause(), e.edges.value = [...k], je(() => {
              b == null || b.resume();
            }));
          },
          { immediate: w }
        ), yn(() => {
          b == null || b.stop(), p == null || p.stop();
        });
      });
    }, r = () => {
      a.run(() => {
        ie(
          () => t.maxZoom,
          () => {
            t.maxZoom && Me(t.maxZoom) && n.setMaxZoom(t.maxZoom);
          },
          {
            immediate: !0
          }
        );
      });
    }, u = () => {
      a.run(() => {
        ie(
          () => t.minZoom,
          () => {
            t.minZoom && Me(t.minZoom) && n.setMinZoom(t.minZoom);
          },
          { immediate: !0 }
        );
      });
    }, s = () => {
      a.run(() => {
        ie(
          () => t.translateExtent,
          () => {
            t.translateExtent && Me(t.translateExtent) && n.setTranslateExtent(t.translateExtent);
          },
          {
            immediate: !0
          }
        );
      });
    }, d = () => {
      a.run(() => {
        ie(
          () => t.nodeExtent,
          () => {
            t.nodeExtent && Me(t.nodeExtent) && n.setNodeExtent(t.nodeExtent);
          },
          {
            immediate: !0
          }
        );
      });
    }, c = () => {
      a.run(() => {
        ie(
          () => t.applyDefault,
          () => {
            Me(t.applyDefault) && (n.applyDefault.value = t.applyDefault);
          },
          {
            immediate: !0
          }
        );
      });
    }, f = () => {
      a.run(() => {
        const b = async (p) => {
          let w = p;
          typeof t.autoConnect == "function" && (w = await t.autoConnect(p)), w !== !1 && n.addEdges([w]);
        };
        ie(
          () => t.autoConnect,
          () => {
            Me(t.autoConnect) && (n.autoConnect.value = t.autoConnect);
          },
          { immediate: !0 }
        ), ie(
          n.autoConnect,
          (p, w, k) => {
            p ? n.onConnect(b) : n.hooks.value.connect.off(b), k(() => {
              n.hooks.value.connect.off(b);
            });
          },
          { immediate: !0 }
        );
      });
    }, h = () => {
      const b = [
        "id",
        "modelValue",
        "translateExtent",
        "nodeExtent",
        "edges",
        "nodes",
        "maxZoom",
        "minZoom",
        "applyDefault",
        "autoConnect"
      ];
      for (const p of Object.keys(t)) {
        const w = p;
        if (!b.includes(w)) {
          const k = Le(() => t[w]), E = n[w];
          Hn(E) && a.run(() => {
            ie(
              k,
              (_) => {
                Me(_) && (E.value = _);
              },
              { immediate: !0, flush: "pre" }
            );
          });
        }
      }
    };
    (() => {
      o(), l(), i(), u(), r(), s(), d(), c(), f(), h();
    })();
  }), () => a.stop();
}
function $a(e) {
  return {
    ...e.computedPosition || { x: 0, y: 0 },
    width: e.dimensions.width || 0,
    height: e.dimensions.height || 0
  };
}
function No(e, t) {
  const n = Math.max(0, Math.min(e.x + e.width, t.x + t.width) - Math.max(e.x, t.x)), a = Math.max(0, Math.min(e.y + e.height, t.y + t.height) - Math.max(e.y, t.y));
  return Math.ceil(n * a);
}
function jn(e) {
  return {
    width: e.offsetWidth,
    height: e.offsetHeight
  };
}
function Mt(e, t = 0, n = 1) {
  return Math.min(Math.max(e, t), n);
}
function Xl(e, t) {
  return {
    x: Mt(e.x, t[0][0], t[1][0]),
    y: Mt(e.y, t[0][1], t[1][1])
  };
}
function Ma(e) {
  const t = e.getRootNode();
  return "elementFromPoint" in t ? t : Xo().document;
}
function Bt(e) {
  return e && typeof e == "object" && "id" in e && "source" in e && "target" in e;
}
function Et(e) {
  return e && typeof e == "object" && "id" in e && "position" in e && !Bt(e);
}
function Ft(e) {
  return Et(e) && "computedPosition" in e;
}
function mn(e) {
  return !Number.isNaN(e) && Number.isFinite(e);
}
function bh(e) {
  return mn(e.width) && mn(e.height) && mn(e.x) && mn(e.y);
}
function _h(e, t, n) {
  const a = {
    id: e.id.toString(),
    type: e.type ?? "default",
    dimensions: Tt({
      width: 0,
      height: 0
    }),
    // todo: shouldn't be defined initially, as we want to use handleBounds to check if a node was actually initialized or not
    handleBounds: {
      source: [],
      target: []
    },
    computedPosition: Tt({
      z: 0,
      ...e.position
    }),
    draggable: void 0,
    selectable: void 0,
    connectable: void 0,
    focusable: void 0,
    selected: !1,
    dragging: !1,
    resizing: !1,
    initialized: !1,
    isParent: !1,
    position: {
      x: 0,
      y: 0
    },
    data: Me(e.data) ? e.data : {},
    events: Tt(Me(e.events) ? e.events : {})
  };
  return Object.assign(t ?? a, e, { id: e.id.toString(), parentNode: n });
}
function Wl(e, t, n) {
  var a, o;
  const l = {
    id: e.id.toString(),
    type: e.type ?? (t == null ? void 0 : t.type) ?? "default",
    source: e.source.toString(),
    target: e.target.toString(),
    sourceHandle: (a = e.sourceHandle) == null ? void 0 : a.toString(),
    targetHandle: (o = e.targetHandle) == null ? void 0 : o.toString(),
    updatable: e.updatable ?? (n == null ? void 0 : n.updatable),
    selectable: e.selectable ?? (n == null ? void 0 : n.selectable),
    focusable: e.focusable ?? (n == null ? void 0 : n.focusable),
    data: Me(e.data) ? e.data : {},
    events: Tt(Me(e.events) ? e.events : {}),
    label: e.label ?? "",
    interactionWidth: e.interactionWidth ?? (n == null ? void 0 : n.interactionWidth),
    ...n ?? {}
  };
  return Object.assign(t ?? l, e, { id: e.id.toString() });
}
function jl(e, t, n, a) {
  const o = typeof e == "string" ? e : e.id, l = /* @__PURE__ */ new Set(), i = a === "source" ? "target" : "source";
  for (const r of n)
    r[i] === o && l.add(r[a]);
  return t.filter((r) => l.has(r.id));
}
function wh(...e) {
  if (e.length === 3) {
    const [l, i, r] = e;
    return jl(l, i, r, "target");
  }
  const [t, n] = e, a = typeof t == "string" ? t : t.id;
  return n.filter((l) => Bt(l) && l.source === a).map((l) => n.find((i) => Et(i) && i.id === l.target));
}
function Ch(...e) {
  if (e.length === 3) {
    const [l, i, r] = e;
    return jl(l, i, r, "source");
  }
  const [t, n] = e, a = typeof t == "string" ? t : t.id;
  return n.filter((l) => Bt(l) && l.target === a).map((l) => n.find((i) => Et(i) && i.id === l.source));
}
function ql({ source: e, sourceHandle: t, target: n, targetHandle: a }) {
  return `vueflow__edge-${e}${t ?? ""}-${n}${a ?? ""}`;
}
function Eh(e, t) {
  return t.some(
    (n) => Bt(n) && n.source === e.source && n.target === e.target && (n.sourceHandle === e.sourceHandle || !n.sourceHandle && !e.sourceHandle) && (n.targetHandle === e.targetHandle || !n.targetHandle && !e.targetHandle)
  );
}
function Jl({ x: e, y: t }, { x: n, y: a, zoom: o }) {
  return {
    x: e * o + n,
    y: t * o + a
  };
}
function So({ x: e, y: t }, { x: n, y: a, zoom: o }, l, [i, r]) {
  const u = {
    x: (e - n) / o,
    y: (t - a) / o
  };
  return l ? {
    x: i * Math.round(u.x / i),
    y: r * Math.round(u.y / r)
  } : u;
}
function kh(e, t) {
  return {
    x: Math.min(e.x, t.x),
    y: Math.min(e.y, t.y),
    x2: Math.max(e.x2, t.x2),
    y2: Math.max(e.y2, t.y2)
  };
}
function Ql({ x: e, y: t, width: n, height: a }) {
  return {
    x: e,
    y: t,
    x2: e + n,
    y2: t + a
  };
}
function Nh({ x: e, y: t, x2: n, y2: a }) {
  return {
    x: e,
    y: t,
    width: n - e,
    height: a - t
  };
}
function ei(e) {
  const t = e.reduce(
    (n, { computedPosition: a = { x: 0, y: 0 }, dimensions: o = { width: 0, height: 0 } } = {}) => kh(
      n,
      Ql({
        ...a,
        ...o
      })
    ),
    { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY, x2: Number.NEGATIVE_INFINITY, y2: Number.NEGATIVE_INFINITY }
  );
  return Nh(t);
}
function ti(e, t, { x: n, y: a, zoom: o } = { x: 0, y: 0, zoom: 1 }, l = !1, i = !1) {
  const r = {
    x: (t.x - n) / o,
    y: (t.y - a) / o,
    width: t.width / o,
    height: t.height / o
  };
  return e.filter((u) => {
    const { computedPosition: s = { x: 0, y: 0 }, dimensions: d = { width: 0, height: 0 }, selectable: c } = u;
    if (i && !c)
      return !1;
    const f = { ...s, width: d.width || 0, height: d.height || 0 }, h = No(r, f), y = typeof d.width > "u" || typeof d.height > "u" || d.width === 0 || d.height === 0, b = l && h > 0, p = d.width * d.height;
    return y || b || h >= p;
  });
}
function Wo(e, t) {
  const n = /* @__PURE__ */ new Set();
  if (typeof e == "string")
    n.add(e);
  else if (e.length >= 1)
    for (const a of e)
      n.add(a.id);
  return t.filter((a) => n.has(a.source) || n.has(a.target));
}
function Ba(e, t, n, a, o, l = 0.1, i = { x: 0, y: 0 }) {
  const r = t / (e.width * (1 + l)), u = n / (e.height * (1 + l)), s = Math.min(r, u), d = Mt(s, a, o), c = e.x + e.width / 2, f = e.y + e.height / 2, h = t / 2 - c * d + (i.x ?? 0), y = n / 2 - f * d + (i.y ?? 0);
  return { x: h, y, zoom: d };
}
function Sh(e, t) {
  return {
    x: t.x + e.x,
    y: t.y + e.y,
    z: (e.z > t.z ? e.z : t.z) + 1
  };
}
function ni(e, t) {
  if (!e.parentNode)
    return !1;
  const n = t(e.parentNode);
  return n ? n.selected ? !0 : ni(n, t) : !1;
}
function bt(e, t) {
  return typeof e > "u" ? "" : typeof e == "string" ? e : `${t ? `${t}__` : ""}${Object.keys(e).sort().map((a) => `${a}=${e[a]}`).join("&")}`;
}
function Ia(e, t, n) {
  return e < t ? Mt(Math.abs(e - t), 1, 50) / 50 : e > n ? -Mt(Math.abs(e - n), 1, 50) / 50 : 0;
}
function oi(e, t) {
  const n = Ia(e.x, 35, t.width - 35) * 20, a = Ia(e.y, 35, t.height - 35) * 20;
  return [n, a];
}
function so(e, t) {
  if (t) {
    const n = e.position.x + e.dimensions.width - t.dimensions.width, a = e.position.y + e.dimensions.height - t.dimensions.height;
    if (n > 0 || a > 0 || e.position.x < 0 || e.position.y < 0) {
      let o = {};
      if (typeof t.style == "function" ? o = { ...t.style(t) } : t.style && (o = { ...t.style }), o.width = o.width ?? `${t.dimensions.width}px`, o.height = o.height ?? `${t.dimensions.height}px`, n > 0)
        if (typeof o.width == "string") {
          const l = Number(o.width.replace("px", ""));
          o.width = `${l + n}px`;
        } else
          o.width += n;
      if (a > 0)
        if (typeof o.height == "string") {
          const l = Number(o.height.replace("px", ""));
          o.height = `${l + a}px`;
        } else
          o.height += a;
      if (e.position.x < 0) {
        const l = Math.abs(e.position.x);
        if (t.position.x = t.position.x - l, typeof o.width == "string") {
          const i = Number(o.width.replace("px", ""));
          o.width = `${i + l}px`;
        } else
          o.width += l;
        e.position.x = 0;
      }
      if (e.position.y < 0) {
        const l = Math.abs(e.position.y);
        if (t.position.y = t.position.y - l, typeof o.height == "string") {
          const i = Number(o.height.replace("px", ""));
          o.height = `${i + l}px`;
        } else
          o.height += l;
        e.position.y = 0;
      }
      t.dimensions.width = Number(o.width.toString().replace("px", "")), t.dimensions.height = Number(o.height.toString().replace("px", "")), typeof t.style == "function" ? t.style = (l) => {
        const i = t.style;
        return {
          ...i(l),
          ...o
        };
      } : t.style = {
        ...t.style,
        ...o
      };
    }
  }
}
function Da(e, t) {
  var n, a;
  const o = e.filter((i) => i.type === "add" || i.type === "remove");
  for (const i of o)
    if (i.type === "add")
      t.findIndex((u) => u.id === i.item.id) === -1 && t.push(i.item);
    else if (i.type === "remove") {
      const r = t.findIndex((u) => u.id === i.id);
      r !== -1 && t.splice(r, 1);
    }
  const l = t.map((i) => i.id);
  for (const i of t) {
    const r = e.filter((u) => u.id === i.id);
    for (const u of r)
      switch (u.type) {
        case "select":
          i.selected = u.selected;
          break;
        case "position":
          if (Ft(i) && (typeof u.position < "u" && (i.position = u.position), typeof u.dragging < "u" && (i.dragging = u.dragging), i.expandParent && i.parentNode)) {
            const s = t[l.indexOf(i.parentNode)];
            s && Ft(s) && so(i, s);
          }
          break;
        case "dimensions":
          if (Ft(i)) {
            if (typeof u.dimensions < "u" && (i.dimensions = u.dimensions), typeof u.updateStyle < "u" && (i.style = {
              ...i.style || {},
              width: `${(n = u.dimensions) == null ? void 0 : n.width}px`,
              height: `${(a = u.dimensions) == null ? void 0 : a.height}px`
            }), typeof u.resizing < "u" && (i.resizing = u.resizing), i.expandParent && i.parentNode) {
              const s = t[l.indexOf(i.parentNode)];
              s && Ft(s) && (s.initialized ? so(i, s) : je(() => {
                so(i, s);
              }));
            }
            i.initialized || (i.initialized = !0);
          }
          break;
      }
  }
  return t;
}
function ot(e, t) {
  return {
    id: e,
    type: "select",
    selected: t
  };
}
function Va(e) {
  return {
    item: e,
    type: "add"
  };
}
function Aa(e) {
  return {
    id: e,
    type: "remove"
  };
}
function Fa(e, t, n, a, o) {
  return {
    id: e,
    source: t,
    target: n,
    sourceHandle: a || null,
    targetHandle: o || null,
    type: "remove"
  };
}
function uo(e, t) {
  return e.reduce(
    (n, a) => {
      let o = t.includes(a.id);
      Me(a.selectable) && !a.selectable && (o = !1);
      const l = Ft(a) ? "changedNodes" : "changedEdges";
      return !a.selected && o ? n[l].push(ot(a.id, !0)) : a.selected && !o && n[l].push(ot(a.id, !1)), n;
    },
    { changedNodes: [], changedEdges: [] }
  );
}
function le(e) {
  const t = /* @__PURE__ */ new Set();
  let n = !1;
  const a = () => t.size > 0;
  e && (n = !0, t.add(e));
  const o = (r) => {
    t.delete(r);
  };
  return {
    on: (r) => {
      e && n && t.delete(e), t.add(r);
      const u = () => {
        o(r), e && n && t.add(e);
      };
      return Gn(u), {
        off: u
      };
    },
    off: o,
    trigger: (r) => Promise.all(Array.from(t).map((u) => u(r))),
    hasListeners: a,
    fns: t
  };
}
function Ta(e, t, n) {
  let a = e;
  do {
    if (a && a.matches(t))
      return !0;
    if (a === n)
      return !1;
    a = a.parentElement;
  } while (a);
  return !1;
}
function xh(e, t, n, a, o) {
  return e.filter(
    (l) => (l.selected || l.id === o) && (!l.parentNode || !ni(l, a)) && (l.draggable || t && typeof l.draggable > "u")
  ).map(
    (l) => {
      var i, r;
      return Tt({
        id: l.id,
        position: l.position || { x: 0, y: 0 },
        distance: {
          x: n.x - ((i = l.computedPosition) == null ? void 0 : i.x) || 0,
          y: n.y - ((r = l.computedPosition) == null ? void 0 : r.y) || 0
        },
        from: l.computedPosition,
        extent: l.extent,
        parentNode: l.parentNode,
        dimensions: l.dimensions,
        expandParent: l.expandParent
      });
    }
  );
}
function co({
  id: e,
  dragItems: t,
  findNode: n
}) {
  const a = t.reduce((o, l) => {
    const i = n(l.id);
    return i && o.push(i), o;
  }, []);
  return [e ? a.find((o) => o.id === e) : a[0], a];
}
function ai(e) {
  if (Array.isArray(e))
    switch (e.length) {
      case 1:
        return [e[0], e[0], e[0], e[0]];
      case 2:
        return [e[0], e[1], e[0], e[1]];
      case 3:
        return [e[0], e[1], e[2], e[1]];
      case 4:
        return e;
      default:
        return [0, 0, 0, 0];
    }
  return [e, e, e, e];
}
function $h(e, t, n) {
  const [a, o, l, i] = typeof e != "string" ? ai(e.padding) : [0, 0, 0, 0];
  return n && typeof n.computedPosition.x < "u" && typeof n.computedPosition.y < "u" && typeof n.dimensions.width < "u" && typeof n.dimensions.height < "u" ? [
    [n.computedPosition.x + i, n.computedPosition.y + a],
    [
      n.computedPosition.x + n.dimensions.width - o,
      n.computedPosition.y + n.dimensions.height - l
    ]
  ] : !1;
}
function Mh(e, t, n, a) {
  let o = e.extent || n;
  if ((o === "parent" || !Array.isArray(o) && (o == null ? void 0 : o.range) === "parent") && !e.expandParent)
    if (e.parentNode && a && e.dimensions.width && e.dimensions.height) {
      const l = $h(o, e, a);
      l && (o = l);
    } else
      t(new Oe(Ae.NODE_EXTENT_INVALID, e.id)), o = n;
  else if (Array.isArray(o)) {
    const l = (a == null ? void 0 : a.computedPosition.x) || 0, i = (a == null ? void 0 : a.computedPosition.y) || 0;
    o = [
      [o[0][0] + l, o[0][1] + i],
      [o[1][0] + l, o[1][1] + i]
    ];
  } else if (o !== "parent" && (o != null && o.range) && Array.isArray(o.range)) {
    const [l, i, r, u] = ai(o.padding), s = (a == null ? void 0 : a.computedPosition.x) || 0, d = (a == null ? void 0 : a.computedPosition.y) || 0;
    o = [
      [o.range[0][0] + s + u, o.range[0][1] + d + l],
      [o.range[1][0] + s - i, o.range[1][1] + d - r]
    ];
  }
  return o === "parent" ? [
    [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
    [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]
  ] : o;
}
function Bh({ width: e, height: t }, n) {
  return [n[0], [n[1][0] - (e || 0), n[1][1] - (t || 0)]];
}
function jo(e, t, n, a, o) {
  const l = Bh(e.dimensions, Mh(e, n, a, o)), i = Xl(t, l);
  return {
    position: {
      x: i.x - ((o == null ? void 0 : o.computedPosition.x) || 0),
      y: i.y - ((o == null ? void 0 : o.computedPosition.y) || 0)
    },
    computedPosition: i
  };
}
function xo(e, t, n) {
  const a = ((n == null ? void 0 : n.x) ?? 0) + t.x, o = ((n == null ? void 0 : n.y) ?? 0) + t.y, l = (n == null ? void 0 : n.width) ?? t.width, i = (n == null ? void 0 : n.height) ?? t.height;
  switch (e) {
    case he.Top:
      return {
        x: a + l / 2,
        y: o
      };
    case he.Right:
      return {
        x: a + l,
        y: o + i / 2
      };
    case he.Bottom:
      return {
        x: a + l / 2,
        y: o + i
      };
    case he.Left:
      return {
        x: a,
        y: o + i / 2
      };
  }
}
function Oa(e = [], t) {
  return e.length && (t ? e.find((n) => n.id === t) : e[0]) || null;
}
function Ih(e, t, n, a, o, l) {
  const i = xo(
    n,
    {
      ...e.dimensions,
      ...e.computedPosition
    },
    t
  ), r = xo(
    l,
    {
      ...a.dimensions,
      ...a.computedPosition
    },
    o
  );
  return {
    sourceX: i.x,
    sourceY: i.y,
    targetX: r.x,
    targetY: r.y
  };
}
function Dh({
  sourcePos: e,
  targetPos: t,
  sourceWidth: n,
  sourceHeight: a,
  targetWidth: o,
  targetHeight: l,
  width: i,
  height: r,
  viewport: u
}) {
  const s = {
    x: Math.min(e.x, t.x),
    y: Math.min(e.y, t.y),
    x2: Math.max(e.x + n, t.x + o),
    y2: Math.max(e.y + a, t.y + l)
  };
  s.x === s.x2 && (s.x2 += 1), s.y === s.y2 && (s.y2 += 1);
  const d = Ql({
    x: (0 - u.x) / u.zoom,
    y: (0 - u.y) / u.zoom,
    width: i / u.zoom,
    height: r / u.zoom
  }), c = Math.max(0, Math.min(d.x2, s.x2) - Math.max(d.x, s.x)), f = Math.max(0, Math.min(d.y2, s.y2) - Math.max(d.y, s.y));
  return Math.ceil(c * f) > 0;
}
function Vh(e, t, n = !1) {
  const a = typeof e.zIndex == "number";
  let o = a ? e.zIndex : 0;
  const l = t(e.source), i = t(e.target);
  return !l || !i ? 0 : (n && (o = a ? e.zIndex : Math.max(l.computedPosition.z || 0, i.computedPosition.z || 0)), o);
}
var Ae = /* @__PURE__ */ ((e) => (e.MISSING_STYLES = "MISSING_STYLES", e.MISSING_VIEWPORT_DIMENSIONS = "MISSING_VIEWPORT_DIMENSIONS", e.NODE_INVALID = "NODE_INVALID", e.NODE_NOT_FOUND = "NODE_NOT_FOUND", e.NODE_MISSING_PARENT = "NODE_MISSING_PARENT", e.NODE_TYPE_MISSING = "NODE_TYPE_MISSING", e.NODE_EXTENT_INVALID = "NODE_EXTENT_INVALID", e.EDGE_INVALID = "EDGE_INVALID", e.EDGE_NOT_FOUND = "EDGE_NOT_FOUND", e.EDGE_SOURCE_MISSING = "EDGE_SOURCE_MISSING", e.EDGE_TARGET_MISSING = "EDGE_TARGET_MISSING", e.EDGE_TYPE_MISSING = "EDGE_TYPE_MISSING", e.EDGE_SOURCE_TARGET_SAME = "EDGE_SOURCE_TARGET_SAME", e.EDGE_SOURCE_TARGET_MISSING = "EDGE_SOURCE_TARGET_MISSING", e.EDGE_ORPHANED = "EDGE_ORPHANED", e))(Ae || {});
const Pa = {
  MISSING_STYLES: () => "It seems that you haven't loaded the necessary styles. Please import '@vue-flow/core/dist/style.css' to ensure that the graph is rendered correctly",
  MISSING_VIEWPORT_DIMENSIONS: () => "The Vue Flow parent container needs a width and a height to render the graph",
  NODE_INVALID: (e) => `Node is invalid
Node: ${e}`,
  NODE_NOT_FOUND: (e) => `Node not found
Node: ${e}`,
  NODE_MISSING_PARENT: (e, t) => `Node is missing a parent
Node: ${e}
Parent: ${t}`,
  NODE_TYPE_MISSING: (e) => `Node type is missing
Type: ${e}`,
  NODE_EXTENT_INVALID: (e) => `Only child nodes can use a parent extent
Node: ${e}`,
  EDGE_INVALID: (e) => `An edge needs a source and a target
Edge: ${e}`,
  EDGE_SOURCE_MISSING: (e, t) => `Edge source is missing
Edge: ${e} 
Source: ${t}`,
  EDGE_TARGET_MISSING: (e, t) => `Edge target is missing
Edge: ${e} 
Target: ${t}`,
  EDGE_TYPE_MISSING: (e) => `Edge type is missing
Type: ${e}`,
  EDGE_SOURCE_TARGET_SAME: (e, t, n) => `Edge source and target are the same
Edge: ${e} 
Source: ${t} 
Target: ${n}`,
  EDGE_SOURCE_TARGET_MISSING: (e, t, n) => `Edge source or target is missing
Edge: ${e} 
Source: ${t} 
Target: ${n}`,
  EDGE_ORPHANED: (e) => `Edge was orphaned (suddenly missing source or target) and has been removed
Edge: ${e}`,
  EDGE_NOT_FOUND: (e) => `Edge not found
Edge: ${e}`
};
class Oe extends Error {
  constructor(t, ...n) {
    var a;
    super((a = Pa[t]) == null ? void 0 : a.call(Pa, ...n)), this.name = "VueFlowError", this.code = t, this.args = n;
  }
}
function qo(e) {
  return "clientX" in e;
}
function kt(e, t) {
  var n, a;
  const o = qo(e), l = o ? e.clientX : (n = e.touches) == null ? void 0 : n[0].clientX, i = o ? e.clientY : (a = e.touches) == null ? void 0 : a[0].clientY;
  return {
    x: l - ((t == null ? void 0 : t.left) ?? 0),
    y: i - ((t == null ? void 0 : t.top) ?? 0)
  };
}
const In = () => {
  var e;
  return typeof navigator < "u" && ((e = navigator == null ? void 0 : navigator.userAgent) == null ? void 0 : e.indexOf("Mac")) >= 0;
};
function li() {
  return {
    handleDomNode: null,
    isValid: !1,
    connection: { source: "", target: "", sourceHandle: null, targetHandle: null },
    endHandle: null
  };
}
function fo(e) {
  e == null || e.classList.remove("valid", "connecting", "vue-flow__handle-valid", "vue-flow__handle-connecting");
}
function La(e, t, n, a) {
  return (t[n] || []).reduce((o, l) => {
    var i, r;
    return `${e.id}-${l.id}-${n}` !== a && o.push({
      id: l.id || null,
      type: n,
      nodeId: e.id,
      x: (((i = e.computedPosition) == null ? void 0 : i.x) ?? 0) + l.x + l.width / 2,
      y: (((r = e.computedPosition) == null ? void 0 : r.y) ?? 0) + l.y + l.height / 2,
      width: l.width,
      height: l.height
    }), o;
  }, []);
}
function Ah(e, t, n, a, o, l) {
  const { x: i, y: r } = kt(e), s = t.elementsFromPoint(i, r).find((y) => y.classList.contains("vue-flow__handle"));
  if (s) {
    const y = s.getAttribute("data-nodeid");
    if (y) {
      const b = Jo(void 0, s), p = s.getAttribute("data-handleid"), w = l({ nodeId: y, id: p, type: b });
      if (w) {
        const k = o.find((E) => E.nodeId === y && E.type === b && E.id === p);
        return {
          handle: {
            id: p,
            type: b,
            nodeId: y,
            x: (k == null ? void 0 : k.x) || n.x,
            y: (k == null ? void 0 : k.y) || n.y
          },
          validHandleResult: w
        };
      }
    }
  }
  let d = [], c = Number.POSITIVE_INFINITY;
  for (const y of o) {
    const b = Math.sqrt((y.x - n.x) ** 2 + (y.y - n.y) ** 2);
    if (b <= a) {
      const p = l(y);
      b <= c && (b < c ? d = [{ handle: y, validHandleResult: p }] : b === c && d.push({
        handle: y,
        validHandleResult: p
      }), c = b);
    }
  }
  if (!d.length)
    return { handle: null, validHandleResult: li() };
  if (d.length === 1)
    return d[0];
  const f = d.some(({ validHandleResult: y }) => y.isValid), h = d.some(({ handle: y }) => y.type === "target");
  return d.find(
    ({ handle: y, validHandleResult: b }) => h ? y.type === "target" : f ? b.isValid : !0
  ) || d[0];
}
function za(e, t, n, a, o, l, i, r, u, s, d) {
  const c = l === "target", f = r.querySelector(`.vue-flow__handle[data-id="${t == null ? void 0 : t.nodeId}-${t == null ? void 0 : t.id}-${t == null ? void 0 : t.type}"]`), { x: h, y } = kt(e), b = r.elementFromPoint(h, y), p = b != null && b.classList.contains("vue-flow__handle") ? b : f, w = li();
  if (p) {
    w.handleDomNode = p;
    const k = Jo(void 0, p), E = p.getAttribute("data-nodeid"), _ = p.getAttribute("data-handleid"), C = p.classList.contains("connectable"), G = p.classList.contains("connectableend"), B = {
      source: c ? E : a,
      sourceHandle: c ? _ : o,
      target: c ? a : E,
      targetHandle: c ? o : _
    };
    w.connection = B;
    const M = C && G && (n === $t.Strict ? c && k === "source" || !c && k === "target" : E !== a || _ !== o);
    w.endHandle = {
      nodeId: E,
      handleId: _,
      type: k
    }, M && (w.isValid = i(B, {
      edges: u,
      nodes: s,
      sourceNode: d(B.source),
      targetNode: d(B.target)
    }));
  }
  return w;
}
function Fh({ nodes: e, nodeId: t, handleId: n, handleType: a }) {
  return e.reduce((o, l) => {
    const { handleBounds: i } = l;
    let r = [], u = [];
    return i && (r = La(l, i, "source", `${t}-${n}-${a}`), u = La(l, i, "target", `${t}-${n}-${a}`)), o.push(...r, ...u), o;
  }, []);
}
function Jo(e, t) {
  return e || (t != null && t.classList.contains("target") ? "target" : t != null && t.classList.contains("source") ? "source" : null);
}
function Th(e, t) {
  let n = null;
  return t ? n = "valid" : e && !t && (n = "invalid"), n;
}
const Oh = ["production", "prod"];
function qn(e, ...t) {
  ii() && console.warn(`[Vue Flow]: ${e}`, ...t);
}
function ii() {
  return !Oh.includes(process.env.NODE_ENV || "");
}
function Ra(e, t, n) {
  const a = t.querySelectorAll(`.vue-flow__handle${e}`);
  if (!a || !a.length)
    return;
  const o = Array.from(a), l = t.getBoundingClientRect();
  return o.map((i) => {
    const r = i.getBoundingClientRect();
    return {
      id: i.getAttribute("data-handleid"),
      position: i.getAttribute("data-handlepos"),
      x: (r.left - l.left) / n,
      y: (r.top - l.top) / n,
      ...jn(i)
    };
  });
}
function $o(e, t, n, a, o, l = !1, i) {
  o.value = !1, e.selected ? (l || e.selected && t) && (a([e]), je(() => {
    i.blur();
  })) : n([e]);
}
function Me(e) {
  return typeof x(e) < "u";
}
function Ph(e, t, n, a) {
  if (!e || !e.source || !e.target)
    return n(new Oe(Ae.EDGE_INVALID, (e == null ? void 0 : e.id) ?? "[ID UNKNOWN]")), !1;
  let o;
  return Bt(e) ? o = e : o = {
    ...e,
    id: ql(e)
  }, o = Wl(o, void 0, a), Eh(o, t) ? !1 : o;
}
function Lh(e, t, n, a, o, l) {
  if (!t.source || !t.target)
    return l(new Oe(Ae.EDGE_INVALID, e.id)), !1;
  const i = a(e.id);
  if (!i)
    return l(new Oe(Ae.EDGE_NOT_FOUND, e.id)), !1;
  const { id: r, ...u } = e, s = {
    ...u,
    id: o ? ql(t) : r,
    source: t.source,
    target: t.target,
    sourceHandle: t.sourceHandle,
    targetHandle: t.targetHandle
  };
  return n.splice(n.indexOf(i), 1, s), s;
}
function Ha(e, t, n, a) {
  const o = {}, l = e.reduce((r, u, s) => {
    if (!Et(u))
      return a(
        new Oe(Ae.NODE_INVALID, u == null ? void 0 : u.id) || `[ID UNKNOWN|INDEX ${s}]`
      ), r;
    const d = _h(u, n(u.id), u.parentNode);
    return u.parentNode && (o[u.parentNode] = !0), r.concat(d);
  }, []), i = [...l, ...t];
  for (const r of l) {
    const u = i.find((s) => s.id === r.parentNode);
    r.parentNode && !u && a(new Oe(Ae.NODE_MISSING_PARENT, r.id, r.parentNode)), (r.parentNode || o[r.id]) && (o[r.id] && (r.isParent = !0), u && (u.isParent = !0));
  }
  return l;
}
function Ua(e, t) {
  e.clear();
  for (const n of t) {
    const { id: a, source: o, target: l, sourceHandle: i = null, targetHandle: r = null } = n, u = `${o}-source-${i}`, s = `${l}-target-${r}`, d = e.get(u) || /* @__PURE__ */ new Map(), c = e.get(s) || /* @__PURE__ */ new Map(), f = Tt({ edgeId: a, source: o, target: l, sourceHandle: i, targetHandle: r });
    e.set(u, d.set(`${l}-${r}`, f)), e.set(s, c.set(`${o}-${i}`, f));
  }
}
function zh() {
  return {
    edgesChange: le(),
    nodesChange: le(),
    nodeDoubleClick: le(),
    nodeClick: le(),
    nodeMouseEnter: le(),
    nodeMouseMove: le(),
    nodeMouseLeave: le(),
    nodeContextMenu: le(),
    nodeDragStart: le(),
    nodeDrag: le(),
    nodeDragStop: le(),
    nodesInitialized: le(),
    miniMapNodeClick: le(),
    miniMapNodeDoubleClick: le(),
    miniMapNodeMouseEnter: le(),
    miniMapNodeMouseMove: le(),
    miniMapNodeMouseLeave: le(),
    connect: le(),
    connectStart: le(),
    connectEnd: le(),
    clickConnectStart: le(),
    clickConnectEnd: le(),
    paneReady: le(),
    init: le(),
    move: le(),
    moveStart: le(),
    moveEnd: le(),
    selectionDragStart: le(),
    selectionDrag: le(),
    selectionDragStop: le(),
    selectionContextMenu: le(),
    selectionStart: le(),
    selectionEnd: le(),
    viewportChangeStart: le(),
    viewportChange: le(),
    viewportChangeEnd: le(),
    paneScroll: le(),
    paneClick: le(),
    paneContextMenu: le(),
    paneMouseEnter: le(),
    paneMouseMove: le(),
    paneMouseLeave: le(),
    edgeContextMenu: le(),
    edgeMouseEnter: le(),
    edgeMouseMove: le(),
    edgeMouseLeave: le(),
    edgeDoubleClick: le(),
    edgeClick: le(),
    edgeUpdateStart: le(),
    edgeUpdate: le(),
    edgeUpdateEnd: le(),
    updateNodeInternals: le(),
    error: le((e) => qn(e.message))
  };
}
function Rh(e, t) {
  Ri(() => {
    for (const [n, a] of Object.entries(t.value)) {
      const o = (l) => {
        e(n, l);
      };
      a.fns.add(o), Gn(() => {
        a.off(o);
      });
    }
  });
}
function ri() {
  return {
    vueFlowRef: null,
    viewportRef: null,
    // todo: change this to a Set
    nodes: [],
    // todo: change this to a Set
    edges: [],
    connectionLookup: /* @__PURE__ */ new Map(),
    nodeTypes: {},
    edgeTypes: {},
    initialized: !1,
    dimensions: {
      width: 0,
      height: 0
    },
    viewport: { x: 0, y: 0, zoom: 1 },
    d3Zoom: null,
    d3Selection: null,
    d3ZoomHandler: null,
    minZoom: 0.5,
    maxZoom: 2,
    translateExtent: [
      [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
      [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]
    ],
    nodeExtent: [
      [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
      [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]
    ],
    selectionMode: Ko.Full,
    paneDragging: !1,
    preventScrolling: !0,
    zoomOnScroll: !0,
    zoomOnPinch: !0,
    zoomOnDoubleClick: !0,
    panOnScroll: !1,
    panOnScrollSpeed: 0.5,
    panOnScrollMode: qt.Free,
    panOnDrag: !0,
    edgeUpdaterRadius: 10,
    onlyRenderVisibleElements: !1,
    defaultViewport: { x: 0, y: 0, zoom: 1 },
    nodesSelectionActive: !1,
    userSelectionActive: !1,
    userSelectionRect: null,
    defaultMarkerColor: "#b1b1b7",
    connectionLineStyle: {},
    connectionLineType: null,
    connectionLineOptions: {
      type: gt.Bezier,
      style: {}
    },
    connectionMode: $t.Loose,
    connectionStartHandle: null,
    connectionEndHandle: null,
    connectionClickStartHandle: null,
    connectionPosition: { x: Number.NaN, y: Number.NaN },
    connectionRadius: 20,
    connectOnClick: !0,
    connectionStatus: null,
    isValidConnection: null,
    snapGrid: [15, 15],
    snapToGrid: !1,
    edgesUpdatable: !1,
    edgesFocusable: !0,
    nodesFocusable: !0,
    nodesConnectable: !0,
    nodesDraggable: !0,
    nodeDragThreshold: 1,
    elementsSelectable: !0,
    selectNodesOnDrag: !0,
    multiSelectionActive: !1,
    selectionKeyCode: "Shift",
    multiSelectionKeyCode: In() ? "Meta" : "Control",
    zoomActivationKeyCode: In() ? "Meta" : "Control",
    deleteKeyCode: "Backspace",
    panActivationKeyCode: "Space",
    hooks: zh(),
    applyDefault: !0,
    autoConnect: !1,
    fitViewOnInit: !1,
    fitViewOnInitDone: !1,
    noDragClassName: "nodrag",
    noWheelClassName: "nowheel",
    noPanClassName: "nopan",
    defaultEdgeOptions: void 0,
    elevateEdgesOnSelect: !1,
    elevateNodesOnSelect: !0,
    autoPanOnNodeDrag: !0,
    autoPanOnConnect: !0,
    disableKeyboardA11y: !1,
    ariaLiveMessage: ""
  };
}
const Hh = [
  "id",
  "vueFlowRef",
  "viewportRef",
  "initialized",
  "modelValue",
  "nodes",
  "edges",
  "maxZoom",
  "minZoom",
  "translateExtent",
  "hooks",
  "defaultEdgeOptions"
];
function Uh(e, t, n, a) {
  const o = vh(t), l = (D) => {
    const O = D ?? n.value ?? [];
    t.hooks.updateNodeInternals.trigger(O);
  }, i = (D) => Ch(D, t.nodes, t.edges), r = (D) => wh(D, t.nodes, t.edges), u = (D) => Wo(D, t.edges), s = (D) => {
    if (D)
      return t.nodes && !n.value.length ? t.nodes.find((O) => O.id === D) : t.nodes[n.value.indexOf(D)];
  }, d = (D) => {
    if (D)
      return t.edges && !a.value.length ? t.edges.find((O) => O.id === D) : t.edges[a.value.indexOf(D)];
  }, c = (D, O, L) => {
    var J, ee;
    const ue = [];
    for (const de of D) {
      const _e = {
        id: de.id,
        type: "position",
        dragging: L,
        from: de.from
      };
      if (O && (_e.position = de.position, de.parentNode)) {
        const Ee = s(de.parentNode);
        _e.position = {
          x: _e.position.x - (((J = Ee == null ? void 0 : Ee.computedPosition) == null ? void 0 : J.x) ?? 0),
          y: _e.position.y - (((ee = Ee == null ? void 0 : Ee.computedPosition) == null ? void 0 : ee.y) ?? 0)
        };
      }
      ue.push(_e);
    }
    ue != null && ue.length && t.hooks.nodesChange.trigger(ue);
  }, f = (D) => {
    if (!t.vueFlowRef)
      return;
    const O = t.vueFlowRef.querySelector(".vue-flow__transformationpane");
    if (!O)
      return;
    const L = window.getComputedStyle(O), { m22: J } = new window.DOMMatrixReadOnly(L.transform), ee = D.reduce((ue, de) => {
      const _e = s(de.id);
      if (_e) {
        const Ee = jn(de.nodeElement);
        !!(Ee.width && Ee.height && (_e.dimensions.width !== Ee.width || _e.dimensions.height !== Ee.height || de.forceUpdate)) && (_e.handleBounds.source = Ra(".source", de.nodeElement, J), _e.handleBounds.target = Ra(".target", de.nodeElement, J), _e.dimensions = Ee, _e.initialized = !0, ue.push({
          id: _e.id,
          type: "dimensions",
          dimensions: Ee
        }));
      }
      return ue;
    }, []);
    !t.fitViewOnInitDone && t.fitViewOnInit && je(() => {
      o.value.fitView(), t.fitViewOnInitDone = !0;
    }), ee.length && t.hooks.nodesChange.trigger(ee);
  }, h = (D, O) => {
    const L = [];
    for (const ue of D)
      L.push(ue.id);
    let J = [], ee = [];
    if (t.multiSelectionActive)
      for (const ue of L)
        J.push(ot(ue, O));
    else {
      const ue = uo([...t.nodes, ...t.edges], L);
      J = ue.changedNodes, ee = ue.changedEdges;
    }
    J.length && t.hooks.nodesChange.trigger(J), ee.length && t.hooks.edgesChange.trigger(ee);
  }, y = (D, O) => {
    const L = [];
    for (const ue of D)
      L.push(ue.id);
    let J = [], ee = [];
    if (t.multiSelectionActive)
      for (const ue of L)
        ee.push(ot(ue, O));
    else {
      const ue = uo([...t.nodes, ...t.edges], L);
      J = ue.changedNodes, ee = ue.changedEdges;
    }
    J.length && t.hooks.nodesChange.trigger(J), ee.length && t.hooks.edgesChange.trigger(ee);
  }, b = (D, O) => {
    const L = [], J = [];
    for (const de of D)
      Et(de) ? L.push(de.id) : Bt(de) && J.push(de.id);
    const { changedNodes: ee, changedEdges: ue } = uo([...t.nodes, ...t.edges], [...L, ...J]);
    if (t.multiSelectionActive) {
      for (const de of L)
        ee.push(ot(de, O));
      for (const de of J)
        ue.push(ot(de, O));
    }
    ee.length && t.hooks.nodesChange.trigger(ee), ue.length && t.hooks.edgesChange.trigger(ue);
  }, p = (D) => {
    h(D, !0);
  }, w = (D) => {
    y(D, !0);
  }, k = (D) => {
    b(D, !0);
  }, E = (D) => {
    if (!D.length)
      return h(D, !1);
    const O = [];
    for (const L of D)
      O.push(ot(L.id, !1));
    O.length && t.hooks.nodesChange.trigger(O);
  }, _ = (D) => {
    if (!D.length)
      return y(D, !1);
    const O = [];
    for (const L of D)
      O.push(ot(L.id, !1));
    O.length && t.hooks.edgesChange.trigger(O);
  }, C = (D) => {
    if (!D || !D.length)
      return b([], !1);
    const O = D.reduce(
      (L, J) => {
        const ee = ot(J.id, !1);
        return Et(J) ? L.nodes.push(ee) : L.edges.push(ee), L;
      },
      { nodes: [], edges: [] }
    );
    O.nodes.length && t.hooks.nodesChange.trigger(O.nodes), O.edges.length && t.hooks.edgesChange.trigger(O.edges);
  }, G = (D) => {
    var O;
    (O = t.d3Zoom) == null || O.scaleExtent([D, t.maxZoom]), t.minZoom = D;
  }, B = (D) => {
    var O;
    (O = t.d3Zoom) == null || O.scaleExtent([t.minZoom, D]), t.maxZoom = D;
  }, $ = (D) => {
    var O;
    (O = t.d3Zoom) == null || O.translateExtent(D), t.translateExtent = D;
  }, M = (D) => {
    t.nodeExtent = D, l(n.value);
  }, A = (D) => {
    t.nodesDraggable = D, t.nodesConnectable = D, t.elementsSelectable = D;
  }, S = (D) => {
    const O = D instanceof Function ? D(t.nodes) : D;
    !t.initialized && !O.length || (t.nodes = Ha(O, t.nodes, s, t.hooks.error.trigger));
  }, Z = (D) => {
    const O = D instanceof Function ? D(t.edges) : D;
    if (!t.initialized && !O.length)
      return;
    const L = t.isValidConnection ? O.filter(
      (J) => t.isValidConnection(J, {
        edges: t.edges,
        nodes: t.nodes,
        sourceNode: s(J.source),
        targetNode: s(J.target)
      })
    ) : O;
    Ua(t.connectionLookup, L), t.edges = L.reduce((J, ee) => {
      const ue = s(ee.source), de = s(ee.target), _e = !ue || typeof ue > "u", Ee = !de || typeof de > "u";
      if (_e && Ee ? t.hooks.error.trigger(new Oe(Ae.EDGE_SOURCE_TARGET_MISSING, ee.id, ee.source, ee.target)) : (_e && t.hooks.error.trigger(new Oe(Ae.EDGE_SOURCE_MISSING, ee.id, ee.source)), Ee && t.hooks.error.trigger(new Oe(Ae.EDGE_TARGET_MISSING, ee.id, ee.target))), _e || Ee)
        return J;
      const Be = d(ee.id);
      return J.push({
        ...Wl(ee, Be, t.defaultEdgeOptions),
        sourceNode: ue,
        targetNode: de
      }), J;
    }, []);
  }, z = (D) => {
    const O = D instanceof Function ? D([...t.nodes, ...t.edges]) : D;
    !t.initialized && !O.length || (S(O.filter(Et)), Z(O.filter(Bt)));
  }, N = (D) => {
    let O = D instanceof Function ? D(t.nodes) : D;
    O = Array.isArray(O) ? O : [O];
    const L = Ha(O, t.nodes, s, t.hooks.error.trigger), J = [];
    for (const ee of L)
      J.push(Va(ee));
    J.length && t.hooks.nodesChange.trigger(J);
  }, P = (D) => {
    let O = D instanceof Function ? D(t.edges) : D;
    O = Array.isArray(O) ? O : [O];
    const J = (t.isValidConnection ? O.filter((ee) => {
      var ue;
      return (ue = t.isValidConnection) == null ? void 0 : ue.call(t, ee, {
        edges: t.edges,
        nodes: t.nodes,
        sourceNode: s(ee.source),
        targetNode: s(ee.target)
      });
    }) : O).reduce((ee, ue) => {
      const de = Ph(ue, t.edges, t.hooks.error.trigger, t.defaultEdgeOptions);
      if (de) {
        const _e = s(de.source), Ee = s(de.target);
        ee.push(
          Va({
            ...de,
            sourceNode: _e,
            targetNode: Ee
          })
        );
      }
      return ee;
    }, []);
    J.length && t.hooks.edgesChange.trigger(J);
  }, T = (D, O = !0, L = !1) => {
    const J = D instanceof Function ? D(t.nodes) : D, ee = Array.isArray(J) ? J : [J], ue = [], de = [];
    function _e(Be) {
      const Ve = u(Be).filter((Ie) => Me(Ie.deletable) ? Ie.deletable : !0);
      de.push(
        ...Ve.map(
          (Ie) => Fa(
            Ie.id,
            Ie.source,
            Ie.target,
            Ie.sourceHandle,
            Ie.targetHandle
          )
        )
      );
    }
    function Ee(Be) {
      const Ve = t.nodes.filter((Ie) => Ie.parentNode === Be);
      if (Ve.length) {
        const Ie = Ve.map((tt) => tt.id);
        ue.push(...Ie.map((tt) => Aa(tt))), O && _e(Ve);
        for (const tt of Ve)
          Ee(tt.id);
      }
    }
    for (const Be of ee) {
      const Ve = typeof Be == "string" ? s(Be) : Be;
      Ve && (Me(Ve.deletable) && !Ve.deletable || (ue.push(Aa(Ve.id)), O && _e([Ve]), L && Ee(Ve.id)));
    }
    de.length && t.hooks.edgesChange.trigger(de), ue.length && t.hooks.nodesChange.trigger(ue);
  }, g = (D) => {
    const O = D instanceof Function ? D(t.edges) : D, L = Array.isArray(O) ? O : [O], J = [];
    for (const ee of L) {
      const ue = typeof ee == "string" ? d(ee) : ee;
      ue && (Me(ue.deletable) && !ue.deletable || J.push(
        Fa(
          typeof ee == "string" ? ee : ee.id,
          ue.source,
          ue.target,
          ue.sourceHandle,
          ue.targetHandle
        )
      ));
    }
    t.hooks.edgesChange.trigger(J);
  }, H = (D, O, L = !0) => Lh(D, O, t.edges, d, L, t.hooks.error.trigger), F = (D) => Da(D, t.nodes), W = (D) => {
    const O = Da(D, t.edges);
    return Ua(t.connectionLookup, O), O;
  }, oe = (D, O, L = { replace: !1 }) => {
    const J = s(D);
    if (!J)
      return;
    const ee = typeof O == "function" ? O(J) : O;
    L.replace ? t.nodes.splice(t.nodes.indexOf(J), 1, ee) : Object.assign(J, ee);
  }, re = (D, O, L = { replace: !1 }) => {
    oe(
      D,
      (J) => {
        const ee = typeof O == "function" ? O(J) : O;
        return L.replace ? { ...J, data: ee } : { ...J, data: { ...J.data, ...ee } };
      },
      L
    );
  }, ye = (D, O, L, J = !1) => {
    J ? t.connectionClickStartHandle = D : t.connectionStartHandle = D, t.connectionEndHandle = null, t.connectionStatus = null, O && (t.connectionPosition = O);
  }, Q = (D, O = null, L = null) => {
    t.connectionStartHandle && (t.connectionPosition = D, t.connectionEndHandle = O, t.connectionStatus = L);
  }, q = (D, O) => {
    t.connectionPosition = { x: Number.NaN, y: Number.NaN }, t.connectionEndHandle = null, t.connectionStatus = null, O ? t.connectionClickStartHandle = null : t.connectionStartHandle = null;
  }, Y = (D) => {
    const O = bh(D), L = O ? null : Ft(D) ? D : s(D.id);
    return !O && !L ? [null, null, O] : [O ? D : $a(L), L, O];
  }, fe = (D, O = !0, L = t.nodes) => {
    const [J, ee, ue] = Y(D);
    return J ? (L || t.nodes).filter((de) => {
      if (!ue && (de.id === ee.id || !de.computedPosition))
        return !1;
      const _e = $a(de), Ee = No(_e, J);
      return O && Ee > 0 || Ee >= Number(J.width) * Number(J.height);
    }) : [];
  }, ce = (D, O, L = !0) => {
    const [J] = Y(D);
    if (!J)
      return !1;
    const ee = No(J, O);
    return L && ee > 0 || ee >= Number(J.width) * Number(J.height);
  }, v = (D) => {
    const { viewport: O, dimensions: L, d3Zoom: J, d3Selection: ee, translateExtent: ue } = t;
    if (!J || !ee || !D.x && !D.y)
      return !1;
    const de = Ut.translate(O.x + D.x, O.y + D.y).scale(O.zoom), _e = [
      [0, 0],
      [L.width, L.height]
    ], Ee = J.constrain()(de, _e, ue), Be = t.viewport.x !== Ee.x || t.viewport.y !== Ee.y || t.viewport.zoom !== Ee.k;
    return J.transform(ee, Ee), Be;
  }, ge = (D) => {
    const O = D instanceof Function ? D(t) : D, L = [
      "d3Zoom",
      "d3Selection",
      "d3ZoomHandler",
      "viewportRef",
      "vueFlowRef",
      "dimensions",
      "hooks"
    ];
    Me(O.defaultEdgeOptions) && (t.defaultEdgeOptions = O.defaultEdgeOptions);
    const J = O.modelValue || O.nodes || O.edges ? [] : void 0;
    J && (O.modelValue && J.push(...O.modelValue), O.nodes && J.push(...O.nodes), O.edges && J.push(...O.edges), z(J));
    const ee = () => {
      Me(O.maxZoom) && B(O.maxZoom), Me(O.minZoom) && G(O.minZoom), Me(O.translateExtent) && $(O.translateExtent);
    };
    for (const ue of Object.keys(O)) {
      const de = ue, _e = O[de];
      ![...Hh, ...L].includes(de) && Me(_e) && (t[de] = _e);
    }
    kn(() => t.d3Zoom).not.toBeNull().then(ee), t.initialized || (t.initialized = !0);
  };
  return {
    updateNodePositions: c,
    updateNodeDimensions: f,
    setElements: z,
    setNodes: S,
    setEdges: Z,
    addNodes: N,
    addEdges: P,
    removeNodes: T,
    removeEdges: g,
    findNode: s,
    findEdge: d,
    updateEdge: H,
    updateNode: oe,
    updateNodeData: re,
    applyEdgeChanges: W,
    applyNodeChanges: F,
    addSelectedElements: k,
    addSelectedNodes: p,
    addSelectedEdges: w,
    setMinZoom: G,
    setMaxZoom: B,
    setTranslateExtent: $,
    setNodeExtent: M,
    removeSelectedElements: C,
    removeSelectedNodes: E,
    removeSelectedEdges: _,
    startConnection: ye,
    updateConnection: Q,
    endConnection: q,
    setInteractive: A,
    setState: ge,
    getIntersectingNodes: fe,
    getIncomers: i,
    getOutgoers: r,
    getConnectedEdges: u,
    isNodeIntersecting: ce,
    panBy: v,
    fitView: (D) => o.value.fitView(D),
    zoomIn: (D) => o.value.zoomIn(D),
    zoomOut: (D) => o.value.zoomOut(D),
    zoomTo: (D, O) => o.value.zoomTo(D, O),
    setViewport: (D, O) => o.value.setViewport(D, O),
    setTransform: (D, O) => o.value.setTransform(D, O),
    getViewport: () => o.value.getViewport(),
    getTransform: () => o.value.getTransform(),
    setCenter: (D, O, L) => o.value.setCenter(D, O, L),
    fitBounds: (D, O) => o.value.fitBounds(D, O),
    project: (D) => o.value.project(D),
    screenToFlowCoordinate: (D) => o.value.screenToFlowCoordinate(D),
    flowToScreenCoordinate: (D) => o.value.flowToScreenCoordinate(D),
    toObject: () => {
      const D = [], O = [];
      for (const L of t.nodes) {
        const {
          computedPosition: J,
          handleBounds: ee,
          selected: ue,
          dimensions: de,
          isParent: _e,
          resizing: Ee,
          dragging: Be,
          initialized: Ve,
          events: Ie,
          ...tt
        } = L;
        D.push(tt);
      }
      for (const L of t.edges) {
        const { selected: J, sourceNode: ee, targetNode: ue, events: de, ..._e } = L;
        O.push(_e);
      }
      return JSON.parse(
        JSON.stringify({
          nodes: D,
          edges: O,
          position: [t.viewport.x, t.viewport.y],
          zoom: t.viewport.zoom,
          viewport: t.viewport
        })
      );
    },
    fromObject: (D) => new Promise((O) => {
      const { nodes: L, edges: J, position: ee, zoom: ue, viewport: de } = D;
      if (L && S(L), J && Z(J), de != null && de.x && (de != null && de.y) || ee) {
        const _e = (de == null ? void 0 : de.x) || ee[0], Ee = (de == null ? void 0 : de.y) || ee[1], Be = (de == null ? void 0 : de.zoom) || ue || t.viewport.zoom;
        return kn(() => o.value.viewportInitialized).toBe(!0).then(() => {
          o.value.setViewport({
            x: _e,
            y: Ee,
            zoom: Be
          }).then(() => {
            O(!0);
          });
        });
      } else
        O(!0);
    }),
    updateNodeInternals: l,
    viewportHelper: o,
    $reset: () => {
      const D = ri();
      if (t.edges = [], t.nodes = [], t.d3Zoom && t.d3Selection) {
        const O = Ut.translate(D.defaultViewport.x ?? 0, D.defaultViewport.y ?? 0).scale(Mt(D.defaultViewport.zoom ?? 1, D.minZoom, D.maxZoom)), L = t.viewportRef.getBoundingClientRect(), J = [
          [0, 0],
          [L.width, L.height]
        ], ee = t.d3Zoom.constrain()(O, J, D.translateExtent);
        t.d3Zoom.transform(t.d3Selection, ee);
      }
      ge(D);
    },
    $destroy: () => {
    }
  };
}
const Gh = ["data-id", "data-handleid", "data-nodeid", "data-handlepos"], Zh = {
  name: "Handle",
  compatConfig: { MODE: 3 }
}, Dn = /* @__PURE__ */ ne({
  ...Zh,
  props: {
    id: {},
    type: {},
    position: { default: () => he.Top },
    isValidConnection: { type: Function },
    connectable: { type: [Boolean, Number, String, Function], default: void 0 },
    connectableStart: { type: Boolean, default: !0 },
    connectableEnd: { type: Boolean, default: !0 }
  },
  setup(e, { expose: t }) {
    const n = el(e, ["position", "connectable", "connectableStart", "connectableEnd", "id"]), a = Le(() => n.type ?? "source"), o = Le(() => n.isValidConnection ?? null), {
      connectionStartHandle: l,
      connectionClickStartHandle: i,
      connectionEndHandle: r,
      vueFlowRef: u,
      nodesConnectable: s,
      noDragClassName: d,
      noPanClassName: c
    } = $e(), { id: f, node: h, nodeEl: y, connectedEdges: b } = ph(), p = ae(), w = Le(() => e.id ?? `${f}__handle-${e.position}`), k = Le(() => typeof e.connectableStart < "u" ? e.connectableStart : !0), E = Le(() => typeof e.connectableEnd < "u" ? e.connectableEnd : !0), _ = Le(
      () => {
        var S, Z, z, N, P, T;
        return ((S = l.value) == null ? void 0 : S.nodeId) === f && ((Z = l.value) == null ? void 0 : Z.handleId) === w.value && ((z = l.value) == null ? void 0 : z.type) === a.value || ((N = r.value) == null ? void 0 : N.nodeId) === f && ((P = r.value) == null ? void 0 : P.handleId) === w.value && ((T = r.value) == null ? void 0 : T.type) === a.value;
      }
    ), C = Le(
      () => {
        var S, Z, z;
        return ((S = i.value) == null ? void 0 : S.nodeId) === f && ((Z = i.value) == null ? void 0 : Z.handleId) === w.value && ((z = i.value) == null ? void 0 : z.type) === a.value;
      }
    ), { handlePointerDown: G, handleClick: B } = Yl({
      nodeId: f,
      handleId: w,
      isValidConnection: o,
      type: a
    }), $ = me(() => typeof e.connectable == "string" && e.connectable === "single" ? !b.value.some((S) => {
      const Z = S[`${a.value}Handle`];
      return S[a.value] !== f ? !1 : Z ? Z === w.value : !0;
    }) : typeof e.connectable == "number" ? b.value.filter((S) => {
      const Z = S[`${a.value}Handle`];
      return S[a.value] !== f ? !1 : Z ? Z === w.value : !0;
    }).length < e.connectable : typeof e.connectable == "function" ? e.connectable(h, b.value) : Me(e.connectable) ? e.connectable : s.value);
    kn(() => h.initialized).toBe(!0, { flush: "post" }).then(() => {
      var S;
      const Z = (S = h.handleBounds[a.value]) == null ? void 0 : S.find((F) => F.id === w.value);
      if (!u.value || Z)
        return;
      const z = u.value.querySelector(".vue-flow__transformationpane");
      if (!y.value || !p.value || !z || !w.value)
        return;
      const N = y.value.getBoundingClientRect(), P = p.value.getBoundingClientRect(), T = window.getComputedStyle(z), { m22: g } = new window.DOMMatrixReadOnly(T.transform), H = {
        id: w.value,
        position: e.position,
        x: (P.left - N.left) / g,
        y: (P.top - N.top) / g,
        ...jn(p.value)
      };
      h.handleBounds[a.value] = [...h.handleBounds[a.value] ?? [], H];
    }), Vt(() => {
      const S = h.handleBounds[a.value];
      S && (h.handleBounds[a.value] = S.filter((Z) => Z.id !== w.value));
    });
    function M(S) {
      const Z = qo(S);
      $.value && k.value && (Z && S.button === 0 || !Z) && G(S);
    }
    function A(S) {
      !f || !i.value && !k.value || $.value && B(S);
    }
    return t({
      handleClick: B,
      handlePointerDown: G,
      onClick: A,
      onPointerDown: M
    }), (S, Z) => (m(), U("div", {
      ref_key: "handle",
      ref: p,
      "data-id": `${x(f)}-${w.value}-${a.value}`,
      "data-handleid": w.value,
      "data-nodeid": x(f),
      "data-handlepos": S.position,
      class: Pe(["vue-flow__handle", [
        `vue-flow__handle-${S.position}`,
        `vue-flow__handle-${w.value}`,
        x(d),
        x(c),
        a.value,
        {
          connectable: $.value,
          connecting: C.value,
          connectablestart: k.value,
          connectableend: E.value,
          connectionindicator: $.value && (k.value && !_.value || E.value && _.value)
        }
      ]]),
      onMousedown: M,
      onTouchstartPassive: M,
      onClick: A
    }, [
      te(S.$slots, "default", { id: S.id })
    ], 42, Gh));
  }
}), Jn = function({
  sourcePosition: e = he.Bottom,
  targetPosition: t = he.Top,
  label: n,
  connectable: a = !0,
  isValidTargetPos: o,
  isValidSourcePos: l,
  data: i
}) {
  const r = i.label || n;
  return [
    Ne(Dn, { type: "target", position: t, connectable: a, isValidConnection: o }),
    typeof r != "string" && r ? Ne(r) : Ne("div", { innerHTML: r }),
    Ne(Dn, { type: "source", position: e, connectable: a, isValidConnection: l })
  ];
};
Jn.props = ["sourcePosition", "targetPosition", "label", "isValidTargetPos", "isValidSourcePos", "connectable", "data"];
Jn.inheritAttrs = !1;
Jn.compatConfig = { MODE: 3 };
const Yh = Jn, Qn = function({
  targetPosition: e = he.Top,
  label: t,
  connectable: n = !0,
  isValidTargetPos: a,
  data: o
}) {
  const l = o.label || t;
  return [
    Ne(Dn, { type: "target", position: e, connectable: n, isValidConnection: a }),
    typeof l != "string" && l ? Ne(l) : Ne("div", { innerHTML: l })
  ];
};
Qn.props = ["targetPosition", "label", "isValidTargetPos", "connectable", "data"];
Qn.inheritAttrs = !1;
Qn.compatConfig = { MODE: 3 };
const Kh = Qn, eo = function({
  sourcePosition: e = he.Bottom,
  label: t,
  connectable: n = !0,
  isValidSourcePos: a,
  data: o
}) {
  const l = o.label || t;
  return [
    typeof l != "string" && l ? Ne(l) : Ne("div", { innerHTML: l }),
    Ne(Dn, { type: "source", position: e, connectable: n, isValidConnection: a })
  ];
};
eo.props = ["sourcePosition", "label", "isValidSourcePos", "connectable", "data"];
eo.inheritAttrs = !1;
eo.compatConfig = { MODE: 3 };
const Xh = eo, Wh = ["transform"], jh = ["width", "height", "x", "y", "rx", "ry"], qh = ["y"], Jh = {
  name: "EdgeText",
  compatConfig: { MODE: 3 }
}, Qh = /* @__PURE__ */ ne({
  ...Jh,
  props: {
    x: {},
    y: {},
    label: {},
    labelStyle: { default: () => ({}) },
    labelShowBg: { type: Boolean, default: !0 },
    labelBgStyle: { default: () => ({}) },
    labelBgPadding: { default: () => [2, 4] },
    labelBgBorderRadius: { default: 2 }
  },
  setup(e) {
    const t = ae({ x: 0, y: 0, width: 0, height: 0 }), n = ae(null), a = me(() => `translate(${e.x - t.value.width / 2} ${e.y - t.value.height / 2})`);
    De(o), ie([() => e.x, () => e.y, n, () => e.label], o);
    function o() {
      if (!n.value)
        return;
      const l = n.value.getBBox();
      (l.width !== t.value.width || l.height !== t.value.height) && (t.value = l);
    }
    return (l, i) => (m(), U("g", {
      transform: a.value,
      class: "vue-flow__edge-textwrapper"
    }, [
      l.labelShowBg ? (m(), U("rect", {
        key: 0,
        class: "vue-flow__edge-textbg",
        width: `${t.value.width + 2 * l.labelBgPadding[0]}px`,
        height: `${t.value.height + 2 * l.labelBgPadding[1]}px`,
        x: -l.labelBgPadding[0],
        y: -l.labelBgPadding[1],
        style: pe(l.labelBgStyle),
        rx: l.labelBgBorderRadius,
        ry: l.labelBgBorderRadius
      }, null, 12, jh)) : j("", !0),
      V("text", Dt(l.$attrs, {
        ref_key: "el",
        ref: n,
        class: "vue-flow__edge-text",
        y: t.value.height / 2,
        dy: "0.3em",
        style: l.labelStyle
      }), [
        te(l.$slots, "default", {}, () => [
          typeof l.label != "string" ? (m(), R(it(l.label), { key: 0 })) : (m(), U(ve, { key: 1 }, [
            Ce(se(l.label), 1)
          ], 64))
        ])
      ], 16, qh)
    ], 8, Wh));
  }
}), em = ["id", "d", "marker-end", "marker-start"], tm = ["d", "stroke-width"], nm = {
  name: "BaseEdge",
  inheritAttrs: !1,
  compatConfig: { MODE: 3 }
}, to = /* @__PURE__ */ ne({
  ...nm,
  props: {
    id: {},
    labelX: {},
    labelY: {},
    path: {},
    label: {},
    markerStart: {},
    markerEnd: {},
    interactionWidth: { default: 20 },
    style: {},
    labelStyle: {},
    labelShowBg: { type: Boolean, default: !0 },
    labelBgStyle: {},
    labelBgPadding: {},
    labelBgBorderRadius: {}
  },
  setup(e, { expose: t }) {
    const n = el(e, ["interactionWidth", "labelShowBg"]), a = ae(null), o = ae(null), l = ae(null), i = Hi();
    return t({
      pathEl: a,
      interactionEl: o,
      labelEl: l
    }), (r, u) => (m(), U(ve, null, [
      V("path", {
        id: r.id,
        ref_key: "pathEl",
        ref: a,
        d: r.path,
        style: pe(n.style),
        class: Pe(["vue-flow__edge-path", x(i).class]),
        "marker-end": r.markerEnd,
        "marker-start": r.markerStart
      }, null, 14, em),
      r.interactionWidth ? (m(), U("path", {
        key: 0,
        ref_key: "interactionEl",
        ref: o,
        fill: "none",
        d: r.path,
        "stroke-width": r.interactionWidth,
        "stroke-opacity": 0,
        class: "vue-flow__edge-interaction"
      }, null, 8, tm)) : j("", !0),
      r.label && r.labelX && r.labelY ? (m(), R(Qh, {
        key: 1,
        ref_key: "labelEl",
        ref: l,
        x: r.labelX,
        y: r.labelY,
        label: r.label,
        "label-show-bg": r.labelShowBg,
        "label-bg-style": r.labelBgStyle,
        "label-bg-padding": r.labelBgPadding,
        "label-bg-border-radius": r.labelBgBorderRadius,
        "label-style": r.labelStyle
      }, null, 8, ["x", "y", "label", "label-show-bg", "label-bg-style", "label-bg-padding", "label-bg-border-radius", "label-style"])) : j("", !0)
    ], 64));
  }
});
function si({
  sourceX: e,
  sourceY: t,
  targetX: n,
  targetY: a
}) {
  const o = Math.abs(n - e) / 2, l = n < e ? n + o : n - o, i = Math.abs(a - t) / 2, r = a < t ? a + i : a - i;
  return [l, r, o, i];
}
function ui({
  sourceX: e,
  sourceY: t,
  targetX: n,
  targetY: a,
  sourceControlX: o,
  sourceControlY: l,
  targetControlX: i,
  targetControlY: r
}) {
  const u = e * 0.125 + o * 0.375 + i * 0.375 + n * 0.125, s = t * 0.125 + l * 0.375 + r * 0.375 + a * 0.125, d = Math.abs(u - e), c = Math.abs(s - t);
  return [u, s, d, c];
}
function gn(e, t) {
  return e >= 0 ? 0.5 * e : t * 25 * Math.sqrt(-e);
}
function Ga({ pos: e, x1: t, y1: n, x2: a, y2: o, c: l }) {
  let i, r;
  switch (e) {
    case he.Left:
      i = t - gn(t - a, l), r = n;
      break;
    case he.Right:
      i = t + gn(a - t, l), r = n;
      break;
    case he.Top:
      i = t, r = n - gn(n - o, l);
      break;
    case he.Bottom:
      i = t, r = n + gn(o - n, l);
      break;
  }
  return [i, r];
}
function di({
  sourceX: e,
  sourceY: t,
  sourcePosition: n = he.Bottom,
  targetX: a,
  targetY: o,
  targetPosition: l = he.Top,
  curvature: i = 0.25
}) {
  const [r, u] = Ga({
    pos: n,
    x1: e,
    y1: t,
    x2: a,
    y2: o,
    c: i
  }), [s, d] = Ga({
    pos: l,
    x1: a,
    y1: o,
    x2: e,
    y2: t,
    c: i
  }), [c, f, h, y] = ui({
    sourceX: e,
    sourceY: t,
    targetX: a,
    targetY: o,
    sourceControlX: r,
    sourceControlY: u,
    targetControlX: s,
    targetControlY: d
  });
  return [
    `M${e},${t} C${r},${u} ${s},${d} ${a},${o}`,
    c,
    f,
    h,
    y
  ];
}
function Za({ pos: e, x1: t, y1: n, x2: a, y2: o }) {
  let l, i;
  switch (e) {
    case he.Left:
    case he.Right:
      l = 0.5 * (t + a), i = n;
      break;
    case he.Top:
    case he.Bottom:
      l = t, i = 0.5 * (n + o);
      break;
  }
  return [l, i];
}
function ci({
  sourceX: e,
  sourceY: t,
  sourcePosition: n = he.Bottom,
  targetX: a,
  targetY: o,
  targetPosition: l = he.Top
}) {
  const [i, r] = Za({
    pos: n,
    x1: e,
    y1: t,
    x2: a,
    y2: o
  }), [u, s] = Za({
    pos: l,
    x1: a,
    y1: o,
    x2: e,
    y2: t
  }), [d, c, f, h] = ui({
    sourceX: e,
    sourceY: t,
    targetX: a,
    targetY: o,
    sourceControlX: i,
    sourceControlY: r,
    targetControlX: u,
    targetControlY: s
  });
  return [
    `M${e},${t} C${i},${r} ${u},${s} ${a},${o}`,
    d,
    c,
    f,
    h
  ];
}
const Ya = {
  [he.Left]: { x: -1, y: 0 },
  [he.Right]: { x: 1, y: 0 },
  [he.Top]: { x: 0, y: -1 },
  [he.Bottom]: { x: 0, y: 1 }
};
function om({
  source: e,
  sourcePosition: t = he.Bottom,
  target: n
}) {
  return t === he.Left || t === he.Right ? e.x < n.x ? { x: 1, y: 0 } : { x: -1, y: 0 } : e.y < n.y ? { x: 0, y: 1 } : { x: 0, y: -1 };
}
function Ka(e, t) {
  return Math.sqrt((t.x - e.x) ** 2 + (t.y - e.y) ** 2);
}
function am({
  source: e,
  sourcePosition: t = he.Bottom,
  target: n,
  targetPosition: a = he.Top,
  center: o,
  offset: l
}) {
  const i = Ya[t], r = Ya[a], u = { x: e.x + i.x * l, y: e.y + i.y * l }, s = { x: n.x + r.x * l, y: n.y + r.y * l }, d = om({
    source: u,
    sourcePosition: t,
    target: s
  }), c = d.x !== 0 ? "x" : "y", f = d[c];
  let h, y, b;
  const p = { x: 0, y: 0 }, w = { x: 0, y: 0 }, [k, E, _, C] = si({
    sourceX: e.x,
    sourceY: e.y,
    targetX: n.x,
    targetY: n.y
  });
  if (i[c] * r[c] === -1) {
    y = o.x ?? k, b = o.y ?? E;
    const B = [
      { x: y, y: u.y },
      { x: y, y: s.y }
    ], $ = [
      { x: u.x, y: b },
      { x: s.x, y: b }
    ];
    i[c] === f ? h = c === "x" ? B : $ : h = c === "x" ? $ : B;
  } else {
    const B = [{ x: u.x, y: s.y }], $ = [{ x: s.x, y: u.y }];
    if (c === "x" ? h = i.x === f ? $ : B : h = i.y === f ? B : $, t === a) {
      const z = Math.abs(e[c] - n[c]);
      if (z <= l) {
        const N = Math.min(l - 1, l - z);
        i[c] === f ? p[c] = (u[c] > e[c] ? -1 : 1) * N : w[c] = (s[c] > n[c] ? -1 : 1) * N;
      }
    }
    if (t !== a) {
      const z = c === "x" ? "y" : "x", N = i[c] === r[z], P = u[z] > s[z], T = u[z] < s[z];
      (i[c] === 1 && (!N && P || N && T) || i[c] !== 1 && (!N && T || N && P)) && (h = c === "x" ? B : $);
    }
    const M = { x: u.x + p.x, y: u.y + p.y }, A = { x: s.x + w.x, y: s.y + w.y }, S = Math.max(Math.abs(M.x - h[0].x), Math.abs(A.x - h[0].x)), Z = Math.max(Math.abs(M.y - h[0].y), Math.abs(A.y - h[0].y));
    S >= Z ? (y = (M.x + A.x) / 2, b = h[0].y) : (y = h[0].x, b = (M.y + A.y) / 2);
  }
  return [[
    e,
    { x: u.x + p.x, y: u.y + p.y },
    ...h,
    { x: s.x + w.x, y: s.y + w.y },
    n
  ], y, b, _, C];
}
function lm(e, t, n, a) {
  const o = Math.min(Ka(e, t) / 2, Ka(t, n) / 2, a), { x: l, y: i } = t;
  if (e.x === l && l === n.x || e.y === i && i === n.y)
    return `L${l} ${i}`;
  if (e.y === i) {
    const s = e.x < n.x ? -1 : 1, d = e.y < n.y ? 1 : -1;
    return `L ${l + o * s},${i}Q ${l},${i} ${l},${i + o * d}`;
  }
  const r = e.x < n.x ? 1 : -1, u = e.y < n.y ? -1 : 1;
  return `L ${l},${i + o * u}Q ${l},${i} ${l + o * r},${i}`;
}
function Mo({
  sourceX: e,
  sourceY: t,
  sourcePosition: n = he.Bottom,
  targetX: a,
  targetY: o,
  targetPosition: l = he.Top,
  borderRadius: i = 5,
  centerX: r,
  centerY: u,
  offset: s = 20
}) {
  const [d, c, f, h, y] = am({
    source: { x: e, y: t },
    sourcePosition: n,
    target: { x: a, y: o },
    targetPosition: l,
    center: { x: r, y: u },
    offset: s
  });
  return [d.reduce((p, w, k) => {
    let E;
    return k > 0 && k < d.length - 1 ? E = lm(d[k - 1], w, d[k + 1], i) : E = `${k === 0 ? "M" : "L"}${w.x} ${w.y}`, p += E, p;
  }, ""), c, f, h, y];
}
function im({
  sourceX: e,
  sourceY: t,
  targetX: n,
  targetY: a
}) {
  const [o, l, i, r] = si({
    sourceX: e,
    sourceY: t,
    targetX: n,
    targetY: a
  });
  return [`M ${e},${t}L ${n},${a}`, o, l, i, r];
}
const rm = ne({
  name: "StraightEdge",
  props: [
    "label",
    "labelStyle",
    "labelShowBg",
    "labelBgStyle",
    "labelBgPadding",
    "labelBgBorderRadius",
    "sourceY",
    "sourceX",
    "targetX",
    "targetY",
    "markerEnd",
    "markerStart",
    "interactionWidth"
  ],
  compatConfig: { MODE: 3 },
  setup(e, { attrs: t }) {
    return () => {
      const [n, a, o] = im(e);
      return Ne(to, {
        path: n,
        labelX: a,
        labelY: o,
        ...t,
        ...e
      });
    };
  }
}), sm = rm, um = ne({
  name: "SmoothStepEdge",
  props: [
    "sourcePosition",
    "targetPosition",
    "label",
    "labelStyle",
    "labelShowBg",
    "labelBgStyle",
    "labelBgPadding",
    "labelBgBorderRadius",
    "sourceY",
    "sourceX",
    "targetX",
    "targetY",
    "borderRadius",
    "markerEnd",
    "markerStart",
    "interactionWidth",
    "offset"
  ],
  compatConfig: { MODE: 3 },
  setup(e, { attrs: t }) {
    return () => {
      const [n, a, o] = Mo({
        ...e,
        sourcePosition: e.sourcePosition ?? he.Bottom,
        targetPosition: e.targetPosition ?? he.Top
      });
      return Ne(to, {
        path: n,
        labelX: a,
        labelY: o,
        ...t,
        ...e
      });
    };
  }
}), Qo = um, dm = ne({
  name: "StepEdge",
  props: [
    "sourcePosition",
    "targetPosition",
    "label",
    "labelStyle",
    "labelShowBg",
    "labelBgStyle",
    "labelBgPadding",
    "labelBgBorderRadius",
    "sourceY",
    "sourceX",
    "targetX",
    "targetY",
    "markerEnd",
    "markerStart",
    "interactionWidth"
  ],
  setup(e, { attrs: t }) {
    return () => Ne(Qo, { ...e, ...t, borderRadius: 0 });
  }
}), cm = dm, fm = ne({
  name: "BezierEdge",
  props: [
    "sourcePosition",
    "targetPosition",
    "label",
    "labelStyle",
    "labelShowBg",
    "labelBgStyle",
    "labelBgPadding",
    "labelBgBorderRadius",
    "sourceY",
    "sourceX",
    "targetX",
    "targetY",
    "curvature",
    "markerEnd",
    "markerStart",
    "interactionWidth"
  ],
  compatConfig: { MODE: 3 },
  setup(e, { attrs: t }) {
    return () => {
      const [n, a, o] = di({
        ...e,
        sourcePosition: e.sourcePosition ?? he.Bottom,
        targetPosition: e.targetPosition ?? he.Top
      });
      return Ne(to, {
        path: n,
        labelX: a,
        labelY: o,
        ...t,
        ...e
      });
    };
  }
}), pm = fm, hm = ne({
  name: "SimpleBezierEdge",
  props: [
    "sourcePosition",
    "targetPosition",
    "label",
    "labelStyle",
    "labelShowBg",
    "labelBgStyle",
    "labelBgPadding",
    "labelBgBorderRadius",
    "sourceY",
    "sourceX",
    "targetX",
    "targetY",
    "markerEnd",
    "markerStart",
    "interactionWidth"
  ],
  compatConfig: { MODE: 3 },
  setup(e, { attrs: t }) {
    return () => {
      const [n, a, o] = ci({
        ...e,
        sourcePosition: e.sourcePosition ?? he.Bottom,
        targetPosition: e.targetPosition ?? he.Top
      });
      return Ne(to, {
        path: n,
        labelX: a,
        labelY: o,
        ...t,
        ...e
      });
    };
  }
}), mm = hm, gm = {
  input: Xh,
  default: Yh,
  output: Kh
}, vm = {
  default: pm,
  straight: sm,
  step: cm,
  smoothstep: Qo,
  simplebezier: mm
};
function ym(e, t, n) {
  const a = me(() => (p) => e.nodes && !t.value.length ? e.nodes.find((w) => w.id === p) : e.nodes[t.value.indexOf(p)]), o = me(() => (p) => e.edges && !n.value.length ? e.edges.find((w) => w.id === p) : e.edges[n.value.indexOf(p)]), l = me(() => {
    const p = {
      ...vm,
      ...e.edgeTypes
    }, w = Object.keys(p);
    for (const k of e.edges)
      k.type && !w.includes(k.type) && (p[k.type] = k.type);
    return p;
  }), i = me(() => {
    const p = {
      ...gm,
      ...e.nodeTypes
    }, w = Object.keys(p);
    for (const k of e.nodes)
      k.type && !w.includes(k.type) && (p[k.type] = k.type);
    return p;
  }), r = me(() => {
    const p = e.nodes.filter((w) => !w.hidden);
    return e.onlyRenderVisibleElements ? p && ti(
      p,
      {
        x: 0,
        y: 0,
        width: e.dimensions.width,
        height: e.dimensions.height
      },
      e.viewport,
      !0
    ) : p ?? [];
  }), u = (p, w, k) => {
    if (w = w ?? a.value(p.source), k = k ?? a.value(p.target), !w || !k) {
      e.hooks.error.trigger(new Oe(Ae.EDGE_ORPHANED, p.id));
      return;
    }
    return !p.hidden && !k.hidden && !w.hidden;
  }, s = me(() => e.onlyRenderVisibleElements ? e.edges.filter((p) => {
    const w = a.value(p.source), k = a.value(p.target);
    return u(p, w, k) && Dh({
      sourcePos: w.computedPosition || { x: 0, y: 0 },
      targetPos: k.computedPosition || { x: 0, y: 0 },
      sourceWidth: w.dimensions.width,
      sourceHeight: w.dimensions.height,
      targetWidth: k.dimensions.width,
      targetHeight: k.dimensions.height,
      width: e.dimensions.width,
      height: e.dimensions.height,
      viewport: e.viewport
    });
  }) : e.edges.filter((p) => u(p))), d = me(() => [...r.value, ...s.value]), c = me(() => e.nodes.filter((p) => p.selected)), f = me(() => e.edges.filter((p) => p.selected)), h = me(() => [
    ...c.value ?? [],
    ...f.value ?? []
  ]), y = me(
    () => r.value.filter((p) => p.initialized && p.handleBounds !== void 0)
  ), b = me(
    () => r.value.length > 0 && y.value.length === r.value.length
  );
  return {
    getNode: a,
    getEdge: o,
    getElements: d,
    getEdgeTypes: l,
    getNodeTypes: i,
    getEdges: s,
    getNodes: r,
    getSelectedElements: h,
    getSelectedNodes: c,
    getSelectedEdges: f,
    getNodesInitialized: y,
    areNodesInitialized: b
  };
}
class vt {
  constructor() {
    this.currentId = 0, this.flows = /* @__PURE__ */ new Map();
  }
  static getInstance() {
    var t;
    const n = (t = Un()) == null ? void 0 : t.appContext.app, a = (n == null ? void 0 : n.config.globalProperties.$vueFlowStorage) ?? vt.instance;
    return vt.instance = a ?? new vt(), n && (n.config.globalProperties.$vueFlowStorage = vt.instance), vt.instance;
  }
  set(t, n) {
    return this.flows.set(t, n);
  }
  get(t) {
    return this.flows.get(t);
  }
  remove(t) {
    return this.flows.delete(t);
  }
  create(t, n) {
    const a = ri(), o = Re(a), l = {};
    for (const [f, h] of Object.entries(o.hooks)) {
      const y = `on${f.charAt(0).toUpperCase() + f.slice(1)}`;
      l[y] = h.on;
    }
    const i = {};
    for (const [f, h] of Object.entries(o.hooks))
      i[f] = h.trigger;
    const r = me(() => o.nodes.map((f) => f.id)), u = me(() => o.edges.map((f) => f.id)), s = ym(o, r, u), d = Uh(t, o, r, u);
    d.setState({ ...o, ...n });
    const c = {
      ...l,
      ...s,
      ...d,
      ..._d(o),
      emits: i,
      id: t,
      vueFlowVersion: "1.34.0",
      $destroy: () => {
        this.remove(t);
      }
    };
    return this.set(t, c), c;
  }
  getId() {
    return `vue-flow-${this.currentId++}`;
  }
}
function $e(e) {
  const t = vt.getInstance(), n = Ja(), a = e == null ? void 0 : e.id, o = (n == null ? void 0 : n.vueFlowId) || a;
  let l;
  if (n) {
    const i = Qe(xa, null);
    typeof i < "u" && i !== null && (l = i);
  }
  if (l || o && (l = t.get(o)), !l || l && a && a !== l.id) {
    const i = a ?? t.getId(), r = t.create(i, e);
    l = r, (n ?? Qa(!0)).run(() => {
      ie(
        r.applyDefault,
        (s, d, c) => {
          const f = (y) => {
            r.applyNodeChanges(y);
          }, h = (y) => {
            r.applyEdgeChanges(y);
          };
          s ? (r.onNodesChange(f), r.onEdgesChange(h)) : (r.hooks.value.nodesChange.off(f), r.hooks.value.edgesChange.off(h)), c(() => {
            r.hooks.value.nodesChange.off(f), r.hooks.value.edgesChange.off(h);
          });
        },
        { immediate: !0 }
      ), Gn(() => {
        if (l) {
          const s = t.get(l.id);
          s ? s.$destroy() : qn(`No store instance found for id ${l.id} in storage.`);
        }
      });
    });
  } else
    e && l.setState(e);
  return n && (rt(xa, l), n.vueFlowId = l.id), l;
}
function bm(e) {
  const t = Xo(), { emits: n, dimensions: a } = $e();
  let o;
  De(() => {
    const l = e.value, i = () => {
      if (!l)
        return;
      const r = jn(l);
      (r.width === 0 || r.height === 0) && n.error(new Oe(Ae.MISSING_VIEWPORT_DIMENSIONS)), a.value = { width: r.width || 500, height: r.height || 500 };
    };
    i(), t.addEventListener("resize", i), l && (o = new ResizeObserver(() => i()), o.observe(l)), Lo(() => {
      t.removeEventListener("resize", i), o && l && o.unobserve(l);
    });
  });
}
const _m = {
  name: "UserSelection",
  compatConfig: { MODE: 3 }
}, wm = /* @__PURE__ */ ne({
  ..._m,
  props: {
    userSelectionRect: {}
  },
  setup(e) {
    return (t, n) => (m(), U("div", {
      class: "vue-flow__selection vue-flow__container",
      style: pe({
        width: `${t.userSelectionRect.width}px`,
        height: `${t.userSelectionRect.height}px`,
        transform: `translate(${t.userSelectionRect.x}px, ${t.userSelectionRect.y}px)`
      })
    }, null, 4));
  }
}), Cm = ["tabIndex"], Em = {
  name: "NodesSelection",
  compatConfig: { MODE: 3 }
}, km = /* @__PURE__ */ ne({
  ...Em,
  setup(e) {
    const { emits: t, viewport: n, getSelectedNodes: a, noPanClassName: o, disableKeyboardA11y: l, userSelectionActive: i } = $e(), r = Kl(), u = ae(null), s = Ul({
      el: u,
      onStart(y) {
        t.selectionDragStart(y);
      },
      onDrag(y) {
        t.selectionDrag(y);
      },
      onStop(y) {
        t.selectionDragStop(y);
      }
    });
    De(() => {
      var y;
      l.value || (y = u.value) == null || y.focus({ preventScroll: !0 });
    });
    const d = me(() => ei(a.value)), c = me(() => ({
      width: `${d.value.width}px`,
      height: `${d.value.height}px`,
      top: `${d.value.y}px`,
      left: `${d.value.x}px`
    }));
    function f(y) {
      t.selectionContextMenu({ event: y, nodes: a.value });
    }
    function h(y) {
      l || Lt[y.key] && r(
        {
          x: Lt[y.key].x,
          y: Lt[y.key].y
        },
        y.shiftKey
      );
    }
    return (y, b) => !x(i) && d.value.width && d.value.height ? (m(), U("div", {
      key: 0,
      class: Pe(["vue-flow__nodesselection vue-flow__container", x(o)]),
      style: pe({ transform: `translate(${x(n).x}px,${x(n).y}px) scale(${x(n).zoom})` })
    }, [
      V("div", {
        ref_key: "el",
        ref: u,
        class: Pe([{ dragging: x(s) }, "vue-flow__nodesselection-rect"]),
        style: pe(c.value),
        tabIndex: x(l) ? void 0 : -1,
        onContextmenu: f,
        onKeydown: h
      }, null, 46, Cm)
    ], 6)) : j("", !0);
  }
});
function Xa(e, t) {
  return {
    x: e.clientX - t.left,
    y: e.clientY - t.top
  };
}
const Nm = {
  name: "Pane",
  compatConfig: { MODE: 3 }
}, Sm = /* @__PURE__ */ ne({
  ...Nm,
  props: {
    isSelecting: { type: Boolean }
  },
  setup(e) {
    const {
      vueFlowRef: t,
      getNodes: n,
      getEdges: a,
      viewport: o,
      emits: l,
      userSelectionActive: i,
      removeSelectedElements: r,
      panOnDrag: u,
      userSelectionRect: s,
      elementsSelectable: d,
      nodesSelectionActive: c,
      addSelectedElements: f,
      getSelectedEdges: h,
      getSelectedNodes: y,
      removeNodes: b,
      removeEdges: p,
      selectionMode: w,
      deleteKeyCode: k,
      multiSelectionKeyCode: E,
      multiSelectionActive: _
    } = $e(), C = ae(null), G = ae(0), B = ae(0), $ = ae(), M = Le(() => d.value && (e.isSelecting || i.value));
    Jt(
      k,
      (F) => {
        if (!F)
          return;
        const W = n.value.reduce((oe, re) => ((!re.selected && re.parentNode && oe.find((ye) => ye.id === re.parentNode) || re.selected) && oe.push(re), oe), []);
        (W || h.value) && (h.value.length > 0 && p(h.value), W.length > 0 && b(W), c.value = !1, r());
      },
      { actInsideInputWithModifier: !1 }
    ), Jt(E, (F) => {
      _.value = F;
    });
    function A() {
      i.value = !1, s.value = null, G.value = 0, B.value = 0;
    }
    function S(F) {
      F.target !== C.value || M.value || (l.paneClick(F), r(), c.value = !1);
    }
    function Z(F) {
      var W;
      if (F.target === C.value) {
        if (Array.isArray(u.value) && ((W = u.value) != null && W.includes(2))) {
          F.preventDefault();
          return;
        }
        l.paneContextMenu(F);
      }
    }
    function z(F) {
      F.target === C.value && l.paneScroll(F);
    }
    function N(F) {
      if ($.value = t.value.getBoundingClientRect(), !M.value || !d || !e.isSelecting || F.button !== 0 || F.target !== C.value || !$.value)
        return;
      const { x: W, y: oe } = Xa(F, $.value);
      r(), s.value = {
        width: 0,
        height: 0,
        startX: W,
        startY: oe,
        x: W,
        y: oe
      }, i.value = !0, l.selectionStart(F);
    }
    function P(F) {
      if (!M.value)
        return l.paneMouseMove(F);
      if (!e.isSelecting || !$.value || !s.value)
        return;
      i.value || (i.value = !0), c.value && (c.value = !1);
      const W = Xa(F, $.value), oe = s.value.startX ?? 0, re = s.value.startY ?? 0, ye = {
        ...s.value,
        x: W.x < oe ? W.x : oe,
        y: W.y < re ? W.y : re,
        width: Math.abs(W.x - oe),
        height: Math.abs(W.y - re)
      }, Q = ti(
        n.value,
        s.value,
        o.value,
        w.value === Ko.Partial
      ), q = Wo(Q, a.value);
      G.value = Q.length, B.value = q.length, s.value = ye, f([...Q, ...q]);
    }
    function T(F) {
      M.value && F.button === 0 && (!i.value && s.value && F.target === C.value && S(F), c.value = G.value > 0, A(), l.selectionEnd(F));
    }
    function g(F) {
      var W;
      if (!M.value)
        return l.paneMouseLeave(F);
      i.value && (c.value = G.value > 0, (W = l.selectionEnd) == null || W.call(l, F)), A();
    }
    function H(F) {
      M.value || l.paneMouseEnter(F);
    }
    return (F, W) => (m(), U("div", {
      ref_key: "container",
      ref: C,
      class: Pe(["vue-flow__pane vue-flow__container", { selection: F.isSelecting }]),
      onClick: S,
      onContextmenu: Z,
      onWheelPassive: z,
      onMouseenter: H,
      onMousedown: N,
      onMousemove: P,
      onMouseup: T,
      onMouseleave: g
    }, [
      te(F.$slots, "default"),
      x(i) && x(s) ? (m(), R(wm, {
        key: 0,
        "user-selection-rect": x(s)
      }, null, 8, ["user-selection-rect"])) : j("", !0),
      x(c) && x(y).length ? (m(), R(km, { key: 1 })) : j("", !0)
    ], 34));
  }
}), xm = {
  name: "Transform",
  compatConfig: { MODE: 3 }
}, $m = /* @__PURE__ */ ne({
  ...xm,
  setup(e) {
    const { viewport: t } = $e();
    return (n, a) => (m(), U("div", {
      class: "vue-flow__transformationpane vue-flow__container",
      style: pe({ transform: `translate(${x(t).x}px,${x(t).y}px) scale(${x(t).zoom})` })
    }, [
      te(n.$slots, "default")
    ], 4));
  }
}), Mm = {
  name: "Viewport",
  compatConfig: { MODE: 3 }
}, Bm = /* @__PURE__ */ ne({
  ...Mm,
  setup(e) {
    const {
      minZoom: t,
      maxZoom: n,
      defaultViewport: a,
      translateExtent: o,
      zoomActivationKeyCode: l,
      selectionKeyCode: i,
      panActivationKeyCode: r,
      panOnScroll: u,
      panOnScrollMode: s,
      panOnScrollSpeed: d,
      panOnDrag: c,
      zoomOnDoubleClick: f,
      zoomOnPinch: h,
      zoomOnScroll: y,
      preventScrolling: b,
      noWheelClassName: p,
      noPanClassName: w,
      emits: k,
      connectionStartHandle: E,
      userSelectionActive: _,
      paneDragging: C,
      d3Zoom: G,
      d3Selection: B,
      d3ZoomHandler: $,
      viewport: M,
      viewportRef: A
    } = $e();
    bm(A);
    const S = ae(!1), Z = ae(!1);
    let z = null, N = !1, P = 0, T = {
      x: 0,
      y: 0,
      zoom: 0
    };
    const g = Jt(r), H = Jt(i), F = Jt(l), W = Le(() => g.value || c.value), oe = Le(() => g.value || u.value), re = Le(() => H.value || i.value === !0 && W.value !== !0);
    De(() => {
      if (!A.value) {
        qn("Viewport element is missing");
        return;
      }
      const ce = A.value, v = ce.getBoundingClientRect(), ge = nh().scaleExtent([t.value, n.value]).translateExtent(o.value), X = Ze(ce).call(ge), we = X.on("wheel.zoom"), Se = Ut.translate(a.value.x ?? 0, a.value.y ?? 0).scale(Mt(a.value.zoom ?? 1, t.value, n.value)), D = [
        [0, 0],
        [v.width, v.height]
      ], O = ge.constrain()(Se, D, o.value);
      ge.transform(X, O), ge.wheelDelta(Q), G.value = ge, B.value = X, $.value = we, M.value = { x: O.x, y: O.y, zoom: O.k }, ge.on("start", (L) => {
        var J;
        if (!L.sourceEvent)
          return null;
        P = L.sourceEvent.button, S.value = !0;
        const ee = Y(L.transform);
        ((J = L.sourceEvent) == null ? void 0 : J.type) === "mousedown" && (C.value = !0), T = ee, k.viewportChangeStart(ee), k.moveStart({ event: L, flowTransform: ee });
      }), ge.on("end", (L) => {
        if (!L.sourceEvent)
          return null;
        if (S.value = !1, C.value = !1, ye(W.value, P ?? 0) && !N && k.paneContextMenu(L.sourceEvent), N = !1, q(T, L.transform)) {
          const J = Y(L.transform);
          T = J, k.viewportChangeEnd(J), k.moveEnd({ event: L, flowTransform: J });
        }
      }), ge.filter((L) => {
        var J, ee;
        const ue = F.value || y.value, de = h.value && L.ctrlKey;
        if ((W.value === !0 || Array.isArray(W.value) && W.value.includes(1)) && L.button === 1 && L.type === "mousedown" && ((J = L.target) != null && J.closest(".vue-flow__node") || (ee = L.target) != null && ee.closest(".vue-flow__edge")))
          return !0;
        if (!W.value && !ue && !oe.value && !f.value && !h.value || _.value || !f.value && L.type === "dblclick" || fe(L, p.value) && L.type === "wheel" || fe(L, w.value) && (L.type !== "wheel" || oe.value && L.type === "wheel" && !F.value) || !h.value && L.ctrlKey && L.type === "wheel" || !ue && !oe.value && !de && L.type === "wheel" || !W.value && (L.type === "mousedown" || L.type === "touchstart") || Array.isArray(W.value) && !W.value.includes(L.button) && (L.type === "mousedown" || L.type === "touchstart"))
          return !1;
        const _e = Array.isArray(W.value) && W.value.includes(L.button) || !L.button || L.button <= 1;
        return (!L.ctrlKey || L.type === "wheel") && _e;
      }), ie(
        [_, W],
        () => {
          _.value && !S.value ? ge.on("zoom", null) : _.value || ge.on("zoom", (L) => {
            M.value = { x: L.transform.x, y: L.transform.y, zoom: L.transform.k };
            const J = Y(L.transform);
            N = ye(W.value, P ?? 0), k.viewportChange(J), k.move({ event: L, flowTransform: J });
          });
        },
        { immediate: !0 }
      ), ie(
        [_, oe, s, F, h, b, p],
        () => {
          oe.value && !F.value && !_.value ? X.on(
            "wheel.zoom",
            (L) => {
              if (fe(L, p.value))
                return !1;
              const J = F.value || y.value, ee = h.value && L.ctrlKey;
              if (!(!b.value || oe.value || J || ee))
                return !1;
              L.preventDefault(), L.stopImmediatePropagation();
              const de = X.property("__zoom").k || 1, _e = In();
              if (L.ctrlKey && h.value && _e) {
                const tt = Xe(L), mi = Q(L), gi = de * 2 ** mi;
                ge.scaleTo(X, gi, tt, L);
                return;
              }
              const Ee = L.deltaMode === 1 ? 20 : 1;
              let Be = s.value === qt.Vertical ? 0 : L.deltaX * Ee, Ve = s.value === qt.Horizontal ? 0 : L.deltaY * Ee;
              !_e && L.shiftKey && s.value !== qt.Vertical && !Be && Ve && (Be = Ve, Ve = 0), ge.translateBy(
                X,
                -(Be / de) * d.value,
                -(Ve / de) * d.value
              );
              const Ie = Y(X.property("__zoom"));
              z && clearTimeout(z), Z.value ? (k.move({ event: L, flowTransform: Ie }), k.viewportChange(Ie), z = setTimeout(() => {
                k.moveEnd({ event: L, flowTransform: Ie }), k.viewportChangeEnd(Ie), Z.value = !1;
              }, 150)) : (Z.value = !0, k.moveStart({ event: L, flowTransform: Ie }), k.viewportChangeStart(Ie));
            },
            { passive: !1 }
          ) : typeof we < "u" && X.on(
            "wheel.zoom",
            function(L, J) {
              const ee = !b.value && L.type === "wheel" && !L.ctrlKey, ue = F.value || y.value, de = h.value && L.ctrlKey;
              if (!ue && !u.value && !de && L.type === "wheel" || ee || fe(L, p.value))
                return null;
              L.preventDefault(), we.call(this, L, J);
            },
            { passive: !1 }
          );
        },
        { immediate: !0 }
      );
    });
    function ye(ce, v) {
      return v === 2 && Array.isArray(ce) && ce.includes(2);
    }
    function Q(ce) {
      const v = ce.ctrlKey && In() ? 10 : 1;
      return -ce.deltaY * (ce.deltaMode === 1 ? 0.05 : ce.deltaMode ? 1 : 2e-3) * v;
    }
    function q(ce, v) {
      return ce.x !== v.x && !Number.isNaN(v.x) || ce.y !== v.y && !Number.isNaN(v.y) || ce.zoom !== v.k && !Number.isNaN(v.k);
    }
    function Y(ce) {
      return {
        x: ce.x,
        y: ce.y,
        zoom: ce.k
      };
    }
    function fe(ce, v) {
      return ce.target.closest(`.${v}`);
    }
    return (ce, v) => (m(), U("div", {
      ref_key: "viewportRef",
      ref: A,
      class: "vue-flow__viewport vue-flow__container"
    }, [
      K(Sm, {
        "is-selecting": re.value,
        class: Pe({ connecting: !!x(E), dragging: x(C), draggable: W.value })
      }, {
        default: I(() => [
          K($m, null, {
            default: I(() => [
              te(ce.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["is-selecting", "class"])
    ], 512));
  }
}), Im = ["id"], Dm = ["id"], Vm = ["id"], Am = {
  name: "A11yDescriptions",
  compatConfig: { MODE: 3 }
}, Fm = /* @__PURE__ */ ne({
  ...Am,
  setup(e) {
    const { id: t, disableKeyboardA11y: n, ariaLiveMessage: a } = $e();
    return (o, l) => (m(), U(ve, null, [
      V("div", {
        id: `${x(zl)}-${x(t)}`,
        style: { display: "none" }
      }, " Press enter or space to select a node. " + se(x(n) ? "" : "You can then use the arrow keys to move the node around.") + " You can then use the arrow keys to move the node around, press delete to remove it and press escape to cancel. ", 9, Im),
      V("div", {
        id: `${x(Rl)}-${x(t)}`,
        style: { display: "none" }
      }, " Press enter or space to select an edge. You can then press delete to remove it or press escape to cancel. ", 8, Dm),
      x(n) ? j("", !0) : (m(), U("div", {
        key: 0,
        id: `${x(ih)}-${x(t)}`,
        "aria-live": "assertive",
        "aria-atomic": "true",
        style: { position: "absolute", width: "1px", height: "1px", margin: "-1px", border: "0", padding: "0", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", "clip-path": "inset(100%)" }
      }, se(x(a)), 9, Vm))
    ], 64));
  }
});
function Tm() {
  const e = $e();
  ie(
    () => e.viewportHelper.value.viewportInitialized,
    (t) => {
      t && setTimeout(() => {
        e.emits.init(e), e.emits.paneReady(e);
      }, 1);
    }
  );
}
function Om(e, t, n) {
  return n === he.Left ? e - t : n === he.Right ? e + t : e;
}
function Pm(e, t, n) {
  return n === he.Top ? e - t : n === he.Bottom ? e + t : e;
}
const ea = function({
  radius: e = 10,
  centerX: t = 0,
  centerY: n = 0,
  position: a = he.Top,
  type: o
}) {
  return Ne("circle", {
    class: `vue-flow__edgeupdater vue-flow__edgeupdater-${o}`,
    cx: Om(t, e, a),
    cy: Pm(n, e, a),
    r: e,
    stroke: "transparent",
    fill: "transparent"
  });
};
ea.props = ["radius", "centerX", "centerY", "position", "type"];
ea.compatConfig = { MODE: 3 };
const Wa = ea, Lm = ne({
  name: "Edge",
  compatConfig: { MODE: 3 },
  props: ["name", "type", "id", "updatable", "selectable", "focusable", "edge"],
  setup(e) {
    const {
      id: t,
      addSelectedEdges: n,
      connectionMode: a,
      edgeUpdaterRadius: o,
      emits: l,
      nodesSelectionActive: i,
      noPanClassName: r,
      getEdgeTypes: u,
      removeSelectedEdges: s,
      findEdge: d,
      findNode: c,
      isValidConnection: f,
      multiSelectionActive: h,
      disableKeyboardA11y: y
    } = $e(), b = dh(e.edge, l), p = jt(e, "edge"), w = ae(!1), k = ae(!1), E = ae(""), _ = ae(null), C = ae("source"), G = ae(null);
    rt(rh, e.id), rt(sh, G);
    const B = me(() => p.value.class instanceof Function ? p.value.class(p.value) : p.value.class), $ = me(() => p.value.style instanceof Function ? p.value.style(p.value) : p.value.style), { handlePointerDown: M } = Yl({
      nodeId: E,
      handleId: _,
      type: C,
      isValidConnection: f,
      edgeUpdaterType: C,
      onEdgeUpdate: Z,
      onEdgeUpdateEnd: z
    });
    return () => {
      const Q = c(p.value.source), q = c(p.value.target), Y = "pathOptions" in p.value ? p.value.pathOptions : {};
      if (!Q && !q)
        return l.error(new Oe(Ae.EDGE_SOURCE_TARGET_MISSING, p.value.id, p.value.source, p.value.target)), null;
      if (!Q)
        return l.error(new Oe(Ae.EDGE_SOURCE_MISSING, p.value.id, p.value.source)), null;
      if (!q)
        return l.error(new Oe(Ae.EDGE_TARGET_MISSING, p.value.id, p.value.target)), null;
      if (!p || Q.hidden || q.hidden)
        return null;
      let fe;
      a.value === $t.Strict ? fe = Q.handleBounds.source : fe = [...Q.handleBounds.source || [], ...Q.handleBounds.target || []];
      const ce = Oa(fe, p.value.sourceHandle);
      let v;
      a.value === $t.Strict ? v = q.handleBounds.target : v = [...q.handleBounds.target || [], ...q.handleBounds.source || []];
      const ge = Oa(v, p.value.targetHandle), X = ce ? ce.position : he.Bottom, we = ge ? ge.position : he.Top, { sourceX: Se, sourceY: D, targetY: O, targetX: L } = Ih(
        Q,
        ce,
        X,
        q,
        ge,
        we
      );
      return p.value.sourceX = Se, p.value.sourceY = D, p.value.targetX = L, p.value.targetY = O, Ne(
        "g",
        {
          ref: G,
          key: e.id,
          "data-id": e.id,
          class: [
            "vue-flow__edge",
            `vue-flow__edge-${e.type === !1 ? "default" : e.name}`,
            r.value,
            B.value,
            {
              updating: w.value,
              selected: p.value.selected,
              animated: p.value.animated,
              inactive: !e.selectable
            }
          ],
          onClick: P,
          onContextmenu: T,
          onDblclick: g,
          onMouseenter: H,
          onMousemove: F,
          onMouseleave: W,
          onKeyDown: e.focusable ? ye : void 0,
          tabIndex: e.focusable ? 0 : void 0,
          "aria-label": p.value.ariaLabel === null ? void 0 : p.value.ariaLabel || `Edge from ${p.value.source} to ${p.value.target}`,
          "aria-describedby": e.focusable ? `${Rl}-${t}` : void 0,
          role: e.focusable ? "button" : "img"
        },
        [
          k.value ? null : Ne(e.type === !1 ? u.value.default : e.type, {
            id: e.id,
            sourceNode: Q,
            targetNode: q,
            source: p.value.source,
            target: p.value.target,
            type: p.value.type,
            updatable: e.updatable,
            selected: p.value.selected,
            animated: p.value.animated,
            label: p.value.label,
            labelStyle: p.value.labelStyle,
            labelShowBg: p.value.labelShowBg,
            labelBgStyle: p.value.labelBgStyle,
            labelBgPadding: p.value.labelBgPadding,
            labelBgBorderRadius: p.value.labelBgBorderRadius,
            data: p.value.data,
            events: { ...p.value.events, ...b.on },
            style: $.value,
            markerStart: `url('#${bt(p.value.markerStart, t)}')`,
            markerEnd: `url('#${bt(p.value.markerEnd, t)}')`,
            sourcePosition: X,
            targetPosition: we,
            sourceX: Se,
            sourceY: D,
            targetX: L,
            targetY: O,
            sourceHandleId: p.value.sourceHandle,
            targetHandleId: p.value.targetHandle,
            interactionWidth: p.value.interactionWidth,
            ...Y
          }),
          [
            e.updatable === "source" || e.updatable === !0 ? [
              Ne(
                "g",
                {
                  onMousedown: oe,
                  onMouseenter: A,
                  onMouseout: S
                },
                Ne(Wa, {
                  position: X,
                  centerX: Se,
                  centerY: D,
                  radius: o.value,
                  type: "source",
                  "data-type": "source"
                })
              )
            ] : null,
            e.updatable === "target" || e.updatable === !0 ? [
              Ne(
                "g",
                {
                  onMousedown: re,
                  onMouseenter: A,
                  onMouseout: S
                },
                Ne(Wa, {
                  position: we,
                  centerX: L,
                  centerY: O,
                  radius: o.value,
                  type: "target",
                  "data-type": "target"
                })
              )
            ] : null
          ]
        ]
      );
    };
    function A() {
      w.value = !0;
    }
    function S() {
      w.value = !1;
    }
    function Z(Q, q) {
      b.emit.update({ event: Q, edge: p.value, connection: q });
    }
    function z(Q) {
      b.emit.updateEnd({ event: Q, edge: p.value }), k.value = !1;
    }
    function N(Q, q) {
      Q.button === 0 && (k.value = !0, E.value = q ? p.value.target : p.value.source, _.value = (q ? p.value.targetHandle : p.value.sourceHandle) ?? "", C.value = q ? "target" : "source", b.emit.updateStart({ event: Q, edge: p.value }), M(Q));
    }
    function P(Q) {
      var q;
      const Y = { event: Q, edge: p.value };
      e.selectable && (i.value = !1, p.value.selected && h.value ? (s([p.value]), (q = G.value) == null || q.blur()) : n([p.value])), b.emit.click(Y);
    }
    function T(Q) {
      b.emit.contextMenu({ event: Q, edge: p.value });
    }
    function g(Q) {
      b.emit.doubleClick({ event: Q, edge: p.value });
    }
    function H(Q) {
      b.emit.mouseEnter({ event: Q, edge: p.value });
    }
    function F(Q) {
      b.emit.mouseMove({ event: Q, edge: p.value });
    }
    function W(Q) {
      b.emit.mouseLeave({ event: Q, edge: p.value });
    }
    function oe(Q) {
      N(Q, !0);
    }
    function re(Q) {
      N(Q, !1);
    }
    function ye(Q) {
      var q;
      !y.value && Hl.includes(Q.key) && e.selectable && (Q.key === "Escape" ? ((q = G.value) == null || q.blur(), s([d(e.id)])) : n([d(e.id)]));
    }
  }
}), zm = Lm, Rm = {
  [he.Left]: he.Right,
  [he.Right]: he.Left,
  [he.Top]: he.Bottom,
  [he.Bottom]: he.Top
}, Hm = ne({
  name: "ConnectionLine",
  compatConfig: { MODE: 3 },
  setup() {
    var e;
    const {
      id: t,
      connectionMode: n,
      connectionStartHandle: a,
      connectionEndHandle: o,
      connectionPosition: l,
      connectionLineType: i,
      connectionLineStyle: r,
      connectionLineOptions: u,
      connectionStatus: s,
      viewport: d,
      findNode: c
    } = $e(), f = (e = Qe(Wn)) == null ? void 0 : e["connection-line"];
    return () => {
      var h, y, b, p, w;
      if (!a.value)
        return null;
      const k = c(a.value.nodeId);
      if (!k)
        return null;
      const E = a.value.handleId, _ = a.value.type, C = ((h = o.value) == null ? void 0 : h.handleId) && c(o.value.nodeId) || null, G = (l.value.x - d.value.x) / d.value.zoom, B = (l.value.y - d.value.y) / d.value.zoom, $ = k.handleBounds;
      let M = $ == null ? void 0 : $[_];
      if (n.value === $t.Loose && (M = M || ($ == null ? void 0 : $[_ === "source" ? "target" : "source"])), !M)
        return null;
      const A = (E ? M.find((F) => F.id === E) : M[0]) ?? null, S = (A == null ? void 0 : A.position) || he.Top, { x: Z, y: z } = xo(
        S,
        { ...k.dimensions, ...k.computedPosition },
        A
      ), N = C && ((y = o.value) == null ? void 0 : y.handleId) && ((n.value === $t.Strict ? (b = C.handleBounds[_ === "source" ? "target" : "source"]) == null ? void 0 : b.find(
        (F) => {
          var W;
          return F.id === ((W = o.value) == null ? void 0 : W.handleId);
        }
      ) : (p = [...C.handleBounds.source || [], ...C.handleBounds.target || []]) == null ? void 0 : p.find(
        (F) => {
          var W;
          return F.id === ((W = o.value) == null ? void 0 : W.handleId);
        }
      )) || ((w = C.handleBounds[_ ?? "target"]) == null ? void 0 : w[0])) || null, P = S ? Rm[S] : null;
      if (!S || !P)
        return null;
      const T = i.value ?? u.value.type ?? gt.Bezier;
      let g = "";
      const H = {
        sourceX: Z,
        sourceY: z,
        sourcePosition: S,
        targetX: G,
        targetY: B,
        targetPosition: P
      };
      return T === gt.Bezier ? [g] = di(H) : T === gt.Step ? [g] = Mo({
        ...H,
        borderRadius: 0
      }) : T === gt.SmoothStep ? [g] = Mo(H) : T === gt.SimpleBezier ? [g] = ci(H) : g = `M${Z},${z} ${G},${B}`, Ne(
        "svg",
        { class: "vue-flow__edges vue-flow__connectionline vue-flow__container" },
        Ne(
          "g",
          { class: "vue-flow__connection" },
          f ? Ne(f, {
            sourceX: Z,
            sourceY: z,
            sourcePosition: S,
            targetX: G,
            targetY: B,
            targetPosition: P,
            sourceNode: k,
            sourceHandle: A,
            targetNode: C,
            targetHandle: N,
            markerEnd: `url(#${bt(u.value.markerEnd, t)})`,
            markerStart: `url(#${bt(u.value.markerStart, t)})`,
            connectionStatus: s.value
          }) : Ne("path", {
            d: g,
            class: [u.value.class, s, "vue-flow__connection-path"],
            style: {
              ...r.value,
              ...u.value.style
            },
            "marker-end": `url(#${bt(u.value.markerEnd, t)})`,
            "marker-start": `url(#${bt(u.value.markerStart, t)})`
          })
        )
      );
    };
  }
}), Um = Hm, Gm = ["id", "markerWidth", "markerHeight", "markerUnits", "orient"], Zm = {
  name: "MarkerType",
  compatConfig: { MODE: 3 }
}, Ym = /* @__PURE__ */ ne({
  ...Zm,
  props: {
    id: {},
    type: {},
    color: { default: "none" },
    width: { default: 12.5 },
    height: { default: 12.5 },
    markerUnits: { default: "strokeWidth" },
    orient: { default: "auto-start-reverse" },
    strokeWidth: { default: 1 }
  },
  setup(e) {
    return (t, n) => (m(), U("marker", {
      id: t.id,
      class: "vue-flow__arrowhead",
      viewBox: "-10 -10 20 20",
      refX: "0",
      refY: "0",
      markerWidth: `${t.width}`,
      markerHeight: `${t.height}`,
      markerUnits: t.markerUnits,
      orient: t.orient
    }, [
      t.type === x(He).ArrowClosed ? (m(), U("polyline", {
        key: 0,
        style: pe({
          stroke: t.color,
          fill: t.color,
          strokeWidth: t.strokeWidth
        }),
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        points: "-5,-4 0,0 -5,4 -5,-4"
      }, null, 4)) : j("", !0),
      t.type === x(He).Arrow ? (m(), U("polyline", {
        key: 1,
        style: pe({
          stroke: t.color,
          strokeWidth: t.strokeWidth
        }),
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        fill: "none",
        points: "-5,-4 0,0 -5,4"
      }, null, 4)) : j("", !0)
    ], 8, Gm));
  }
}), Km = {
  name: "MarkerDefinitions",
  compatConfig: { MODE: 3 }
}, Xm = /* @__PURE__ */ ne({
  ...Km,
  setup(e) {
    const { id: t, edges: n, connectionLineOptions: a, defaultMarkerColor: o } = $e(), l = me(() => {
      const i = [], r = [], u = (s) => {
        if (s) {
          const d = bt(s, t);
          i.includes(d) || (typeof s == "object" ? r.push({ ...s, id: d, color: s.color || o.value }) : r.push({ id: d, color: o.value, type: s }), i.push(d));
        }
      };
      for (const s of [a.value.markerEnd, a.value.markerStart])
        u(s);
      return n.value.reduce((s, d) => {
        for (const c of [d.markerStart, d.markerEnd])
          u(c);
        return s.sort((c, f) => c.id.localeCompare(f.id));
      }, r), r;
    });
    return (i, r) => (m(), U("defs", null, [
      (m(!0), U(ve, null, be(l.value, (u) => (m(), R(Ym, {
        id: u.id,
        key: u.id,
        type: u.type,
        color: u.color,
        width: u.width,
        height: u.height,
        markerUnits: u.markerUnits,
        "stroke-width": u.strokeWidth,
        orient: u.orient
      }, null, 8, ["id", "type", "color", "width", "height", "markerUnits", "stroke-width", "orient"]))), 128))
    ]));
  }
}), Wm = { class: "vue-flow__edges vue-flow__container" }, jm = {
  name: "Edges",
  compatConfig: { MODE: 3 }
}, qm = /* @__PURE__ */ ne({
  ...jm,
  setup(e) {
    const t = Qe(Wn), {
      edgesUpdatable: n,
      edgesFocusable: a,
      elementsSelectable: o,
      findNode: l,
      getEdges: i,
      getEdgeTypes: r,
      elevateEdgesOnSelect: u,
      dimensions: s,
      emits: d
    } = $e(), c = Un();
    function f(p) {
      return typeof p > "u" ? o.value : p;
    }
    function h(p) {
      return typeof p > "u" ? n.value : p;
    }
    function y(p) {
      return typeof p > "u" ? a.value : p;
    }
    function b(p, w) {
      const k = p || "default", E = t == null ? void 0 : t[`edge-${k}`];
      if (E)
        return E;
      let _ = w ?? r.value[k];
      if (typeof _ == "string" && c) {
        const C = Object.keys(c.appContext.components);
        C && C.includes(k) && (_ = ft(k, !1));
      }
      return _ && typeof _ != "string" ? _ : (d.error(new Oe(Ae.EDGE_TYPE_MISSING, _)), !1);
    }
    return (p, w) => x(s).width && x(s).height ? (m(), U(ve, { key: 0 }, [
      (m(), U("svg", Wm, [
        K(Xm)
      ])),
      (m(!0), U(ve, null, be(x(i), (k) => (m(), U("svg", {
        key: k.id,
        class: "vue-flow__edges vue-flow__container",
        style: pe({ zIndex: x(Vh)(k, x(l), x(u)) })
      }, [
        K(x(zm), {
          id: k.id,
          edge: k,
          type: b(k.type, k.template),
          name: k.type || "default",
          selectable: f(k.selectable),
          updatable: h(k.updatable),
          focusable: y(k.focusable)
        }, null, 8, ["id", "edge", "type", "name", "selectable", "updatable", "focusable"])
      ], 4))), 128)),
      K(x(Um))
    ], 64)) : j("", !0);
  }
}), Jm = ne({
  name: "Node",
  compatConfig: { MODE: 3 },
  props: ["name", "type", "id", "draggable", "selectable", "focusable", "connectable", "node", "resizeObserver"],
  setup(e) {
    rt(Gl, e.id);
    const {
      id: t,
      noPanClassName: n,
      selectNodesOnDrag: a,
      nodesSelectionActive: o,
      multiSelectionActive: l,
      emits: i,
      findNode: r,
      removeSelectedNodes: u,
      addSelectedNodes: s,
      updateNodeDimensions: d,
      onUpdateNodeInternals: c,
      getIntersectingNodes: f,
      getNodeTypes: h,
      nodeExtent: y,
      elevateNodesOnSelect: b,
      disableKeyboardA11y: p,
      ariaLiveMessage: w,
      snapToGrid: k,
      snapGrid: E,
      nodeDragThreshold: _,
      getConnectedEdges: C
    } = $e(), G = Kl(), B = jt(e, "node"), $ = me(() => {
      var Y;
      return r((Y = B.value) == null ? void 0 : Y.parentNode);
    }), M = me(() => C(B.value ? [B.value] : [])), A = ae(null);
    rt(Zl, A);
    const { emit: S, on: Z } = mh(B.value, i), z = Ul({
      id: e.id,
      el: A,
      disabled: () => !e.draggable,
      selectable: () => e.selectable,
      dragHandle: () => B.value.dragHandle,
      onStart(Y) {
        S.dragStart({ ...Y, intersections: f(B.value) });
      },
      onDrag(Y) {
        S.drag({ ...Y, intersections: f(B.value) });
      },
      onStop(Y) {
        S.dragStop({ ...Y, intersections: f(B.value) });
      }
    }), N = me(() => B.value.class instanceof Function ? B.value.class(B.value) : B.value.class), P = me(() => {
      const Y = (B.value.style instanceof Function ? B.value.style(B.value) : B.value.style) || {}, fe = B.value.width instanceof Function ? B.value.width(B.value) : B.value.width, ce = B.value.height instanceof Function ? B.value.height(B.value) : B.value.height;
      return fe && (Y.width = typeof fe == "string" ? fe : `${fe}px`), ce && (Y.height = typeof ce == "string" ? ce : `${ce}px`), Y;
    }), T = Le(() => Number(B.value.zIndex ?? P.value.zIndex ?? 0));
    return c((Y) => {
      Y.includes(e.id) && H();
    }), De(() => {
      e.resizeObserver.observe(A.value);
    }), Lo(() => {
      e.resizeObserver.unobserve(A.value);
    }), ie([() => B.value.type, () => B.value.sourcePosition, () => B.value.targetPosition], () => {
      je(() => {
        d([{ id: e.id, nodeElement: A.value, forceUpdate: !0 }]);
      });
    }), ie(
      [
        () => B.value.position.x,
        () => B.value.position.y,
        () => {
          var Y;
          return (Y = $.value) == null ? void 0 : Y.computedPosition.x;
        },
        () => {
          var Y;
          return (Y = $.value) == null ? void 0 : Y.computedPosition.y;
        },
        () => {
          var Y;
          return (Y = $.value) == null ? void 0 : Y.computedPosition.z;
        },
        T,
        () => B.value.selected,
        () => B.value.dimensions.height,
        () => B.value.dimensions.width,
        () => {
          var Y;
          return (Y = $.value) == null ? void 0 : Y.dimensions.height;
        },
        () => {
          var Y;
          return (Y = $.value) == null ? void 0 : Y.dimensions.width;
        }
      ],
      ([Y, fe, ce, v, ge, X]) => {
        const we = {
          x: Y,
          y: fe,
          z: X + (b.value && B.value.selected ? 1e3 : 0)
        };
        typeof ce < "u" && typeof v < "u" ? B.value.computedPosition = Sh({ x: ce, y: v, z: ge }, we) : B.value.computedPosition = we;
      },
      { flush: "post", immediate: !0 }
    ), ie([() => B.value.extent, y], ([Y, fe], [ce, v]) => {
      (Y !== ce || fe !== v) && g();
    }), B.value.extent === "parent" || typeof B.value.extent == "object" && "range" in B.value.extent && B.value.extent.range === "parent" ? kn(() => B.value.initialized).toBe(!0).then(g) : g(), () => Ne(
      "div",
      {
        ref: A,
        "data-id": B.value.id,
        class: [
          "vue-flow__node",
          `vue-flow__node-${e.type === !1 ? "default" : e.name}`,
          {
            [n.value]: e.draggable,
            dragging: z == null ? void 0 : z.value,
            draggable: e.draggable,
            selected: B.value.selected,
            selectable: e.selectable,
            parent: B.value.isParent
          },
          N.value
        ],
        style: {
          visibility: B.value.initialized ? "visible" : "hidden",
          zIndex: B.value.computedPosition.z ?? T.value,
          transform: `translate(${B.value.computedPosition.x}px,${B.value.computedPosition.y}px)`,
          pointerEvents: e.selectable || e.draggable ? "all" : "none",
          ...P.value
        },
        tabIndex: e.focusable ? 0 : void 0,
        role: e.focusable ? "button" : void 0,
        "aria-describedby": p.value ? void 0 : `${zl}-${t}`,
        "aria-label": B.value.ariaLabel,
        onMouseenter: F,
        onMousemove: W,
        onMouseleave: oe,
        onContextmenu: re,
        onClick: Q,
        onDblclick: ye,
        onKeydown: q
      },
      [
        Ne(e.type === !1 ? h.value.default : e.type, {
          id: B.value.id,
          type: B.value.type,
          data: B.value.data,
          events: { ...B.value.events, ...Z },
          selected: B.value.selected,
          resizing: B.value.resizing,
          dragging: z.value,
          connectable: e.connectable,
          position: B.value.computedPosition,
          dimensions: B.value.dimensions,
          isValidTargetPos: B.value.isValidTargetPos,
          isValidSourcePos: B.value.isValidSourcePos,
          parent: B.value.parentNode,
          parentNodeId: B.value.parentNode,
          zIndex: B.value.computedPosition.z ?? T.value,
          targetPosition: B.value.targetPosition,
          sourcePosition: B.value.sourcePosition,
          label: B.value.label,
          dragHandle: B.value.dragHandle,
          onUpdateNodeInternals: H
        })
      ]
    );
    function g() {
      const Y = B.value.computedPosition;
      k.value && (Y.x = E.value[0] * Math.round(Y.x / E.value[0]), Y.y = E.value[1] * Math.round(Y.y / E.value[1]));
      const { computedPosition: fe, position: ce } = jo(
        B.value,
        Y,
        i.error,
        y.value,
        $.value
      );
      (B.value.computedPosition.x !== fe.x || B.value.computedPosition.y !== fe.y) && (B.value.computedPosition = { ...B.value.computedPosition, ...fe }), (B.value.position.x !== ce.x || B.value.position.y !== ce.y) && (B.value.position = ce);
    }
    function H() {
      A.value && d([{ id: e.id, nodeElement: A.value, forceUpdate: !0 }]);
    }
    function F(Y) {
      z != null && z.value || S.mouseEnter({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function W(Y) {
      z != null && z.value || S.mouseMove({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function oe(Y) {
      z != null && z.value || S.mouseLeave({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function re(Y) {
      return S.contextMenu({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function ye(Y) {
      return S.doubleClick({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function Q(Y) {
      e.selectable && (!a.value || !e.draggable || _.value > 0) && $o(
        B.value,
        l.value,
        s,
        u,
        o,
        !1,
        A.value
      ), S.click({ event: Y, node: B.value, connectedEdges: M.value });
    }
    function q(Y) {
      if (!(ko(Y) || p.value))
        if (Hl.includes(Y.key) && e.selectable) {
          const fe = Y.key === "Escape";
          $o(
            B.value,
            l.value,
            s,
            u,
            o,
            fe,
            A.value
          );
        } else
          e.draggable && B.value.selected && Lt[Y.key] && (w.value = `Moved selected node ${Y.key.replace("Arrow", "").toLowerCase()}. New position, x: ${~~B.value.position.x}, y: ${~~B.value.position.y}`, G(
            {
              x: Lt[Y.key].x,
              y: Lt[Y.key].y
            },
            Y.shiftKey
          ));
    }
  }
}), Qm = Jm;
function eg(e = { includeHiddenNodes: !1 }) {
  const { nodes: t } = $e();
  return me(() => {
    if (t.value.length === 0)
      return !1;
    for (const n of t.value)
      if ((e.includeHiddenNodes || !n.hidden) && ((n == null ? void 0 : n.handleBounds) === void 0 || n.dimensions.width === 0 || n.dimensions.height === 0))
        return !1;
    return !0;
  });
}
const tg = { class: "vue-flow__nodes vue-flow__container" }, ng = {
  name: "Nodes",
  compatConfig: { MODE: 3 }
}, og = /* @__PURE__ */ ne({
  ...ng,
  setup(e) {
    const {
      nodes: t,
      nodesDraggable: n,
      nodesFocusable: a,
      elementsSelectable: o,
      nodesConnectable: l,
      getNodes: i,
      getNodeTypes: r,
      updateNodeDimensions: u,
      emits: s
    } = $e(), d = eg(), c = Qe(Wn), f = ae(), h = Un();
    ie(
      d,
      (E) => {
        E && je(() => {
          s.nodesInitialized(t.value);
        });
      },
      { immediate: !0 }
    ), De(() => {
      f.value = new ResizeObserver((E) => {
        const _ = E.map((C) => ({
          id: C.target.getAttribute("data-id"),
          nodeElement: C.target,
          forceUpdate: !0
        }));
        je(() => u(_));
      });
    }), Lo(() => {
      var E;
      return (E = f.value) == null ? void 0 : E.disconnect();
    });
    function y(E) {
      return typeof E > "u" ? n.value : E;
    }
    function b(E) {
      return typeof E > "u" ? o.value : E;
    }
    function p(E) {
      return typeof E > "u" ? l.value : E;
    }
    function w(E) {
      return typeof E > "u" ? a.value : E;
    }
    function k(E, _) {
      const C = E || "default", G = c == null ? void 0 : c[`node-${C}`];
      if (G)
        return G;
      let B = _ ?? r.value[C];
      if (typeof B == "string" && h) {
        const $ = Object.keys(h.appContext.components);
        $ && $.includes(C) && (B = ft(C, !1));
      }
      return B && typeof B != "string" ? B : (s.error(new Oe(Ae.NODE_TYPE_MISSING, B)), !1);
    }
    return (E, _) => (m(), U("div", tg, [
      f.value ? (m(!0), U(ve, { key: 0 }, be(x(i), (C) => (m(), R(x(Qm), {
        id: C.id,
        key: C.id,
        "resize-observer": f.value,
        type: k(C.type, C.template),
        name: C.type || "default",
        draggable: y(C.draggable),
        selectable: b(C.selectable),
        connectable: p(C.connectable),
        focusable: w(C.focusable),
        node: C
      }, null, 8, ["id", "resize-observer", "type", "name", "draggable", "selectable", "connectable", "focusable", "node"]))), 128)) : j("", !0)
    ]));
  }
});
function ag() {
  const { emits: e } = $e();
  De(() => {
    if (ii()) {
      const t = document.querySelector(".vue-flow__pane");
      t && window.getComputedStyle(t).zIndex !== "1" && e.error(new Oe(Ae.MISSING_STYLES));
    }
  });
}
const lg = /* @__PURE__ */ V("div", { class: "vue-flow__edge-labels" }, null, -1), ig = {
  name: "VueFlow",
  compatConfig: { MODE: 3 }
}, fi = /* @__PURE__ */ ne({
  ...ig,
  props: {
    id: {},
    modelValue: {},
    nodes: {},
    edges: {},
    edgeTypes: {},
    nodeTypes: {},
    connectionMode: {},
    connectionLineType: {},
    connectionLineStyle: { default: void 0 },
    connectionLineOptions: { default: void 0 },
    connectionRadius: {},
    isValidConnection: { type: [Function, null], default: void 0 },
    deleteKeyCode: { default: void 0 },
    selectionKeyCode: { default: void 0 },
    multiSelectionKeyCode: { default: void 0 },
    zoomActivationKeyCode: { default: void 0 },
    panActivationKeyCode: { default: void 0 },
    snapToGrid: { type: Boolean, default: void 0 },
    snapGrid: {},
    onlyRenderVisibleElements: { type: Boolean, default: void 0 },
    edgesUpdatable: { type: [Boolean, String], default: void 0 },
    nodesDraggable: { type: Boolean, default: void 0 },
    nodesConnectable: { type: Boolean, default: void 0 },
    nodeDragThreshold: {},
    elementsSelectable: { type: Boolean, default: void 0 },
    selectNodesOnDrag: { type: Boolean, default: void 0 },
    panOnDrag: { type: [Boolean, Array], default: void 0 },
    minZoom: {},
    maxZoom: {},
    defaultViewport: {},
    translateExtent: {},
    nodeExtent: {},
    defaultMarkerColor: {},
    zoomOnScroll: { type: Boolean, default: void 0 },
    zoomOnPinch: { type: Boolean, default: void 0 },
    panOnScroll: { type: Boolean, default: void 0 },
    panOnScrollSpeed: {},
    panOnScrollMode: {},
    zoomOnDoubleClick: { type: Boolean, default: void 0 },
    preventScrolling: { type: Boolean, default: void 0 },
    selectionMode: {},
    edgeUpdaterRadius: {},
    fitViewOnInit: { type: Boolean, default: void 0 },
    connectOnClick: { type: Boolean, default: void 0 },
    applyDefault: { type: Boolean, default: void 0 },
    autoConnect: { type: [Boolean, Function], default: void 0 },
    noDragClassName: {},
    noWheelClassName: {},
    noPanClassName: {},
    defaultEdgeOptions: {},
    elevateEdgesOnSelect: { type: Boolean, default: void 0 },
    elevateNodesOnSelect: { type: Boolean, default: void 0 },
    disableKeyboardA11y: { type: Boolean, default: void 0 },
    edgesFocusable: { type: Boolean, default: void 0 },
    nodesFocusable: { type: Boolean, default: void 0 },
    autoPanOnConnect: { type: Boolean, default: void 0 },
    autoPanOnNodeDrag: { type: Boolean, default: void 0 }
  },
  emits: ["nodesChange", "edgesChange", "nodesInitialized", "miniMapNodeClick", "miniMapNodeDoubleClick", "miniMapNodeMouseEnter", "miniMapNodeMouseMove", "miniMapNodeMouseLeave", "connect", "connectStart", "connectEnd", "clickConnectStart", "clickConnectEnd", "moveStart", "move", "moveEnd", "selectionDragStart", "selectionDrag", "selectionDragStop", "selectionContextMenu", "selectionStart", "selectionEnd", "viewportChangeStart", "viewportChange", "viewportChangeEnd", "paneReady", "init", "paneScroll", "paneClick", "paneContextMenu", "paneMouseEnter", "paneMouseMove", "paneMouseLeave", "updateNodeInternals", "error", "edgeContextMenu", "edgeMouseEnter", "edgeMouseMove", "edgeMouseLeave", "edgeDoubleClick", "edgeClick", "edgeUpdateStart", "edgeUpdate", "edgeUpdateEnd", "nodeDoubleClick", "nodeClick", "nodeMouseEnter", "nodeMouseMove", "nodeMouseLeave", "nodeContextMenu", "nodeDragStart", "nodeDrag", "nodeDragStop", "update:modelValue", "update:nodes", "update:edges"],
  setup(e, { expose: t, emit: n }) {
    const a = e, o = Je(), l = jt(a, "modelValue", n), i = jt(a, "nodes", n), r = jt(a, "edges", n), { vueFlowRef: u, hooks: s, getNodeTypes: d, getEdgeTypes: c, ...f } = $e(a), h = yh({ modelValue: l, nodes: i, edges: r }, a, {
      vueFlowRef: u,
      hooks: s,
      getNodeTypes: d,
      getEdgeTypes: c,
      ...f
    });
    return Rh(n, s), Tm(), ag(), rt(Wn, o), Vt(() => {
      h();
    }), t({
      vueFlowRef: u,
      hooks: s,
      getNodeTypes: d,
      getEdgeTypes: c,
      ...f
    }), (y, b) => (m(), U("div", {
      ref_key: "vueFlowRef",
      ref: u,
      class: "vue-flow"
    }, [
      K(Bm, null, {
        default: I(() => [
          K(qm),
          lg,
          K(og),
          te(y.$slots, "zoom-pane")
        ]),
        _: 3
      }),
      te(y.$slots, "default"),
      K(Fm)
    ], 512));
  }
}), fv = ne({
  __name: "MyNextFlow",
  setup(e) {
    const t = ae([
      // nodes
      // an input node, specified by using `type: 'input'`
      { id: "1", type: "input", label: "Node 1", position: { x: 250, y: 5 } },
      // default node, you can omit `type: 'default'` as it's the fallback type
      { id: "2", label: "Node 2", position: { x: 100, y: 100 } },
      // An output node, specified by using `type: 'output'`
      { id: "3", type: "output", label: "Node 3", position: { x: 400, y: 200 } },
      // A custom node, specified by using a custom type name
      // we choose `type: 'special'` for this example
      {
        id: "4",
        type: "special",
        label: "Node 4",
        position: { x: 400, y: 200 },
        // pass custom data to the node
        data: {
          // you can pass any data you want to the node
          hello: "world"
        }
      },
      // edges
      // simple default bezier edge
      // consists of an id, source-id and target-id
      { id: "e1-3", source: "1", target: "3" },
      // an animated edge, specified by using `animated: true`
      { id: "e1-2", source: "1", target: "2", animated: !0 },
      // a custom edge, specified by using a custom type name
      // we choose `type: 'special'` for this example
      {
        id: "e1-4",
        type: "special",
        source: "1",
        target: "4",
        // pass custom data to the edge
        data: {
          // You can pass any data you want to the edge       
          hello: "world"
        }
      }
    ]);
    return (n, a) => {
      const o = ft("SpecialNode"), l = ft("SpecialEdge");
      return m(), R(x(fi), {
        modelValue: t.value,
        "onUpdate:modelValue": a[0] || (a[0] = (i) => t.value = i)
      }, {
        "node-special": I((i) => [
          K(o, St(zt(i)), null, 16)
        ]),
        "edge-special": I((i) => [
          K(l, St(zt(i)), null, 16)
        ]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
});
var We = /* @__PURE__ */ ((e) => (e.Lines = "lines", e.Dots = "dots", e))(We || {});
const pi = function({ dimensions: e, size: t, color: n }) {
  return Ne("path", {
    stroke: n,
    "stroke-width": t,
    d: `M${e[0] / 2} 0 V${e[1]} M0 ${e[1] / 2} H${e[0]}`
  });
}, hi = function({ radius: e, color: t }) {
  return Ne("circle", { cx: e, cy: e, r: e, fill: t });
};
We.Lines + "", We.Dots + "";
const rg = {
  [We.Dots]: "#81818a",
  [We.Lines]: "#eee"
}, sg = ["id", "x", "y", "width", "height", "patternTransform"], ug = {
  key: 2,
  height: "100",
  width: "100"
}, dg = ["fill"], cg = ["x", "y", "fill"], fg = {
  name: "Background",
  compatConfig: { MODE: 3 }
}, pg = /* @__PURE__ */ ne({
  ...fg,
  props: {
    id: {},
    variant: { default: () => We.Dots },
    gap: { default: 20 },
    size: { default: 1 },
    lineWidth: { default: 1 },
    patternColor: {},
    color: {},
    bgColor: {},
    height: { default: 100 },
    width: { default: 100 },
    x: { default: 0 },
    y: { default: 0 },
    offset: { default: 2 }
  },
  setup(e) {
    const { id: t, viewport: n } = $e(), a = me(() => {
      const [i, r] = Array.isArray(e.gap) ? e.gap : [e.gap, e.gap], u = [i * n.value.zoom || 1, r * n.value.zoom || 1], s = e.size * n.value.zoom, d = e.variant === We.Dots ? [s / e.offset, s / e.offset] : [u[0] / e.offset, u[1] / e.offset];
      return {
        scaledGap: u,
        offset: d,
        size: s
      };
    }), o = Le(() => `pattern-${t}${e.id ? `-${e.id}` : ""}`), l = Le(() => e.color || e.patternColor || rg[e.variant || We.Dots]);
    return (i, r) => (m(), U("svg", {
      class: "vue-flow__background vue-flow__container",
      style: pe({
        height: `${i.height > 100 ? 100 : i.height}%`,
        width: `${i.width > 100 ? 100 : i.width}%`
      })
    }, [
      te(i.$slots, "pattern-container", { id: o.value }, () => [
        V("pattern", {
          id: o.value,
          x: x(n).x % a.value.scaledGap[0],
          y: x(n).y % a.value.scaledGap[1],
          width: a.value.scaledGap[0],
          height: a.value.scaledGap[1],
          patternTransform: `translate(-${a.value.offset[0]},-${a.value.offset[1]})`,
          patternUnits: "userSpaceOnUse"
        }, [
          te(i.$slots, "pattern", {}, () => [
            i.variant === x(We).Lines ? (m(), R(x(pi), {
              key: 0,
              size: i.lineWidth,
              color: l.value,
              dimensions: a.value.scaledGap
            }, null, 8, ["size", "color", "dimensions"])) : i.variant === x(We).Dots ? (m(), R(x(hi), {
              key: 1,
              color: l.value,
              radius: a.value.size / i.offset
            }, null, 8, ["color", "radius"])) : j("", !0),
            i.bgColor ? (m(), U("svg", ug, [
              V("rect", {
                width: "100%",
                height: "100%",
                fill: i.bgColor
              }, null, 8, dg)
            ])) : j("", !0)
          ])
        ], 8, sg)
      ]),
      V("rect", {
        x: i.x,
        y: i.y,
        width: "100%",
        height: "100%",
        fill: `url(#${o.value})`
      }, null, 8, cg),
      te(i.$slots, "default", { id: o.value })
    ], 4));
  }
}), hg = { class: "myNode_box" }, mg = { class: "myNode_top" }, gg = { class: "myNode_title" }, vg = {
  key: 0,
  class: "myNode_subtitle"
}, yg = ["onClick"], bg = {
  key: 0,
  class: "myNode_content_left"
}, _g = {
  key: 1,
  class: "myNode_content_content"
}, wg = {
  key: 2,
  class: "myNode_content_content"
}, Cg = /* @__PURE__ */ V("div", { class: "myNode_content_right" }, [
  /* @__PURE__ */ V("div", null, " > ")
], -1), Eg = {
  key: 0,
  style: { position: "relative" }
}, kg = {
  style: {
    pointerEvents: "all",
    position: "absolute",
    top: "16px",
    left: "88px"
  },
  class: "nodrag nopan"
}, Ng = {
  key: 1,
  style: { position: "relative" }
}, Sg = {
  style: {
    pointerEvents: "all",
    position: "absolute",
    top: "48px",
    left: "56px"
  },
  class: "nodrag nopan"
}, xg = { style: { color: "#3D7EFF", "font-size": "10px" } }, $g = {
  key: 2,
  style: { position: "relative" }
}, Mg = {
  style: {
    pointerEvents: "all",
    position: "absolute",
    bottom: "100px",
    left: "88px"
  },
  class: "nodrag nopan"
}, Bg = ["d"], pv = ne({
  __name: "MyApprovalFlow",
  props: {
    nodes: {},
    edges: {},
    nodeClick: {},
    nodeDoubleClick: {},
    edgeClick: {},
    edgeDoubleClick: {},
    zoom: { default: 0.8 },
    maxZoom: { default: 6 },
    minZoom: { default: 0.1 }
  },
  emits: ["nodesChange"],
  setup(e, { emit: t }) {
    const { addNodes: n, removeEdges: a, addEdges: o, findNode: l, removeNodes: i } = $e(), r = e, u = t;
    let { nodes: s, edges: d } = Ge(r);
    const c = ae();
    ie(() => {
      var $;
      return ($ = c.value) == null ? void 0 : $.nodes.length;
    }, () => {
      u("nodesChange", {
        nodes: c.value.nodes,
        edges: c.value.edges
      });
    }, { deep: !1, immediate: !1 });
    const f = ($, M = !1) => h($) || $.data.branchId !== void 0 || M ? [
      { content: "审批节点", value: "APPROVE_NODE" },
      { content: "办理节点", value: "HANDLE_NODE" },
      { content: "条件分支", value: "CONDITIONAL_NODE", disabled: !0 }
    ] : [
      { content: "审批节点", value: "APPROVE_NODE" },
      { content: "办理节点", value: "HANDLE_NODE" },
      { content: "条件分支", value: "CONDITIONAL_NODE" }
    ], h = ($) => c.value.getConnectedEdges($.id).filter((S) => S.source == $.id).length > 1 && !$.data.branchId, y = ($) => c.value.getConnectedEdges($.id).filter((S) => S.target == $.id).length > 1 && !$.data.branchId, b = ($, M, A) => {
      let S = !1;
      for (let Z = 0; Z < M.length; Z++)
        if (M[Z].data.branchId == $.data.branchId)
          for (let z = 0; z < A.length; z++)
            A[z].source === M[Z].id && A[z].targetY - A[z].sourceY > 120 && (S = !0);
      return S;
    }, p = ($) => {
      const { sourceX: M, sourceY: A, targetX: S, targetY: Z } = $, z = S - M;
      return z == 0 ? `M${M},${A} L${S},${Z}` : `M${M},${A} L${M},${Z - 65} Q${M},${Z - 60} ${z > 0 ? M + 5 : M - 5},${Z - 60}
    L${z > 0 ? S - 5 : S + 5},${Z - 60} Q${S},${Z - 60} ${S},${Z - 55} L${S},${Z}`;
    }, w = ($, M) => {
      const A = c.value.nodes, S = c.value.edges, Z = c.value.getConnectedEdges(M.id);
      if ($.value === "APPROVE_NODE" || $.value === "HANDLE_NODE") {
        let z = !1;
        const N = b(M, A, S);
        for (let P = 0; P < Z.length; P++)
          Z[P].source == M.id && M.data.branchId && Z[P].targetY - Z[P].sourceY > 120 && (z = !0);
        if (!z)
          for (let P = 0; P < A.length; P++)
            A[P].position.y > M.position.y && (A[P].position.y += 150, M.data.branchId && A[P].data.branchId && A[P].data.groupId === M.data.groupId && A[P].data.branchId !== M.data.branchId && (A[P].position.y -= 150), N && A[P].data.groupId !== M.data.groupId && (A[P].position.y -= 150));
        k($.value, M, Z);
      }
      if ($.value === "CONDITIONAL_NODE") {
        let z = null;
        for (let N = 0; N < Z.length; N++)
          Z[N].source == M.id && (z = Z[N].target, a(Z[N].id));
        for (let N = 0; N < A.length; N++)
          A[N].position.y > M.position.y && (A[N].position.y += 400);
        _(M, z);
      }
    }, k = ($, M, A) => {
      const S = String(Date.now());
      let Z = {};
      M.data.branchId ? Z = {
        id: S,
        type: "approval",
        label: `${$ == "APPROVE_NODE" ? "审批" : "办理"}节点`,
        style: {
          background: `${$ == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          borderRadius: "10px"
        },
        position: {
          x: M.position.x,
          y: M.position.y + 150
        },
        draggable: !1,
        data: {
          name: `请添加${$ == "APPROVE_NODE" ? "审批" : "办理"}人`,
          bgcolor: `${$ == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          person: "",
          role: "",
          kind: $,
          groupId: M.data.groupId,
          branchId: M.data.branchId
        }
      } : Z = {
        id: S,
        type: "approval",
        label: `${$ == "APPROVE_NODE" ? "审批" : "办理"}节点`,
        style: {
          background: `${$ == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          borderRadius: "10px"
        },
        position: {
          x: M.position.x,
          y: M.position.y + 150
        },
        draggable: !1,
        data: {
          name: `请添加${$ == "APPROVE_NODE" ? "审批" : "办理"}人`,
          bgcolor: `${$ == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          person: "",
          role: "",
          kind: $
        }
      }, n([Z]);
      for (let N = 0; N < A.length; N++)
        A[N].source == M.id && (A[N].source = S);
      const z = {
        id: String(Date.now() + 2),
        type: "smoothstep",
        source: M.id,
        target: S,
        markerEnd: He.ArrowClosed
      };
      o([z]);
    }, E = ($, M) => {
      const A = c.value.nodes;
      c.value.edges;
      const S = c.value.getConnectedEdges(M.id);
      for (let P = 0; P < A.length; P++)
        A[P].position.y > M.position.y && (A[P].position.y += 150), A[P].id == M.id && (A[P].position.y += 150);
      const Z = String(Date.now());
      let z = {};
      M.data.branchId ? z = {
        id: Z,
        type: "approval",
        label: `${$.value == "APPROVE_NODE" ? "审批" : "办理"}节点`,
        style: {
          background: `${$.value == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          borderRadius: "10px"
        },
        position: {
          x: M.position.x,
          y: M.position.y
        },
        draggable: !1,
        data: {
          name: `请添加${$.value == "APPROVE_NODE" ? "审批" : "办理"}人`,
          bgcolor: `${$.value == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          person: "",
          role: "",
          kind: $.value
        }
      } : z = {
        id: Z,
        type: "approval",
        label: `${$.value == "APPROVE_NODE" ? "审批" : "办理"}节点`,
        style: {
          background: `${$.value == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          borderRadius: "10px"
        },
        position: {
          x: M.position.x,
          y: M.position.y
        },
        draggable: !1,
        data: {
          name: `请添加${$.value == "APPROVE_NODE" ? "审批" : "办理"}人`,
          bgcolor: `${$.value == "APPROVE_NODE" ? "#F2902B" : "#8C5EF0"}`,
          person: "",
          role: "",
          kind: $.value
        }
      }, n([z]);
      for (let P = 0; P < S.length; P++)
        S[P].target == M.id && (S[P].target = Z);
      const N = {
        id: String(Date.now() + 1),
        type: "smoothstep",
        source: Z,
        target: M.id,
        markerEnd: He.ArrowClosed
      };
      o([N]);
    }, _ = ($, M) => {
      const A = Date.now(), S = [
        {
          id: String(A + 3),
          type: "approval",
          label: "默认条件",
          style: {
            background: "#C1C9E5",
            borderRadius: "10px"
          },
          position: {
            x: $.position.x + 200,
            y: $.position.y + 200
          },
          draggable: !1,
          data: {
            name: "未满足其他条件分支时，将转入默认流程",
            subtitle: "优先级2",
            priority: 1,
            bgcolor: "#C1C9E5",
            person: "",
            role: "",
            kind: "CONDITIONAL_NODE",
            groupId: String(A),
            branchId: String(A + 1)
          }
        },
        {
          id: String(A + 4),
          type: "approval",
          label: "审批节点",
          style: {
            background: "#F2902B",
            borderRadius: "10px"
          },
          position: {
            x: $.position.x + 200,
            y: $.position.y + 350
          },
          draggable: !1,
          data: {
            name: "请添加审批人",
            bgcolor: "#F2902B",
            person: "",
            role: "",
            kind: "APPROVE_NODE",
            groupId: String(A),
            branchId: String(A + 1)
          }
        },
        {
          id: String(A + 5),
          type: "approval",
          label: "条件分支1",
          style: {
            background: "#C1C9E5",
            borderRadius: "10px"
          },
          position: {
            x: $.position.x - 200,
            y: $.position.y + 200
          },
          draggable: !1,
          data: {
            name: "请配置条件",
            subtitle: "优先级1",
            priority: 2,
            bgcolor: "#C1C9E5",
            person: "",
            role: "",
            kind: "CONDITIONAL_NODE",
            groupId: String(A),
            branchId: String(A + 2)
          }
        }
      ];
      n(S);
      const Z = [
        {
          id: String(A + 6),
          type: "smoothstep",
          source: $.id,
          target: String(A + 3),
          markerEnd: He.ArrowClosed
        },
        {
          id: String(A + 7),
          type: "smoothstep",
          source: String(A + 3),
          target: String(A + 4),
          markerEnd: He.ArrowClosed
        },
        {
          id: String(A + 8),
          type: "smoothstep",
          source: String(A + 4),
          target: M,
          markerEnd: He.ArrowClosed
        },
        {
          id: String(A + 9),
          type: "smoothstep",
          source: $.id,
          target: String(A + 5),
          markerEnd: He.ArrowClosed
        },
        {
          id: String(A + 10),
          type: "smoothstep",
          source: String(A + 5),
          target: M,
          markerEnd: He.ArrowClosed
        }
      ];
      o(Z);
    }, C = ($) => {
      const M = c.value.nodes, A = c.value.getConnectedEdges($.id);
      let S = [], Z = null;
      for (let g = 0; g < A.length; g++)
        if (A[g].source == $.id) {
          const H = l(A[g].target);
          H && S.push({
            nodeId: H.id,
            priority: H.data.priority,
            branchId: H.data.branchId,
            groupId: H.data.groupId
          });
        }
      let z = 0;
      for (let g = 0; g < M.length; g++) {
        if (M[g].data.groupId && M[g].data.groupId === S[0].groupId)
          for (let H = 0; H < S.length; H++)
            M[g].data.branchId === S[H].branchId && (S[H].priority === 1 ? (M[g].position.x += 200, M[g].data.priority === 1 && (M[g].data.subtitle = `优先级${S.length + 1}`)) : M[g].position.x -= 200);
        !M[g].data.groupId && M[g].position.y > $.position.y && (z ? M[g].position.y - $.position.y < z && (Z = M[g].id) : (z = M[g].position.y - $.position.y, Z = M[g].id));
      }
      const N = Date.now(), P = {
        id: String(N),
        type: "approval",
        label: `条件分支${S.length}`,
        style: {
          background: "#C1C9E5",
          borderRadius: "10px"
        },
        position: {
          x: $.position.x + (S.length - 2) * 200,
          y: $.position.y + 200
        },
        draggable: !1,
        data: {
          name: "请配置条件",
          subtitle: `优先级${S.length}`,
          priority: S.length + 1,
          bgcolor: "#C1C9E5",
          person: "",
          role: "",
          kind: "CONDITIONAL_NODE",
          groupId: S[0].groupId,
          branchId: String(N + 1)
        }
      };
      n([P]);
      const T = [
        {
          id: String(N + 2),
          type: "smoothstep",
          source: $.id,
          target: String(N),
          markerEnd: He.ArrowClosed
        },
        {
          id: String(N + 3),
          type: "smoothstep",
          source: String(N),
          target: Z,
          markerEnd: He.ArrowClosed
        }
      ];
      o(T);
    }, G = ($, M) => {
      let A = {};
      for (let S = 0; S < M.length; S++)
        M[S].data.groupId == $.data.groupId && (A[M[S].data.branchId] ? A[M[S].data.branchId].push(M[S].id) : A[M[S].data.branchId] = [M[S].id]);
      return A;
    }, B = ($) => {
      const M = c.value.nodes, A = c.value.getConnectedEdges($.id);
      if ($.data.groupId) {
        const S = G($, M);
        if ($.data.priority)
          if (Object.keys(S).length == 2 || $.data.priority == 1) {
            let Z = 0;
            for (let H = 0; H < Object.keys(S).length; H++) {
              const F = Object.keys(S)[H];
              S[F].length > Z && (Z = S[F].length);
              for (let W = 0; W < S[F].length; W++) {
                const oe = c.value.getConnectedEdges(S[F][W]);
                for (let re = 0; re < oe.length; re++)
                  a(oe[re]);
                i(S[F][W]);
              }
            }
            const z = Z * 80 + (Z - 1) * 70 + 170;
            let N = null;
            for (let H = 0; H < A.length; H++)
              A[H].target == $.id && (N = A[H].source);
            const P = l(N);
            let T = null, g = 0;
            for (let H = 0; H < M.length; H++)
              !M[H].data.groupId && M[H].position.y > $.position.y && (g ? M[H].position.y - $.position.y < g && (T = M[H]) : (g = M[H].position.y - $.position.y, T = M[H])), M[H].position.y > P.position.y && (M[H].position.y -= z);
            o([{
              id: String(Date.now()),
              type: "smoothstep",
              source: P.id,
              target: T.id,
              markerEnd: He.ArrowClosed
            }]);
          } else {
            const Z = $.data.branchId, z = $.data.groupId, N = $.data.priority;
            let P = null;
            for (let q = 0; q < A.length; q++)
              A[q].target == $.id && (P = A[q].source);
            const T = l(P);
            let g = null, H = 0;
            for (let q = 0; q < M.length; q++)
              !M[q].data.groupId && M[q].position.y > $.position.y && (H ? M[q].position.y - $.position.y < H && (g = M[q]) : (H = M[q].position.y - $.position.y, g = M[q]));
            const F = g.position.y - (T == null ? void 0 : T.position.y), W = S[Z];
            for (let q = 0; q < W.length; q++) {
              const Y = c.value.getConnectedEdges(W[q]);
              for (let fe = 0; fe < Y.length; fe++)
                a(Y[fe]);
              i(W[q]), delete S[Z];
            }
            const oe = Object.keys(S).sort((q, Y) => Number(q) - Number(Y));
            let re = 0;
            for (let q = 0; q < oe.length; q++) {
              if (q == 0)
                for (let Y = 0; Y < S[oe[q]].length; Y++) {
                  const fe = l(S[oe[q]][Y]);
                  fe.position.x -= 200;
                }
              else if (Number(oe[q]) > Number(Z))
                for (let Y = 0; Y < S[oe[q]].length; Y++) {
                  const fe = l(S[oe[q]][Y]);
                  fe.position.x -= 200;
                }
              else
                for (let Y = 0; Y < S[oe[q]].length; Y++) {
                  const fe = l(S[oe[q]][Y]);
                  fe.position.x += 200;
                }
              S[oe[q]].length > re && (re = S[oe[q]].length);
            }
            const ye = re * 80 + (re - 1) * 70 + 240, Q = F - 80 - ye;
            for (let q = 0; q < M.length; q++)
              Q > 0 && M[q].position.y >= g.position.y && (M[q].position.y -= Q), M[q].data.groupId == z && M[q].data.priority && (M[q].data.priority != 1 ? M[q].data.priority > N && (M[q].data.priority -= 1, M[q].data.subtitle = `优先级${M[q].data.priority - 1}`) : M[q].data.subtitle = `优先级${Object.keys(S).length}`);
          }
        else {
          const Z = $.data.branchId, z = $.data.groupId, N = S[Z];
          for (let H = 0; H < M.length; H++)
            M[H].position.y > $.position.y && N.includes(M[H].id) && (M[H].position.y -= 150);
          let P = 0, T = 0;
          for (let H in S)
            S[H].length > P ? (P = S[H].length, T = 1) : S[H].length === P && T++;
          if (N.length == P && T == 1)
            for (let H = 0; H < M.length; H++)
              M[H].position.y > $.position.y && M[H].data.groupId != z && (M[H].position.y -= 150);
          const g = A.filter((H) => H.source == $.id);
          for (let H = 0; H < A.length; H++)
            A[H].target == $.id ? A[H].target = g[0].target : a(A[H].id);
          i($.id);
        }
      } else {
        for (let S = 0; S < M.length; S++)
          M[S].position.y > $.position.y && (M[S].position.y -= 150);
        if (h($)) {
          const S = A.filter((Z) => Z.target == $.id);
          for (let Z = 0; Z < A.length; Z++)
            A[Z].source == $.id && (A[Z].source = S[0].source);
          a(S[0].id);
        } else {
          const S = A.filter((Z) => Z.source == $.id);
          for (let Z = 0; Z < A.length; Z++)
            A[Z].target == $.id && (A[Z].target = S[0].target);
          a(S[0].id);
        }
        i($.id);
      }
    };
    return ($, M) => {
      const A = Fe, S = ut, Z = Po;
      return m(), R(x(fi), {
        ref_key: "form",
        ref: c,
        nodes: x(s),
        edges: x(d),
        "fit-view-on-init": "",
        onNodeDoubleClick: r.nodeDoubleClick,
        onEdgeClick: r.edgeClick,
        onEdgeDoubleClick: r.edgeDoubleClick,
        "default-viewport": { zoom: r.zoom },
        "max-zoom": r.maxZoom,
        "min-zoom": r.minZoom
      }, {
        "node-approval": I((z) => {
          var N, P, T, g;
          return [
            V("div", hg, [
              V("div", mg, [
                V("span", gg, se(z.label), 1),
                (N = z.data) != null && N.subtitle ? (m(), U("span", vg, se(z.data.subtitle), 1)) : j("", !0),
                !["START_NODE", "ENDING_NODE"].includes(z.data.kind) && !(h(z) && y(z)) ? (m(), R(S, {
                  key: 1,
                  style: pe({ "background-color": z.data.bgcolor }),
                  class: "myNode-delete",
                  size: "small",
                  shape: "rectangle",
                  variant: "outline",
                  onClick: (H) => B(z)
                }, {
                  default: I(() => [
                    K(A, {
                      name: "multiply",
                      size: "20",
                      color: "#FFFFFF"
                    })
                  ]),
                  _: 2
                }, 1032, ["style", "onClick"])) : j("", !0)
              ]),
              V("div", {
                class: "myNode_content",
                onClick: (H) => r.nodeClick(z)
              }, [
                (P = z.data) != null && P.person ? (m(), U("div", bg, se(z.data.person + " :"), 1)) : j("", !0),
                (T = z.data) != null && T.role ? (m(), U("div", _g, se(z.data.role), 1)) : (m(), U("div", wg, se((g = z.data) == null ? void 0 : g.name), 1)),
                Cg
              ], 8, yg)
            ]),
            z.data.kind !== "ENDING_NODE" ? (m(), U("div", Eg, [
              V("div", kg, [
                K(Z, {
                  options: f(z),
                  onClick: (H) => w(H, z)
                }, {
                  default: I(() => [
                    K(S, {
                      style: { "background-color": "#FFFFFF" },
                      size: "small",
                      theme: "default",
                      variant: "base",
                      shape: "circle"
                    }, {
                      default: I(() => [
                        K(A, {
                          color: "#3D7EFF",
                          name: "add",
                          size: "16"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["options", "onClick"])
              ])
            ])) : j("", !0),
            h(z) ? (m(), U("div", Ng, [
              V("div", Sg, [
                K(S, {
                  onClick: (H) => C(z),
                  style: { "background-color": "#FFFFFF" },
                  size: "small",
                  variant: "text",
                  shape: "squar"
                }, {
                  default: I(() => [
                    V("span", xg, [
                      K(A, {
                        color: "#3D7EFF",
                        name: "add",
                        size: "11"
                      }),
                      Ce("添加条件分支")
                    ])
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ])) : j("", !0),
            y(z) ? (m(), U("div", $g, [
              V("div", Mg, [
                K(Z, {
                  options: f(z, !0),
                  onClick: (H) => E(H, z)
                }, {
                  default: I(() => [
                    K(S, {
                      style: { "background-color": "#FFFFFF" },
                      size: "small",
                      theme: "default",
                      variant: "base",
                      shape: "circle"
                    }, {
                      default: I(() => [
                        K(A, {
                          color: "#3D7EFF",
                          name: "add",
                          size: "16"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["options", "onClick"])
              ])
            ])) : j("", !0)
          ];
        }),
        "edge-smoothstep": I((z) => [
          z.targetY - z.sourceY > 120 ? (m(), U("path", {
            key: 0,
            class: "vue-flow__edge-path",
            d: p(z)
          }, null, 8, Bg)) : (m(), R(x(Qo), St(Dt({ key: 1 }, z)), null, 16))
        ]),
        default: I(() => [
          K(x(pg), { bgColor: "#F0F0F2" })
        ]),
        _: 1
      }, 8, ["nodes", "edges", "onNodeDoubleClick", "onEdgeClick", "onEdgeDoubleClick", "default-viewport", "max-zoom", "min-zoom"]);
    };
  }
}), hv = ne({
  __name: "MyLink",
  props: {
    theme: { default: "primary" },
    disabled: { type: Boolean, default: !1 },
    hover: { default: "underline" },
    size: { default: "medium" }
  },
  setup(e) {
    return (t, n) => {
      const a = Fi;
      return m(), R(a, {
        theme: t.theme,
        disabled: t.disabled,
        hover: t.hover,
        size: t.size
      }, {
        default: I(() => [
          te(t.$slots, "default")
        ]),
        _: 3
      }, 8, ["theme", "disabled", "hover", "size"]);
    };
  }
}), mv = ne({
  __name: "MyTag",
  props: {
    theme: { default: "primary" },
    variant: { default: "dark" },
    size: { default: "medium" }
  },
  setup(e) {
    return (t, n) => {
      const a = Ti;
      return m(), R(a, {
        theme: t.theme,
        variant: t.variant,
        size: t.size
      }, {
        default: I(() => [
          te(t.$slots, "default")
        ]),
        _: 3
      }, 8, ["theme", "variant", "size"]);
    };
  }
}), gv = ne({
  __name: "MyInputNumber",
  props: {
    value: { default: 0 },
    placeholder: { default: "请输入" },
    disabled: { type: Boolean, default: !1 },
    defaultValue: {},
    theme: { default: "column" },
    min: {},
    max: {},
    size: { default: "medium" },
    decimalPlaces: {}
  },
  emits: ["update:value", "change", "blur", "validate"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(""), l = ae("default");
    Je();
    const i = ae(a.value);
    ie(
      () => a.value,
      (d) => {
        i.value = d;
      }
    );
    const r = (d) => {
      d === void 0 && (l.value = "default", o.value = ""), n("update:value", d), n("change", { value: d });
    }, u = (d) => {
      let c = d;
      if (a.max !== void 0 && c > a.max ? c = a.max : a.min !== void 0 && c < a.min && (c = a.min), c !== void 0 && typeof a.decimalPlaces == "number") {
        c = c.toString();
        const f = c.indexOf(".");
        f > -1 && (c = c.slice(0, f + a.decimalPlaces + 1)), typeof d == "number" && (c = Number(c));
      }
      i.value = c, n("update:value", c), n("blur", { value: c });
    }, s = (d) => {
      const { error: c } = d;
      if (c === "exceed-maximum")
        o.value = `超出最大值${a.max}`;
      else if (c === "below-minimum")
        o.value = `小于最小值${a.min}`;
      else {
        let f = i.value;
        if (f !== void 0 && typeof a.decimalPlaces == "number") {
          f = f.toString();
          const h = f.indexOf(".");
          h > -1 && f.length - h - 1 > a.decimalPlaces ? (l.value = "error", o.value = `超出最大小数位数${a.decimalPlaces}位`) : (l.value = "default", o.value = "");
        } else
          l.value = "default", o.value = "";
      }
      n("validate", c);
    };
    return (d, c) => {
      const f = To;
      return m(), R(f, {
        modelValue: i.value,
        "onUpdate:modelValue": c[0] || (c[0] = (h) => i.value = h),
        placeholder: d.placeholder,
        disabled: d.disabled,
        defaultValue: d.defaultValue,
        theme: d.theme,
        min: d.min,
        max: d.max,
        size: d.size,
        clearable: "",
        onChange: r,
        onBlur: u,
        onValidate: s,
        tips: o.value,
        status: l.value
      }, {
        suffix: I(() => [
          te(d.$slots, "suffix")
        ]),
        _: 3
      }, 8, ["modelValue", "placeholder", "disabled", "defaultValue", "theme", "min", "max", "size", "tips", "status"]);
    };
  }
}), vv = ne({
  __name: "MyPopup",
  props: {
    content: { default: "" },
    text: { default: "触发元素" },
    disabled: { type: Boolean, default: !1 },
    placement: { default: "top" }
  },
  setup(e) {
    return Je(), (t, n) => {
      const a = Oi;
      return m(), R(a, {
        content: t.content,
        placement: t.placement,
        disabled: t.disabled
      }, Te({
        default: I(() => [
          t.$slots.default && t.$slots.default().length ? te(t.$slots, "default", { key: 0 }) : (m(), R(zo, { key: 1 }, {
            default: I(() => [
              Ce(se(t.text), 1)
            ]),
            _: 1
          }))
        ]),
        _: 2
      }, [
        t.content ? void 0 : {
          name: "content",
          fn: I(() => [
            te(t.$slots, "content")
          ]),
          key: "0"
        }
      ]), 1032, ["content", "placement", "disabled"]);
    };
  }
}), yv = ne({
  __name: "MyDropdown",
  props: {
    disabled: { type: Boolean, default: !1 },
    trigger: { default: "hover" },
    options: {},
    placement: { default: "bottom-left" },
    text: { default: "下拉菜单" }
  },
  setup(e) {
    return (t, n) => {
      const a = Po;
      return m(), R(a, {
        options: t.options,
        disabled: t.disabled,
        trigger: t.trigger,
        placement: t.placement
      }, {
        default: I(() => [
          t.$slots.default && t.$slots.default().length ? te(t.$slots, "default", { key: 0 }) : (m(), R(zo, { key: 1 }, {
            default: I(() => [
              Ce(se(t.text), 1)
            ]),
            _: 1
          }))
        ]),
        _: 3
      }, 8, ["options", "disabled", "trigger", "placement"]);
    };
  }
}), Ig = ne({
  __name: "MyRadio",
  props: {
    value: { type: [String, Number, Boolean] },
    disabled: { type: Boolean, default: !1 },
    checked: { type: Boolean, default: !1 },
    allowUncheck: { type: Boolean, default: !1 }
  },
  emits: ["update:checked", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.checked);
    ie(
      () => a.checked,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:checked", i), n("change", { checked: i });
    };
    return (i, r) => {
      const u = Fn;
      return m(), R(u, {
        checked: o.value,
        "onUpdate:checked": r[0] || (r[0] = (s) => o.value = s),
        value: i.value,
        disabled: i.disabled,
        allowUncheck: i.allowUncheck,
        onChange: l
      }, {
        default: I(() => [
          te(i.$slots, "default")
        ]),
        _: 3
      }, 8, ["checked", "value", "disabled", "allowUncheck"]);
    };
  }
}), bv = ne({
  __name: "MyRadioGroup",
  props: {
    disabled: { type: Boolean, default: !1 },
    value: {},
    defaultValue: { default: "" },
    variant: { default: "outline" },
    options: { default: [] },
    theme: { default: "button" },
    allowUncheck: { type: Boolean, default: !1 }
  },
  emits: ["update:value", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value || a.defaultValue);
    ie(
      () => a.value,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:value", i), n("change", { value: i });
    };
    return (i, r) => {
      const u = Ao, s = Tn;
      return i.theme == "default" ? (m(), R(u, {
        key: 0,
        modelValue: o.value,
        "onUpdate:modelValue": r[0] || (r[0] = (d) => o.value = d),
        "allow-uncheck": i.allowUncheck,
        "default-value": i.defaultValue,
        variant: i.variant,
        disabled: i.disabled,
        onChange: l
      }, {
        default: I(() => [
          (m(!0), U(ve, null, be(i.options, (d, c) => (m(), R(Ig, {
            key: c,
            checked: d.disabled,
            value: d.value
          }, {
            default: I(() => [
              Ce(se(d.label), 1)
            ]),
            _: 2
          }, 1032, ["checked", "value"]))), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "allow-uncheck", "default-value", "variant", "disabled"])) : (m(), R(u, {
        key: 1,
        modelValue: o.value,
        "onUpdate:modelValue": r[1] || (r[1] = (d) => o.value = d),
        "allow-uncheck": i.allowUncheck,
        "default-value": i.defaultValue,
        variant: i.variant,
        disabled: i.disabled,
        onChange: l
      }, {
        default: I(() => [
          (m(!0), U(ve, null, be(i.options, (d) => (m(), R(s, {
            key: d.value,
            value: d.value,
            disabled: i.disabled
          }, {
            default: I(() => [
              Ce(se(d.label), 1)
            ]),
            _: 2
          }, 1032, ["value", "disabled"]))), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "allow-uncheck", "default-value", "variant", "disabled"]));
    };
  }
}), _v = ne({
  __name: "MyCheckbox",
  props: {
    checked: { type: Boolean, default: !1 },
    value: { type: [String, Number, Boolean] },
    disabled: { type: Boolean, default: !1 }
  },
  emits: ["update:checked", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.checked);
    ie(
      () => a.checked,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:checked", i), n("change", { checked: i });
    };
    return (i, r) => {
      const u = pt;
      return m(), R(u, {
        checked: o.value,
        "onUpdate:checked": r[0] || (r[0] = (s) => o.value = s),
        value: i.value,
        disabled: i.disabled,
        onChange: l
      }, {
        default: I(() => [
          te(i.$slots, "default")
        ]),
        _: 3
      }, 8, ["checked", "value", "disabled"]);
    };
  }
}), wv = ne({
  __name: "MyCheckboxGroup",
  props: {
    value: { default: [] },
    options: { default: [] },
    disabled: { type: Boolean, default: !0 }
  },
  emits: ["update:value", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value);
    ie(
      () => a.value,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:value", i), n("change", { value: i });
    };
    return (i, r) => {
      const u = pt, s = Fo;
      return m(), R(s, {
        modelValue: o.value,
        "onUpdate:modelValue": r[0] || (r[0] = (d) => o.value = d),
        onChange: l,
        disabled: !0
      }, {
        default: I(() => [
          (m(!0), U(ve, null, be(i.options, (d, c) => (m(), R(u, {
            key: c,
            label: d.label,
            value: d.value,
            disabled: d.disabled
          }, null, 8, ["label", "value", "disabled"]))), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
}), Cv = ne({
  __name: "MyDrawer",
  props: {
    showAttachedElement: { type: Boolean },
    showOverlay: { type: Boolean, default: !0 },
    visible: { type: Boolean, default: !1 },
    size: { default: "600px" },
    confirmBtn: { default: "确定" },
    cancelBtn: { default: "取消" },
    closeBtn: { type: [String, Boolean], default: !0 },
    header: {},
    footer: { type: Boolean, default: !0 }
  },
  emits: ["update:visible", "cancel", "close", "confirm"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    Je();
    const o = ae(a.visible);
    ie(
      () => a.visible,
      (u) => {
        o.value = u;
      }
    );
    const l = () => {
      n("update:visible", !1), n("close");
    }, i = () => {
      n("confirm");
    }, r = () => {
      n("update:visible", !1), n("cancel");
    };
    return (u, s) => {
      const d = Pi;
      return m(), R(d, {
        visible: o.value,
        "onUpdate:visible": s[0] || (s[0] = (c) => o.value = c),
        showOverlay: u.showOverlay,
        "close-btn": u.closeBtn,
        onClose: l,
        onConfirm: i,
        onCancel: r,
        size: u.size,
        confirmBtn: u.confirmBtn,
        cancelBtn: u.cancelBtn,
        header: u.header,
        footer: u.footer,
        "show-in-attached-element": u.showAttachedElement
      }, Te({
        default: I(() => [
          te(u.$slots, "default")
        ]),
        _: 2
      }, [
        u.$slots.header ? {
          name: "header",
          fn: I(() => [
            te(u.$slots, "header")
          ]),
          key: "0"
        } : void 0,
        u.$slots.footer ? {
          name: "footer",
          fn: I(() => [
            te(u.$slots, "footer")
          ]),
          key: "1"
        } : void 0
      ]), 1032, ["visible", "showOverlay", "close-btn", "size", "confirmBtn", "cancelBtn", "header", "footer", "show-in-attached-element"]);
    };
  }
}), Ev = ne({
  __name: "MyDatePicker",
  props: {
    value: { default: "" },
    mode: { default: "date" },
    placeholder: { default: "请选择" },
    disabled: { type: Boolean, default: !1 },
    disableDate: {},
    size: { default: "medium" },
    tips: {},
    status: { default: "default" },
    enableTimePicker: { type: Boolean, default: !1 },
    format: {},
    clearable: { type: Boolean, default: !1 },
    allowInput: { type: Boolean, default: !1 }
  },
  emits: ["update:value", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value);
    ie(
      () => a.value,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:value", i), n("change", { value: i });
    };
    return (i, r) => {
      const u = Pn;
      return m(), R(u, {
        modelValue: o.value,
        "onUpdate:modelValue": r[0] || (r[0] = (s) => o.value = s),
        mode: i.mode,
        placeholder: i.placeholder,
        disabled: i.disabled,
        disableDate: i.disableDate,
        size: i.size,
        tips: i.tips,
        status: i.status,
        enableTimePicker: i.enableTimePicker,
        format: i.format,
        clearable: i.clearable,
        allowInput: i.allowInput,
        onChange: l
      }, null, 8, ["modelValue", "mode", "placeholder", "disabled", "disableDate", "size", "tips", "status", "enableTimePicker", "format", "clearable", "allowInput"]);
    };
  }
}), kv = ne({
  __name: "MySwitch",
  props: {
    size: { default: "large" },
    defaultValue: { type: [String, Number, Boolean] },
    value: { type: [String, Number, Boolean] },
    label: {},
    disabled: { type: Boolean, default: !1 }
  },
  emits: ["update:value", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e, o = ae(a.value);
    ie(
      () => a.value,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:value", i), n("change", { value: i });
    };
    return (i, r) => {
      const u = On;
      return m(), R(u, {
        modelValue: o.value,
        "onUpdate:modelValue": r[0] || (r[0] = (s) => o.value = s),
        size: i.size,
        defaultValue: i.defaultValue,
        label: i.label,
        disabled: i.disabled,
        onChane: l
      }, null, 8, ["modelValue", "size", "defaultValue", "label", "disabled"]);
    };
  }
}), Dg = { key: 1 }, Nv = ne({
  __name: "MySelect",
  props: {
    value: { type: [String, Number, Boolean], default: "" },
    placeholder: { default: "请选择" },
    clearable: { type: Boolean, default: !1 },
    options: { default: [] },
    disabled: { type: Boolean, default: !1 },
    keys: {},
    loading: { type: Boolean, default: !1 },
    filterable: { type: Boolean, default: !0 }
  },
  emits: ["update:value", "change"],
  setup(e, { emit: t }) {
    const n = t, a = e;
    Je();
    const o = ae(a.value);
    ie(
      () => a.value,
      (i) => {
        o.value = i;
      }
    );
    const l = (i) => {
      n("update:value", i), n("change", { value: i });
    };
    return (i, r) => {
      const u = Ln, s = an;
      return m(), R(s, {
        modelValue: o.value,
        "onUpdate:modelValue": r[0] || (r[0] = (d) => o.value = d),
        placeholder: i.placeholder,
        clearable: i.clearable,
        disabled: i.disabled,
        keys: i.keys,
        loading: i.loading,
        filterable: i.filterable,
        onChange: l
      }, {
        default: I(() => [
          i.options.length <= 0 ? (m(), R(u, { key: 0 }, {
            default: I(() => [
              i.$slots.empty && i.$slots.empty().length ? te(i.$slots, "empty", { key: 0 }) : (m(), U("div", Dg, "暂无内容"))
            ]),
            _: 3
          })) : j("", !0),
          (m(!0), U(ve, null, be(i.options, (d, c) => (m(), R(u, {
            key: c,
            value: d.value,
            label: d.label
          }, {
            default: I(() => [
              Ce(se(d.label), 1)
            ]),
            _: 2
          }, 1032, ["value", "label"]))), 128))
        ]),
        _: 3
      }, 8, ["modelValue", "placeholder", "clearable", "disabled", "keys", "loading", "filterable"]);
    };
  }
}), Sv = ne({
  __name: "MyImage",
  props: {
    src: { default: "" },
    lazy: { type: Boolean, default: !0 },
    alt: { default: "" },
    fit: { default: "cover" },
    shape: { default: "square" }
  },
  setup(e) {
    return (t, n) => {
      const a = Li;
      return m(), R(a, {
        src: t.src,
        lazy: t.lazy,
        alt: t.alt,
        fit: t.fit,
        shape: t.shape
      }, null, 8, ["src", "lazy", "alt", "fit", "shape"]);
    };
  }
});
export {
  pv as MyApprovalFlow,
  Yg as MyBaiduMap,
  Jg as MyBaiduSingleCustomOverlay,
  Xg as MyBaiduSingleMarker,
  Cs as MyBreadCrumb,
  zo as MyButton,
  wt as MyCard,
  _v as MyCheckbox,
  wv as MyCheckboxGroup,
  cv as MyContent,
  Ev as MyDatePicker,
  Og as MyDialog,
  Cv as MyDrawer,
  yv as MyDropdown,
  Pg as MyForm,
  jg as MyGeoJSONLayer,
  Gg as MyGridList,
  sv as MyHeadMenu,
  En as MyIcon,
  Ug as MyIconSelect,
  dv as MyIfcDataTransform,
  Sv as MyImage,
  Kg as MyImageViewer,
  fr as MyInput,
  gv as MyInputNumber,
  tv as MyLayout,
  nv as MyLegend,
  hv as MyLink,
  Tg as MyList,
  dr as MyLoading,
  uv as MyLogin,
  rv as MyMenu,
  Qg as MyModal,
  ov as MyModelDiffViewer,
  lv as MyModelOuctPropertyView,
  Zg as MyModelViewer,
  fv as MyNextFlow,
  av as MyObjDiffViewer,
  Wg as MyPassWordForm,
  vv as MyPopup,
  Ig as MyRadio,
  bv as MyRadioGroup,
  iv as MyRuleCheckResultExplorer,
  Nv as MySelect,
  Hg as MyStackList,
  ev as MySteps,
  kv as MySwitch,
  Lg as MyTab,
  nr as MyTable,
  zg as MyTableTree,
  qg as MyTransfer,
  sr as MyTree,
  Rg as MyTreeSelect,
  Er as MyUpload,
  mv as Mytag
};
